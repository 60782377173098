export const Soccer = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.3334 16C29.3334 23.3638 23.3638 29.3333 16 29.3333C8.6362 29.3333 2.66669 23.3638 2.66669 16C2.66669 8.63617 8.6362 2.66666 16 2.66666C23.3638 2.66666 29.3334 8.63617 29.3334 16ZM26.7527 16L26.7525 15.9848L25.3513 17.2075L21.9814 14.0623L22.866 9.52709L24.7103 9.69225C23.3722 7.85268 21.4843 6.46166 19.2958 5.75994L20.0298 7.47709L16 9.70967L11.9703 7.47714L12.7042 5.75999C10.5198 6.46042 8.63002 7.84977 7.28975 9.6923L9.14809 9.52709L10.0186 14.0623L6.64884 17.2075L5.24755 15.9848L5.24739 16C5.24739 18.3126 5.97303 20.5135 7.31577 22.3436L7.72997 20.5212L12.3073 21.0828L14.2589 25.267L12.6513 26.2233C14.8127 26.9288 17.183 26.9302 19.3487 26.2233L17.7411 25.267L19.6928 21.0828L24.2701 20.5212L24.6843 22.3436C26.0271 20.5135 26.7527 18.3126 26.7527 16ZM13.4139 19.7404L11.8067 14.8311L16 11.792L20.1934 14.8311L18.6008 19.7404H13.4139Z"
      fill="#FDFCEA"
    />
  </svg>
)
