export const CreditCard = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2 18.1115C2 19.0317 2.74653 19.7782 3.66667 19.7782H20.3333C21.2535 19.7782 22 19.0317 22 18.1115V12.0004H2V18.1115ZM8.66667 15.7504C8.66667 15.5213 8.85417 15.3338 9.08333 15.3338H13.8056C14.0347 15.3338 14.2222 15.5213 14.2222 15.7504V17.1393C14.2222 17.3685 14.0347 17.556 13.8056 17.556H9.08333C8.85417 17.556 8.66667 17.3685 8.66667 17.1393V15.7504ZM4.22222 15.7504C4.22222 15.5213 4.40972 15.3338 4.63889 15.3338H7.13889C7.36806 15.3338 7.55556 15.5213 7.55556 15.7504V17.1393C7.55556 17.3685 7.36806 17.556 7.13889 17.556H4.63889C4.40972 17.556 4.22222 17.3685 4.22222 17.1393V15.7504ZM22 5.88932V7.55599H2V5.88932C2 4.96918 2.74653 4.22266 3.66667 4.22266H20.3333C21.2535 4.22266 22 4.96918 22 5.88932Z"
      fill="#FAFAFA"
    />
  </svg>
)
