import React, { useEffect, useState, useRef } from "react"
import { initOnRamp } from "@coinbase/cbpay-js"
import styles from "./CoinbasePay.module.scss"
import { getTokenBalance } from "../../helpers/getTokenAccounts"
import { useSelector, useDispatch } from "react-redux"
import { setUSDCBalance } from "../../reducers/userSlice"

const CoinbasePay = (props) => {
  const [isReady, setIsReady] = useState(false)
  const onrampInstance = useRef()
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user?.publicKey) return
    if (window.coinbaseInit) return

    window.coinbaseInit = true

    //initOnRamp parameters
    const options: InitOnRampOptions = {
      appId: "20bc7a8e-0e6c-4796-86d2-86d6b163fdf9",
      target: "#cbpay-button-container",
      widgetParameters: {
        presetCryptoAmount: (props.cost / 100.0).toFixed(2),
        destinationWallets: [
          {
            address: user.publicKey,
            blockchains: ["solana"],
          },
        ],
      },
      onSuccess: () => {
        updateUsdcBalance()
      },
      onExit: () => {
        // handle navigation from dismiss / exit events due to errors
      },
      onEvent: (event) => {
        console.log("event", event)
      },
      experienceLoggedIn: "popup",
      experienceLoggedOut: "popup",
    }

    // instance.destroy() should be called before initOnramp if there is already an instance.
    if (onrampInstance.current) {
      window.coinbaseInit = false
      onrampInstance.current.destroy()
    }

    initOnRamp(options, (error, instance) => {
      if (instance) {
        onrampInstance.current = instance
        setIsReady(true)
      }
    })
  }, [user])

  useEffect(() => {
    return () => {
      if (onrampInstance.current) {
        window.coinbaseInit = false
        onrampInstance.current.destroy()
      }
    }
  }, [])

  const updateUsdcBalance = async () => {
    let usdc = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
    let balance = await getTokenBalance(user, usdc)
    dispatch(setUSDCBalance(parseInt(balance)))
  }

  const pollBalance = async () => {
    console.log("checking usdc bal")
    updateUsdcBalance()
    setTimeout(() => pollBalance(), 3000)
  }

  return (
    <div className={styles.actionsContainer}>
      <div className={styles.action} onClick={pollBalance}>
        <div className={styles.content}>
          <div>Add funds to continue</div>
          <p>Please add funds to your wallet or use Coinbase Pay</p>
        </div>
        <a id="cbpay-button-container" className={!isReady && styles.notReady}>
          <img
            className={styles.coinbasePayButton}
            src="https://cdn.hngr.co/cbpaybutton.png"
            alt="cpay"
          />
        </a>
      </div>
    </div>
  )
}

export default CoinbasePay
