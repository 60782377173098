export const Accessible = ({ className }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6895 8.5326L10.9673 9.09242C11.0441 9.24701 10.981 9.43453 10.8264 9.51127L9.54775 10.1536C9.2343 10.3092 8.8533 10.1769 8.70422 9.85953L7.47811 7.25H4.75C4.43897 7.25 4.17527 7.02129 4.13127 6.71338C3.46939 2.08023 3.50742 2.36805 3.5 2.25C3.5 1.53988 4.09215 0.968066 4.80961 1.00139C5.45943 1.03156 5.98242 1.56594 5.99955 2.21625C6.0165 2.85947 5.54736 3.39639 4.93299 3.48654L5.0242 4.12502H7.5625C7.7351 4.12502 7.875 4.26492 7.875 4.43752V5.06252C7.875 5.23512 7.7351 5.37502 7.5625 5.37502H5.20277L5.29207 6.00002H7.875C7.99366 6.00002 8.10987 6.0338 8.21004 6.09741C8.31021 6.16103 8.3902 6.25184 8.44066 6.35924L9.564 8.75L10.2706 8.39162C10.4252 8.31488 10.6127 8.37801 10.6895 8.5326ZM7.08121 7.875H6.60258C6.45047 8.93367 5.53758 9.75 4.4375 9.75C3.23131 9.75 2.25 8.76869 2.25 7.5625C2.25 6.75186 2.69324 6.04279 3.35008 5.66492C3.27758 5.15779 3.21641 4.73016 3.16477 4.36935C1.89758 4.87625 1 6.11646 1 7.5625C1 9.45795 2.54205 11 4.4375 11C5.84121 11 7.0509 10.1541 7.58418 8.94547L7.08121 7.875Z"
      fill="#F7F7F7"
    />
  </svg>
)
