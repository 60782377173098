export const Football = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.5538 5.97848C28.2957 4.99999 27.5269 4.23117 26.5484 3.96773C25.2634 3.62365 23.2581 3.21504 20.914 3.16666L29.3333 11.5968C29.2903 9.25805 28.8925 7.26343 28.5538 5.97848ZM3.44624 27.0215C3.70431 28 4.47312 28.7688 5.45162 29.0322C6.73656 29.3763 8.74194 29.7849 11.086 29.8333L2.66667 21.4032C2.70968 23.7419 3.10753 25.7365 3.44624 27.0215ZM2.89248 17.9731L14.5161 29.6183C21.6398 28.5753 27.8925 23.2365 29.1129 15.0269L17.4839 3.38171C10.3602 4.42472 4.10753 9.76343 2.89248 17.9731ZM19.9516 11.3279C20.1183 11.1613 20.3925 11.1613 20.5591 11.3279L21.1667 11.9355C21.3333 12.1021 21.3333 12.3763 21.1667 12.543L19.6452 14.0645L21.1667 15.586C21.3333 15.7527 21.3333 16.0269 21.1667 16.1935L20.5591 16.8011C20.3925 16.9677 20.1183 16.9677 19.9516 16.8011L18.4301 15.2796L17.2151 16.5L18.7366 18.0215C18.9032 18.1882 18.9032 18.4624 18.7366 18.629L18.129 19.2365C17.9624 19.4032 17.6882 19.4032 17.5215 19.2365L16 17.715L14.785 18.9301L16.3065 20.4516C16.4731 20.6183 16.4731 20.8925 16.3065 21.0591L15.6989 21.6667C15.5323 21.8333 15.2581 21.8333 15.0914 21.6667L13.5699 20.1452L12.0484 21.6667C11.8817 21.8333 11.6075 21.8333 11.4409 21.6667L10.8333 21.0591C10.6667 20.8925 10.6667 20.6183 10.8333 20.4516L12.3548 18.9301L10.8333 17.414C10.6667 17.2473 10.6667 16.9731 10.8333 16.8064L11.4409 16.1989C11.6075 16.0322 11.8817 16.0322 12.0484 16.1989L13.5699 17.7204L14.785 16.5054L13.2634 14.9839C13.0968 14.8172 13.0968 14.543 13.2634 14.3763L13.871 13.7688C14.0376 13.6021 14.3118 13.6021 14.4785 13.7688L16 15.2903L17.2151 14.0753L15.6936 12.5538C15.5269 12.3871 15.5269 12.1129 15.6936 11.9462L16.3011 11.3387C16.4677 11.172 16.7419 11.172 16.9086 11.3387L18.4301 12.8602L19.9516 11.3279Z"
      fill="#FDFCEA"
    />
  </svg>
)
