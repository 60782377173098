export const TriangleDown = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5 1H11.5L6 10.5" fill="#8B8B8B" />
  </svg>
)
