export const CheckCircleGreenTransparent = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31777 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31777 4.31777 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31777 14.6663 7.99967ZM7.22855 11.5296L12.1748 6.58338C12.3427 6.41543 12.3427 6.14309 12.1748 5.97513L11.5665 5.36688C11.3986 5.19889 11.1262 5.19889 10.9583 5.36688L6.92441 9.4007L5.0411 7.51739C4.87314 7.34943 4.6008 7.34943 4.43282 7.51739L3.82457 8.12564C3.65661 8.2936 3.65661 8.56594 3.82457 8.73389L6.62027 11.5296C6.78825 11.6976 7.06056 11.6976 7.22855 11.5296Z"
      fill="#19B963"
    />
  </svg>
)
