import { TriangleDown } from "../../css/icons"
import classNames from "classnames"
import styles from "./FilterButton.module.scss"

const FilterButton = ({ buttonCopy, className, disabled, icon, onClick }) => (
  <button
    onClick={onClick}
    className={classNames(
      styles.filterButton,
      disabled && styles.disabled,
      className
    )}
    disabled={disabled}
  >
    {icon && icon}
    <span>{buttonCopy}</span>
    <TriangleDown />
  </button>
)

export default FilterButton
