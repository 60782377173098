export const ArrowRight = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.5605 10.9441C22.1465 11.5301 22.1465 12.4816 21.5605 13.0676L14.0605 20.5676C13.4746 21.1535 12.523 21.1535 11.9371 20.5676C11.3512 19.9816 11.3512 19.0301 11.9371 18.4441L16.8824 13.5035H2.50117C1.67148 13.5035 1.00117 12.8332 1.00117 12.0035C1.00117 11.1738 1.67148 10.5035 2.50117 10.5035H16.8777L11.9418 5.56289C11.3559 4.97695 11.3559 4.02539 11.9418 3.43945C12.5277 2.85352 13.4793 2.85352 14.0652 3.43945L21.5652 10.9395L21.5605 10.9441Z"
      fill="#FAFAFA"
    />
  </svg>
)
