import { useSelector } from "react-redux"
import { useState, useEffect, useRef } from "react"
import { Button } from "../"
import { PublicKey, Connection, Transaction } from "@solana/web3.js"
import { Base64 } from "js-base64"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  CNFT,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"

import postToSlack from "../../postToSlack"
import SignInModal from "../../Authentication/SignInModal"
import DispenserSuccess from "../../Redeem/DispenserSuccess"
import { recordIterablePromoEntered } from "../../helpers/recordIterableUser"

const Dispenser = ({
  campaign,
  setInitialDispenser,
  fragment,
  buttonCopy,
  className,
  setParentLoading,
  onSuccess,
  onCloseCallback,
  claimKey,
}) => {
  const url = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/${fragment}`
  const user = useSelector((state) => state.user.user)
  const [showSigninModal, setShowSigninModal] = useState(false)
  const [showDispenserSuccess, setShowDispenserSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clickedDispenser, setClickedDispenser] = useState(false)
  const [mint, setMint] = useState(false)
  const [discount, setDiscount] = useState(null)
  const [maxDiscount, setMaxDiscount] = useState(null)
  const [discountType, setDiscountType] = useState(null)
  const [expiration, setExpiration] = useState(null)
  const [minimum, setMinimum] = useState(null)
  const { sendTransaction } = useWallet()
  const adapter = useWallet()
  const [autoRedeeming, setAutoRedeeming] = useState(false)

  const mintRef = useRef()
  const autoRedeemingRef = useRef()

  useEffect(() => {}, [])

  useEffect(() => {
    mintRef.current = mint
  }, [mint])

  useEffect(() => {
    autoRedeemingRef.current = mint
  }, [autoRedeeming])

  useEffect(() => {
    setParentLoading(loading)
  }, [loading])

  useEffect(() => {
    if (
      user?.publicKey &&
      !autoRedeemingRef.current &&
      !loading &&
      clickedDispenser
    ) {
      setAutoRedeeming(true)
      dispenser()
    }
  }, [user])

  useEffect(() => {
    autoRedeemingRef.current = autoRedeeming
  }, [autoRedeeming])

  const getTxn = async () => {
    let params = {
      account: user.publicKey.toString(),
    }
    if (claimKey) {
      params["claimKey"] = claimKey
    }
    try {
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      })

      const connection = new Connection(process.env.REACT_APP_RPC) // eslint-disable-line no-unused-vars
      const data = await res.json()
      let mint = res.headers.get("X-DISPENSER-MINT")
      if (!mint) {
        mint = res.headers.get("x-dispenser-mint")
      }
      setMint(mint)
      const txEncoded = data.transaction
      const tx = Transaction.from(Base64.toUint8Array(txEncoded))
      return tx
    } catch (err) {
      return false
    }
    return false
  }

  const phoneDispenser = async () => {
    console.log("in phone dispenser")
    setLoading(true)
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/couponDispenser`
    let params = {
      wallet: user.publicKey.toString(),
    }
    if (claimKey) {
      params["claimKey"] = claimKey
    }

    let dispenserUrl = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/${fragment}`
    try {
      params["url"] = dispenserUrl
      let resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      })
      resp = await resp.json()
      if (resp.success) {
        let msg = `had success with ${fragment}`
        if (onSuccess) onSuccess()
        setShowDispenserSuccess(true)
        setMint(resp.mint)
        //loadNft(resp.mint)
        //recordKeycodeUsed(resp.mint)
        let discount = resp.metadata.discount
        let formattedDiscount
        if (resp.metadata.discount_type === "flat") {
          formattedDiscount = "$" + (discount / 100).toFixed(0)
        } else if (resp.metadata.discount_type == "percent") {
          formattedDiscount = discount * 100 + "% off"
        }
        setDiscount(formattedDiscount)
        setDiscountType(resp.metadata.discount_type)
        setExpiration(resp.metadata.expiration)
        if (resp.metadata.max_discount) {
          setMaxDiscount(resp.metadata.max_discount)
        }
        if (resp.metadata.minimum) {
          let formattedMinimum = "$" + (resp.metadata.minimum / 100).toFixed(0)
          setMinimum(formattedMinimum)
        }

        postToSlack(msg, "dispenser", user)
        setLoading(false)
        return
      } else {
        if (resp.error) {
          let msg = `Had a fail on ${window.FRAGMENT} while hitting express. ${resp.error}`
          postToSlack(msg, "dispenser", user)
        } else {
          let msg = `Had a fail on ${window.FRAGMENT} while hitting express, but no crash.`
          postToSlack(msg, "dispenser", user)
        }
      }
    } catch (err) {
      let msg = `Had an unknwon error on ${window.FRAGMENT}`
      postToSlack(msg, "dispenser", user)
      setLoading(false)
      console.log("Phone dispenser error", err)
    }
    setLoading(false)
    alert("Error")
  }

  const loadNft = async (mintAddress) => {
    let mint = new PublicKey(mintAddress)

    let address = PDA.token(
      mint,
      new PublicKey(user.publicKey.toString()) //oracle
    )

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    const operator = new Operator("mainnet-beta")

    const sleep = (time) =>
      new Promise((res) => setTimeout(res, time, "done sleeping"))

    let loadToken = async () => {
      try {
        try {
          let token = await TokenAccount.init(operator, address)
          return token
        } catch (err) {
          let cnft = await CNFT.init(operator, {
            address: mint,
            owner: user.publicKey,
          })
          token = {
            pk: cnft.address.toString(),
            mint: {
              address: cnft.address,
            },
            metadata: {
              _json: cnft.json,
              json: cnft.json,
            },
          }
          return token
        }
      } catch (err) {
        return false
      }
    }

    let token
    for (let i = 0; i < 20; i++) {
      token = await loadToken()
      if (token) break
      else await sleep(1000)
    }

    let discount = token.metadata._json.discount
    const formattedDiscount = "$" + (discount / 100).toFixed(0)
    const formattedMinimum =
      "$" + (token.metadata._json.minimum / 100).toFixed(0)
    setDiscount(formattedDiscount)
    setExpiration(token.metadata._json.expiration)
    setMinimum(formattedMinimum)

    recordIterablePromoEntered(user, {
      address: token?.mint?.address,
      description: token?.metadata?.description,
      discount:
        token?.metadata?.discountType === "flat"
          ? token?.metadata?.discount / 100
          : token?.metadata?.discount,
      discountType: token?.metadata?.discount_type,
      expiration: new Date(token?.metadata?.expiration),
      image: token?.metadata?.image,
      minimum: token?.metadata?.minimum / 100,
      minimumSpend: token?.metadata?.minimum_spend / 100,
      symbol: token?.metadata?.symbol,
      valid: true,
    })
  }

  const dispenser = async () => {
    if (user?.loginMethod === "phone") {
      await phoneDispenser()
      return
    }

    setLoading(true)
    let txn
    try {
      txn = await getTxn()
      if (!txn) {
        alert("Error")
        setLoading(false)
        return
      }
    } catch (err) {
      alert("Error")
      setLoading(false)
      return
    }

    const connection = new Connection(process.env.REACT_APP_RPC)

    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext()

    try {
      const signature = await sendTransaction(txn, connection, {
        minContextSlot,
      })
      await connection.confirmTransaction(
        {
          blockhash,
          lastValidBlockHeight,
          signature,
        },
        "confirmed"
      )
      setLoading(false)
      loadNft(mintRef.current)
      if (onSuccess) onSuccess()
      setShowDispenserSuccess(true)
      recordKeycodeUsed(mintRef.current)
      let msg = `had success with ${fragment}`
      postToSlack(msg, "dispenser", user)
    } catch (err) {
      let msg = `had fail with ${fragment} ${err}`
      postToSlack(msg, "dispenser", user, adapter)
      alert("Error")
      setLoading(false)
    }
  }

  const recordKeycodeUsed = async (_mint) => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/${fragment}/redeemed`
    let params = {
      mint: _mint,
      account: user.publicKey.toString(),
    }
    let resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    })
    resp = await resp.json()
    if (setInitialDispenser) {
      let url = `${process.env.REACT_APP_HNGR_API}/api/xp/set-initial-dispenser`
      let params = {
        wallet: user.publicKey.toString(),
        dispenser: fragment,
      }
      let resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      })
    }
  }

  return (
    <>
      {showSigninModal && (
        <SignInModal
          onClose={() => {
            setShowSigninModal(false)
          }}
        />
      )}

      {showDispenserSuccess && (
        <DispenserSuccess
          discount={discount}
          minimum={minimum}
          expiration={expiration}
          onClose={() => {
            if (onCloseCallback) {
              onCloseCallback()
            } else {
              let a = "pass"
            }
            setShowDispenserSuccess(false)
          }}
        />
      )}

      <Button
        className={className}
        onClick={() => {
          setClickedDispenser(true)
          if (!user?.publicKey) {
            if (campaign) {
              window.INITIAL_CAMPAIGN = campaign
            }
            setShowSigninModal(true)
          } else {
            dispenser()
          }
        }}
      >
        {loading ? "Loading...." : buttonCopy}
      </Button>
    </>
  )
}

export default Dispenser
