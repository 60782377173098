export const ArrowUpRight = ({ className, onClick }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick && onClick}
  >
    <path
      d="M1.86406 3.4746L1.86406 2.07317C1.86406 1.47977 2.34383 1 2.93407 1.00316L15.2029 1C15.7963 1 16.2761 1.47977 16.2729 2.07001V14.342C16.2729 14.9354 15.7931 15.4152 15.2029 15.412L13.8015 15.412C13.2018 15.412 12.7188 14.9165 12.7315 14.3167L12.9114 6.8898L3.83995 15.9612C3.42015 16.381 2.74469 16.381 2.32489 15.9612L1.31485 14.9512C0.895051 14.5314 0.895051 13.8559 1.31485 13.4361L10.3863 4.3647L2.95932 4.54461C2.35645 4.56039 1.8609 4.07747 1.86406 3.4746Z"
      fill="#FDFCEA"
    />
  </svg>
)
