import { useSelector } from "react-redux"
import { useState, useEffect } from "react"
import { Button, Input, Layout } from "../components"
import { Connection, Transaction } from "@solana/web3.js"
import { Base64 } from "js-base64"
import { useWallet } from "@solana/wallet-adapter-react"
import { CNFT, setConfig, Operator, TokenAccount, PDA } from "@captainxyz/solana-core"
import { PublicKey } from "@solana/web3.js"
import DispenserSuccess from "./DispenserSuccess"
import RedeemLoading from "./RedeemLoading"
import SignInModal from "../Authentication/SignInModal"
import styles from "./Redeem.module.scss"
import classNames from "classnames"
import postToSlack from "../postToSlack"

const Redeem = () => {
  const useOneOffCopy = window.location.pathname.includes("decrypt")
  const user = useSelector((state) => state.user.user)
  const [showDispenserSuccess, setShowDispenserSuccess] = useState(false)
  const [showSigninModal, setShowSigninModal] = useState(false)
  const [keycode, setKeycode] = useState(null)
  const [discount, setDiscount] = useState(null)
  const [FRAGMENTS, setFragments] = useState([])
  const [loading, setLoading] = useState(null)
  const [expiration, setExpiration] = useState(null)
  const [minimum, setMinimum] = useState(null)
  const { sendTransaction } = useWallet()

  useEffect(() => {
    getFragments()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getFragments = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/promo-dispensers`
    let resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    resp = await resp.json()
    setFragments(resp.fragments)
  }

  useEffect(() => {
    if (user?.publicKey && !window.AUTO_REDEEM_CLICK && !loading && keycode) {
      window.AUTO_REDEEM_CLICK = true
      dispenser()
    }

    return () => {
      window.AUTO_REDEEM_CLICK = false
    }
  }, [user])

  const getTxn = async () => {
    let params = {
      account: user.publicKey.toString(),
      claimKey: keycode,
    }
    for (let i = 0; i < FRAGMENTS.length; i++) {
      let FRAGMENT = FRAGMENTS[i]
      let url = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/${FRAGMENT}`
      try {
        const res = await fetch(url, {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "Content-Type": "application/json",
          },
        })

        const connection = new Connection(process.env.REACT_APP_RPC) // eslint-disable-line no-unused-vars
        const data = await res.json()
        const txEncoded = data.transaction
        const tx = Transaction.from(Base64.toUint8Array(txEncoded))
        window.FRAGMENT = FRAGMENT
        return tx
      } catch (err) {
        console.log("error", err)
      }
    }
    return false
  }

  const recordKeycodeUsed = async () => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/${window.FRAGMENT}/redeemed`
    let params = {
      claimKey: keycode,
      account: user.publicKey.toString(),
    }
    let resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    })
    resp = await resp.json()
    let mint = new PublicKey(resp.address)

    let address = PDA.token(
      mint,
      new PublicKey(user.publicKey.toString()) //oracle
    )

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    const operator = new Operator("mainnet-beta")

    const sleep = (time) =>
      new Promise((res) => setTimeout(res, time, "done sleeping"))

    let loadToken = async () => {
      try {
        try {
          let token = await TokenAccount.init(operator, address)
          return token
        } catch (err) {
          let cnft = await CNFT.init(operator, {
            address: mint,
            owner: user.publicKey,
          })
          token = {
            pk: cnft.address.toString(),
            mint: {
              address: cnft.address,
            },
            metadata: {
              _json: cnft.json,
              json: cnft.json,
            },
          }
          return token
        }
      } catch (err) {
        return false
      }
    }

    let token
    for (let i = 0; i < 20; i++) {
      token = await loadToken()
      if (token) break
      else await sleep(1000)
    }

    let discount = token.metadata._json.discount
    const formattedDiscount = "$" + (discount / 100).toFixed(0)
    const formattedMinimum = "$" + (token.metadata._json.minimum / 100).toFixed(0)
    setDiscount(formattedDiscount)
    setExpiration(token.metadata._json.expiration)
    setMinimum(formattedMinimum)
  }

  const phoneDispenser = async () => {
    setLoading(true)
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/couponDispenser`
    let params = {
      wallet: user.publicKey.toString(),
      claimKey: keycode,
    }

    for (let i = 0; i < FRAGMENTS.length; i++) {
      let FRAGMENT = FRAGMENTS[i]
      let dispenserUrl = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/${FRAGMENT}`
      try {
        params["url"] = dispenserUrl
        let resp = await fetch(url, {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "Content-Type": "application/json",
          },
        })
        resp = await resp.json()
        if (resp.success) {
          let msg = `had success with keycode kc:${keycode} on ${FRAGMENT}`
          postToSlack(msg, "dispenser", user)
          setLoading(false)
          setShowDispenserSuccess(true)
          window.FRAGMENT = FRAGMENT
          // if its fake
          if(resp.metadata) {
            let discount = resp.metadata.discount
            const formattedDiscount = "$" + (discount / 100).toFixed(0)
            const formattedMinimum = "$" + (resp.metadata.minimum / 100).toFixed(0)
            setDiscount(formattedDiscount)
            setExpiration(resp.metadata.expiration)
            setMinimum(formattedMinimum)
          } else {
            recordKeycodeUsed()
          }
          return
        }
      } catch (err) {
        console.log("Phone dispenser error", err)
      }
    }
    setLoading(false)
    let msg = `Had a fail or invalid keycode  with keycode kc:${keycode} on ${window.FRAGMENT}`
    postToSlack(msg, "dispenser", user)
    alert("Invalid keycode.")
  }

  const dispenser = async () => {
    let msg = `is trying kc:${keycode}`
    postToSlack(msg, "dispenser", user)

    if (user?.loginMethod === "phone" ) {
      return phoneDispenser()
    }

    setLoading(true)
    let txn
    try {
      txn = await getTxn()
      if (!txn) {
        alert("Invalid keycode")
        setLoading(false)
        return
      }
    } catch (err) {
      alert("Invalid keycode")
      setLoading(false)
      return
    }

    const connection = new Connection(process.env.REACT_APP_RPC)

    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext()

    try {
      const signature = await sendTransaction(txn, connection, {
        minContextSlot,
      })
      let result = await connection.confirmTransaction(
        {
          blockhash,
          lastValidBlockHeight,
          signature,
        },
        "confirmed"
      )
      setLoading(false)
      setShowDispenserSuccess(true)
      recordKeycodeUsed()
      msg = `had success with kc:${keycode} on ${window.FRAGMENT}`
      postToSlack(msg, "dispenser", user)
    } catch (err) {
      setLoading(false)
      msg = `had an error or deny with kc:${keycode} on ${
        window.FRAGMENT
      } - ${err.toString()}`
      postToSlack(msg, "dispenser", user)
      console.log("Dispenser or wallet error", err)
    }
  }

  return (
    <Layout className={styles.redeemContainer}>
      {showDispenserSuccess && (
        <DispenserSuccess
          useOneOffCopy={useOneOffCopy}
          discount={discount}
          minimum={minimum}
          expiration={expiration}
          onClose={() => setShowDispenserSuccess(false)}
        />
      )}
      {showSigninModal && (
        <SignInModal
          onClose={() => {
            setShowSigninModal(false)
          }}
        />
      )}
      <div className={styles.redeemContent}>
        {loading && <RedeemLoading useOneOffCopy={useOneOffCopy} />}
        <div className={styles.redeemLeft}>
          <img
            src="https://hngr-icons.s3.amazonaws.com/ticketdex/xpdiscounts.png"
            alt="XPDiscounts"
            className={styles.discountLogo}
          />
          <img
            src="https://hngr-icons.s3.amazonaws.com/ticketdex/mobilelogo.png"
            alt="discounts"
            className={styles.mobileLogo}
          />
          <h1>Claim your reward</h1>
          <p>Say Goodbye to Fees - Welcome to Smarter, Fairer Ticketing</p>

          <div className={styles.redeemSteps}>
            <h2>Instructions</h2>
            <div className={styles.redeemStep}>
              <span className={styles.stepNum}>1</span>
              <div className={styles.stepInstructions}>
                <p className={styles.stepText}>Enter your code</p>
                <p className={styles.subtext}>below to claim your reward.</p>
              </div>
            </div>
            <div className={styles.redeemStep}>
              <span className={styles.stepNum}>2</span>
              <div className={styles.stepInstructions}>
                <p className={styles.stepText}>Sign In or Sign Up</p>
                <p className={styles.subtext}>
                  in order to link your reward to your account.
                </p>
              </div>
            </div>
            <div className={styles.redeemStep}>
              <span className={styles.stepNum}>3</span>
              <div
                className={classNames(
                  styles.stepInstructions,
                  styles.longSubtext
                )}
              >
                <p className={styles.stepText}>
                  Find Tickets & Apply Reward at Checkout
                </p>
                <p className={styles.subtext}>
                  Visit xp.xyz and find tickets to your next live event. If
                  you've successfully claimed your reward and linked it to your
                  account, you’ll see an option to apply it at checkout.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Enter your code"
              value={keycode}
              onChange={(e) => setKeycode(e.target.value.toUpperCase())}
            />
            <Button
              variant="beige"
              onClick={() => {
                if (user) {
                  dispenser()
                } else {
                  setShowSigninModal(true)
                }
              }}
            >
              {loading
                ? "Retrieving your reward. This will take a moment."
                : "Redeem"}
            </Button>
          </div>
          <p>
            Forgot code? Check your inbox.{" "}
            <a
              href="https://cdn.hngr.co/xptc.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </p>
        </div>
        <div className={styles.redeemRight}>
          <img
            src="https://hngr-icons.s3.amazonaws.com/ticketdex/artistsredeem.png"
            alt="events"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Redeem
