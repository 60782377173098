export const Warning = ({ className }) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M29.0332 25.0194C29.8877 26.5006 28.8151 28.3521 27.1083 28.3521H4.89136C3.18132 28.3521 2.11363 26.4977 2.9665 25.0194L14.0751 5.75885C14.9301 4.27696 17.0714 4.27964 17.9248 5.75885L29.0332 25.0194ZM16 21.0373C14.8238 21.0373 13.8703 21.9908 13.8703 23.167C13.8703 24.3431 14.8238 25.2966 16 25.2966C17.1761 25.2966 18.1296 24.3431 18.1296 23.167C18.1296 21.9908 17.1761 21.0373 16 21.0373ZM13.9781 13.3824L14.3215 19.6787C14.3376 19.9733 14.5812 20.204 14.8762 20.204H17.1237C17.4188 20.204 17.6624 19.9733 17.6784 19.6787L18.0219 13.3824C18.0392 13.0642 17.7858 12.7966 17.4671 12.7966H14.5327C14.214 12.7966 13.9607 13.0642 13.9781 13.3824Z"
      fill="#FF9900"
    />
  </svg>
)
