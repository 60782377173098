import { Identity } from "@captainxyz/solana-core";
import * as secp256k1 from "@noble/secp256k1";

import { encodeHex, decodeHex } from "./codec";

export function getSeed(): Uint8Array {
  return crypto.getRandomValues(new Uint8Array(12));
}

export async function getPrivateKey(
  seed: Uint8Array,
  identity: Identity
): Promise<Uint8Array> {
  const signature = await identity.sign(
    "Sign the message to derive key pair to manage the secret >>>" +
      encodeHex(seed)
  );
  return secp256k1.utils.hashToPrivateKey(signature);
}

export async function getPublicKey(
  privateKey: Uint8Array
): Promise<Uint8Array> {
  return secp256k1.getPublicKey(privateKey, false);
}

export async function encryptPrivate(
  privateKey: Uint8Array,
  message: string | Uint8Array
): Promise<Uint8Array> {
  return await encrypt(privateKey, message);
}

export async function decryptPrivate(
  privateKey: Uint8Array,
  message: string | Uint8Array
): Promise<Uint8Array> {
  try {
    return await decrypt(privateKey, message);
  } catch {
    throw new Error("Unable to decrypt message");
  }
}

export async function encryptShared(
  privateKeyA: Uint8Array,
  publicKeyB: Uint8Array,
  message: string | Uint8Array
): Promise<Uint8Array> {
  const sharedKey = await getSharedKey(privateKeyA, publicKeyB);
  return await encrypt(sharedKey, message);
}

export async function decryptShared(
  privateKeyA: Uint8Array,
  publicKeyB: Uint8Array,
  message: string | Uint8Array
): Promise<Uint8Array> {
  const sharedKey = await getSharedKey(privateKeyA, publicKeyB);
  try {
    return await decrypt(sharedKey, message);
  } catch {
    throw new Error("Unable to decrypt message");
  }
}

async function getSharedKey(
  privateKeyA: Uint8Array,
  publicKeyB: Uint8Array
): Promise<Uint8Array> {
  const sharedKey = await crypto.subtle.importKey(
    "raw",
    secp256k1.getSharedSecret(privateKeyA, publicKeyB),
    "HKDF",
    false,
    ["deriveKey"]
  );
  const derivedKey = await crypto.subtle.deriveKey(
    {
      name: "HKDF",
      hash: "SHA-256",
      salt: new Uint8Array(0),
      info: new ArrayBuffer(0),
    },
    sharedKey,
    { name: "AES-GCM", length: 256 },
    true,
    ["encrypt", "decrypt"]
  );
  return new Uint8Array(await crypto.subtle.exportKey("raw", derivedKey));
}

async function encrypt(
  sharedKey: Uint8Array,
  plaintext: string | Uint8Array
): Promise<Uint8Array> {
  if (typeof plaintext == "string") plaintext = Buffer.from(plaintext, "utf-8");
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const iKey = await crypto.subtle.importKey(
    "raw",
    sharedKey,
    { name: "AES-GCM", length: 256 },
    true,
    ["encrypt"]
  );
  const cipher = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv },
    iKey,
    plaintext
  );
  const ciphertext = new Uint8Array(cipher);
  const encrypted = new Uint8Array(iv.length + ciphertext.byteLength);
  encrypted.set(iv, 0);
  encrypted.set(ciphertext, iv.length);
  return encrypted;
}

async function decrypt(
  sharedKey: Uint8Array,
  encoded: string | Uint8Array
): Promise<Uint8Array> {
  if (typeof encoded == "string") encoded = decodeHex(encoded);
  const iv = encoded.slice(0, 12);

  const ciphertextWithTag = encoded.slice(12);
  const iKey = await crypto.subtle.importKey(
    "raw",
    sharedKey,
    { name: "AES-GCM", length: 256 },
    true,
    ["decrypt"]
  );
  const plaintext = await crypto.subtle.decrypt(
    { name: "AES-GCM", iv },
    iKey,
    ciphertextWithTag
  );
  return new Uint8Array(plaintext);
}
