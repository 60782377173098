import styles from "./SegmentedFilter.module.scss"
import classNames from "classnames"

const SegmentedFilter = ({ fullWidth, options, onSelect, selected }) => (
  <div
    className={classNames(
      styles.filterContainer,
      fullWidth && styles.fullWidth
    )}
  >
    {options.map((option) => (
      <div
        className={classNames(
          styles.option,
          selected === option && styles.selected
        )}
        onClick={() => onSelect(option)}
      >
        {option}
      </div>
    ))}
  </div>
)

export default SegmentedFilter
