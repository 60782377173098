import styles from "./Redeem.module.scss"
import classNames from "classnames"
import { SolidCircle } from "../css/icons"
import { Modal } from "../components"

const RedeemLoading = ({ useOneOffCopy }) => {
  return (
    <Modal
      className={styles.loading}
      containerClass={styles.modalContainer}
      onClose={() => {}}
    >
      <div className={styles.loadingLeft}>
        <img
          src={
            "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Logo.png"
          }
          alt="logo"
          className={styles.loadingLogo}
        />
        <h1 className={styles.loadingH1}>
          {useOneOffCopy
            ? "RANDOMIZING YOUR PRIZE..."
            : "Your Reward Is On The Way"}
        </h1>
        <p>
          {useOneOffCopy
            ? "YOU’LL RECEIVE $25, $50, OR THE GRAND PRIZE OF $200! THIS MAY TAKE UP TO 60 SECONDS..."
            : "Great things take time. This may take up to 1 minute."}
        </p>
      </div>
      <img
        src={
          useOneOffCopy
            ? "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Tensor-Wheel-GIF-Image-long-blurred-compressed.gif"
            : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Gift+Token+Render+Fading.gif"
        }
        alt="loading gift"
        className={styles.loadingGift}
      />
      <div className={styles.progressContainer}>
        <div
          className={classNames(styles.progressDot, styles.progressDotActive)}
        >
          <SolidCircle />
        </div>
        <div
          className={classNames(styles.progressBar, styles.progressBarActive)}
        />
        <img
          src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
          alt="loading"
          className={styles.progressLoading}
        />
        <div className={styles.progressBar} />
        <div className={styles.progressDot}>
          <SolidCircle />
        </div>
      </div>
      <div className={styles.progressText}>
        <span>{useOneOffCopy ? "SPIN THE WHEEL" : "Enter Code"}</span>
        <span>Get Reward</span>
      </div>
    </Modal>
  )
}

export default RedeemLoading
