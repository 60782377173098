import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Basketball, Football, Hockey, Soccer } from "../css/icons"
import styles from "./CityCampaignHero.module.scss"
import { EventCard } from "../components"

const CityCampaignHero = ({ campaign }) => {
  const [featuredEvents, setFeaturedEvents] = useState([])
  const [eventSections, setEventSections] = useState({})
  const [eventSectionsAsList, setEventSectionsAsList] = useState([])

  const {
    city_background_image,
    city_background_mobile_image,
    city_campaign_logo_image,
    city_name,
    city_cbsa,
    city_icon,
    city_hero_event_copy,
    city_sports_copy,
    city_hide_sports_section,
    city_featured_sports_teams,
    city_secondary_sports_teams,
  } = campaign.json.city_campaign

  const eventsJson = campaign.json.events
  const featuredEventsJson = campaign.json.featured_events

  useEffect(() => {
    getFeaturedEvents()
    getEvents()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let list = []
    for (const [key, value] of Object.entries(eventSections)) {
      list.push(value)
    }
    setEventSectionsAsList(JSON.parse(JSON.stringify(list)))
  }, [eventSections]) // eslint-disable-line react-hooks/exhaustive-deps

  const getFeaturedEvents = async () => {
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(featuredEventsJson),
    })
    resp = await resp.json()
    setFeaturedEvents(resp.events)
  }

  const getEvents = async () => {
    let eventOrder = []
    for (let i = 0; i < eventsJson.length; i++) {
      getEventsHelper(eventsJson[i], i)
    }
  }

  const getEventsHelper = async (json, index) => {
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json),
    })
    resp = await resp.json()
    let section = {
      events: resp.events,
      json: json,
    }
    // this madness needs to be here so there is no race condition
    setEventSections((prevState) => {
      prevState[index] = section
      return JSON.parse(JSON.stringify(prevState))
    })
  }

  const generateLink = (json) => {
    let url = "/events?"
    for (const [key, value] of Object.entries(json)) {
      url += `${key}=${value}&`
    }
    url += "a=1"
    return url
  }

  const getSportIcon = (icon) => {
    switch (icon) {
      case "basketball":
        return <Basketball />
      case "football":
        return <Football />
      case "soccer":
        return <Soccer />
      case "hockey":
        return <Hockey />
      default:
        return null
    }
  }

  return (
    <div
      className={styles.cityHeroContainer}
      style={{
        backgroundImage: `url(${
          window.innerWidth > 960
            ? city_background_image
            : city_background_mobile_image
        })`,
      }}
    >
      <div className={styles.cityHero}>
        <div className={styles.cityHeroLeft}>
          <div className={styles.cityHeroLogoContainer}>
            {city_campaign_logo_image && (
              <img
                src={city_campaign_logo_image}
                alt={city_name}
                className={styles.cityLogoImage}
              />
            )}
          </div>

          <div className={styles.cityName}>
            {/* <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/experience.png"
              alt="Experience"
              className={styles.experience}
            /> */}
            <h1>
              {city_name}{" "}
              {city_icon && (
                <img
                  src={city_icon}
                  alt={city_name}
                  className={styles.cityIcon}
                />
              )}
            </h1>
          </div>
        </div>
        <div className={styles.cityHeroRight}>
          <Link to={generateLink(featuredEventsJson)}>
            <h2>
              {city_hero_event_copy}{" "}
              <img
                src="https://hngr-icons.s3.amazonaws.com/ticketdex/arrowupright.png"
                alt="Arrow "
                className={styles.arrowUpRight}
              />
            </h2>
          </Link>
          <div className={styles.cityHeroEventList}>
            {featuredEvents.map((event, i) => {
              if (i > 5) return null
              return (
                <Link
                  to={`/event/${event.event_id}`}
                  className={styles.heroCard}
                >
                  <img
                    src={event.image}
                    alt={event.title}
                    className={styles.heroCardImage}
                  />
                  <div className={styles.cardBg} />
                  <div className={styles.cityCardInfo}>
                    <span className={styles.date}>
                      {event.date_formatted.split(", ")[1].split("•")[0].trim()}
                      {/* ew */}
                    </span>
                    <span className={styles.title}>
                      {event.short_title || event.title}
                    </span>
                  </div>
                </Link>
              )
            })}
            <Link
              className={styles.heroMoreEvents}
              to={`/events?cbsa=${city_cbsa}`}
            >
              <span>See All {city_name} Events</span>
              <img
                src="https://hngr-icons.s3.amazonaws.com/ticketdex/arrowupright.png"
                alt="Arrow "
                className={styles.arrowUpRight}
              />
            </Link>
          </div>
        </div>
      </div>

      {!city_hide_sports_section && (
        <div className={styles.citySportsSection}>
          <Link to={`/events?eventType=sports&cbsa=${city_cbsa}`}>
            <h2>
              {city_sports_copy}{" "}
              <img
                src="https://hngr-icons.s3.amazonaws.com/ticketdex/arrowupright.png"
                alt="Arrow "
                className={styles.arrowUpRight}
              />
            </h2>
          </Link>
          <div className={styles.sportsRow}>
            {city_featured_sports_teams.map((team) => {
              return (
                <Link
                  to={`/artist/${team.artist_id}`}
                  className={styles.primarySport}
                >
                  <img src={team.team_image} alt={team.name} />
                </Link>
              )
            })}
            <div className={styles.otherSports}>
              {city_secondary_sports_teams.map((team) => {
                return (
                  <Link
                    className={styles.secondarySport}
                    to={`/artist/${team.artist_id}`}
                  >
                    {team.team_name}
                    {getSportIcon(team.team_icon)}
                  </Link>
                )
              })}
              <Link
                className={styles.secondarySport}
                to={`/events/cbsa/${city_cbsa}?eventType=sports&`}
              >
                Other Sports{" "}
                <img
                  src="https://hngr-icons.s3.amazonaws.com/ticketdex/arrowupright.png"
                  alt="Arrow "
                  className={styles.arrowUpRight}
                />
              </Link>
            </div>
          </div>
        </div>
      )}

      {eventSectionsAsList.map((section) => (
        <div className={styles.citySection} key={JSON.stringify(section.json)}>
          <Link to={generateLink(section.json)}>
            <h2>
              {section.json.title}
              <img
                src="https://hngr-icons.s3.amazonaws.com/ticketdex/arrowupright.png"
                alt="Arrow "
                className={styles.arrowUpRight}
              />
            </h2>
          </Link>
          <div className={styles.citySectionList}>
            {section.events?.map((event, i) => {
              if (i > 3) return null
              return (
                <EventCard
                  key={event.name}
                  className={styles.card4w}
                  event={event}
                />
              )
            })}
          </div>
        </div>
      ))}
    </div>
  )
}

export default CityCampaignHero
