import { TransactionInstruction, PublicKey, AccountMeta } from "@solana/web3.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types" // eslint-disable-line @typescript-eslint/no-unused-vars
import { PROGRAM_ID } from "../programId"

export interface UnverifyCreatorArgs {
  root: Array<number>
  dataHash: Array<number>
  creatorHash: Array<number>
  nonce: BN
  index: number
  message: types.MetadataArgsFields
}

export interface UnverifyCreatorAccounts {
  treeAuthority: PublicKey
  leafOwner: PublicKey
  leafDelegate: PublicKey
  merkleTree: PublicKey
  payer: PublicKey
  creator: PublicKey
  logWrapper: PublicKey
  compressionProgram: PublicKey
  systemProgram: PublicKey
}

export const layout = borsh.struct([
  borsh.array(borsh.u8(), 32, "root"),
  borsh.array(borsh.u8(), 32, "dataHash"),
  borsh.array(borsh.u8(), 32, "creatorHash"),
  borsh.u64("nonce"),
  borsh.u32("index"),
  types.MetadataArgs.layout("message"),
])

/** Unverifies a creator from a leaf node. */
export function unverifyCreator(
  args: UnverifyCreatorArgs,
  accounts: UnverifyCreatorAccounts,
  programId: PublicKey = PROGRAM_ID
) {
  const keys: Array<AccountMeta> = [
    { pubkey: accounts.treeAuthority, isSigner: false, isWritable: false },
    { pubkey: accounts.leafOwner, isSigner: false, isWritable: false },
    { pubkey: accounts.leafDelegate, isSigner: false, isWritable: false },
    { pubkey: accounts.merkleTree, isSigner: false, isWritable: true },
    { pubkey: accounts.payer, isSigner: true, isWritable: false },
    { pubkey: accounts.creator, isSigner: true, isWritable: false },
    { pubkey: accounts.logWrapper, isSigner: false, isWritable: false },
    { pubkey: accounts.compressionProgram, isSigner: false, isWritable: false },
    { pubkey: accounts.systemProgram, isSigner: false, isWritable: false },
  ]
  const identifier = Buffer.from([107, 178, 57, 39, 105, 115, 112, 152])
  const buffer = Buffer.alloc(1000)
  const len = layout.encode(
    {
      root: args.root,
      dataHash: args.dataHash,
      creatorHash: args.creatorHash,
      nonce: args.nonce,
      index: args.index,
      message: types.MetadataArgs.toEncodable(args.message),
    },
    buffer
  )
  const data = Buffer.concat([identifier, buffer]).slice(0, 8 + len)
  const ix = new TransactionInstruction({ keys, programId, data })
  return ix
}
