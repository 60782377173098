export const Minus = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0714 9.85742H3.92857C3.13973 9.85742 2.5 10.4972 2.5 11.286V12.7146C2.5 13.5034 3.13973 14.1431 3.92857 14.1431H21.0714C21.8603 14.1431 22.5 13.5034 22.5 12.7146V11.286C22.5 10.4972 21.8603 9.85742 21.0714 9.85742Z"
      fill="#FDFCEA"
    />
  </svg>
)
