import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { setSelectedEvent } from "../reducers/eventSlice"
import styles from "./ArtistPage.module.scss"

const EventListItem = ({ event, home, away, performer }) => {
  const dispatch = useDispatch()

  const isHome = () => {
    if (parseInt(event.performer_id) === performer.performer_id) {
      return true
    } else {
      return false
    }
  }
  const getPerformerName = () => {
    let performer
    if (isHome()) {
      performer =
        "vs " +
          event.performers.find(
            (performer) => performer.role === "supporting act"
          )?.name || event.title
    } else {
      performer =
        "at " +
          event.performers.find((performer) => performer.role === "headliner")
            ?.name || event.title
    }
    return performer
  }

  return (
    <Link
      className={styles.eventListItem}
      to={`/event/${event.event_id}`}
      onClick={() => dispatch(setSelectedEvent(event))}
    >
      <div className={styles.dateTag}>
        <div className={styles.month}>
          {event?.date_month} {event?.date_day}
        </div>
        <div className={styles.dateTime}>
          {event?.date_formatted.split(",")[0]}{" "}
          {event?.date_formatted.split(" ")[4]}{" "}
          {event?.date_formatted.split(" ")[5]}
        </div>
      </div>
      <div className={styles.eventInfo}>
        <div>
          <h3>
            {event?.event_type === "sport"
              ? getPerformerName()
              : `${event.venue_city}, ${event.venue_state}`}
          </h3>
          <div className={styles.venueInfo}>
            <p className={styles.venue}>
              {event.venue_name}
              {event?.event_type === "sport" &&
                `, ${event.venue_city}, ${event.venue_state}`}
            </p>
          </div>
        </div>
        <div className={styles.priceContainer}>
          <div className={styles.priceDescription}>
            <span className={styles.price}>
              From{" "}
              <span className={styles.amt}>
                ${Math.ceil(event.min_ticket_price / 100)}
              </span>
            </span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default EventListItem
