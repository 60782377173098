import { useNavigate } from "react-router-dom"
import styles from "./CompactEventCard.module.scss"
import { useEffect, useState } from "react"

const CompactEventCard = ({ event }) => {
  const [fullEvent, setFullEvent] = useState(event)
  const navigate = useNavigate()
  const [error, setError] = useState(false)

  // since we use this in multiple places, with multiple event formats
  useEffect(() => {
    if (!event?.title) {
      getEvent()
    }
  }, [event]) // eslint-disable-line react-hooks/exhaustive-deps

  const getEvent = async () => {
    try {
      const endpoint = "/api/stagehand/event"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`
      let headers = {
        "Content-Type": "application/json",
      }
      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ event_id: event.event_id }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      if (response.error) {
        setError(true)
        return
      } else {
        setFullEvent(response)
      }
    } catch (err) {
      console.error(err)
      setError(true)
    }
  }

  if (error) {
    return null
  }

  return (
    <div
      className={styles.compactEventCard}
      onClick={() => navigate(`/event/${fullEvent.event_id}`)}
    >
      <div className={styles.compactEventTitle}>
        <img
          src={
            fullEvent?.image
              ? fullEvent.image
              : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
          }
          alt={fullEvent?.title}
        />
        <div className={styles.compactLeftContent}>
          <h3>{fullEvent?.title}</h3>
          <p>
            <span>{fullEvent?.venue_name}</span> •{" "}
            <span>{fullEvent?.venue_city}</span> •{" "}
            <span>{fullEvent?.venue_state}</span>
          </p>
        </div>
      </div>
      <div className={styles.compactRightContent}>
        <div className={styles.compactEventDate}>
          <h3>
            {fullEvent?.date_month} {fullEvent?.date_formatted?.split(" ")[2]}
          </h3>
          <p>
            {new Date(fullEvent?.date_local?.split(",")[0]).toLocaleDateString(
              "en-US",
              {
                weekday: "short",
              }
            )}{" "}
            • {fullEvent?.date_formatted?.split(" • ")[1]}
          </p>
        </div>
        <div className={styles.compactPrice}>
          <span>from</span>
          <p className={styles.priceDescription}>
            {(fullEvent?.min_ticket_price / 100).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CompactEventCard
