export const Plus = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0714 9.85714H14.6429V3.42857C14.6429 2.63973 14.0031 2 13.2143 2H11.7857C10.9969 2 10.3571 2.63973 10.3571 3.42857V9.85714H3.92857C3.13973 9.85714 2.5 10.4969 2.5 11.2857V12.7143C2.5 13.5031 3.13973 14.1429 3.92857 14.1429H10.3571V20.5714C10.3571 21.3603 10.9969 22 11.7857 22H13.2143C14.0031 22 14.6429 21.3603 14.6429 20.5714V14.1429H21.0714C21.8603 14.1429 22.5 13.5031 22.5 12.7143V11.2857C22.5 10.4969 21.8603 9.85714 21.0714 9.85714Z"
      fill="#FDFCEA"
    />
  </svg>
)
