import React, { useRef } from "react"
import classNames from "classnames"
import { useState } from "react"
import DatePicker from "react-datepicker"
import Button from "../Button/Button"
import "react-datepicker/dist/react-datepicker.css"
import styles from "./DatePicker.module.scss"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"

const CustomDatePicker = ({
  className,
  containerStartDate,
  containerEndDate,
  onClose,
  setDates,
}) => {
  const [startDate, setStartDate] = useState(containerStartDate || null)
  const [endDate, setEndDate] = useState(containerEndDate || null)

  const ref = useRef()

  useDetectClickOutside(ref, () => {
    onClose()
  })

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleClose = (start, end) => {
    if (start || end) {
      setDates(start, end)
    }
    onClose()
  }

  const showDate = () => {
    if (startDate && endDate) {
      return `${startDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      })} - ${endDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      })}`
    } else if (startDate) {
      return startDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      })
    } else {
      return "Choose Date"
    }
  }
  return (
    <div className={classNames(styles.dateContainer, className)} ref={ref}>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        popperPlacement="bottom-start"
        selectsRange
        filterDate={(date) => date > Date.now()}
        inline
        calendarClassName={styles.calendar}
      />
      <div className={styles.buttonContainer}>
        <Button
          fullWidth
          disabled={!startDate && !endDate}
          onClick={() => handleClose(startDate, endDate)}
        >
          {showDate()}
        </Button>
      </div>
    </div>
  )
}

export default CustomDatePicker
