export const Location = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.74062 15.6C10.3437 13.5938 14 8.73125 14 6C14 2.6875 11.3125 0 8 0C4.6875 0 2 2.6875 2 6C2 8.73125 5.65625 13.5938 7.25938 15.6C7.64375 16.0781 8.35625 16.0781 8.74062 15.6ZM8 8C6.89688 8 6 7.10312 6 6C6 4.89688 6.89688 4 8 4C9.10312 4 10 4.89688 10 6C10 7.10312 9.10312 8 8 8Z"
      fill="#FAFAFA"
    />
  </svg>
)
