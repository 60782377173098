export const PersonPlus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3359 7.0013H13.0026V5.66797C13.0026 5.48464 12.8526 5.33464 12.6693 5.33464H12.0026C11.8193 5.33464 11.6693 5.48464 11.6693 5.66797V7.0013H10.3359C10.1526 7.0013 10.0026 7.1513 10.0026 7.33464V8.0013C10.0026 8.18464 10.1526 8.33464 10.3359 8.33464H11.6693V9.66797C11.6693 9.8513 11.8193 10.0013 12.0026 10.0013H12.6693C12.8526 10.0013 13.0026 9.8513 13.0026 9.66797V8.33464H14.3359C14.5193 8.33464 14.6693 8.18464 14.6693 8.0013V7.33464C14.6693 7.1513 14.5193 7.0013 14.3359 7.0013ZM6.0026 8.0013C7.47552 8.0013 8.66927 6.80755 8.66927 5.33464C8.66927 3.86172 7.47552 2.66797 6.0026 2.66797C4.52969 2.66797 3.33594 3.86172 3.33594 5.33464C3.33594 6.80755 4.52969 8.0013 6.0026 8.0013ZM7.86927 8.66797H7.52135C7.05885 8.88047 6.54427 9.0013 6.0026 9.0013C5.46094 9.0013 4.94844 8.88047 4.48385 8.66797H4.13594C2.5901 8.66797 1.33594 9.92213 1.33594 11.468V12.3346C1.33594 12.8867 1.78385 13.3346 2.33594 13.3346H9.66927C10.2214 13.3346 10.6693 12.8867 10.6693 12.3346V11.468C10.6693 9.92213 9.4151 8.66797 7.86927 8.66797Z"
      fill="#85D5FF"
    />
  </svg>
)
