import { useRef } from "react"
import classNames from "classnames"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"
import styles from "./Modal.module.scss"

const Modal = ({ children, className, containerClass, onClose, width }) => {
  const ref = useRef()

  useDetectClickOutside(ref, () => {
    onClose()
  })

  return (
    <div
      className={classNames(
        styles.modalContainer,
        containerClass && containerClass
      )}
    >
      <div
        className={classNames(
          styles.modal,
          width && styles[width],
          className && className
        )}
        ref={ref}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal
