import { TransactionInstruction, PublicKey, AccountMeta } from "@solana/web3.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types" // eslint-disable-line @typescript-eslint/no-unused-vars
import { PROGRAM_ID } from "../programId"

export interface MintToCollectionV1Args {
  metadataArgs: types.MetadataArgsFields
}

export interface MintToCollectionV1Accounts {
  treeAuthority: PublicKey
  leafOwner: PublicKey
  leafDelegate: PublicKey
  merkleTree: PublicKey
  payer: PublicKey
  treeDelegate: PublicKey
  collectionAuthority: PublicKey
  /**
   * If there is no collecton authority record PDA then
   * this must be the Bubblegum program address.
   */
  collectionAuthorityRecordPda: PublicKey
  collectionMint: PublicKey
  collectionMetadata: PublicKey
  editionAccount: PublicKey
  bubblegumSigner: PublicKey
  logWrapper: PublicKey
  compressionProgram: PublicKey
  tokenMetadataProgram: PublicKey
  systemProgram: PublicKey
}

export const layout = borsh.struct([types.MetadataArgs.layout("metadataArgs")])

/** Mints a new asset and adds it to a collection. */
export function mintToCollectionV1(
  args: MintToCollectionV1Args,
  accounts: MintToCollectionV1Accounts,
  programId: PublicKey = PROGRAM_ID
) {
  const keys: Array<AccountMeta> = [
    { pubkey: accounts.treeAuthority, isSigner: false, isWritable: true },
    { pubkey: accounts.leafOwner, isSigner: false, isWritable: false },
    { pubkey: accounts.leafDelegate, isSigner: false, isWritable: false },
    { pubkey: accounts.merkleTree, isSigner: false, isWritable: true },
    { pubkey: accounts.payer, isSigner: true, isWritable: false },
    { pubkey: accounts.treeDelegate, isSigner: true, isWritable: false },
    { pubkey: accounts.collectionAuthority, isSigner: true, isWritable: false },
    {
      pubkey: accounts.collectionAuthorityRecordPda,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: accounts.collectionMint, isSigner: false, isWritable: false },
    { pubkey: accounts.collectionMetadata, isSigner: false, isWritable: true },
    { pubkey: accounts.editionAccount, isSigner: false, isWritable: false },
    { pubkey: accounts.bubblegumSigner, isSigner: false, isWritable: false },
    { pubkey: accounts.logWrapper, isSigner: false, isWritable: false },
    { pubkey: accounts.compressionProgram, isSigner: false, isWritable: false },
    {
      pubkey: accounts.tokenMetadataProgram,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: accounts.systemProgram, isSigner: false, isWritable: false },
  ]
  const identifier = Buffer.from([153, 18, 178, 47, 197, 158, 86, 15])
  const buffer = Buffer.alloc(1000)
  const len = layout.encode(
    {
      metadataArgs: types.MetadataArgs.toEncodable(args.metadataArgs),
    },
    buffer
  )
  const data = Buffer.concat([identifier, buffer]).slice(0, 8 + len)
  const ix = new TransactionInstruction({ keys, programId, data })
  return ix
}
