import { Button, Modal } from "../components"
import { useState } from "react"
import styles from "./DispenserSuccess.module.scss"
import { AngleRight, Clock, X } from "../css/icons"
import { useNavigate } from "react-router-dom"
import ExtendModal from "./ExtendModal"
import ExtendSuccess from "./ExtendSuccess"
import classNames from "classnames"

const { formatCents } = require("../helpers/money")

const DispenserSuccess = ({ discount, discountType, maxDiscount, onClose, minimum, expiration }) => {
  // const expiration = "2024-12-31"
  // const minimum = "$0.01"
  // const discount = "$10"

  const [extending, setExtending] = useState(false)
  const [extendSuccess, setExtendSuccess] = useState(false)

  const navigate = useNavigate()

  if (extending) {
    return (
      <ExtendModal
        expiration={expiration}
        onClose={onClose}
        goBack={() => setExtending(false)}
        setExtendSuccess={setExtendSuccess}
        setExtending={setExtending}
      />
    )
  }

  if (extendSuccess) {
    return (
      <ExtendSuccess
        onClose={onClose}
        setExtendSuccess={setExtendSuccess}
        expiration={expiration}
        discount={discount}
        minimum={minimum}
      />
    )
  }

  return (
    <Modal
      className={styles.blackBackground}
      width="medium"
      onClose={() => onClose()}
      containerClass={styles.modalContainer}
    >
      <div className={styles.dispenserSuccessModal}>
        <X className={styles.close} onClick={() => onClose()} />
        <h1>You've claimed your prize</h1>
        <p>
         You can find it in the My Rewards section of your account. Remember to apply it at checkout and redeem it for some tickets!
        </p>
        <div className={styles.imageContainer}>
          <img
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Redemption+Image.png"
            alt="discount"
          />
          <div className={styles.info}>
            <div className={classNames(styles.discountAmount, discountType == 'percent' && styles.discountAmountPercent)}>{discount}</div>
            {minimum && (
              <div className={styles.minimum}>
                <span>{minimum} Minimum Spend</span>
              </div>
            )}
            {maxDiscount && (
              <div className={styles.maxDiscount}>
                <span>Max {formatCents(maxDiscount)} Savings</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.discountDetails}>
          {/* <div className={styles.extend} onClick={() => setExtending(true)}>
            <Clock className={styles.clock} />
            <div className={styles.extendContent}>
              <div className={styles.extendHeader}>
                Need more time to decide?
              </div>
              <div className={styles.extendSubheader}>
                Add your email & extend the expiration date by 30 days.
              </div>
            </div>
            <AngleRight className={styles.angleRight} />
          </div> */}
          {expiration && (
            <div className={styles.detail}>
              <span className={styles.expirationLabel}>Expiration</span>
              <span className={styles.expiration}>
                {new Date(expiration).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  timeZone: "UTC",
                })}
              </span>
            </div>
          )}
        </div>
        <div className={styles.buttonRow}>
          <Button fullWidth onClick={() => navigate("/events")}>
            Browse Events
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default DispenserSuccess
