import { store } from "../store"

const hashString = async (string) => {
  const msgBuffer = new TextEncoder().encode(string)
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")

  return hashHex
}

const getCookie = (cookieName) => {
  const cookies = document.cookie.split("; ")
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=")
    if (name === cookieName) {
      return decodeURIComponent(value)
    }
  }
  return null // Return null if the cookie is not found
}

const getIp = () => {
  const state = store.getState()
  return state.user.ip
}

export const trackFbPageView = async (data) => {
  try {
    const url = `https://graph.facebook.com/v21.0/1514233069351079/events?access_token=${process.env.REACT_APP_FACEBOOK_ACCESS_TOKEN}`

    let body = {
      data: [
        {
          event_name: "PageView",
          event_time: Math.floor(new Date().getTime() / 1000),
          action_source: "website",
          event_source_url: window.location.href,
          user_data: {
            em: [await hashString(data.email)],
            ph: [await hashString(data.phone)],
            fn: [await hashString(data.firstName)],
            ln: [await hashString(data.lastName)],
            ct: [await hashString(data.ipInferredCity)],
            client_user_agent: navigator.userAgent,
            client_ip_address: getIp(),
            fbc: getCookie("_fbc"),
          },
        },
      ],
    }

    await fetch(url, {
      method: "post",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
  } catch (error) {
    console.error(error)
  }
}

export const trackFbPurchase = async (userData, purchaseData, eventId) => {
  try {
    const url = `https://graph.facebook.com/v21.0/1514233069351079/events?access_token=${process.env.REACT_APP_FACEBOOK_ACCESS_TOKEN}`

    let body = {
      data: [
        {
          event_name: "Purchase",
          event_time: Math.floor(new Date().getTime() / 1000),
          action_source: "website",
          event_source_url: window.location.href,
          event_id: eventId,
          user_data: {
            em: [await hashString(userData.email)],
            ph: [await hashString(userData.phone)],
            fn: [await hashString(userData.firstName)],
            ln: [await hashString(userData.lastName)],
            ct: [await hashString(userData.ipInferredCity)],
            client_user_agent: navigator.userAgent,
            client_ip_address: getIp(),
            fbc: getCookie("_fbc"),
          },
          custom_data: {
            currency: "USD",
            value: purchaseData.value,
            crypto: purchaseData.currency === "USDC" && "USDC",
          },
        },
      ],
    }

    await fetch(url, {
      method: "post",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
  } catch (error) {
    console.error(error)
  }
}
