import { InlineInput } from "../index"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import styles from "./RedeemInputField.module.scss"
import DispenserSuccess from "../../Redeem/DispenserSuccess"
import RedeemLoading from "../../Redeem/RedeemLoading"
import SignInModal from "../../Authentication/SignInModal"
import EmailCapture from "../../Authentication/EmailCapture"
import postToSlack from "../../postToSlack"
import { PublicKey } from "@solana/web3.js"
import { Connection, Transaction } from "@solana/web3.js"
import { Base64 } from "js-base64"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  CNFT,
  setConfig,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"

import { recordIterablePromoEntered } from "../../helpers/recordIterableUser"

const { keycodeDispenser } = require("../../helpers/dispenser")

const RedeemInputField = ({ emailCapture }) => {
  const [keycode, setKeycode] = useState(null)
  const user = useSelector((state) => state.user.user)
  const [loading, setLoading] = useState(null)
  const [showDispenserSuccess, setShowDispenserSuccess] = useState(false)
  const [discount, setDiscount] = useState(null)
  const [maxDiscount, setMaxDiscount] = useState(null)
  const [discountType, setDiscountType] = useState(null)
  const [expiration, setExpiration] = useState(null)
  const [minimum, setMinimum] = useState(null)
  const [showSigninModal, setShowSigninModal] = useState(false)
  const [showEmailCapture, setShowEmailCapture] = useState(false)

  useEffect(() => {
    if (
      user?.publicKey &&
      (user?.email || user?.user?.email) &&
      !window.AUTO_REDEEM_CLICK &&
      !loading &&
      keycode
    ) {
      window.AUTO_REDEEM_CLICK = true
      dispenser()
    }

    return () => {
      window.AUTO_REDEEM_CLICK = false
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const dispenser = async () => {
    keycodeDispenser({
      user: user,
      setLoading: setLoading,
      keycode: keycode,
      onError: (err) => {
        alert(err)
        setLoading(false)
      },
      onSuccess: (resp) => {
        setShowDispenserSuccess(true)
        if (resp.metadata) {
          let discount = resp.metadata.discount
          let formattedDiscount
          if (resp.metadata.discount_type === "flat") {
            formattedDiscount = "$" + (discount / 100).toFixed(0)
          } else if (resp.metadata.discount_type == "percent") {
            formattedDiscount = discount * 100 + "% off"
          }
          setDiscount(formattedDiscount)
          setDiscountType(resp.metadata.discount_type)
          setExpiration(resp.metadata.expiration)
          if (resp.metadata.max_discount) {
            setMaxDiscount(resp.metadata.max_discount)
          }
          if (resp.metadata.minimum) {
            let formattedMinimum =
              "$" + (resp.metadata.minimum / 100).toFixed(0)
            setMinimum(formattedMinimum)
          }

          recordIterablePromoEntered(user, {
            address: resp?.mint?.address,
            description: resp?.metadata?.description,
            discount:
              resp?.metadata?.discountType === "flat"
                ? resp?.metadata?.discount / 100
                : resp?.metadata?.discount,
            discountType: resp?.metadata?.discount_type,
            expiration: new Date(resp?.metadata?.expiration),
            fragment_id: resp?.fragment,
            image: resp?.metadata?.image,
            minimum: resp?.metadata?.minimum / 100,
            minimumSpend: resp?.metadata?.minimum_spend / 100,
            promoCode: keycode,
            symbol: resp?.metadata?.symbol,
            valid: true,
          })
        }
      },
    })
  }

  return (
    <>
      {showDispenserSuccess && (
        <DispenserSuccess
          useOneOffCopy={false}
          discount={discount}
          maxDiscount={maxDiscount}
          discountType={discountType}
          minimum={minimum}
          expiration={expiration}
          onClose={() => setShowDispenserSuccess(false)}
        />
      )}

      {showEmailCapture && (
        <EmailCapture
          onClose={() => {
            setShowEmailCapture(false)
          }}
        />
      )}

      {showSigninModal && (
        <SignInModal
          onClose={() => {
            setShowSigninModal(false)
          }}
          shouldCaptureEmail={true}
        />
      )}
      {loading && <RedeemLoading useOneOffCopy={false} />}
      <InlineInput
        buttonText={loading ? "Retrieving your reward" : "Redeem"}
        className={styles.input}
        onChange={(value) => setKeycode(value.toUpperCase())}
        onSubmit={() => {
          if (user) {
            if (emailCapture && !user?.email) {
              setShowEmailCapture(true)
              return
            }
            dispenser()
          } else {
            window.CLICKED_REDEEM = true
            setShowSigninModal(true)
          }
        }}
        placeholder="Enter Your Code"
      />
    </>
  )
}

export default RedeemInputField
