import { PublicKey, Connection } from "@solana/web3.js"
import BN from "bn.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types" // eslint-disable-line @typescript-eslint/no-unused-vars
import { PROGRAM_ID } from "../programId"

export interface VoucherFields {
  leafSchema: types.LeafSchemaKind
  index: number
  merkleTree: PublicKey
}

export interface VoucherJSON {
  leafSchema: types.LeafSchemaJSON
  index: number
  merkleTree: string
}

export class Voucher {
  readonly leafSchema: types.LeafSchemaKind
  readonly index: number
  readonly merkleTree: PublicKey

  static readonly discriminator = Buffer.from([
    191, 204, 149, 234, 213, 165, 13, 65,
  ])

  static readonly layout = borsh.struct([
    types.LeafSchema.layout("leafSchema"),
    borsh.u32("index"),
    borsh.publicKey("merkleTree"),
  ])

  constructor(fields: VoucherFields) {
    this.leafSchema = fields.leafSchema
    this.index = fields.index
    this.merkleTree = fields.merkleTree
  }

  static async fetch(
    c: Connection,
    address: PublicKey,
    programId: PublicKey = PROGRAM_ID
  ): Promise<Voucher | null> {
    const info = await c.getAccountInfo(address)

    if (info === null) {
      return null
    }
    if (!info.owner.equals(programId)) {
      throw new Error("account doesn't belong to this program")
    }

    return this.decode(info.data)
  }

  static async fetchMultiple(
    c: Connection,
    addresses: PublicKey[],
    programId: PublicKey = PROGRAM_ID
  ): Promise<Array<Voucher | null>> {
    const infos = await c.getMultipleAccountsInfo(addresses)

    return infos.map((info) => {
      if (info === null) {
        return null
      }
      if (!info.owner.equals(programId)) {
        throw new Error("account doesn't belong to this program")
      }

      return this.decode(info.data)
    })
  }

  static decode(data: Buffer): Voucher {
    if (!data.slice(0, 8).equals(Voucher.discriminator)) {
      throw new Error("invalid account discriminator")
    }

    const dec = Voucher.layout.decode(data.slice(8))

    return new Voucher({
      leafSchema: types.LeafSchema.fromDecoded(dec.leafSchema),
      index: dec.index,
      merkleTree: dec.merkleTree,
    })
  }

  toJSON(): VoucherJSON {
    return {
      leafSchema: this.leafSchema.toJSON(),
      index: this.index,
      merkleTree: this.merkleTree.toString(),
    }
  }

  static fromJSON(obj: VoucherJSON): Voucher {
    return new Voucher({
      leafSchema: types.LeafSchema.fromJSON(obj.leafSchema),
      index: obj.index,
      merkleTree: new PublicKey(obj.merkleTree),
    })
  }
}
