import { PublicKey, AccountInfo } from "@solana/web3.js";
import * as SPLToken from "@solana/spl-token";

import { Operator, TransactionBlock, TransactionResult } from "../operator";
import { Amount, Currency } from "../currency";
import { Transactions } from "../transactions";
import { PDA } from "../pda";
import { BaseAccount } from "./base";
import { MetadataAccount } from "./metadata";
import { TokenAccount } from "./token";

/**
 * Not implemented yet.
 *
 * @group Accounts
 */
export class MintAccount extends BaseAccount {
  public readonly programId: PublicKey;
  public readonly supply: Amount;
  public readonly metadata: MetadataAccount;
  public readonly tokens: Map<string, TokenAccount>;

  private _account: SPLToken.Mint;

  public get mintAuthority(): PublicKey | null {
    return this._account.mintAuthority;
  }

  protected constructor(params: {
    operator: Operator;
    address: PublicKey;
    lamports: number | bigint;
    programId: PublicKey;
    supply: Amount;
    metadata: MetadataAccount;
    account: SPLToken.Mint;
  }) {
    super(params);
    this.programId = params.programId;
    this.supply = params.supply;
    this.metadata = params.metadata;
    this.tokens = new Map();

    this._account = params.account;

    this.onDeactivate.subscribe(async () => {
      const promises = [this.metadata.deactivate()];
      for (let token of this.tokens.values()) {
        promises.push(token.deactivate());
      }
      await Promise.all(promises);
      this.tokens.clear();
    });
  }

  static async init(
    operator: Operator,
    address: PublicKey,
    accountInfo?: AccountInfo<Buffer> | null,
    metadataInfo?: AccountInfo<Buffer> | null
  ): Promise<MintAccount> {
    const existent = await this._checkExistent<MintAccount>(
      operator,
      address,
      accountInfo
    );
    if (existent) return existent;

    accountInfo ??= await operator.connection.getAccountInfo(address);
    if (!accountInfo) throw new Error(`Account not found ${address}`);

    const lamports = accountInfo.lamports;
    const programId = accountInfo.owner;
    const account = SPLToken.unpackMint(address, accountInfo, programId);

    const metadata = await MetadataAccount.init(
      operator,
      account,
      metadataInfo
    );
    const supply = metadata.currency.amountFromQty(account.supply);

    const result = new this({
      operator,
      address,
      lamports,
      programId,
      supply,
      metadata,
      account,
    });
    return await result._init();
  }

  /**
   * Checks whether the account is an NFT mint.
   */
  public get isNFT(): boolean {
    return this._getCached("isNFT", () =>
      Boolean(
        this.supply.currency.decimals === 0 &&
          this.supply.qty === 1n &&
          (!this.mintAuthority || !PublicKey.isOnCurve(this.mintAuthority))
      )
    );
  }

  /**
   * Checks whether the {@link operator}
   * can perform {@link mint} action on the account.
   */
  public get canMint(): boolean {
    return this._getCached("canMint", () =>
      Boolean(
        this.mintAuthority &&
          this.operator.identity?.publicKey.equals(this.mintAuthority)
      )
    );
  }

  /**
   * Mint tokens to given recipients.
   */
  public async mint(
    ...instructions: MintInstruction[]
  ): Promise<TransactionResult> {
    if (!this.canMint) {
      throw new Error(`Operator has no mint authority on ${this}`);
    }
    const operatorPublicKey = this.operator.identity!.publicKey;
    const create: TransactionBlock[] = [];
    const mint: TransactionBlock[] = [];

    const addresses: PublicKey[] = [];
    for (let { to, value, qty } of instructions) {
      to ??= operatorPublicKey;

      const amount = new Amount(this.supply.currency, value, qty);
      if (amount.qty <= 0) throw new Error(`Cannot send ${amount}`);

      const address = PDA.token(this.address, to, this.programId);
      addresses.push(address);
      mint.push(
        Transactions.mintTokens({
          mint: this.address,
          dstToken: address,
          mintAuthority: this.mintAuthority as PublicKey,
          qty: amount.qty,
          programId: this.programId,
        })
      );
    }

    let accountsInfo = await this.operator.connection.getMultipleAccountsInfo(
      addresses
    );
    addresses.forEach((address, index) => {
      if (!accountsInfo[index]) {
        create.push(
          Transactions.createTokenAccount({
            payer: operatorPublicKey,
            owner: instructions[index].to,
            address: address,
            mint: this.address,
            programId: this.programId,
          })
        );
      }
    });

    return await this.operator.execute(...create, ...mint);
  }

  /**
   * @inheritDoc
   */
  protected async _refresh(accountInfo: AccountInfo<Buffer>): Promise<void> {
    super._refresh(accountInfo);
    if (!accountInfo.data.length) return;

    const account = SPLToken.unpackMint(this.address, accountInfo);
    this.supply.qty = account.supply;
  }
}

/**
 * @group Accounts
 */
export interface MintInstruction {
  /**
   * Recipient address.
   *
   * @default Operator's {@link Operator.publicKey}.
   */
  to?: PublicKey;

  /**
   * Amount to mint in base currency units.
   *
   * @see Currency.{@link Currency.decimals} for details.
   */
  value?: bigint | number | string;

  /**
   * Amount to mint in atomic currency units.
   *
   * @see Currency.{@link Currency.decimals} for details.
   */
  qty?: bigint | number | string;
}
