import { PublicKey, Connection } from "@solana/web3.js"
import BN from "bn.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types" // eslint-disable-line @typescript-eslint/no-unused-vars
import { PROGRAM_ID } from "../programId"

export interface TreeConfigFields {
  treeCreator: PublicKey
  treeDelegate: PublicKey
  totalMintCapacity: BN
  numMinted: BN
  isPublic: boolean
  isDecompressible: types.DecompressibleStateKind
}

export interface TreeConfigJSON {
  treeCreator: string
  treeDelegate: string
  totalMintCapacity: string
  numMinted: string
  isPublic: boolean
  isDecompressible: types.DecompressibleStateJSON
}

export class TreeConfig {
  readonly treeCreator: PublicKey
  readonly treeDelegate: PublicKey
  readonly totalMintCapacity: BN
  readonly numMinted: BN
  readonly isPublic: boolean
  readonly isDecompressible: types.DecompressibleStateKind

  static readonly discriminator = Buffer.from([
    122, 245, 175, 248, 171, 34, 0, 207,
  ])

  static readonly layout = borsh.struct([
    borsh.publicKey("treeCreator"),
    borsh.publicKey("treeDelegate"),
    borsh.u64("totalMintCapacity"),
    borsh.u64("numMinted"),
    borsh.bool("isPublic"),
    types.DecompressibleState.layout("isDecompressible"),
  ])

  constructor(fields: TreeConfigFields) {
    this.treeCreator = fields.treeCreator
    this.treeDelegate = fields.treeDelegate
    this.totalMintCapacity = fields.totalMintCapacity
    this.numMinted = fields.numMinted
    this.isPublic = fields.isPublic
    this.isDecompressible = fields.isDecompressible
  }

  static async fetch(
    c: Connection,
    address: PublicKey,
    programId: PublicKey = PROGRAM_ID
  ): Promise<TreeConfig | null> {
    const info = await c.getAccountInfo(address)

    if (info === null) {
      return null
    }
    if (!info.owner.equals(programId)) {
      throw new Error("account doesn't belong to this program")
    }

    return this.decode(info.data)
  }

  static async fetchMultiple(
    c: Connection,
    addresses: PublicKey[],
    programId: PublicKey = PROGRAM_ID
  ): Promise<Array<TreeConfig | null>> {
    const infos = await c.getMultipleAccountsInfo(addresses)

    return infos.map((info) => {
      if (info === null) {
        return null
      }
      if (!info.owner.equals(programId)) {
        throw new Error("account doesn't belong to this program")
      }

      return this.decode(info.data)
    })
  }

  static decode(data: Buffer): TreeConfig {
    if (!data.slice(0, 8).equals(TreeConfig.discriminator)) {
      throw new Error("invalid account discriminator")
    }

    const dec = TreeConfig.layout.decode(data.slice(8))

    return new TreeConfig({
      treeCreator: dec.treeCreator,
      treeDelegate: dec.treeDelegate,
      totalMintCapacity: dec.totalMintCapacity,
      numMinted: dec.numMinted,
      isPublic: dec.isPublic,
      isDecompressible: types.DecompressibleState.fromDecoded(
        dec.isDecompressible
      ),
    })
  }

  toJSON(): TreeConfigJSON {
    return {
      treeCreator: this.treeCreator.toString(),
      treeDelegate: this.treeDelegate.toString(),
      totalMintCapacity: this.totalMintCapacity.toString(),
      numMinted: this.numMinted.toString(),
      isPublic: this.isPublic,
      isDecompressible: this.isDecompressible.toJSON(),
    }
  }

  static fromJSON(obj: TreeConfigJSON): TreeConfig {
    return new TreeConfig({
      treeCreator: new PublicKey(obj.treeCreator),
      treeDelegate: new PublicKey(obj.treeDelegate),
      totalMintCapacity: new BN(obj.totalMintCapacity),
      numMinted: new BN(obj.numMinted),
      isPublic: obj.isPublic,
      isDecompressible: types.DecompressibleState.fromJSON(
        obj.isDecompressible
      ),
    })
  }
}
