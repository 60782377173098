export const Person = ({ className }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9 10.2656C11.0962 10.2656 12.7969 8.47046 12.7969 6.25781C12.7969 4.04517 11.0962 2.25 9 2.25C6.90381 2.25 5.20312 4.04517 5.20312 6.25781C5.20312 8.47046 6.90381 10.2656 9 10.2656ZM12.375 11.1562H10.9222C10.3368 11.4401 9.68555 11.6016 9 11.6016C8.31445 11.6016 7.66582 11.4401 7.07783 11.1562H5.625C3.76084 11.1562 2.25 12.751 2.25 14.7188V15.1641C2.25 15.9016 2.81689 16.5 3.51562 16.5H14.4844C15.1831 16.5 15.75 15.9016 15.75 15.1641V14.7188C15.75 12.751 14.2392 11.1562 12.375 11.1562Z"
      fill="#FAFAFA"
    />
  </svg>
)
