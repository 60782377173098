export const Phantom = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.75445 11.654C7.0009 12.8087 5.7382 14.2699 4.058 14.2699C3.26373 14.2699 2.5 13.9429 2.5 12.5226C2.5 8.9053 7.43877 3.30566 12.0211 3.30566C14.628 3.30566 15.6667 5.11431 15.6667 7.1682C15.6667 9.80451 13.9559 12.8189 12.2553 12.8189C11.7156 12.8189 11.4509 12.5226 11.4509 12.0525C11.4509 11.9299 11.4712 11.7971 11.512 11.654C10.9315 12.6452 9.81142 13.5648 8.76257 13.5648C7.99883 13.5648 7.61188 13.0846 7.61188 12.4102C7.61188 12.1649 7.6628 11.9095 7.75445 11.654ZM13.946 7.09678C13.946 7.69528 13.5929 7.99451 13.1979 7.99451C12.7969 7.99451 12.4498 7.69528 12.4498 7.09678C12.4498 6.4983 12.7969 6.19906 13.1979 6.19906C13.5929 6.19906 13.946 6.4983 13.946 7.09678ZM11.7017 7.0968C11.7017 7.69528 11.3486 7.99451 10.9536 7.99451C10.5526 7.99451 10.2055 7.69528 10.2055 7.0968C10.2055 6.4983 10.5526 6.19906 10.9536 6.19906C11.3486 6.19906 11.7017 6.4983 11.7017 7.0968Z"
      fill="#85D5FF"
    />
  </svg>
)
