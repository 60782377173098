import { MetadataAccount, JSONMetadata } from "@captainxyz/solana-core";
import {
  EncodedSealedSecret,
  EncodedUnsealedSecret,
  EncodedSealedSecretLegacy,
  EncodedUnsealedSecretLegacy,
} from "./secrets";

export interface TamperproofMetadataJSON extends JSONMetadata {
  secret:
    | EncodedSealedSecret
    | EncodedUnsealedSecret
    | EncodedSealedSecretLegacy
    | EncodedUnsealedSecretLegacy;
}

export interface TamperproofMetadata extends MetadataAccount {
  readonly json: TamperproofMetadataJSON;
}

export function isTamperproofMetadataJSON(
  json: any
): json is TamperproofMetadataJSON {
  return (
    json?.secret?.protocol === "tamperproof" ||
    json?.secret?.type === "unsealed" ||
    json?.secret?.type === "sealed"
  );
}

export function isTamperproofMetadata(
  account: any
): account is TamperproofMetadata {
  return (
    account instanceof MetadataAccount &&
    isTamperproofMetadataJSON(account.json)
  );
}
