import { useRef, useState } from "react"
import { Button, RangeSlider } from "../components"
import styles from "./PriceRangeModal.module.scss"
import { useDetectClickOutside } from "../hooks/detectClickOutside"
import classNames from "classnames"

// eventually this can be a modal but I'm lazy right now

const PriceRangeModal = ({
  className,
  onClose,
  maxAvailable,
  minAvailable,
  priceRange,
  reset,
  setPriceRange,
}) => {
  const [newMinVal, setNewMinVal] = useState(priceRange.minPrice)
  const [newMaxVal, setNewMaxVal] = useState(priceRange.maxPrice)
  const ref = useRef(null)
  // useDetectClickOutside(ref, onClose)

  return (
    <div className={classNames(styles.modal, className)} ref={ref}>
      <RangeSlider
        min={minAvailable}
        max={maxAvailable}
        setMinVal={setNewMinVal}
        setMaxVal={setNewMaxVal}
        initialMinValue={priceRange.minPrice}
        initialMaxValue={priceRange.maxPrice}
        onChange={({ min, max }) => {
          setNewMinVal(min)
          setNewMaxVal(max)
        }}
      />
      <div className={styles.buttonRow}>
        <span
          className={styles.reset}
          onClick={() => {
            reset()
            setNewMinVal(0)
            setNewMaxVal(maxAvailable)
            onClose() // closing it to reset the slider
          }}
        >
          Reset
        </span>
        <Button
          onClick={() => {
            setPriceRange(newMinVal, newMaxVal)
            onClose()
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}

export default PriceRangeModal
