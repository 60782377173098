import { PublicKey, PublicKeyInitData } from "@solana/web3.js";
import * as SPLToken from "@solana/spl-token";
import * as MPLAuctionHouse from "@metaplex-foundation/mpl-auction-house";
import { PDA as CorePDA } from "@captainxyz/solana-core";

export class PDA extends CorePDA {
  public static readonly PROGRAM_AS_SIGNER = CorePDA.find(
    MPLAuctionHouse.PROGRAM_ID,
    Buffer.from("auction_house", "utf8"),
    Buffer.from("signer", "utf8")
  );

  /**
   * Returns auction house account address.
   */
  public static auctionHouse(owner: PublicKey, treasuryMint?: PublicKey): PDA {
    treasuryMint ??= SPLToken.NATIVE_MINT;
    return this.find(
      MPLAuctionHouse.PROGRAM_ID,
      Buffer.from("auction_house", "utf8"),
      owner.toBuffer(),
      treasuryMint.toBuffer()
    );
  }

  /**
   * Returns auction house treasury account address.
   */
  public static treasury(auctionHouse: PublicKey): PDA {
    return this.find(
      MPLAuctionHouse.PROGRAM_ID,
      Buffer.from("auction_house", "utf8"),
      auctionHouse.toBuffer(),
      Buffer.from("treasury", "utf8")
    );
  }

  /**
   * Returns auction house fee account address.
   */
  public static feeAccount(auctionHouse: PublicKey): PDA {
    return this.find(
      MPLAuctionHouse.PROGRAM_ID,
      Buffer.from("auction_house", "utf8"),
      auctionHouse.toBuffer(),
      Buffer.from("fee_payer", "utf8")
    );
  }

  public static tradeState(
    auctionHouse: PublicKey,
    treasuryMint: PublicKey,
    wallet: PublicKey,
    tokenAddress: PublicKey,
    tokenMint: PublicKey,
    priceQty: bigint,
    tokenQty: bigint
  ): PDA {
    function bigIntToBuffer(n: bigint): Buffer {
      const result = Buffer.alloc(8);
      result.writeBigUInt64LE(n);
      return result;
    }
    return PDA.find(
      MPLAuctionHouse.PROGRAM_ID,
      Buffer.from("auction_house", "utf8"),
      wallet.toBuffer(),
      auctionHouse.toBuffer(),
      tokenAddress.toBuffer(),
      treasuryMint.toBuffer(),
      tokenMint.toBuffer(),
      bigIntToBuffer(priceQty),
      bigIntToBuffer(tokenQty)
    );
  }

  public static buyerEscrow(auctionHouse: PublicKey, buyer: PublicKey): PDA {
    return PDA.find(
      MPLAuctionHouse.PROGRAM_ID,
      Buffer.from("auction_house", "utf8"),
      auctionHouse.toBuffer(),
      buyer.toBuffer()
    );
  }
}
