import { Button } from "../components"
import { ArrowUpRight } from "../css/icons"
import classNames from "classnames"
import styles from "./QualityXperienceGuarantee.module.scss"

const QualityXperienceGuarantee = ({ className }) => (
  <div className={classNames(styles.xperienceSection, className)}>
    <h1>
      Quality XPerience<span>TM</span> Guaranteed.
    </h1>
    <p>
      Our commitment to customers isn't just fan service. If something goes
      wrong, we'll make it right. Period.
    </p>
    <div className={styles.xperienceButtonContainer}>
      <a
        href="https://xpsupport.zendesk.com/hc/en-us/articles/25552755543323-What-is-the-Quality-XPerience-Guarantee"
        target="_blank"
        rel="noreferrer"
      >
        <Button rightIcon={window.innerWidth > 960 && <ArrowUpRight />} rm>
          Our Policy
        </Button>
      </a>
      <a
        href="https://xpsupport.zendesk.com/hc/en-us/requests/new"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          variant="outline"
          rm
          rightIcon={window.innerWidth > 960 && <ArrowUpRight />}
        >
          Contact Support
        </Button>
      </a>
    </div>
  </div>
)

export default QualityXperienceGuarantee
