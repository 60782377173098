import styles from "./SendToAnotherAttendeeModal.module.scss"
import { Button, Input, Modal } from "../components"
import { AngleLeft, CloseCircle } from "../css/icons"
import { useState } from "react"

const SendToAnotherAttendeeModal = ({ close, setShowUnsealModal, unseal }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(true)

  const validateEmail = (input) => {
    if (/^\S+@\S+\.\S+$/.test(input)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }

  return (
    <Modal
      onClose={() => close()}
      width="medium"
      containerClass={styles.modalContainer}
    >
      <div className={styles.unsealModalContainer}>
        <div className={styles.modalNavigation}>
          <div className={styles.angleContainer}>
            <AngleLeft
              className={styles.angle}
              onClick={() => {
                close()
                setShowUnsealModal(true)
              }}
            />
          </div>
          <CloseCircle className={styles.close} onClick={() => close()} />
        </div>
        <div className={styles.unsealWarning}>Send to Another Attendee</div>

        <div className={styles.subtext}>
          Tickets will be sent to provided email. Upon receipt, tickets will be
          marked as 'open' on the blockchain.
        </div>
        <div className={styles.ticketFormRow}>
          <div className={styles.ticketInputFieldContainer}>
            <div className={styles.label}>First Name</div>
            <Input
              name="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className={styles.ticketInputFieldContainer}>
            <div className={styles.label}>Last Name</div>
            <Input
              name="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.ticketInputFieldContainer}>
          <div className={styles.label}>Email</div>
          <Input
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              validateEmail(e.target.value)
            }}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            variant="gray"
            onClick={() => {
              close()
              setShowUnsealModal(true)
            }}
          >
            Not Now
          </Button>
          <Button
            variant="blue"
            disabled={!validEmail}
            onClick={() => unseal(email)}
          >
            Send Tickets
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SendToAnotherAttendeeModal
