import classNames from "classnames"
import styles from "./CheckoutPage.module.scss"
import {
  ApplePayWhite,
  CoinbaseUSDC,
  CreditCard,
  RadioSelected,
  RadioUnselected,
  USDC,
} from "../css/icons"

const PaymentMethodBox = ({
  type,
  disabled,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
}) => (
  <div
    className={classNames(
      styles.paymentMethodBox,
      type === selectedPaymentMethod && styles.selectedPaymentMethodBorder,
      disabled && styles.paymentMethodBoxDisabled
    )}
    onClick={() => disabled !== true && setSelectedPaymentMethod(type)}
  >
    <div className={styles.paymentMethodBoxTop}>
     {type === "applepay" && (
        <>
          <div className={styles.paymentMethodBoxBottomText}>Apple Pay</div>
        </>
      )}

      {type === "usdc" && (
        <>
          <div className={styles.paymentMethodBoxBottomText}>usdc</div>
        </>
      )}
      {type === "cc" && (
        <>
          <div className={styles.paymentMethodBoxBottomText}>Credit Card</div>
        </>
      )}

      {type === "coinbase" && (
        <>
          <div className={styles.paymentMethodBoxBottomText}>
            Add USDC with Coinbase Pay
          </div>
        </>
      )}

      {type === "applepay" && <ApplePayWhite />}
      {type === "usdc" && (
        <div className={styles.paymentMethodBoxBottomIconAndText}>
          <USDC />
          <div className={styles.paymentMethodBoxBestRate}>
            GET 6% BACK
          </div>
        </div>
      )}
      {type === "coinbase" && <CoinbaseUSDC />}
      {type === "cc" && <CreditCard className={styles.creditCard} />}
 

    </div>

    {type === selectedPaymentMethod && (
      <RadioSelected className={styles.filledCircle} />
    )}
    {type !== selectedPaymentMethod && (
      <RadioUnselected className={styles.unfilledCircle} />
    )}
  </div>
)

export default PaymentMethodBox
