import classNames from "classnames"
import styles from "./Button.module.scss"

const Button = ({
  buttonGroup,
  children,
  className,
  compact,
  large,
  disabled,
  fullWidth,
  leftIcon,
  loading,
  id,
  onClick,
  rightIcon,
  rightIconOnClick,
  rounded,
  variant, // blue, gray, beige, blueModule, grayModule
  outline,
  rm, // roboto mono
}) => {
  return (
    <button
      id={id}
      className={classNames(
        styles.button,
        compact && styles.compact,
        rounded && styles.rounded,
        variant && styles[variant],
        outline && styles.outline,
        fullWidth && styles.fullWidth,
        buttonGroup && styles.buttonGroup,
        large && styles.large,
        rm && styles.rm,
        className
      )}
      disabled={disabled || loading}
      loading={loading}
      onClick={onClick}
    >
      {loading ? (
        <img
          src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
          alt="loading"
        />
      ) : (
        <>
          {leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
          <span className={styles.textContent}>{children}</span>
          {rightIcon && (
            <span
              className={styles.rightIcon}
              onClick={(e) => rightIconOnClick && rightIconOnClick(e)}
            >
              {rightIcon}
            </span>
          )}
        </>
      )}
    </button>
  )
}

export default Button
