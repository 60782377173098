import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import styles from "./Search.module.scss"
import { Search as SearchIcon } from "../../css/icons/Search"
import { formatLocationName } from "../../helpers/location"
import { AngleRight, City, Microphone, Venue } from "../../css/icons"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"
import Button from "../Button/Button"

const Search = ({ className, inHeader, isMobile, withButton, close }) => {
  const navigate = useNavigate()
  const [query, setQuery] = useState("")
  const autocompleteRef = useRef()
  const [resultsLoading, setResultsLoading] = useState(false)
  const [entered, setEntered] = useState(false)

  useDetectClickOutside(autocompleteRef, () => {
    setResults([])
  })

  const [results, setResults] = useState([])
  const [performers, setPerformers] = useState([])
  const [events, setEvents] = useState([])
  const [cities, setCities] = useState([])
  const [venues, setVenues] = useState([])
  const [exactMatch, setExactMatch] = useState(null)

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (entered) {
        setEntered(false)
      }
      getAutocompleteSuggestions(query)
      // Send Axios request here
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  const getAutocompleteSuggestions = async (query) => {
    if (entered) {
      return
    }
    if (query === "") {
      setResults([])
      return
    }

    try {
      setResultsLoading(true)
      setResults([])

      const endpoint = "/api/stagehand/dropdown/v2"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: query,
          experimental:
            window.location.href.indexOf("alpha") > -1 ||
            window.location.href.indexOf("localhost") > -1,
        }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })

      let count = 0
      let searchResults = []


      response.performers &&
        response.performers.forEach((performer) => {
          searchResults.push({ ...performer, type: "artist" })
        })

      response.performers && setPerformers(response.performers)

      response.events &&
        response.events.forEach((event) => {
          searchResults.push({ ...event, type: "event" })
        })

      response.events && setEvents(response.events)

      response.cities &&
        response.cities.forEach((city) => {
          searchResults.push({ ...city, type: "city" })
        })

      response.cities && setCities(response.cities)

      response.venues &&
        response.venues.forEach((venue) => {
          searchResults.push({ ...venue, type: "venue" })
        })

      response.venues && setVenues(response.venues)

      setExactMatch(response.exact_match)

      setResults(searchResults)
      setResultsLoading(false)

      // return response.events
    } catch (err) {
      console.log(err)
    }
  }

  const getUniqueKey = (result) => {
    if (result.type === "artist") {
      return result.performer_id
    } else if (result.type === "venue") {
      return result.venue_id
    } else if (result.type === "cbsa") {
      return result.cbsaname
    }
  }

  const goToExactMatch = (exactMatch) => {
    if(exactMatch.type === "event") {
      navigate(`/event/${exactMatch.event.event_id}`)
    } else if(exactMatch.type === "performer") {
      navigate(`/artist/${exactMatch.performer.performer_id}`)
    } else if(exactMatch.type === "venue") {
      navigate(`/venue/${exactMatch.venue.venue_id}`)
    } else if(exactMatch.type === "city") {
      navigate(`/events/city/${exactMatch.city.name}/${exactMatch.city.state}`)
    }
  }

  const renderPerformer = (performer) => {
    return (
      <div
        className={styles.autocompleteItem}
        key={getUniqueKey(performer)}
        onClick={() => {
          navigate(`/artist/${performer.performer_id}`)
          setResults([])
          close && close()
        }}
      >
        <div className={styles.autocompleteInfo}>
          <div className={styles.autocompleteIconContainer}>
            {performer.default_img ? (
              <img
                src={performer.default_img}
                alt={performer.name}
              />
            ) : (
              <Microphone />
            )}
          </div>
          <p className={styles.autocompleteItemTitle}>
            {performer.name}
          </p>
        </div>
        <div className={styles.autocompleteItemLabel}>
          Performer
          <AngleRight className={styles.angle} />
        </div>
      </div>
    )
  }

  const renderEvent = (event) => {
    return (
      <div
          className={styles.autocompleteItem}
          key={getUniqueKey(event)}
          onClick={() => {
            navigate(`/event/${event.event_id}`)
            setResults([])
            close && close()
          }}
        >
          <div className={styles.autocompleteInfo}>
            <div
              className={classNames(
                styles.autocompleteIconContainer,
                styles.dateContainer
              )}
            >
              {event?.date_local ? (
                <>
                  <div className={styles.month}>
                    {new Date(
                      event?.date_local
                    ).toLocaleDateString("en-US", {
                      month: "short",
                    })}
                  </div>
                  <div className={styles.day}>
                    {new Date(
                      event?.date_local
                    ).toLocaleDateString("en-US", {
                      day: "numeric",
                    })}
                  </div>
                </>
              ) : (
                <Microphone />
              )}
            </div>
            <p className={styles.autocompleteItemTitle}>
              {event.name}
            </p>
          </div>
          <div className={styles.autocompleteItemLabel}>
            Event
            <AngleRight className={styles.angle} />
          </div>
      </div>
    )
  }

  const renderVenue = (venue) => {
    return (
      <div
        className={styles.autocompleteItem}
        key={getUniqueKey(venue?.venue_id)}
        onClick={() => {
          navigate(`/venue/${venue.venue_id}`)
          setResults([])
          close && close()
        }}
      >
        <div className={styles.autocompleteInfo}>
          <div className={styles.autocompleteIconContainer}>
            <Venue />
          </div>
          <p className={styles.autocompleteItemTitle}>
            {venue?.name}
          </p>
        </div>
        <div className={styles.autocompleteItemLabel}>
          Venue
          <AngleRight className={styles.angle} />
        </div>
      </div>
    )
  }

  const renderCity = (city) => {
    return (
      <div
        className={styles.autocompleteItem}
        key={getUniqueKey(city)}
        onClick={() => {
          navigate(`/events/city/${city.name}/${city.state}`)
          setResults([])
          close && close()
        }}
      >
        <div className={styles.autocompleteInfo}>
          <div className={styles.autocompleteIconContainer}>
            <City />
          </div>
          <p className={styles.autocompleteItemTitle}>
            {formatLocationName(city.name, city.state)}
          </p>
        </div>
        <div className={styles.autocompleteItemLabel}>
          City
          <AngleRight className={styles.angle} />
        </div>
      </div>
    )
  }


  return (
    <div className={!isMobile && !inHeader && styles.border1}>
      <div className={!isMobile && !inHeader && styles.border2}>
        <div
          className={classNames(
            !inHeader && !isMobile && styles.pulse,
            isMobile && styles.fullWidth
          )}
        >
          <div
            className={classNames(
              styles.searchContainer,
              styles.box,
              isMobile && styles.isMobile,
              withButton && styles.withButton,
              inHeader && styles.inHeader,
              className
            )}
          >
            <SearchIcon className={styles.searchIcon} />
            <input
              autoFocus={isMobile}
              className={styles.search}
              type="text"
              onChange={(e) => setQuery(e.target.value)}
              placeholder={
                window.innerWidth > 960
                  ? "Find tickets by artist, team or city"
                  : "Search for events"
              }
              autoComplete="new-password" // weird hack from stack overflow but works
              onKeyUp={(e) => {
                if (e.key === "Enter" && query !== "") {
                  if(exactMatch) {
                    goToExactMatch(exactMatch)
                    return 
                  }
                  navigate(`/events/search/${query}`)
                  close && close()
                  setEntered(true)
                  setResults([]) // close the suggestion dropdown
                }
              }}
            />
            {withButton && (
              <Button
                onClick={() => {
                  if (query === "") return
                  navigate(`/events/search/${query}`)
                }}
                rm
              >
                Search
              </Button>
            )}
            {!entered && results.length > 0 && (
              <div className={styles.autocomplete} ref={autocompleteRef}>
                {exactMatch && (
                  <div className={styles.autocompleteSection}>
                    <div className={styles.autocompleteSectionTitle}>
                      Top Match
                    </div>

                    {exactMatch.type === "event" && renderEvent(exactMatch.event)}
                    {exactMatch.type === "performer" && renderPerformer(exactMatch.performer)}
                    {exactMatch.type === "venue" && renderVenue(exactMatch.venue)}
                    {exactMatch.type === "city" && renderCity(exactMatch.city)}


                  </div>

                )}


                {performers.length > 0 && (
                  <div className={styles.autocompleteSection}>
                    <div className={styles.autocompleteSectionTitle}>
                      Performers
                    </div>
                    {performers.map((performer) => (
                      renderPerformer(performer)
                    ))}
                  </div>
                )}
                {events.length > 0 && (
                  <div className={styles.autocompleteSection}>
                    <div className={styles.autocompleteSectionTitle}>
                      Events
                    </div>
                    {events.map((event) => (
                      renderEvent(event)
                    ))}
                  </div>
                )}
                {venues.length > 0 && (
                  <div className={styles.autocompleteSection}>
                    <div className={styles.autocompleteSectionTitle}>
                      Venues
                    </div>
                    {venues.map((venue) => (
                      renderVenue(venue)
                    ))}
                  </div>
                )}
                {cities.length > 0 && (
                  <div className={styles.autocompleteSection}>
                    <div className={styles.autocompleteSectionTitle}>
                      Cities
                    </div>
                    {cities.map((city) => (
                      renderCity(city)
                    ))}
                  </div>
                )}
              </div>
            )}
            {!entered && resultsLoading && (
              <div className={styles.loadingContainer}>
                {[...Array(4)].map(() => (
                  <div className={styles.autocompleteLoading}>
                    <div className={styles.loadingLeft}>
                      <img
                        className={styles.loadingImage}
                        src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                        alt="Loading"
                      />
                      <div>
                        <img
                          className={styles.loadingTitle}
                          src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                          alt="Loading"
                        />
                        <img
                          className={styles.loadingSubtitle}
                          src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                          alt="Loading"
                        />
                      </div>
                    </div>
                    <img
                      className={styles.loadingDescription}
                      src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                      alt="loading"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Search
