export const GoldStar = ({ className }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.4225 16.4778L35.8299 19.9938L39.4224 23.5098C40.4929 24.5232 39.9945 26.3252 38.5893 26.6613L33.6948 27.9105L35.0745 32.7554C35.4644 34.1487 34.1507 35.4636 32.7573 35.0735L27.914 33.6932L26.6652 38.5894C26.3263 40.0077 24.4914 40.4766 23.5148 39.4229L20 35.829L16.4851 39.4229C15.4985 40.4658 13.6815 40.0406 13.3347 38.5894L12.086 33.6932L7.24267 35.0735C5.84954 35.4635 4.53548 34.149 4.9254 32.7554L6.30517 27.9105L1.41063 26.6613C0.00593501 26.3253 -0.493441 24.5236 0.577499 23.5098L4.17008 19.9938L0.577499 16.4778C-0.492972 15.4644 0.00538799 13.6624 1.4107 13.3264L6.30525 12.0772L4.92548 7.23224C4.53563 5.83895 5.84923 4.52403 7.24275 4.91419L12.0861 6.29442L13.3349 1.39824C13.6691 -0.000356007 15.4985 -0.478248 16.4853 0.564802L20 4.1852L23.5149 0.564723C24.5129 -0.490279 26.3348 0.0154254 26.6653 1.39816L27.914 6.29435L32.7573 4.91411C34.1505 4.52411 35.4645 5.83856 35.0746 7.23216L33.6948 12.0771L38.5894 13.3263C39.9941 13.6623 40.4934 15.464 39.4225 16.4778ZM10.2948 20.6661L16.7948 27.1661C17.1853 27.5567 17.8184 27.5567 18.209 27.1662L29.709 15.6661C30.0995 15.2756 30.0995 14.6424 29.709 14.2519L28.2948 12.8377C27.9043 12.4472 27.2711 12.4472 26.8806 12.8377L17.5019 22.2164L13.1232 17.8377C12.7327 17.4472 12.0994 17.4472 11.7089 17.8377L10.2948 19.2519C9.90425 19.6425 9.90425 20.2756 10.2948 20.6661Z"
      fill="#FFBD30"
    />
  </svg>
)
