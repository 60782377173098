export const Key = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.6666 15.2917C36.6666 21.62 31.5365 26.75 25.2083 26.75C24.4778 26.75 23.7636 26.6809 23.0711 26.5502L21.5078 28.3089C21.3612 28.4739 21.1813 28.6058 20.9801 28.6962C20.7788 28.7866 20.5606 28.8334 20.34 28.8333H17.9166V31.4375C17.9166 32.3005 17.217 33 16.3541 33H13.7499V35.6042C13.7499 36.4671 13.0504 37.1667 12.1874 37.1667H4.89575C4.0328 37.1667 3.33325 36.4671 3.33325 35.6042V30.5222C3.33325 30.1078 3.4979 29.7104 3.79087 29.4173L14.3249 18.8833C13.9523 17.7535 13.7499 16.5463 13.7499 15.2917C13.7499 8.96342 18.8799 3.83341 25.2082 3.83334C31.5551 3.83328 36.6666 8.94474 36.6666 15.2917ZM25.2083 12.1667C25.2083 13.8926 26.6073 15.2917 28.3333 15.2917C30.0592 15.2917 31.4583 13.8926 31.4583 12.1667C31.4583 10.4408 30.0592 9.04168 28.3333 9.04168C26.6073 9.04168 25.2083 10.4408 25.2083 12.1667Z"
      fill="#FAFAFA"
      fillOpacity="0.5"
    />
  </svg>
)
