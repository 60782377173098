import { TransactionInstruction, PublicKey, AccountMeta } from "@solana/web3.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types" // eslint-disable-line @typescript-eslint/no-unused-vars
import { PROGRAM_ID } from "../programId"

export interface UnverifyCollectionArgs {
  root: Array<number>
  dataHash: Array<number>
  creatorHash: Array<number>
  nonce: BN
  index: number
  message: types.MetadataArgsFields
}

export interface UnverifyCollectionAccounts {
  treeAuthority: PublicKey
  leafOwner: PublicKey
  leafDelegate: PublicKey
  merkleTree: PublicKey
  payer: PublicKey
  /**
   * This account is checked to be a signer in
   * the case of `set_and_verify_collection` where
   * we are actually changing the NFT metadata.
   */
  treeDelegate: PublicKey
  collectionAuthority: PublicKey
  /**
   * If there is no collecton authority record PDA then
   * this must be the Bubblegum program address.
   */
  collectionAuthorityRecordPda: PublicKey
  collectionMint: PublicKey
  collectionMetadata: PublicKey
  editionAccount: PublicKey
  bubblegumSigner: PublicKey
  logWrapper: PublicKey
  compressionProgram: PublicKey
  tokenMetadataProgram: PublicKey
  systemProgram: PublicKey
}

export const layout = borsh.struct([
  borsh.array(borsh.u8(), 32, "root"),
  borsh.array(borsh.u8(), 32, "dataHash"),
  borsh.array(borsh.u8(), 32, "creatorHash"),
  borsh.u64("nonce"),
  borsh.u32("index"),
  types.MetadataArgs.layout("message"),
])

/** Unverifies a collection from a leaf node. */
export function unverifyCollection(
  args: UnverifyCollectionArgs,
  accounts: UnverifyCollectionAccounts,
  programId: PublicKey = PROGRAM_ID
) {
  const keys: Array<AccountMeta> = [
    { pubkey: accounts.treeAuthority, isSigner: false, isWritable: false },
    { pubkey: accounts.leafOwner, isSigner: false, isWritable: false },
    { pubkey: accounts.leafDelegate, isSigner: false, isWritable: false },
    { pubkey: accounts.merkleTree, isSigner: false, isWritable: true },
    { pubkey: accounts.payer, isSigner: true, isWritable: false },
    { pubkey: accounts.treeDelegate, isSigner: false, isWritable: false },
    { pubkey: accounts.collectionAuthority, isSigner: true, isWritable: false },
    {
      pubkey: accounts.collectionAuthorityRecordPda,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: accounts.collectionMint, isSigner: false, isWritable: false },
    { pubkey: accounts.collectionMetadata, isSigner: false, isWritable: true },
    { pubkey: accounts.editionAccount, isSigner: false, isWritable: false },
    { pubkey: accounts.bubblegumSigner, isSigner: false, isWritable: false },
    { pubkey: accounts.logWrapper, isSigner: false, isWritable: false },
    { pubkey: accounts.compressionProgram, isSigner: false, isWritable: false },
    {
      pubkey: accounts.tokenMetadataProgram,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: accounts.systemProgram, isSigner: false, isWritable: false },
  ]
  const identifier = Buffer.from([250, 251, 42, 106, 41, 137, 186, 168])
  const buffer = Buffer.alloc(1000)
  const len = layout.encode(
    {
      root: args.root,
      dataHash: args.dataHash,
      creatorHash: args.creatorHash,
      nonce: args.nonce,
      index: args.index,
      message: types.MetadataArgs.toEncodable(args.message),
    },
    buffer
  )
  const data = Buffer.concat([identifier, buffer]).slice(0, 8 + len)
  const ix = new TransactionInstruction({ keys, programId, data })
  return ix
}
