export const Venue = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 2.25H5.25L7.5 3.75L5.25 5.25V6.2475C6.3525 6.09 7.635 6 9 6C10.365 6 11.6475 6.09 12.75 6.2475V2.25H14.25L16.5 3.75L14.25 5.25V6.5325C15.6375 6.8775 16.5 7.35 16.5 7.875C16.5 8.91 13.125 9.75 9 9.75C4.875 9.75 1.5 8.91 1.5 7.875C1.5 7.35 2.3625 6.8775 3.75 6.5325V2.25ZM9 7.125C6.5175 7.125 5.25 7.2525 5.25 7.875C5.25 8.4975 6.5175 8.625 9 8.625C11.4825 8.625 12.75 8.4975 12.75 7.875C12.75 7.2525 11.4825 7.125 9 7.125ZM9 11.0625C11.8575 11.0625 14.4 10.56 16.05 9.7875L15 15.75H11.25V14.25C11.25 13.8522 11.092 13.4706 10.8107 13.1893C10.5294 12.908 10.1478 12.75 9.75 12.75H8.25C7.85218 12.75 7.47064 12.908 7.18934 13.1893C6.90804 13.4706 6.75 13.8522 6.75 14.25V15.75H3L1.95 9.7875C3.6 10.56 6.1425 11.0625 9 11.0625Z"
      fill="#8D8D8D"
    />
  </svg>
)
