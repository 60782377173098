export const USDC = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1553_17568)">
      <path
        d="M12 24C18.65 24 24 18.65 24 12C24 5.34996 18.65 0 12 0C5.34996 0 0 5.34996 0 12C0 18.65 5.34996 24 12 24Z"
        fill="#2775CA"
      />
      <path
        d="M15.3 13.9C15.3 12.15 14.25 11.55 12.15 11.3001C10.65 11.1 10.35 10.7001 10.35 9.99998C10.35 9.2999 10.85 8.85002 11.85 8.85002C12.75 8.85002 13.25 9.15002 13.5 9.90002C13.55 10.05 13.7 10.15 13.85 10.15H14.65C14.85 10.15 15 9.99998 15 9.80006V9.75002C14.8 8.64998 13.9 7.80002 12.75 7.70006V6.50006C12.75 6.30002 12.6 6.15002 12.35 6.09998H11.6C11.4 6.09998 11.25 6.24998 11.2 6.50006V7.65002C9.69996 7.85006 8.75004 8.85002 8.75004 10.1001C8.75004 11.7501 9.75 12.4 11.85 12.6501C13.25 12.9 13.7 13.2 13.7 14.0001C13.7 14.8001 13 15.3501 12.05 15.3501C10.75 15.3501 10.3 14.8 10.15 14.05C10.1 13.8501 9.95004 13.75 9.80004 13.75H8.94996C8.75004 13.75 8.60004 13.9 8.60004 14.1V14.1501C8.79996 15.4 9.6 16.3 11.25 16.5501V17.7501C11.25 17.95 11.4 18.1 11.65 18.15H12.4C12.6 18.15 12.75 18 12.8 17.7501V16.5501C14.3 16.3 15.3 15.25 15.3 13.9Z"
        fill="white"
      />
      <path
        d="M9.45 19.15C5.55 17.7501 3.54996 13.4001 5.00004 9.54998C5.75004 7.44998 7.40004 5.85002 9.45 5.10002C9.65004 5.00006 9.75 4.85006 9.75 4.59998V3.90002C9.75 3.69998 9.65004 3.54998 9.45 3.50006C9.39996 3.50006 9.3 3.50006 9.24996 3.54998C4.5 5.04998 1.89996 10.1001 3.39996 14.85C4.29996 17.65 6.45 19.8 9.24996 20.7C9.45 20.8 9.65004 20.7 9.69996 20.5C9.75 20.4501 9.75 20.4 9.75 20.3001V19.6C9.75 19.45 9.6 19.2501 9.45 19.15ZM14.75 3.54998C14.55 3.45002 14.35 3.54998 14.3 3.75002C14.25 3.80006 14.25 3.84998 14.25 3.95006V4.65002C14.25 4.85006 14.4 5.04998 14.55 5.15006C18.45 6.54998 20.45 10.9 19 14.7501C18.25 16.8501 16.6 18.45 14.55 19.2C14.35 19.3 14.25 19.45 14.25 19.7001V20.4C14.25 20.6001 14.35 20.7501 14.55 20.8C14.6 20.8 14.7 20.8 14.75 20.7501C19.5 19.2501 22.1 14.2 20.6 9.45002C19.7 6.60002 17.5 4.44998 14.75 3.54998Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1553_17568">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
