import styles from "./ProgressBar.module.scss"
import classNames from "classnames"

const ProgressBar = ({ steps, stepsCompleted, labels, complete }) => (
  <div
    className={classNames(
      styles.progressContainer,
      complete && styles.complete
    )}
  >
    <div className={styles.progressBar}>
      <div
        className={classNames(styles.progress, complete && styles.completeBar)}
        style={{ width: `${(stepsCompleted / steps) * 100}%` }}
      />
    </div>
    <div className={styles.labelContainer}>
      {labels.map((label) => (
        <span className={styles.label}>{label}</span>
      ))}
    </div>
  </div>
)

export default ProgressBar
