export const Edit = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.98958 3.49914L9.55556 5.06511C9.62153 5.13108 9.62153 5.23872 9.55556 5.30469L5.76389 9.09636L4.15278 9.27518C3.9375 9.29948 3.75521 9.11719 3.77951 8.90191L3.95833 7.2908L7.75 3.49914C7.81597 3.43316 7.92361 3.43316 7.98958 3.49914ZM10.8021 3.10157L9.95486 2.25434C9.69097 1.99045 9.26215 1.99045 8.99653 2.25434L8.38195 2.86893C8.31597 2.9349 8.31597 3.04254 8.38195 3.10851L9.94792 4.67448C10.0139 4.74045 10.1215 4.74045 10.1875 4.67448L10.8021 4.0599C11.066 3.79427 11.066 3.36546 10.8021 3.10157ZM7.66667 8.06511V9.83247H2.11111V4.27691H6.10069C6.15625 4.27691 6.20833 4.25434 6.24826 4.21615L6.94271 3.52171C7.07465 3.38976 6.9809 3.1658 6.79514 3.1658H1.83333C1.37326 3.1658 1 3.53907 1 3.99914V10.1102C1 10.5703 1.37326 10.9436 1.83333 10.9436H7.94444C8.40451 10.9436 8.77778 10.5703 8.77778 10.1102V7.37066C8.77778 7.1849 8.55382 7.09289 8.42188 7.22309L7.72743 7.91754C7.68924 7.95747 7.66667 8.00955 7.66667 8.06511Z"
      fill="#FAFAFA"
    />
  </svg>
)
