import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import classNames from "classnames"
import styles from "./AccountSidebar.module.scss"
import { Gear, Gift, LogOut, Ticket } from "../css/icons"
import { useWallet } from "@solana/wallet-adapter-react"
import { setUSDCBalance, signOut } from "../reducers/userSlice"
import { useEffect } from "react"
import { getTokenBalance } from "../helpers/getTokenAccounts"

const AccountSidebar = () => {
  let navigate = useNavigate()
  const location = useLocation()
  const adapter = useWallet()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user.user)
  const USDCBalance = useSelector((state) => state.user.usdc)
  const numTickets = useSelector((state) => state.user.numTickets)
  const numCoupons = useSelector((state) => state.user.numRewards)

  const getUSDCBalance = async (user) => {
    const usdc = await getTokenBalance(
      user,
      "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
    )

    dispatch(setUSDCBalance(usdc))
  }

  useEffect(() => {
    if (!USDCBalance) {
      getUSDCBalance(user)
    }
  }, [USDCBalance]) // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: WE NEED A LOADING STATE FOR THE VALUES HERE

  return (
    <div className={styles.container}>
      {user?.first_name && (
        <>
          <div className={styles.initials}>
            {user?.first_name[0]}
            {user?.last_name[0]}
          </div>
          <h1>
            {user?.first_name} {user?.last_name}
          </h1>
          <p>{user?.email}</p>
        </>
      )}
      <div>
        <div className={styles.usdcButton} onClick={() => navigate("/account")}>
          <span>USDC Balance</span>
          <span className={styles.usdcBalance}>${USDCBalance}</span>
        </div>
        <div
          className={classNames(
            styles.menuButton,
            location.pathname.includes("tickets") && styles.active
          )}
          onClick={() => navigate("/holder/tickets")}
        >
          <span>My Tickets</span>
          <div className={styles.count}>
            <span className={styles.number}>{numTickets}</span> <Ticket />
          </div>
        </div>
        <div
          className={classNames(
            styles.menuButton,
            location.pathname === "/account/rewards" && styles.active
          )}
          onClick={() => navigate("/account/rewards")}
        >
          <span>My Rewards</span>
          <div className={styles.count}>
            <Gift />
          </div>
        </div>
        <div
          className={classNames(
            styles.menuButton,
            location.pathname === "/account" && styles.active
          )}
          onClick={() => navigate("/account")}
        >
          <span>Manage Account</span>
          <Gear />
        </div>
        <div
          className={classNames(styles.menuButton, styles.logOut)}
          onClick={() => {
            adapter?.disconnect()
            window.WALLET_USER_SIGNED_IN = false // for iterable tracking
            if (user?.loginMethod === "phone") {
              dispatch(signOut())
              window.PHONE_USER_SIGNED_IN = false // for iterable tracking
            }
            if (location.pathname !== "/") {
              navigate("/")
            }
          }}
        >
          <span>Log Out</span>
          <LogOut />
        </div>
      </div>
    </div>
  )
}

export default AccountSidebar
