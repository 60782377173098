export const Wallet = ({ className }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.2141 4.5H2.8125C2.50172 4.5 2.25 4.24828 2.25 3.9375C2.25 3.62672 2.50172 3.375 2.8125 3.375H16.3125C16.6233 3.375 16.875 3.12328 16.875 2.8125C16.875 1.88051 16.1195 1.125 15.1875 1.125H2.25C1.00723 1.125 0 2.13223 0 3.375V14.625C0 15.8678 1.00723 16.875 2.25 16.875H16.2141C17.1991 16.875 18 16.1181 18 15.1875V6.1875C18 5.25691 17.1991 4.5 16.2141 4.5ZM14.625 11.8125C14.0038 11.8125 13.5 11.3087 13.5 10.6875C13.5 10.0663 14.0038 9.5625 14.625 9.5625C15.2462 9.5625 15.75 10.0663 15.75 10.6875C15.75 11.3087 15.2462 11.8125 14.625 11.8125Z"
      fill="#FAFAFA"
    />
  </svg>
)
