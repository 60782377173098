export const Bell = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.88418 2.29845C8.57602 2.34622 8.18312 2.46623 8.01106 2.56516C7.71617 2.73485 7.29894 3.13195 7.29347 3.24826C7.29207 3.27738 8.33295 3.30122 9.60642 3.30122H11.9219L11.6835 2.99658C11.2421 2.43265 10.007 2.12463 8.88418 2.29845ZM7.17496 4.47971C6.03489 4.85235 5.31521 5.51543 4.8187 6.65071C4.60798 7.13245 4.562 7.49714 4.52102 9.01438C4.47678 10.6481 4.4911 10.8208 4.68156 10.9475C4.94827 11.125 14.0393 11.1524 14.3999 10.9769C14.6068 10.8761 14.625 10.7411 14.625 9.3044C14.625 7.58251 14.4706 6.80864 13.9889 6.11421C13.5378 5.46395 12.9006 4.94101 12.1903 4.63796C11.5817 4.37824 11.417 4.35875 9.61911 4.33449C8.09022 4.31395 7.59149 4.3435 7.17496 4.47971ZM10.406 5.57803C11.7573 6.32765 11.3686 8.17346 9.81108 8.40352C9.30875 8.47767 8.43015 8.13448 8.15925 7.75835C7.20639 6.43516 8.93214 4.76041 10.406 5.57803ZM4.75874 12.1491C4.50461 12.2421 4.49692 12.2883 4.50053 13.7048C4.50449 15.2787 4.55688 15.4405 5.1251 15.6371C5.57237 15.7919 13.5378 15.7856 14.028 15.6301C14.231 15.5657 14.4484 15.4259 14.511 15.3193C14.5737 15.2128 14.625 14.4778 14.625 13.686V12.2464L14.3264 12.1431C13.9471 12.012 5.1187 12.0174 4.75874 12.1491Z"
      fill="#85D5FF"
    />
  </svg>
)
