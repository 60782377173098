import {
  setConfig,
  CNFT,
  PDA,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
} from "@captainxyz/solana-core"

import { PublicKey } from "@solana/web3.js"
import postToSlack from "../postToSlack"

export const getTokenAccountsForUser = async (adapter, user) => {
  const collectionMintAddress = "FTJFivetDPeuj4TeB56Z4XCVo45vPSWBbKKcfE7YEgo3"

  setConfig("mainnet-beta", {
    rpcEndpoint: process.env.REACT_APP_RPC,
  })

  let operator
  let tokens = []
  let publicKey
  if (adapter?.publicKey) {
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    operator = new Operator("mainnet-beta", walletAdapterIdentity)
    publicKey = adapter.publicKey
  } else if (user?.publicKey) {
    operator = new Operator("mainnet-beta")
    publicKey = new PublicKey(user.publicKey)
  } else {
    return { decryptList: [], decryptListPast: [] }
  }


  /* we're just straight web2 for now
  let maxPages = 3
  for (let page = 1; page <= maxPages; page++) {
    const result = await operator.rpc.searchAssets({
      limit: 1000,
      page: page,
      ownerAddress: publicKey.toString(),
      grouping: ["collection", collectionMintAddress],
      burnt: false,
      sortBy: {
        sortBy: "created",
        sortDirection: "desc",
      },
    })
    if(result?.items){
      for (const asset of result.items) {
        const address = new PublicKey(asset.id)
        try {
          const token = await TokenAccount.init(
            operator,
            PDA.token(address, publicKey)
          )
          tokens.push(token)
        } catch (err) {
          console.log("Burned token?")
        }
      }
      if (maxPages === 1 && result.total > result.limit) {
        maxPages = Math.ceil(result.total / result.limit)
      }
    }
  }
  */

  let decryptList = []
  let decryptListPast = []
  for (let token of tokens) {
    if (
      new Date(
        token.metadata?.json?.attributes?.find(
          (attr) => attr.trait_type === "Date"
        ).value
      ) > new Date()
    ) {
      decryptList.push(token)
    } else {
      decryptListPast.push(token)
    }
  }

  let url = `${process.env.REACT_APP_HNGR_API}/api/xp/backend-tickets-by-user`
  let headers = {
    "Content-Type": "application/json",
  }
  let params = {
    wallet: publicKey.toString(),
  }
  let resp = await fetch(url, {
    method: "post",
    headers: headers,
    body: JSON.stringify(params),
  })
  resp = await resp.json()
  let tickets = resp["tickets"]
  let mockTokensPast = []
  let mockTokensCurrent = []
  for (let i = 0; i < tickets.length; i++) {
    let mockToken = {
      metadata: {
        _json: tickets[i],
      },
      notNft: true,
      uuid: tickets[i].uuid,
    }
    if (tickets[i].past) {
      mockTokensPast.push(mockToken)
    } else {
      mockTokensCurrent.push(mockToken)
    }
  }

  decryptList = decryptList.concat(mockTokensCurrent)
  decryptListPast = decryptListPast.concat(mockTokensPast)
  return { decryptList, decryptListPast }
}

export const getRebateChip = async (adapter) => {
  if (!adapter) return false

  const collectionMintAddress = "Gnf4Gyqmu2dAC4uAzYYb419QryC5WSbbn1RP8hSQyCjq"

  setConfig("mainnet-beta", {
    rpcEndpoint: process.env.REACT_APP_RPC,
  })

  const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
  let operator = new Operator("mainnet-beta", walletAdapterIdentity)

  let maxPages = 1
  for (let page = 1; page <= maxPages; page++) {
    const result = await operator.rpc.searchAssets({
      limit: 1000,
      page: page,
      ownerAddress: operator.identity.publicKey.toString(),
      grouping: ["collection", collectionMintAddress],
      burnt: false,
      sortBy: {
        sortBy: "created",
        sortDirection: "desc",
      },
    })
    if (result.items.length) {
      return result.items[0].id
    } else {
      return false
    }
  }
  return false
}

export const hasSolanaChip = async ({ adapter, time, orderId }) => {
  if (!adapter) return false

  let rebateChipMint = await getRebateChip(adapter)
  if (!rebateChipMint) return false

  let data = {
    token: rebateChipMint,
    account: adapter.publicKey.toString(),
    orderId: orderId,
  }
  let headers = {
    "Content-Type": "application/json",
  }
  let url = `${process.env.REACT_APP_EXPRESS_API}/api/velvet/REBATE_TEST`
  try {
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(data),
    })
    resp = await resp.json()
    return resp.valid
  } catch (err) {
    return false
  }
}

export const getEligibleRebateOrder = async (publicKey) => {
  if (!publicKey) return false

  let headers = {
    "Content-Type": "application/json",
  }
  let url = `${process.env.REACT_APP_HNGR_API}/api/xp/eligible-rebate-order`
  try {
    let data = { wallet: publicKey.toString() }
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(data),
    })
    resp = await resp.json()
    if (resp.order_id) return resp.order_id
    else return false
  } catch (err) {
    return false
  }
}

export const getTokenBalance = async (user, mint) => {
  if (!user || !user?.publicKey) return null
  setConfig("mainnet-beta", {
    rpcEndpoint: process.env.REACT_APP_RPC,
  })
  let operator = new Operator("mainnet-beta")
  let publicKey = new PublicKey(user.publicKey)
  let tokenAddress = PDA.token(new PublicKey(mint), publicKey)
  try {
    let token = await TokenAccount.init(operator, tokenAddress)
    return parseInt(token.balance.qty) / 10 ** token.balance.currency.decimals
  } catch (err) {
    return 0
  }
}

export const getCoupon = async (user, total) => {
  if (!user?.publicKey) return null
  let coupons = await getCoupons(user, total)
  // loop through coupons and see if one has minimum met, otherwise
  // return one where minimum is not met

  // if there are more than one coupon with minimum met, return all coupons and let user choose
  let minimumNotMetCoupons = []
  let minimumMetCoupons = []
  for (let i in coupons) {
    let token = coupons[i]
    console.log("dext", token)

    if (token.metadata._json?.state === "unused") {
      if(!token.enabled){
          minimumNotMetCoupons.push(token)
      } else {
        if (token.metadata._json.minimum  && !token.metadata._json.minimum_spend){
          if (token.metadata._json.minimum <= total) {
            minimumMetCoupons.push(token)
            // return { coupon: token, minimumIsMet: true }
          } else {
            minimumNotMetCoupons.push(token)
          }
        } else {
          minimumMetCoupons.push(token)
          // return { coupon: token, minimumIsMet: true }
        }
      }
    }
  }

  return {
    coupons: minimumMetCoupons,
    unavailableCoupons: minimumNotMetCoupons,
  }
}

const checkCollectionForCoupon = async (
  collectionMintAddress,
  user,
  total,
  returnMinimumIsMet = false
) => {
  try {
    if (!user?.publicKey) return []

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    let operator = new Operator("mainnet-beta")

    let maxPages = 1
    let tokens = []

    for (let page = 1; page <= maxPages; page++) {
      const result = await operator.rpc.searchAssets({
        limit: 1000,
        page: page,
        ownerAddress: user.publicKey.toString(),
        grouping: ["collection", collectionMintAddress],
        burnt: false,
        sortBy: {
          sortBy: "created",
          sortDirection: "desc",
        },
      })
      if (result.items.length) {
        for (let j = 0; j < result.items.length; j++) {
          let mint = new PublicKey(result.items[j].id)
          let token
          let tokenAddress = PDA.token(mint, new PublicKey(user.publicKey))
          if (result.items[j].compression?.compressed) {
            let cnft = await CNFT.init(operator, {
              address: mint,
              owner: user.publicKey,
            })
            token = {
              pk: cnft.address.toString(),
              mint: {
                address: cnft.address,
              },
              metadata: {
                _json: cnft.json,
                json: cnft.json,
              },
            }
          } else {
            let tokenAddress = PDA.token(mint, new PublicKey(user.publicKey))
            token = await TokenAccount.init(operator, tokenAddress)
          }

          if (token.metadata._json?.state === "unused") {
            tokens.push(token)
          } else {
            console.log("nope", token.metadata)
          }
        }
      }
    }
    return tokens
  } catch (err) {
    console.log("error checking for coupons", err)
    return []
  }
}

export const getCoupons = async (user, total) => {
  if (!user?.publicKey) {
    console.log("not getting coupons, no user")
    return null
  }

  let url = `${process.env.REACT_APP_HNGR_API}/api/xp/backend-coupons-by-user`
  let headers = {
    "Content-Type": "application/json",
  }
  let params = {
    wallet: user.publicKey.toString(),
  }
  let resp = await fetch(url, {
    method: "post",
    headers: headers,
    body: JSON.stringify(params),
  })
  resp = await resp.json()
  let coupons = resp["coupons"]
  let mockTokens = []
  for (let i = 0; i < coupons.length; i++) {
    let mockToken = {
      enabled:coupons[i].enabled,
      disabled_message:coupons[i].disabled_message,
      pk: coupons[i].uuid,
      metadata: {
        _json: JSON.parse(coupons[i].json_metadata),
        json: JSON.parse(coupons[i].json_metadata),
      },
      mint: {
        address: coupons[i].address,
      },
      notNft: true,
      fragment_id: coupons[i].fragment_id,
      uuid: coupons[i].uuid,
    }
    mockTokens.push(mockToken)
  }
  return mockTokens

}

export const getBurnedHorseCNFTS = async (wallet) => {
  //const collectionMintAddress = "obVpscagNZC1mGubqybJCfBsuFGYjc3zQfa12Ggta4y"
  const collectionMintAddress = "BiJoNd7fXA6cVekMeYpDP3j4RdKZ44ETAAbSs1uAbWuX"
  setConfig("mainnet-beta", {
    rpcEndpoint: process.env.REACT_APP_RPC,
  })
  let operator = new Operator("mainnet-beta")
  let tokens = []

  const result = await operator.rpc.searchAssets({
    limit: 1000,
    page: 1,
    ownerAddress: wallet.toString(),
    grouping: ["collection", collectionMintAddress],
    burnt: true,
    sortBy: {
      sortBy: "created",
      sortDirection: "desc",
    },
  })
  console.log("nft result", result)
  console.log("nft check wallet", wallet.toString())
  if (result.items.length) {
    for (let j = 0; j < result.items.length; j++) {
      let mint = result.items[j].id
      let cnft = await CNFT.init(operator, {
        address: mint,
      })
      tokens.push(cnft)
    }
  }
  return tokens
}

export const getHorseCNFTS = async (wallet) => {
  //const collectionMintAddress = "obVpscagNZC1mGubqybJCfBsuFGYjc3zQfa12Ggta4y"
  const collectionMintAddress = "BiJoNd7fXA6cVekMeYpDP3j4RdKZ44ETAAbSs1uAbWuX"
  setConfig("mainnet-beta", {
    rpcEndpoint: process.env.REACT_APP_RPC,
  })
  let operator = new Operator("mainnet-beta")
  let tokens = []

  const result = await operator.rpc.searchAssets({
    limit: 1000,
    page: 1,
    ownerAddress: wallet.toString(),
    grouping: ["collection", collectionMintAddress],
    burnt: false,
    sortBy: {
      sortBy: "created",
      sortDirection: "desc",
    },
  })
  console.log("nft result", result)
  console.log("nft check wallet", wallet.toString())
  if (result.items.length) {
    for (let j = 0; j < result.items.length; j++) {
      let mint = result.items[j].id
      let cnft = await CNFT.init(operator, {
        address: mint,
      })
      tokens.push(cnft)
    }
  }
  return tokens
}
