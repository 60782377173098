import { useState, useRef } from "react"
import ReactPixel from "react-facebook-pixel"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { Base64 } from "js-base64"
import classNames from "classnames"
import { Bell, CloseCircle, Explosion, Phantom } from "../css/icons"
import {
  CoinbasePay,
  Button,
  Layout,
  Promo,
  SeatMapTevo,
  SeatMap,
} from "../components"
import SeatPreview from "../SeatPreview/SeatPreview"
import { clearSelectedEvent } from "../reducers/eventSlice"
import {
  clearSelectedTicketThumb,
  clearSelectedTicketGroup,
} from "../reducers/ticketSlice"

import "./CheckoutPage.css"
import styles from "./CheckoutPage.module.scss"
import "react-tooltip/dist/react-tooltip.css"
import { useEffect } from "react"
import { AngleLeft, Info, CheckCircle, Warning } from "../css/icons"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"
import { AuctionHouseAccount } from "@captainxyz/marketplace"
import postToSlack from "../postToSlack"
import { setUser } from "../reducers/userSlice"
import { getTokenBalance } from "../helpers/getTokenAccounts"
import { augmentUser } from "../helpers/augmentUser"
import SignInModal from "../Authentication/SignInModal"
import SelectDeliveryMethod from "./SelectDeliveryMethod"
import PaymentMethodBox from "./PaymentMethodBox"
import StripeStuff from "./StripeStuff"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { Blurhash } from "react-blurhash"
import {
  recordIterableCheckoutStarted,
  recordIterablePageview,
  recordIterablePurchase,
} from "../helpers/recordIterableUser"
import { trackFbPageView, trackFbPurchase } from "../helpers/facebook"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const {
  PublicKey,
  Connection,
  Transaction,
  VersionedTransaction,
} = require("@solana/web3.js")
const Base58 = require("base58")
const bs58 = require("bs58")
const { formatCents } = require("../helpers/money")

const CheckoutPage = () => {
  const query = new URLSearchParams(window.location.search)
  const _quantity = query.get("quantity")

  const [quantity, setQuantity] = useState(_quantity)
  const [loading, setLoading] = useState(false)
  const [buying, setBuying] = useState(false)
  const [price, setPrice] = useState(false)
  const [tax, setTax] = useState(0)
  const [ticketGroup, setTicketGroup] = useState(false)
  const [usdcBalance, setUsdcBalance] = useState(0)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cc")
  const [showSigninModal, setShowSigninModal] = useState(false)
  const [coupon, setCoupon] = useState(null)
  const [deliveryMethod, setDeliveryMethod] = useState("instant")
  const [seatsSelected, setSeatsSelected] = useState(false)
  const [stripeClientSecret, setStripeClientSecret] = useState(null)
  const [paymentIntentId, setPaymentIntentId] = useState(null)
  const [showApplePay, setShowApplePay] = useState(null)
  const [applePayLoading, setApplePayLoading] = useState(null)

  const stripeRef = useRef()
  const applePayRef = useRef()

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const adapter = useWallet()

  const user = useSelector((state) => state.user.user)

  // TODO verify quantity is valid
  const ticket_group_uvid = params.ticket_group_uvid
  const event_id = params.event_id

  useEffect(() => {
    checkForApplePay()
    window.scrollTo(0, 0)
    getTicketGroup()
    getEvent()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ticketGroup) {
      checkIfTicketIsAvailable()
    }
  }, [ticketGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ticketGroup && quantity) {
      setPrice(ticketGroup.price * quantity)
    }
  }, [ticketGroup, quantity]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (price) {
      getTax()
    }
  }, [price, coupon]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(price && paymentIntentId && tax){
      updatePaymentIntent()
    }
  }, [tax]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (price && paymentIntentId) {
      updatePaymentIntent()
    }
  }, [price, paymentIntentId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      location.pathname.includes("payment") &&
      ticketGroup &&
      ((user?.user?.email || user?.email) && user.publicKey)
    ) {
      getPaymentIntentId()
    }

    if (user) {
      if (location.pathname.includes("payment")) {
        recordIterablePageview(user, window.location.pathname, "payment", {
          eventId: selectedEvent?.event_id,
          eventName: selectedEvent?.title,
        })
        trackFbPageView(user)
      } else if (location.pathname.includes("delivery")) {
        recordIterablePageview(user, window.location.pathname, "delivery", {
          eventId: selectedEvent?.event_id,
          eventName: selectedEvent?.title,
        })
        trackFbPageView(user)
      } else if (location.pathname.includes("tickets")) {
        recordIterablePageview(user, window.location.pathname, "tickets", {
          eventId: selectedEvent?.event_id,
          eventName: selectedEvent?.title,
        })
        trackFbPageView(user)
        if (!window.RECORDED_CHECKOUT_STARTED) {
          recordIterableCheckoutStarted(user, {
            ageRestriction: ticketGroup?.age_restriction,
            dateLocal: new Date(selectedEvent?.date_local),
            eventCategory: selectedEvent?.category,
            eventId: selectedEvent?.event_id,
            eventSubType:
              selectedEvent?.event_type === "sports"
                ? selectedEvent?.league
                : selectedEvent?.genre,
            eventType: selectedEvent?.event_type,
            inHand: ticketGroup?.in_hand,
            inHandOn: ticketGroup?.in_hand_on,
            isAda: ticketGroup?.is_ada,
            isObstructedView: ticketGroup?.is_obstructed_view,
            isStandingOnly: ticketGroup?.is_standing_only,
            notes: ticketGroup?.notes,
            price: (ticketGroup?.price / 100) * quantity,
            quantity,
            rowId: ticketGroup?.row?.id,
            rowName: ticketGroup?.row?.name,
            seats: ticketGroup?.seats,
            sectionID: ticketGroup?.section?.id,
            sectionName: ticketGroup?.section?.name,
            sectionVendorName: ticketGroup?.section?.vendor_name,
            splits: ticketGroup?.splits,
            ticketFormat: ticketGroup?.ticket_format,
            ticketType: ticketGroup?.ticket_type,
            vendor: ticketGroup?.vendor,
            vendorId: ticketGroup?.vendor_id,
          })

          window.RECORDED_CHECKOUT_STARTED = true
        }
      }
    }

    return () => {
      window.RECORDED_CHECKOUT_STARTED = false
    }
  }, [location.pathname, user, ticketGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      // get rid of these when you leave this screen
      dispatch(clearSelectedEvent())
      dispatch(clearSelectedTicketThumb())
      dispatch(clearSelectedTicketGroup())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setUserWithUuid = async (u) => {
    u = await augmentUser(u)
    dispatch(setUser(u))
  }

  useEffect(() => {
    // mark them authenticated and also note in local storage
    // that they're in a campaign so that if they navigate away
    // they will be reauthenticated
    localStorage.setItem("phantom_browser", "yes")
    if (user && !user.authenticated) {
      let u2 = JSON.parse(JSON.stringify(user))
      u2.authenticated = true
      setUserWithUuid(u2)
    }

    if (user?.loginMethod === "phone" && selectedPaymentMethod === null) {
      setSelectedPaymentMethod("cc")
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      window.checkedForCoupon = false
      window.updatedPaymentIntentLog = false
      window.checkedIfAvailable = false
      window.GETTING_PAYMENT_INTENT_ID = null
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adapter?.publicKey) {
      getUsdcBalance()
    }
  }, [adapter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      user?.loginMethod !== "phone" &&
      usdcBalance !== null &&
      ticketGroup &&
      !selectedPaymentMethod
    ) {
      if (
        usdcBalance > (ticketGroup.price / 100) * quantity &&
        !selectedPaymentMethod
      ) {
        setSelectedPaymentMethod("usdc")
      } else {
        setSelectedPaymentMethod("cc")
      }
    }
  }, [user, usdcBalance, ticketGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkForApplePay = async () => {
    if (window.ApplePaySession) {
      if (window.ApplePaySession.canMakePaymentsWithActiveCard("xp.xyz")) {
        setShowApplePay(true)
        setSelectedPaymentMethod("applepay")
      } else {
        setShowApplePay(false)
      }
    } else {
      setShowApplePay(false)
    }
  }

  const updatePaymentIntent = async () => {
    let total = calculateTotal(coupon)
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      total: total,
      payment_intent_id: paymentIntentId,
    }
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/update-payment-intent-quantity`

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    if (applePayRef.current) {
      setApplePayLoading(true)
      await applePayRef.current.update()
      setApplePayLoading(false)
    }

    resp = await resp.json()
  }

  const getTicketGroup = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/live-ticketgroup-by-id`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      ticket_group_uvid: ticket_group_uvid,
      event_id: event_id,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    if (!resp.ticket_group) {
      alert("This ticket is no longer available")
      navigate(`/event/${params.event_id}`)
    }
    setTicketGroup(resp.ticket_group)
  }

  const getEvent = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/event`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      event_id: event_id,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    setSelectedEvent(resp)
  }

  const getTax = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/tax`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      uvid: ticketGroup.uvid,
      quantity: quantity,
      event_id: event_id,
      promo:calculateDiscount(coupon)
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setTax(resp.tax)
  }

  const getPaymentIntentId = async () => {
    if (window.GETTING_PAYMENT_INTENT_ID) return
    if (paymentIntentId) return

    window.GETTING_PAYMENT_INTENT_ID = true

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/create-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      total: ticketGroup.price * quantity,
      wallet: user.publicKey,
      email:user.email,
      first_name:user.first_name,
      last_name:user.last_name,
      event_id:event_id
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    setPaymentIntentId(resp.payment_intent_id)
    setStripeClientSecret(resp.client_secret)
  }

  const sendReceiptNFT = async (uuid) => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/receiptMint`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      uuid: uuid,
    }
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
  }

  const transferNftAfterCreditCard = async () => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/xferStripeNFT`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      wallet: user.publicKey.toString(),
      payment_intent_id: paymentIntentId,
      deliveryMethod: deliveryMethod,
      coupon_mint: coupon?.mint.address.toString(),
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })

      resp = await resp.json()
      return resp.success
    } catch (err) {
      return false
    }
  }

  const checkIfTicketIsAvailable = async () => {
    //TODO dont return
    return

    if (ticketGroup?.vendor !== "tevo") return
    if (window.checkedIfAvailable) return

    window.checkedIfAvailable = true
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/ticket-availability`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      ticket_group_id: ticketGroup.id,
      quantity: quantity,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    if (!resp.available) {
      alert("This ticket is no longer available")
      navigate(-1)
    }
  }

  const getUsdcBalance = async () => {
    let balance = await getTokenBalance(
      { publicKey: adapter.publicKey },
      "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
    )
    setUsdcBalance(balance)
  }

  const pollUsdcBalance = async () => {
    getUsdcBalance()
    setTimeout(() => pollUsdcBalance(), 2000)
  }

  const recordBought = async (address, apple_pay) => {
    let url = `${process.env.REACT_APP_HNGR_API}/stagehand/bought/v2`
    let headers = {
      "Content-Type": "application/json",
    }

    let utm_source, utm_medium, utm_campaign

    try {
      utm_medium = await localStorage.getItem("utm_medium")
      utm_source = await localStorage.getItem("utm_source")
      utm_campaign = await localStorage.getItem("utm_campaign")
    } catch (err) {
      console.log("error getting utms", err)
    }

    // TODO: backend handle nft_uuid
    let parameters = {
      quantity: quantity,
      event_id: params.event_id,
      sold_to: address,
      delivery_method: deliveryMethod,
      email: user?.user?.email || user?.email,
      first_name: user?.user?.first_name || user?.first_name,
      last_name: user?.user?.last_name || user?.last_name,
      coupon_mint: coupon?.mint.address.toString(),
      currency: selectedPaymentMethod,
      tax:tax,
      promo: calculateDiscount(coupon),
      ticket_group_uvid: ticketGroup.uvid,
      payment_intent_id: paymentIntentId,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      apple_pay: apple_pay,
      phone_usdc_wallet: adapter?.publicKey?.toString(),
      login_type: user?.loginMethod,
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(parameters),
      })

      resp = await resp.json()
      if (resp.success) {
        try {
          await localStorage.removeItem("utm_medium")
          await localStorage.removeItem("utm_source")
          await localStorage.removeItem("utm_campaign")
        } catch (err) {
          console.log("Error unsetting utms", err)
        }
        return resp.uuid
      } else if (resp.message) {
        alert(resp.message)
        navigate("/event/" + event_id)
        return false
      } else {
        alert(
          "An unknown error occurred. If the problem persists, please reach out to support@xp.xyz."
        )
        navigate("/")
      }
      return false
    } catch (err) {
      console.log("checkout error", err)
      alert(
        "An unknown error occurred. If the problem persists, please reach out to support@xp.xyz."
      )
      navigate("/")
      return false
    }
  }

  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2")
  }

  const checkoutWithUSDC = async () => {
    postToSlack("Is starting checkout with USDC", "firehose", user)
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/buyTicketWithUSDC`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      wallet: adapter.publicKey.toString(),
      deliveryMethod: deliveryMethod,
      couponMint: coupon?.mint.address.toString(),
      event_id: event_id,
      ticket_group_uvid: ticketGroup.uvid,
      quantity: quantity,
    }

    try {
      postToSlack("is fetching usdc transaction from express", "firehose", user)
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      postToSlack(
        "succesfully fetched usdc transaction from express",
        "firehose",
        user
      )
      const connection = new Connection(process.env.REACT_APP_RPC) // eslint-disable-line no-unused-vars
      const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight },
      } = await connection.getLatestBlockhashAndContext()

      const data = await resp.json()
      const txEncoded = data.txn
      const txn = Transaction.from(Base64.toUint8Array(txEncoded))
      postToSlack("asking user to run usdc transaction", "firehose", user)
      const signature = await adapter.sendTransaction(txn, connection, {
        minContextSlot,
      })
      postToSlack("waiting to confirm usdc transaction", "firehose", user)
      let result = await connection.confirmTransaction(
        {
          blockhash,
          lastValidBlockHeight,
          signature,
        },
        "confirmed"
      )
      postToSlack("waiting to confirm usdc transaction", "firehose", user)

      if (result.value?.err) {
        alert("Buying failed")
        postToSlack("Transaction failed usdc " + signature, "firehose", user)
        return false
      } else if (result) {
        postToSlack("usdc transaction succcess", "firehose", user)
        return true
      } else {
        // TODO be smarter here
        try {
          postToSlack(
            "unknown usdc error " + JSON.stringify(result),
            "firehose",
            user
          )
        } catch (err) {
          postToSlack("unknown usdc error", "firehose", user)
        }
        alert("Buying may have failed. Check your wallet")
      }
    } catch (err) {
      console.log("usdc error", err)
      let e = err.toString()
      let msg = "Error buying with USDC: " + e
      postToSlack(msg, "firehose", user)
      alert("Unknown error")
      return false
    }
  }

  const sleep = (time) =>
    new Promise((res) => setTimeout(res, time, "done sleeping"))

  const captureStripePayment = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/complete-stripe-purchase/v2`
    let headers = {
      "Content-Type": "application/json",
    }

    let parameters = {
      event_id: params.event_id,
      total: calculateTotal(coupon),
      tax:tax,
      promo:calculateDiscount(coupon),
      ticket_group_uvid: ticketGroup.uvid,
      quantity: quantity,
      payment_intent_id: paymentIntentId,
      coupon_mint: coupon?.mint.address.toString(),
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(parameters),
    })

    return true
  }

  const checkoutWithStripe = async () => {
    if (calculateTotal(coupon) === 0) return true

    let success = await stripeRef.current.submitPayment()
    if (success) {
      try {
        let success = await captureStripePayment()
        if (!success) {
          postToSlack("Fail capturing stripe stripe", "firehose", user)
          return false
          alert("Payment error. Please refresh and try again.")
        }
      } catch (err) {
        console.log("capturing stripe error", err)
        postToSlack("Crash capturing stripe <@U1210FPAL>", "firehose", user)
        alert("Payment error. Please refresh and try again.")
        return false
      }
      return true
    } else {
      setBuying(false)
    }
  }

  const checkoutWithGoogleApplePay = async (express) => {
    if (calculateTotal(coupon) === 0) return true
    try {
      let success = await captureStripePayment()
      if (!success) {
        postToSlack("Fail capturing stripe stripe", "firehose", user)
        return false
        alert("Payment error. Please refresh and try again.")
      }
    } catch (err) {
      console.log("capturing stripe error", err)
      postToSlack(
        "Crash capturing apple pay/google pay stripe <@U1210FPAL>",
        "firehose",
        user
      )
      alert("Payment error. Please refresh and try again.")
      return false
    }
    return true
  }

  const verifyTicketPrice = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/live-ticketgroup-by-id`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      ticket_group_uvid: ticket_group_uvid,
      event_id: event_id,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    let tg = resp.ticket_group

    if (!tg) {
      alert("This ticket is no longer available")
      postToSlack(
        `Tried to purchase but the ticket group is no longer available for ${selectedEvent?.title}`,
        "firehose",
        user
      )
      navigate(`/event/${params.event_id}`)
      return false
    }


    if (tg.splits.indexOf(parseInt(quantity)) == -1){
      let msg = "This quantity of tickets is no longer available"
      alert(msg)
      postToSlack(
        `Tried to check out on ${selectedEvent?.title} with qty ${quantity} but that split is no longer available.`,
        "firehose",
        user
      )
      navigate(`/event/${params.event_id}`)
      return false
    } 

    if (tg.price !== ticketGroup.price) {
      let msg = `The ticket price has changed to ${formatCents(tg.price)}`
      alert(msg)
      postToSlack(
        `Tried to check out on ${selectedEvent?.title} but the ticket price has changed.`,
        "firehose",
        user
      )
      setTicketGroup(tg)
      setBuying(false)
      return false
    } else {
      return true
    }
    


  }

  const cancelApplePayPaymentIntentAndGetNewPaymentIntent = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/cancel-apple-pay-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      payment_intent_id: paymentIntentId,
      wallet: user.publicKey.toString(),
      total: calculateTotal(coupon),
    }
    setPaymentIntentId(null)
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    return true
  }

  const checkout = async (express) => {
    // verify they are logged in and have an email set
    let email = user?.user?.email || user?.email
    if (!email) {
      postToSlack(
        "logged in, but email isn't set when trying to purhcase tickets, bumping them back a page ... <@U1210FPAL> <@U01BNA4G4G3>",
        "firehose",
        user
      )
      alert("Please enter your email.")
      navigate(
        `/checkout/${params.event_id}/${params.ticket_group_uvid}/delivery?quantity=${quantity}`
      )
      return
    }

    /*
      John Stokovich jstokovich52@gmail.com 7735623701 clicked 'Purchase Tickets' for 2 tickets to Usher, Wed Sep 18 8:00 PM (DTE 5), Ball Arena, Denver, CO. Paid $382.00 (pi_3Q0jnrFORYwLqvNc0Wpa4LNA) Promo $50.00 (ALANAHXP50 from Alanah Influencer) Sec 201 Row 3 (TG 23456)
     
    */
    if (!(express || selectedPaymentMethod === "applepay")) {
      let total = calculateTotal(coupon)
      let msg = `${user.first_name} ${user.last_name} ${user.email} ${
        user.phone
      } clicked 'Purchase Tickets' for ${quantity} tickets to <https://xp.xyz/event/${
        selectedEvent?.event_id
      }|${selectedEvent?.title}>, ${selectedEvent?.date_formatted} ${
        selectedEvent?.venue_name
      }, ${selectedEvent?.venue_city}, ${selectedEvent?.venue_state}. Paid ${(
        total / 100
      ).toFixed(2)} (${paymentIntentId}) Promo ${(
        calculateDiscount(coupon) / 100
      ).toFixed(2)} Sec ${ticketGroup?.section?.name} Row ${
        ticketGroup?.row?.name
      } (TG ${params.ticket_group_uvid})`
      postToSlack(msg, "firehose", user)
    }

    setBuying(true)
    let priceStillLegit = await verifyTicketPrice()
    if (!priceStillLegit) {
      if (express || selectedPaymentMethod === "applepay") {
        await cancelApplePayPaymentIntentAndGetNewPaymentIntent()
        window.location.reload()
      }
      return
    }

    let success
    if (express || selectedPaymentMethod === "applepay") {
      success = await checkoutWithGoogleApplePay()
    } else if (selectedPaymentMethod == "cc") {
      success = await checkoutWithStripe()
    } else {
      success = await checkoutWithUSDC()
    }

    if (success) {
      let uuid = await recordBought(user.publicKey.toString(), express)
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: uuid,
          value: calculateTotal(coupon) / 100,
          currency: "USD",
          items: [
            {
              event_id: selectedEvent?.event_id,
              item_name: selectedEvent?.performer_name,
              item_id: ticketGroup?.id,
              price: price / 100 / quantity,
              quantity: quantity,
              discount: calculateDiscount(coupon) / 100,
              coupon_pk: coupon?.pk,
            },
          ],
        },
      })

      recordIterablePurchase(user, uuid, selectedEvent, ticketGroup, {
        quantity,
        total: calculateTotal(coupon) / 100,
        currency:
          selectedPaymentMethod === "applepay" || selectedPaymentMethod === "cc"
            ? "cc"
            : "usdc",
        promo: calculateDiscount(coupon) / 100,
        fragment_id: coupon?.fragment_id,
      })
      trackFbPurchase(
        user,
        {
          currency:
            selectedPaymentMethod === "applepay" ||
            selectedPaymentMethod === "cc"
              ? "cc"
              : "usdc",
          value: calculateTotal(coupon) / 100,
        },
        uuid
      )

      if (user?.loginMethod != "phone") {
        sendReceiptNFT(uuid)
      }

      // if it's phantom, send them a receipt

      if (!uuid) {
        postToSlack(
          `Had trouble buying and probably needs customer support. ${selectedEvent?.title}`,
          "firehose",
          user
        )
        return
      }
      navigate(`/holder/ticket/${uuid}?success=true&quantity=${quantity}`)
      // removing for now to test whether just adding an eventId to the conversion api fixes duplication issue
      // reactpixel is no longer being maintained and doesn't support eventid

      // ReactPixel.track(
      //   "Purchase",
      //   {
      //     user: user?.publicKey?.toString(),
      //     ticketGroup: ticketGroup?.id,
      //     value: price / 100,
      //     currency: "USD",
      //   },
      //   { eventId: uuid }
      // )
    }
    setBuying(false)
  }

  // note we are passing in coupon_ manually and not using the state one
  const calculateDiscount = (coupon) => {
    if (!coupon) return 0
    if (coupon) {
      let discountType = coupon.metadata._json.discount_type
      let discount
      if (discountType === "percent") {
        discount =
          coupon.metadata._json.discount * quantity * ticketGroup?.price
        // if there's any floating point nonsense, round it
        discount = Math.round(discount)

        if (coupon.metadata._json.max_discount) {
          discount = Math.min(discount, coupon.metadata._json.max_discount)
        }
      }

      if (discountType === "flat") {
        discount = coupon.metadata._json.discount
      }
      discount = Math.min(discount, price)

      if (coupon.metadata._json.minimum_spend) {
        if (price - discount < coupon.metadata._json.minimum_spend) {
          discount = price - coupon.metadata._json.minimum_spend
        }
      }
      return discount
    }
  }

  // note we are passing in coupon_ manually and not using the state one
  const calculateTotal = (coupon_) => {
    let subtotal = price
    let discount = calculateDiscount(coupon_)
    let total = subtotal - discount + tax
    return total
  }

  const stripeOptions = () => {
    if (!stripeClientSecret) return {}
    return {
      clientSecret: stripeClientSecret,
    }
  }

  const hasEnoughUsdc = () => {
    try {
      if (usdcBalance > calculateTotal(coupon) / 100) {
        return true
      } else {
        return false
      }
    } catch (err) {
      console.log("error checking if enough usdc", err)
      return false
    }
  }

  const formatSeatRange = (seats) => {
    if (!seats) return ""
    let firstSeat = seats[0]
    let lastSeat = seats[seats.length - 1]
    return `${firstSeat} - ${lastSeat}`
  }

  const removeCoupon = async () => {
    setCoupon(null)
    setLoading(true)

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/remove-coupon-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      total: calculateTotal(),
      payment_intent_id: paymentIntentId,
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      if (applePayRef.current) {
        setApplePayLoading(true)
        await applePayRef.current.update()
        setApplePayLoading(false)
      }
      setLoading(false)
    } catch (err) {
      alert("Unknown error applying coupon")
      setCoupon(null)
      setLoading(false)
    }
  }

  const applyCoupon = async (coupon) => {
    setCoupon(coupon)
    setLoading(true)

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/apply-coupon-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      total: calculateTotal(coupon),
      payment_intent_id: paymentIntentId,
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      if (applePayRef.current) {
        setApplePayLoading(true)
        await applePayRef.current.update()
        setApplePayLoading(false)
      }
      setLoading(false)
      return true
    } catch (err) {
      alert("Unknown error applying coupon")
      console.log("error applying coupon", err)
      setCoupon(null)
      setLoading(false)
      return false
    }
  }

  const renderCheckout = () => {
    return (
      <div className={classNames(styles.container)}>
        {calculateTotal(coupon) === 0 ? (
          <div> </div>
        ) : (
          <>
            <div className={styles.paymentMethodBoxRow}>
              <PaymentMethodBox
                type="cc"
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />
              <PaymentMethodBox
                /*disabled={user?.loginMethod === "phone"}*/
                type="usdc"
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />
              {showApplePay && (
                <PaymentMethodBox
                  type="applepay"
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                />
              )}
            </div>
            {selectedPaymentMethod === "cc" && paymentIntentId && (
              <div className={styles.stripeContainer}>
                <Elements stripe={stripePromise} options={stripeOptions()}>
                  {selectedPaymentMethod === "cc" && (
                    <StripeStuff
                      ref={stripeRef}
                      clientSecret={stripeClientSecret}
                    />
                  )}
                </Elements>
              </div>
            )}
            {selectedPaymentMethod === "usdc" && (
              <>
                {adapter?.publicKey && (
                  <div className={styles.sufficientUSDC}>
                    <div className={styles.walletInfo}>
                      <span>My USDC Balance</span>
                      <span className={styles.walletBalance}>
                        ${usdcBalance.toFixed(2)}
                      </span>
                    </div>
                    {hasEnoughUsdc() ? (
                      <div className={styles.saveWithUSDC}>
                        <div className={styles.saveWithUSDCContent}>
                          <div className={styles.saveWithUSDCHeader}>
                            Get 6% back with USDC
                          </div>
                          <div className={styles.saveWithUSDCSubheader}>
                            Pay with USDC & automatically get 6% back in XP
                            Credit for your next order!{" "}
                            <a
                              style={{ color: "white", marginLeft: "8px" }}
                              href="https://cdn.hngr.co/USDC_Promotion_Terms__Contions_1.pdf"
                              target="_blank"
                            >
                              *Terms apply
                            </a>
                          </div>
                        </div>
                        <CheckCircle />
                      </div>
                    ) : (
                      <div
                        className={classNames(
                          styles.saveWithUSDC,
                          styles.notEnoughUSDC
                        )}
                      >
                        <div className={styles.saveWithUSDCContent}>
                          <div className={styles.saveWithUSDCSubheader}>
                            Your USDC balance does not cover this purchase
                          </div>
                        </div>
                        <Warning />
                      </div>
                    )}
                  </div>
                )}
                {user?.loginMethod === "phone" && (
                  <div style={{ marginTop: "20px" }}>
                    {adapter.publicKey ? (
                      <WalletMultiButton>
                        Connected as{" "}
                        {adapter.publicKey.toString().substring(0, 5)}...
                        {adapter.publicKey
                          .toString()
                          .substring(adapter.publicKey.toString().length - 4)}
                      </WalletMultiButton>
                    ) : (
                      <WalletMultiButton>
                        Connect a Solana Wallet
                        <div>
                          <Phantom />
                          <Explosion />
                          <Bell />
                        </div>
                      </WalletMultiButton>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}

        {/* total/price box thing */}
        <div className={styles.buttonContainer}>
          {selectedPaymentMethod !== "applepay" ||
          buying ||
          calculateTotal(coupon) == 0 ? (
            <Button
              className={styles.checkoutButton}
              disabled={selectedPaymentMethod === "usdc" && !hasEnoughUsdc()}
              loading={loading || buying}
              fullWidth
              onClick={() => {
                if (!user?.publicKey) {
                  postToSlack(
                    "is not logged in while trying to purhcase tickets, showing modal... <@U1210FPAL> <@U01BNA4G4G3>",
                    "firehose",
                    user
                  )
                  setShowSigninModal(true)
                } else {
                  checkout()
                }
              }}
            >
              Purchase Tickets
            </Button>
          ) : (
            <>
              {!!paymentIntentId && !applePayLoading && (
                <Elements stripe={stripePromise} options={stripeOptions()}>
                  <StripeStuff
                    total={calculateTotal(coupon)}
                    promo={calculateDiscount(coupon)}
                    ticketGroup={ticketGroup}
                    paymentIntentId={paymentIntentId}
                    ticket_group_uvid={params?.ticket_group_uvid}
                    quantity={quantity}
                    ref={applePayRef}
                    applePay={true}
                    selectedEvent={selectedEvent}
                    expressSuccess={() => checkout(true)}
                    clientSecret={stripeClientSecret}
                  />
                </Elements>
              )}
            </>
          )}
        </div>
      </div>
    )
  }

  // the user has not yet minted the ticket
  // can be logged in or not, will be prompted to log in before continuing
  // this is its own view because the sidebar is different, all others here share
  // one sidebar
  if (location.pathname.includes("tickets")) {
    return (
      <>
        {!!ticketGroup && (
          <SeatPreview
            ticketGroup={ticketGroup}
            updateQuantity={(qty) => {
              let base = location.pathname
              navigate(`${base}?quantity=${qty}`, { replace: true })
              setQuantity(qty)
            }}
            onClick={(q) => {
              postToSlack(
                `has selected seats for <https://xp.xyz/event/${selectedEvent.event_id}|${selectedEvent.title}>`,
                "firehose",
                user
              )
              setSeatsSelected(true)
              if (!user?.publicKey) {
                setShowSigninModal(true)
              } else {
                navigate(
                  `/checkout/${params.event_id}/${params.ticket_group_uvid}/delivery?quantity=${quantity}`
                )
              }
            }}
          />
        )}

        {showSigninModal && (
          <SignInModal
            onClose={() => {
              setShowSigninModal(false)
            }}
          />
        )}
      </>
    )
  }

  let mapVendor, mapConfig
  if (selectedEvent?.seatmap_id_3ddv) {
    mapVendor = "3ddv"
    mapConfig = {
      venueId: selectedEvent.seatmap_id_3ddv,
    }
  } else if (
    selectedEvent?.tevo_venue_id &&
    selectedEvent?.tevo_venue_config_id
  ) {
    mapVendor = "tevo"
    mapConfig = {
      venueId: selectedEvent?.tevo_venue_id,
      configurationId: selectedEvent?.tevo_venue_config_id,
    }
  }

  return (
    <Layout
      className={styles.topLevelContainer}
      contentClassName={styles.layoutContainer}
      noScroll
      showHeader={window.innerWidth > 960}
    >
      <div className={styles.blurhashContainer}>
        {selectedEvent?.image_blurhash && (
          <Blurhash
            hash={selectedEvent?.image_blurhash}
            width={window.innerWidth}
            height={window.innerHeight}
            resolutionX={32}
            resolutionY={32}
            punch={0}
          />
        )}
        <div className={styles.blurhashOverlay} />
      </div>
      <div className={styles.pageContainerOuter}>
        <div className={styles.leftColumn}>
          <div className={styles.back} onClick={() => navigate(-1)}>
            <AngleLeft />
            <span>Back</span>
          </div>
          <div className={styles.showInfo}>
            <picture>
              <source srcSet={selectedEvent?.image_avif} type="image/avif" />
              <source srcSet={selectedEvent?.image_webp} type="image/webp" />
              <img
                src={
                  selectedEvent?.image
                    ? selectedEvent.image
                    : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
                }
                alt={selectedEvent?.title}
              />
            </picture>

            <div className={styles.showInfoHeader}>
              <div className={styles.showInfoContent}>
                <div className={styles.date}>
                  {selectedEvent?.date_formatted
                    ?.replace(",", " |")
                    .replace("•", " |")}
                </div>
                <div className={styles.title}>
                  {selectedEvent?.short_title || selectedEvent?.title}
                </div>
                <div className={styles.venue}>
                  {selectedEvent?.venue_name}, {selectedEvent?.venue_city},{" "}
                  {selectedEvent?.venue_state}
                  <Info />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.seatInfoContainer}>
            <div className={styles.seatInfo}>
              <div className={styles.section}>
                Section
                <span className={styles.value}>
                  {ticketGroup?.section?.name}
                </span>
              </div>
              <div className={styles.divider} />

              <div className={styles.row}>
                Row
                <span className={styles.value}>{ticketGroup?.row?.name}</span>
              </div>

              {selectedEvent?.venue_state == "MD" && (
                <>
                  <div className={styles.divider} />

                  <div className={styles.row}>
                    Seats
                    <span className={styles.value}>
                      {formatSeatRange(ticketGroup?.seats)}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className={styles.ticketInfoRight}>
              <div className={styles.ticketPrice}>
                <span style={{ textWrap: "nowrap" }}>
                  ${(ticketGroup?.price / 100).toFixed(2)}
                  {selectedEvent?.venue_country == "CA" && " USD"}
                </span>
                ea.
              </div>
            </div>
          </div>
          {deliveryMethod && price && location.pathname.includes("payment") && (
            <>
              <Promo
                total={price}
                removeCoupon={() => {
                  removeCoupon()
                  // todo remove coupon function for payment intent id
                }}
                setCoupon={applyCoupon}
              />
              <div
                className={classNames(
                  styles.cardBottom,
                  !coupon && styles.noCoupon
                )}
              >
                <div className={styles.cardContent}>
                  <div className={styles.cardTitle}>Subtotal</div>
                  <div className={styles.subtotal}>
                    {quantity} x ${(ticketGroup?.price / 100).toFixed(2)}
                    {selectedEvent?.venue_country == "CA" && " USD"}
                  </div>
                </div>
              
                {tax && (
                  <div className={styles.cardContent}>
                    <div className={styles.cardTitle}>Tax</div>
                    <div className={styles.subtotal}>
                      ${(tax/ 100).toFixed(2)}
                    </div>
                  </div>
                )}


                {coupon && (
                  <div className={styles.cardContent}>
                    <div
                      className={classNames(styles.cardTitle, styles.discount)}
                    >
                      Discount
                    </div>
                    <div className={styles.discount}>
                      -{coupon && formatCents(calculateDiscount(coupon))}
                    </div>
                  </div>
                )}
                <div className={styles.cardContent}>
                  <div className={classNames(styles.cardTitle, styles.total)}>
                    Total
                  </div>
                  <div className={styles.total}>
                    {formatCents(calculateTotal(coupon))}{" "}
                    {selectedEvent?.venue_country == "CA" && " USD"}
                  </div>
                </div>
                <div className={styles.taxDisclaimer}>
                  Sales Tax and Applicable Fees included in Total
                </div>
              </div>
              {renderCheckout()}
            </>
          )}
          {location.pathname.includes("delivery") && (
            <SelectDeliveryMethod
              quantity={quantity}
              setDeliveryMethod={setDeliveryMethod}
            />
          )}
        </div>
        {/* end left sidebar */}
        <div className={styles.checkoutContainer}>
          <div className={styles.seatmap}>
            {mapVendor === "3ddv" && ticketGroup && (
              <SeatMap
                config={mapConfig}
                ticketGroups={[ticketGroup]}
                zoomedTicketGroup={ticketGroup}
                highlightedSections={[ticketGroup.section.name]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
                translationRules={selectedEvent?.translations_3ddv}
              />
            )}
            {mapVendor === "tevo" && ticketGroup && (
              <SeatMapTevo
                config={mapConfig}
                ticketGroups={[ticketGroup]}
                zoomedTicketGroup={ticketGroup}
                highlightedSections={[]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
                translationRules={selectedEvent?.translations_3ddv}
              />
            )}
          </div>
        </div>
      </div>
      {showSigninModal && (
        <SignInModal
          onClose={() => {
            setShowSigninModal(false)
          }}
        />
      )}
    </Layout>
  )
}

export default CheckoutPage
