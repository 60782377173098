export const Spotify = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0C4.02947 0 0 4.02954 0 9C0 13.9708 4.02954 18 9 18C13.9709 18 18 13.9708 18 9C18 4.02989 13.971 0 9 0ZM13.1273 12.9807C12.966 13.245 12.62 13.3289 12.3556 13.1666C10.2425 11.8759 7.58243 11.5835 4.44966 12.2993C4.30459 12.3323 4.15235 12.3063 4.0264 12.2271C3.90046 12.1479 3.81112 12.022 3.77803 11.8769C3.76154 11.8051 3.75938 11.7307 3.77167 11.6581C3.78396 11.5854 3.81045 11.5159 3.84964 11.4535C3.88882 11.3912 3.93993 11.3371 4.00004 11.2945C4.06014 11.2519 4.12806 11.2215 4.19991 11.2052C7.62834 10.4219 10.569 10.7591 12.9414 12.209C13.2057 12.3713 13.2896 12.7163 13.1273 12.9807ZM14.2289 10.5301C14.0257 10.8602 13.5937 10.9645 13.2638 10.7613C10.8446 9.27436 7.1569 8.8437 4.29553 9.71227C3.92442 9.82434 3.5325 9.61523 3.41986 9.24476C3.30806 8.87365 3.51731 8.48243 3.88779 8.36965C7.15627 7.37782 11.2196 7.85827 13.9978 9.56545C14.3277 9.76866 14.432 10.2006 14.2289 10.5301ZM14.3234 7.97822C11.4228 6.25528 6.63708 6.0968 3.86768 6.93745C3.42295 7.07231 2.95263 6.82123 2.81791 6.3765C2.68313 5.93156 2.93393 5.46159 3.37901 5.32638C6.55805 4.36127 11.843 4.54781 15.1825 6.53034C15.5834 6.76772 15.7145 7.28437 15.477 7.68382C15.2406 8.0839 14.7221 8.21573 14.3234 7.97822Z"
      fill="#1ED760"
    />
  </svg>
)
