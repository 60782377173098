export const Basketball = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0806 3.29031C11.7258 3.62902 9.44623 4.58601 7.51074 6.18279L11.672 10.3441C13.1667 8.41934 14 5.97848 14.0806 3.29031V3.29031ZM16 14.672L24.4892 6.18279C22.2097 4.30107 19.457 3.30644 16.6667 3.16666C16.6021 6.60214 15.5107 9.74193 13.5107 12.1828L16 14.672ZM5.68279 8.01074C4.08601 9.94623 3.12902 12.2258 2.79031 14.5806C5.47848 14.5 7.92472 13.6667 9.84408 12.172L5.68279 8.01074ZM20.3172 18.9892C22.7527 16.9946 25.8925 15.9032 29.3333 15.8333C29.1935 13.043 28.1989 10.2903 26.3172 8.01074L17.8279 16.5L20.3172 18.9892V18.9892ZM16 18.3279L7.51074 26.8172C9.79031 28.6935 12.543 29.6935 15.3333 29.8333C15.4032 26.3925 16.4946 23.2527 18.4892 20.8172L16 18.3279ZM26.3172 24.9892C27.914 23.0538 28.871 20.7742 29.2097 18.4193C26.5161 18.5054 24.0753 19.3387 22.1559 20.8279L26.3172 24.9892ZM11.6828 14.0107C9.24193 16.0107 6.10214 17.1075 2.66666 17.1667C2.80644 19.957 3.80107 22.7097 5.68279 24.9892L14.172 16.5L11.6828 14.0107ZM17.9193 29.7097C20.2742 29.371 22.5538 28.414 24.4892 26.8172L20.3279 22.6559C18.8387 24.5753 18.0054 27.0215 17.9193 29.7097Z"
      fill="#FDFCEA"
    />
  </svg>
)
