import { PublicKey } from "@solana/web3.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh"

export interface UpdateArgsFields {
  name: string | null
  symbol: string | null
  uri: string | null
  creators: Array<types.CreatorFields> | null
  sellerFeeBasisPoints: number | null
  primarySaleHappened: boolean | null
  isMutable: boolean | null
}

export interface UpdateArgsJSON {
  name: string | null
  symbol: string | null
  uri: string | null
  creators: Array<types.CreatorJSON> | null
  sellerFeeBasisPoints: number | null
  primarySaleHappened: boolean | null
  isMutable: boolean | null
}

export class UpdateArgs {
  readonly name: string | null
  readonly symbol: string | null
  readonly uri: string | null
  readonly creators: Array<types.Creator> | null
  readonly sellerFeeBasisPoints: number | null
  readonly primarySaleHappened: boolean | null
  readonly isMutable: boolean | null

  constructor(fields: UpdateArgsFields) {
    this.name = fields.name
    this.symbol = fields.symbol
    this.uri = fields.uri
    this.creators =
      (fields.creators &&
        fields.creators.map((item) => new types.Creator({ ...item }))) ||
      null
    this.sellerFeeBasisPoints = fields.sellerFeeBasisPoints
    this.primarySaleHappened = fields.primarySaleHappened
    this.isMutable = fields.isMutable
  }

  static layout(property?: string) {
    return borsh.struct(
      [
        borsh.option(borsh.str(), "name"),
        borsh.option(borsh.str(), "symbol"),
        borsh.option(borsh.str(), "uri"),
        borsh.option(borsh.vec(types.Creator.layout()), "creators"),
        borsh.option(borsh.u16(), "sellerFeeBasisPoints"),
        borsh.option(borsh.bool(), "primarySaleHappened"),
        borsh.option(borsh.bool(), "isMutable"),
      ],
      property
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromDecoded(obj: any) {
    return new UpdateArgs({
      name: obj.name,
      symbol: obj.symbol,
      uri: obj.uri,
      creators:
        (obj.creators &&
          obj.creators.map(
            (
              item: any /* eslint-disable-line @typescript-eslint/no-explicit-any */
            ) => types.Creator.fromDecoded(item)
          )) ||
        null,
      sellerFeeBasisPoints: obj.sellerFeeBasisPoints,
      primarySaleHappened: obj.primarySaleHappened,
      isMutable: obj.isMutable,
    })
  }

  static toEncodable(fields: UpdateArgsFields) {
    return {
      name: fields.name,
      symbol: fields.symbol,
      uri: fields.uri,
      creators:
        (fields.creators &&
          fields.creators.map((item) => types.Creator.toEncodable(item))) ||
        null,
      sellerFeeBasisPoints: fields.sellerFeeBasisPoints,
      primarySaleHappened: fields.primarySaleHappened,
      isMutable: fields.isMutable,
    }
  }

  toJSON(): UpdateArgsJSON {
    return {
      name: this.name,
      symbol: this.symbol,
      uri: this.uri,
      creators:
        (this.creators && this.creators.map((item) => item.toJSON())) || null,
      sellerFeeBasisPoints: this.sellerFeeBasisPoints,
      primarySaleHappened: this.primarySaleHappened,
      isMutable: this.isMutable,
    }
  }

  static fromJSON(obj: UpdateArgsJSON): UpdateArgs {
    return new UpdateArgs({
      name: obj.name,
      symbol: obj.symbol,
      uri: obj.uri,
      creators:
        (obj.creators &&
          obj.creators.map((item) => types.Creator.fromJSON(item))) ||
        null,
      sellerFeeBasisPoints: obj.sellerFeeBasisPoints,
      primarySaleHappened: obj.primarySaleHappened,
      isMutable: obj.isMutable,
    })
  }

  toEncodable() {
    return UpdateArgs.toEncodable(this)
  }
}
