export const Instagram = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0023 6.53057C8.97542 6.53057 6.53392 8.9726 6.53392 12.0002C6.53392 15.0277 8.97542 17.4698 12.0023 17.4698C15.0292 17.4698 17.4707 15.0277 17.4707 12.0002C17.4707 8.9726 15.0292 6.53057 12.0023 6.53057ZM12.0023 15.5561C10.0462 15.5561 8.44714 13.9614 8.44714 12.0002C8.44714 10.0389 10.0415 8.44421 12.0023 8.44421C13.9631 8.44421 15.5575 10.0389 15.5575 12.0002C15.5575 13.9614 13.9583 15.5561 12.0023 15.5561ZM18.9698 6.30683C18.9698 7.01612 18.3987 7.58259 17.6944 7.58259C16.9852 7.58259 16.4189 7.01136 16.4189 6.30683C16.4189 5.6023 16.99 5.03107 17.6944 5.03107C18.3987 5.03107 18.9698 5.6023 18.9698 6.30683ZM22.5916 7.60164C22.5107 5.89268 22.1205 4.3789 20.8688 3.1317C19.6219 1.8845 18.1084 1.49416 16.3998 1.40847C14.6389 1.3085 9.36092 1.3085 7.59999 1.40847C5.89618 1.4894 4.38274 1.87974 3.13106 3.12694C1.87938 4.37414 1.49388 5.88792 1.40821 7.59687C1.30827 9.35819 1.30827 14.6374 1.40821 16.3987C1.48912 18.1076 1.87938 19.6214 3.13106 20.8686C4.38274 22.1158 5.89142 22.5062 7.59999 22.5919C9.36092 22.6918 14.6389 22.6918 16.3998 22.5919C18.1084 22.5109 19.6219 22.1206 20.8688 20.8686C22.1157 19.6214 22.506 18.1076 22.5916 16.3987C22.6916 14.6374 22.6916 9.36295 22.5916 7.60164ZM20.3167 18.2885C19.9455 19.2216 19.2268 19.9404 18.2893 20.3164C16.8853 20.8734 13.5538 20.7449 12.0023 20.7449C10.4508 20.7449 7.11455 20.8686 5.71533 20.3164C4.78252 19.9451 4.06387 19.2263 3.68789 18.2885C3.13106 16.8842 3.25956 13.552 3.25956 12.0002C3.25956 10.4483 3.13582 7.11132 3.68789 5.71179C4.05911 4.77877 4.77776 4.05996 5.71533 3.6839C7.11931 3.12694 10.4508 3.25547 12.0023 3.25547C13.5538 3.25547 16.89 3.1317 18.2893 3.6839C19.2221 4.0552 19.9407 4.77401 20.3167 5.71179C20.8735 7.11608 20.745 10.4483 20.745 12.0002C20.745 13.552 20.8735 16.889 20.3167 18.2885Z"
      fill="#FDFCEA"
    />
  </svg>
)
