export const Lock = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.4375 5.375H8.96875V3.96875C8.96875 2.33203 7.63672 1 6 1C4.36328 1 3.03125 2.33203 3.03125 3.96875V5.375H2.5625C2.04492 5.375 1.625 5.79492 1.625 6.3125V10.0625C1.625 10.5801 2.04492 11 2.5625 11H9.4375C9.95508 11 10.375 10.5801 10.375 10.0625V6.3125C10.375 5.79492 9.95508 5.375 9.4375 5.375ZM7.40625 5.375H4.59375V3.96875C4.59375 3.19336 5.22461 2.5625 6 2.5625C6.77539 2.5625 7.40625 3.19336 7.40625 3.96875V5.375Z"
      fill="#F7F7F7"
    />
  </svg>
)
