export const Unavailable = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.9993 4.66602C15.1128 4.66602 4.66602 15.1127 4.66602 27.9993C4.66602 40.886 15.1128 51.3327 27.9993 51.3327C40.8859 51.3327 51.3327 40.8859 51.3327 27.9993C51.3327 15.1128 40.886 4.66602 27.9993 4.66602ZM40.2407 15.758C46.3984 21.9157 46.8267 31.3275 42.1861 37.9281L18.0705 13.8127C24.6757 9.16897 34.0863 9.60374 40.2407 15.758ZM15.758 40.2407C9.60026 34.0829 9.17198 24.6712 13.8126 18.0705L37.9282 42.186C31.3231 46.8297 21.9124 46.395 15.758 40.2407V40.2407Z"
      fill="#FDFCEA"
    />
  </svg>
)
