export const CoinbaseUSDC = (props) => (
  <svg
    {...props}
    width="40"
    height="24"
    viewBox="0 0 40 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6911_8309)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#0052FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5625 12C3.5625 16.6599 7.34009 20.4375 12 20.4375C16.6599 20.4375 20.4375 16.6599 20.4375 12C20.4375 7.34009 16.6599 3.5625 12 3.5625C7.34009 3.5625 3.5625 7.34009 3.5625 12ZM9.84375 9.28125C9.53309 9.28125 9.28125 9.53309 9.28125 9.84375V14.1562C9.28125 14.4669 9.53309 14.7188 9.84375 14.7188H14.1562C14.4669 14.7188 14.7188 14.4669 14.7188 14.1562V9.84375C14.7188 9.53309 14.4669 9.28125 14.1562 9.28125H9.84375Z"
        fill="white"
      />
    </g>
    <g clipPath="url(#clip1_6911_8309)">
      <path
        d="M28 24C34.65 24 40 18.65 40 12C40 5.34996 34.65 0 28 0C21.35 0 16 5.34996 16 12C16 18.65 21.35 24 28 24Z"
        fill="#2775CA"
      />
      <path
        d="M31.3001 13.8996C31.3001 12.1497 30.2501 11.5496 28.1501 11.2997C26.6501 11.0996 26.3501 10.6997 26.3501 9.99961C26.3501 9.29953 26.8501 8.84965 27.8501 8.84965C28.7501 8.84965 29.2501 9.14965 29.5001 9.89965C29.5501 10.0496 29.7001 10.1496 29.8501 10.1496H30.65C30.8501 10.1496 31.0001 9.99961 31.0001 9.79969V9.74965C30.8 8.64961 29.9 7.79965 28.7501 7.69969V6.49969C28.7501 6.29965 28.6001 6.14965 28.3501 6.09961H27.6001C27.4001 6.09961 27.2501 6.24961 27.2 6.49969V7.64965C25.7 7.84969 24.7501 8.84965 24.7501 10.0997C24.7501 11.7497 25.7501 12.3996 27.8501 12.6497C29.2501 12.8997 29.7001 13.1997 29.7001 13.9997C29.7001 14.7997 29 15.3497 28.0501 15.3497C26.75 15.3497 26.3 14.7996 26.15 14.0496C26.1001 13.8497 25.9501 13.7496 25.8001 13.7496H24.95C24.7501 13.7496 24.6001 13.8996 24.6001 14.0997V14.1497C24.8 15.3996 25.6001 16.2996 27.2501 16.5497V17.7497C27.2501 17.9496 27.4001 18.0996 27.65 18.1497H28.4C28.6001 18.1497 28.7501 17.9997 28.8001 17.7497V16.5497C30.3001 16.2996 31.3001 15.2496 31.3001 13.8996Z"
        fill="#FAFAFA"
      />
      <path
        d="M25.45 19.1499C21.55 17.75 19.55 13.4 21 9.54992C21.75 7.44992 23.4 5.84996 25.45 5.09996C25.65 5 25.75 4.85 25.75 4.59992V3.89996C25.75 3.69992 25.65 3.54992 25.45 3.5C25.4 3.5 25.3 3.5 25.25 3.54992C20.5 5.04992 17.9 10.1 19.4 14.85C20.3 17.6499 22.45 19.8 25.25 20.7C25.45 20.7999 25.65 20.7 25.7 20.4999C25.75 20.45 25.75 20.4 25.75 20.3V19.5999C25.75 19.4499 25.6 19.25 25.45 19.1499ZM30.75 3.54992C30.55 3.44996 30.35 3.54992 30.3 3.74996C30.25 3.8 30.25 3.84992 30.25 3.95V4.64996C30.25 4.85 30.4 5.04992 30.55 5.15C34.45 6.54992 36.45 10.8999 35 14.75C34.25 16.85 32.6 18.45 30.55 19.2C30.35 19.2999 30.25 19.4499 30.25 19.7V20.4C30.25 20.6 30.35 20.75 30.55 20.7999C30.6 20.7999 30.7 20.7999 30.75 20.75C35.5 19.25 38.1 14.1999 36.6 9.44996C35.7 6.59996 33.5 4.44992 30.75 3.54992Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <clipPath id="clip0_6911_8309">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_6911_8309">
        <rect width="24" height="24" fill="white" transform="translate(16)" />
      </clipPath>
    </defs>
  </svg>
)
