export const ArrowDown = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1276 7.00251L14.7882 7.66315C15.0679 7.94288 15.0679 8.39521 14.7882 8.67196L9.00612 14.457C8.72639 14.7368 8.27406 14.7368 7.99731 14.457L2.21224 8.67196C1.93251 8.39223 1.93251 7.9399 2.21224 7.66315L2.87288 7.00251C3.15559 6.7198 3.61684 6.72575 3.8936 7.01441L7.30989 10.6003V2.0477C7.30989 1.65191 7.6283 1.3335 8.02409 1.3335H8.97637C9.37215 1.3335 9.69057 1.65191 9.69057 2.0477V10.6003L13.1069 7.01441C13.3836 6.72278 13.8449 6.71682 14.1276 7.00251V7.00251Z"
      fill="#FDFCEA"
    />
  </svg>
)
