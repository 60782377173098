export const Gear = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2181 10.1028L13.073 9.44151C13.1885 8.81785 13.1885 8.17807 13.073 7.55442L14.2181 6.89313C14.3498 6.81786 14.409 6.66195 14.366 6.51679C14.0676 5.5598 13.5595 4.69421 12.8955 3.97379C12.7934 3.86357 12.6267 3.83669 12.4977 3.91196L11.3525 4.57325C10.8713 4.15927 10.3176 3.83938 9.71813 3.6297V2.30981C9.71813 2.15928 9.61329 2.02756 9.46544 1.9953C8.47888 1.77487 7.46814 1.78562 6.52997 1.9953C6.38212 2.02756 6.27728 2.15928 6.27728 2.30981V3.63239C5.68051 3.84475 5.12675 4.16465 4.64288 4.57593L3.50041 3.91465C3.36869 3.83938 3.20471 3.86357 3.10256 3.97647C2.43859 4.69421 1.93052 5.5598 1.63214 6.51948C1.58644 6.66464 1.64827 6.82055 1.77999 6.89582L2.92514 7.55711C2.80955 8.18076 2.80955 8.82054 2.92514 9.4442L1.77999 10.1055C1.64827 10.1808 1.58913 10.3367 1.63214 10.4818C1.93052 11.4388 2.43859 12.3044 3.10256 13.0248C3.20471 13.135 3.37138 13.1619 3.50041 13.0867L4.64557 12.4254C5.12675 12.8393 5.68051 13.1592 6.27997 13.3689V14.6915C6.27997 14.842 6.38481 14.9737 6.53266 15.006C7.51921 15.2264 8.52996 15.2157 9.46813 15.006C9.61598 14.9737 9.72082 14.842 9.72082 14.6915V13.3689C10.3176 13.1565 10.8713 12.8367 11.3552 12.4254L12.5004 13.0867C12.6321 13.1619 12.7961 13.1377 12.8982 13.0248C13.5622 12.3071 14.0703 11.4415 14.3686 10.4818C14.409 10.334 14.3498 10.1781 14.2181 10.1028V10.1028ZM7.9977 10.6485C6.81222 10.6485 5.84717 9.68344 5.84717 8.49796C5.84717 7.31248 6.81222 6.34743 7.9977 6.34743C9.18318 6.34743 10.1482 7.31248 10.1482 8.49796C10.1482 9.68344 9.18318 10.6485 7.9977 10.6485Z"
      fill="#FAFAFA"
    />
  </svg>
)
