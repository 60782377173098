import type { default as NodeBundlr, WebBundlr } from "@bundlr-network/client";
import { Cluster } from "@solana/web3.js";
import fetch from "cross-fetch";

import { getConfig } from "./config";
import {
  Identity,
  KeypairIdentity,
  WalletAdapterIdentity,
  DummyKeypairWalletAdapter,
} from "./identity";

export type Bundlr = NodeBundlr | WebBundlr;

/**
 * Fix for inconsistency of Bundlr ESM and CJS modules:
 * - CJS: { default: NodeBundlr, WebBundlr: WebBundlr }
 * - ESM: { default: { default: NodeBundlr, WebBundlr: WebBundlr } }
 */
import * as bundlr from "@bundlr-network/client";
let NodeBundlrCtor = bundlr.default;
let WebBundlrCtor = bundlr.WebBundlr;
if (
  NodeBundlrCtor.hasOwnProperty("default") &&
  NodeBundlrCtor.hasOwnProperty("WebBundlr")
) {
  // @ts-ignore
  WebBundlrCtor = NodeBundlrCtor.WebBundlr;
  // @ts-ignore
  NodeBundlrCtor = NodeBundlrCtor.default;
}

// @ts-ignore
const browser = Boolean(process.browser);

export class File {
  public readonly mimetype: string;
  public readonly data: Buffer;

  private _text?: string;
  public get text(): string {
    this._text ??= this.data.toString("utf-8");
    return this._text;
  }

  private _json?: any;
  public get json(): any {
    this._json ??= JSON.parse(this.text);
    return this._json;
  }

  constructor(data: Buffer, mimetype: string = "application/octet-stream") {
    this.data = data;
    this.mimetype = mimetype;
  }
}

export interface FileInput {
  mimetype?: string;
  data?: Buffer;
  text?: string;
  json?: any;
}

export abstract class Storage {
  public static init(cluster: Cluster | "unittest", identity: Identity | null) {
    if (cluster == "unittest") return new MockStorage(identity);
    return new BundlrStorage(cluster, identity);
  }

  public async get(url: string): Promise<File> {
    const result = await fetch(url);
    if (result.status >= 400) {
      throw new Error(`GET ${url}: ${result.status} ${result.statusText}`);
    }
    return new File(
      Buffer.from(await result.arrayBuffer()),
      result.headers.get("Content-Type") ?? "application/octet-stream"
    );
  }

  public abstract upload(input: FileInput): Promise<string>;

  protected _normalize(input: FileInput): {
    data: Buffer;
    mimetype: string;
  } {
    if (input.json && !input.text) {
      input.text = JSON.stringify(input.json);
      input.mimetype ??= "application/json";
    }
    if (input.text && !input.data) {
      input.data = Buffer.from(input.text, "utf-8");
      input.mimetype ??= "text/plain";
    }
    if (!input.data) throw new Error("No data is provided");
    return {
      data: input.data,
      mimetype: input.mimetype ?? "application/octet-stream",
    };
  }
}

export class BundlrStorage extends Storage {
  private _bundlr?: Bundlr;

  public constructor(cluster: Cluster, identity: Identity | null) {
    super();
    const config = getConfig(cluster);
    const options = { providerUrl: config.rpcEndpoint };
    if (identity instanceof KeypairIdentity) {
      if (!browser) {
        this._bundlr = new NodeBundlrCtor(
          config.bundlrEndpoint,
          "solana",
          identity.keypair.secretKey,
          options
        );
      } else {
        this._bundlr = new WebBundlrCtor(
          config.bundlrEndpoint,
          "solana",
          new DummyKeypairWalletAdapter(identity.keypair),
          options
        );
      }
    } else if (identity instanceof WalletAdapterIdentity) {
      this._bundlr = new WebBundlrCtor(
        config.bundlrEndpoint,
        "solana",
        identity.adapter,
        options
      );
    }
  }

  public async upload(input: FileInput): Promise<string> {
    if (!this._bundlr) throw new Error("Storage is in read only mode");

    const normalInput = this._normalize(input);

    let lastError: any;

    for (const timeout of [0, 1, 10]) {
      if (timeout) {
        console.warn(`${lastError}. Retrying upload in ${timeout}s`);
        await new Promise((r) => setTimeout(r, timeout * 1000));
      }

      try {
        let [price, balance] = await Promise.all([
          this._bundlr.getPrice(normalInput.data.length),
          this._bundlr.getLoadedBalance(),
        ]);
        if (price.minus(balance).gt(0)) {
          await this._bundlr.fund(
            // Price might change during the upload,
            // so we add 20% lee-way to the price
            price.minus(balance).multipliedBy(1.2).decimalPlaces(0, 6)
          );
          // Wait for balance to be updated
          for (let i = 0; i < 10; i++) {
            balance = await this._bundlr.getLoadedBalance();
            if (price.minus(balance).lte(0)) break;
            await new Promise((r) => setTimeout(r, 1000)); // sleep 1 second
          }
        }
        const response = await this._bundlr.upload(normalInput.data, {
          tags: [
            {
              name: "Content-Type",
              value: normalInput.mimetype,
            },
          ],
        });
        return `https://arweave.net/${response.id}`;
      } catch (e: any) {
        lastError = e;
      }
    }
    throw lastError;
  }
}

export class MockStorage extends Storage {
  private static _prefix = "https://mockstorage.local/";
  private static _storage: Map<string, File> = new Map();
  private static _counter: number = 0;

  private _readonly: boolean;

  public constructor(identity: Identity | null) {
    super();
    this._readonly = identity === null;
  }

  public async get(url: string): Promise<File> {
    if (url.startsWith(MockStorage._prefix)) {
      const result = MockStorage._storage.get(url);
      if (!result) {
        throw new Error(`GET ${url}: 404 Not Found`);
      }
      return result;
    }
    return await super.get(url);
  }

  public async upload(input: FileInput): Promise<string> {
    if (this._readonly) throw new Error("Storage is in read only mode");

    const normalInput = this._normalize(input);
    const url = MockStorage._prefix + ++MockStorage._counter;
    MockStorage._storage.set(
      url,
      new File(normalInput.data, normalInput.mimetype)
    );
    return url;
  }
}
