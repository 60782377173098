import {
  BaseAccount,
  TokenAccount,
  TransactionResult,
  Transactions,
} from "@captainxyz/solana-core";

import { TamperproofMetadata, isTamperproofMetadata } from "./metadata";
import { SealedSecret, UnsealedSecret, getState } from "./secrets";
import { encodeHex } from "./codec";

export interface TamperproofHolder extends TokenAccount {
  /**
   * TamperproofHolder extension identifier, is used for type checking.
   */
  readonly extTamperproofHolder: true;

  /**
   * Metadata account.
   */
  readonly metadata: TamperproofMetadata;

  /**
   * Returns `true` if the secret is sealed and the holder owns the NFT,
   * i.e. the account has a balance of 1.
   */
  readonly canRequestUnsealing: boolean;

  /**
   * Sends unsealing request to the oracle.
   */
  requestUnsealing(): Promise<TransactionResult>;

  requestUnsealingWithKey(): Promise<TransactionResult>;

  /**
   * Returns `true` if the secret is unsealed and the holder owns the NFT,
   * i.e. the account has a balance of 1.
   */
  readonly canDecrypt: boolean;

  /**
   * Decrypts the unsealed secret.
   */
  decrypt(): Promise<string>;
}

/**
 * Narrows down type checking to {@link TamperproofHolder}.
 */
export function isTamperproofHolder(
  account: any
): account is TamperproofHolder {
  return (
    account instanceof TokenAccount &&
    account.hasOwnProperty("extTamperproofHolder")
  );
}

BaseAccount.extensions.add({
  isApplicable(account: BaseAccount): boolean {
    return Boolean(
      account.operator.identity &&
        account instanceof TokenAccount &&
        isTamperproofMetadata(account.metadata) &&
        !account.metadata.canUpdate
    );
  },
  properties: {
    extTamperproofHolder: {
      value: true,
    },
    canRequestUnsealing: {
      get(this: TamperproofHolder): boolean {
        return Boolean(
          this.operator.identity &&
            getState(this.metadata.json.secret) === "sealed" &&
            this.balance.value === 1
        );
      },
    },
    canDecrypt: {
      get(this: TamperproofHolder): boolean {
        return Boolean(
          this.operator.identity &&
            getState(this.metadata.json.secret) === "unsealed" &&
            this.balance.value === 1
        );
      },
    },
  },
  methods: {
    async requestUnsealing(
      this: TamperproofHolder
    ): Promise<TransactionResult> {
      if (!this.canRequestUnsealing) {
        throw new Error(`Cannot request unsealing for ${this}`);
      }
      const identity = this.operator.identity!;
      const secret = SealedSecret.decode(this.metadata.json.secret);
      const holderKey = await secret.getPublicKey(identity);
      return await this.operator.execute(
        Transactions.sendSOLs({
          src: identity.publicKey,
          dst: this.metadata.address,
          qty: 1n,
        }),
        Transactions.addMemo({
          memo: `Unseal secret using ${encodeHex(holderKey)}`,
        })
      );
    },

    async requestUnsealingWithKey(
      this: TamperproofHolder,
      holderKey: Uint8Array
    ): Promise<TransactionResult> {
      if (!this.canRequestUnsealing) {
        throw new Error(`Cannot request unsealing for ${this}`);
      }
      const identity = this.operator.identity!;
      return await this.operator.execute(
        Transactions.sendSOLs({
          src: identity.publicKey,
          dst: this.metadata.address,
          qty: 1n,
        }),
        Transactions.addMemo({
          memo: `Unseal secret using ${encodeHex(holderKey)}`,
        })
      );
    },




    async decrypt(this: TamperproofHolder): Promise<string> {
      if (!this.canDecrypt) {
        throw new Error(`Cannot decrypt ${this}`);
      }
      const identity = this.operator.identity!;
      const secret = UnsealedSecret.decode(this.metadata.json.secret);
      return await secret.decrypt(identity);
    },
  },
});
