import { useEffect, useRef, useState } from "react"
import FilterButton from "../FilterButton/FilterButton"
import styles from "./PricePicker.module.scss"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"

const PricePicker = ({ maxPrice, updatePrice }) => {
  const priceListRef = useRef()
  const [price, setPrice] = useState(maxPrice)
  const [showPriceList, setShowPriceList] = useState(false)

  const prices = ["25", "50", "100", "250"]

  useEffect(() => {
    updatePrice(price)
  }, [price]) // eslint-disable-line react-hooks/exhaustive-deps

  useDetectClickOutside(priceListRef, () => {
    setShowPriceList(false)
  })

  return (
    <div className={styles.menuContainer}>
      <FilterButton
        onClick={() => setShowPriceList(!showPriceList)}
        buttonCopy={`$${price}`}
      />
      {showPriceList && (
        <div className={styles.priceListContainer} ref={priceListRef}>
          {prices.map((p) => (
            <div
              className={styles.priceListItem}
              onClick={() => {
                setPrice(p)
                setShowPriceList(false)
              }}
            >
              ${p}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default PricePicker
