import { Button, Layout } from "../components"
import { Link } from "react-router-dom"
import styles from "./NoMatch.module.scss"

const NoMatch = () => (
  <Layout>
    <div className={styles.container}>
      <h1>Page Not Found</h1>
      <Link to={"/"}>
        <Button>Return to Home</Button>
      </Link>
    </div>
  </Layout>
)

export default NoMatch
