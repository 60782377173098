export const Tiktok = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3334 10.082C19.4985 10.0863 17.7086 9.51413 16.2165 8.44613V15.8947C16.216 17.2742 15.7943 18.6208 15.0079 19.7542C14.2214 20.8876 13.1076 21.754 11.8155 22.2373C10.5233 22.7207 9.11441 22.7981 7.7771 22.4592C6.43978 22.1202 5.23782 21.3811 4.33193 20.3406C3.42604 19.3001 2.85941 18.0079 2.70779 16.6367C2.55617 15.2655 2.82681 13.8807 3.4835 12.6675C4.14019 11.4542 5.15164 10.4704 6.3826 9.84747C7.61357 9.22457 9.00537 8.9923 10.3719 9.18173V12.9281C9.74658 12.7314 9.07508 12.7373 8.45332 12.945C7.83156 13.1527 7.29133 13.5516 6.90977 14.0846C6.52821 14.6176 6.32484 15.2576 6.3287 15.9131C6.33256 16.5686 6.54346 17.2061 6.93127 17.7346C7.31908 18.2631 7.86397 18.6555 8.48814 18.8559C9.1123 19.0562 9.78382 19.0542 10.4068 18.8502C11.0298 18.6462 11.5723 18.2505 11.957 17.7197C12.3417 17.1889 12.5488 16.5502 12.5488 15.8947V1.3335H16.2165C16.214 1.64322 16.2399 1.95251 16.294 2.25748C16.4215 2.93827 16.6865 3.5859 17.0728 4.16077C17.4592 4.73563 17.9587 5.22564 18.5409 5.60083C19.3693 6.1485 20.3404 6.44041 21.3334 6.44021V10.082Z"
      fill="#FDFCEA"
    />
  </svg>
)
