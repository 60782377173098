import { Modal } from "../components"
import { Location, X } from "../css/icons"
import styles from "./ShowInfoModal.module.scss"

const ShowInfoModal = ({ event, onClose }) => {
  return (
    <Modal onClose={onClose}>
      <div className={styles.container}>
        <X onClick={onClose} className={styles.close} />
        <img
          src={
            event?.image ||
            "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
          }
          alt={event?.title}
        />
        <div className={styles.cardInfo}>
          <div className={styles.eventInfo}>
            <div className={styles.date}>
              {event?.date_formatted.replace(",", " |").replace("•", "|")}
            </div>
            <h1>{event?.title}</h1>
            <div className={styles.venue}>
              <span>
                {event?.venue_name}, {event?.venue_city}, {event?.venue_state}
              </span>
              <Location />
            </div>
            {/* <div className={styles.lineup}>
              <h2>Lineup</h2>
            </div> */}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ShowInfoModal
