import { PublicKey } from "@solana/web3.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh"

export interface UnknownJSON {
  kind: "Unknown"
}

export class Unknown {
  static readonly discriminator = 0
  static readonly kind = "Unknown"
  readonly discriminator = 0
  readonly kind = "Unknown"

  toJSON(): UnknownJSON {
    return {
      kind: "Unknown",
    }
  }

  toEncodable() {
    return {
      Unknown: {},
    }
  }
}

export interface MintV1JSON {
  kind: "MintV1"
}

export class MintV1 {
  static readonly discriminator = 1
  static readonly kind = "MintV1"
  readonly discriminator = 1
  readonly kind = "MintV1"

  toJSON(): MintV1JSON {
    return {
      kind: "MintV1",
    }
  }

  toEncodable() {
    return {
      MintV1: {},
    }
  }
}

export interface RedeemJSON {
  kind: "Redeem"
}

export class Redeem {
  static readonly discriminator = 2
  static readonly kind = "Redeem"
  readonly discriminator = 2
  readonly kind = "Redeem"

  toJSON(): RedeemJSON {
    return {
      kind: "Redeem",
    }
  }

  toEncodable() {
    return {
      Redeem: {},
    }
  }
}

export interface CancelRedeemJSON {
  kind: "CancelRedeem"
}

export class CancelRedeem {
  static readonly discriminator = 3
  static readonly kind = "CancelRedeem"
  readonly discriminator = 3
  readonly kind = "CancelRedeem"

  toJSON(): CancelRedeemJSON {
    return {
      kind: "CancelRedeem",
    }
  }

  toEncodable() {
    return {
      CancelRedeem: {},
    }
  }
}

export interface TransferJSON {
  kind: "Transfer"
}

export class Transfer {
  static readonly discriminator = 4
  static readonly kind = "Transfer"
  readonly discriminator = 4
  readonly kind = "Transfer"

  toJSON(): TransferJSON {
    return {
      kind: "Transfer",
    }
  }

  toEncodable() {
    return {
      Transfer: {},
    }
  }
}

export interface DelegateJSON {
  kind: "Delegate"
}

export class Delegate {
  static readonly discriminator = 5
  static readonly kind = "Delegate"
  readonly discriminator = 5
  readonly kind = "Delegate"

  toJSON(): DelegateJSON {
    return {
      kind: "Delegate",
    }
  }

  toEncodable() {
    return {
      Delegate: {},
    }
  }
}

export interface DecompressV1JSON {
  kind: "DecompressV1"
}

export class DecompressV1 {
  static readonly discriminator = 6
  static readonly kind = "DecompressV1"
  readonly discriminator = 6
  readonly kind = "DecompressV1"

  toJSON(): DecompressV1JSON {
    return {
      kind: "DecompressV1",
    }
  }

  toEncodable() {
    return {
      DecompressV1: {},
    }
  }
}

export interface CompressJSON {
  kind: "Compress"
}

export class Compress {
  static readonly discriminator = 7
  static readonly kind = "Compress"
  readonly discriminator = 7
  readonly kind = "Compress"

  toJSON(): CompressJSON {
    return {
      kind: "Compress",
    }
  }

  toEncodable() {
    return {
      Compress: {},
    }
  }
}

export interface BurnJSON {
  kind: "Burn"
}

export class Burn {
  static readonly discriminator = 8
  static readonly kind = "Burn"
  readonly discriminator = 8
  readonly kind = "Burn"

  toJSON(): BurnJSON {
    return {
      kind: "Burn",
    }
  }

  toEncodable() {
    return {
      Burn: {},
    }
  }
}

export interface CreateTreeJSON {
  kind: "CreateTree"
}

export class CreateTree {
  static readonly discriminator = 9
  static readonly kind = "CreateTree"
  readonly discriminator = 9
  readonly kind = "CreateTree"

  toJSON(): CreateTreeJSON {
    return {
      kind: "CreateTree",
    }
  }

  toEncodable() {
    return {
      CreateTree: {},
    }
  }
}

export interface VerifyCreatorJSON {
  kind: "VerifyCreator"
}

export class VerifyCreator {
  static readonly discriminator = 10
  static readonly kind = "VerifyCreator"
  readonly discriminator = 10
  readonly kind = "VerifyCreator"

  toJSON(): VerifyCreatorJSON {
    return {
      kind: "VerifyCreator",
    }
  }

  toEncodable() {
    return {
      VerifyCreator: {},
    }
  }
}

export interface UnverifyCreatorJSON {
  kind: "UnverifyCreator"
}

export class UnverifyCreator {
  static readonly discriminator = 11
  static readonly kind = "UnverifyCreator"
  readonly discriminator = 11
  readonly kind = "UnverifyCreator"

  toJSON(): UnverifyCreatorJSON {
    return {
      kind: "UnverifyCreator",
    }
  }

  toEncodable() {
    return {
      UnverifyCreator: {},
    }
  }
}

export interface VerifyCollectionJSON {
  kind: "VerifyCollection"
}

export class VerifyCollection {
  static readonly discriminator = 12
  static readonly kind = "VerifyCollection"
  readonly discriminator = 12
  readonly kind = "VerifyCollection"

  toJSON(): VerifyCollectionJSON {
    return {
      kind: "VerifyCollection",
    }
  }

  toEncodable() {
    return {
      VerifyCollection: {},
    }
  }
}

export interface UnverifyCollectionJSON {
  kind: "UnverifyCollection"
}

export class UnverifyCollection {
  static readonly discriminator = 13
  static readonly kind = "UnverifyCollection"
  readonly discriminator = 13
  readonly kind = "UnverifyCollection"

  toJSON(): UnverifyCollectionJSON {
    return {
      kind: "UnverifyCollection",
    }
  }

  toEncodable() {
    return {
      UnverifyCollection: {},
    }
  }
}

export interface SetAndVerifyCollectionJSON {
  kind: "SetAndVerifyCollection"
}

export class SetAndVerifyCollection {
  static readonly discriminator = 14
  static readonly kind = "SetAndVerifyCollection"
  readonly discriminator = 14
  readonly kind = "SetAndVerifyCollection"

  toJSON(): SetAndVerifyCollectionJSON {
    return {
      kind: "SetAndVerifyCollection",
    }
  }

  toEncodable() {
    return {
      SetAndVerifyCollection: {},
    }
  }
}

export interface MintToCollectionV1JSON {
  kind: "MintToCollectionV1"
}

export class MintToCollectionV1 {
  static readonly discriminator = 15
  static readonly kind = "MintToCollectionV1"
  readonly discriminator = 15
  readonly kind = "MintToCollectionV1"

  toJSON(): MintToCollectionV1JSON {
    return {
      kind: "MintToCollectionV1",
    }
  }

  toEncodable() {
    return {
      MintToCollectionV1: {},
    }
  }
}

export interface SetDecompressibleStateJSON {
  kind: "SetDecompressibleState"
}

export class SetDecompressibleState {
  static readonly discriminator = 16
  static readonly kind = "SetDecompressibleState"
  readonly discriminator = 16
  readonly kind = "SetDecompressibleState"

  toJSON(): SetDecompressibleStateJSON {
    return {
      kind: "SetDecompressibleState",
    }
  }

  toEncodable() {
    return {
      SetDecompressibleState: {},
    }
  }
}

export interface UpdateMetadataJSON {
  kind: "UpdateMetadata"
}

export class UpdateMetadata {
  static readonly discriminator = 17
  static readonly kind = "UpdateMetadata"
  readonly discriminator = 17
  readonly kind = "UpdateMetadata"

  toJSON(): UpdateMetadataJSON {
    return {
      kind: "UpdateMetadata",
    }
  }

  toEncodable() {
    return {
      UpdateMetadata: {},
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromDecoded(obj: any): types.InstructionNameKind {
  if (typeof obj !== "object") {
    throw new Error("Invalid enum object")
  }

  if ("Unknown" in obj) {
    return new Unknown()
  }
  if ("MintV1" in obj) {
    return new MintV1()
  }
  if ("Redeem" in obj) {
    return new Redeem()
  }
  if ("CancelRedeem" in obj) {
    return new CancelRedeem()
  }
  if ("Transfer" in obj) {
    return new Transfer()
  }
  if ("Delegate" in obj) {
    return new Delegate()
  }
  if ("DecompressV1" in obj) {
    return new DecompressV1()
  }
  if ("Compress" in obj) {
    return new Compress()
  }
  if ("Burn" in obj) {
    return new Burn()
  }
  if ("CreateTree" in obj) {
    return new CreateTree()
  }
  if ("VerifyCreator" in obj) {
    return new VerifyCreator()
  }
  if ("UnverifyCreator" in obj) {
    return new UnverifyCreator()
  }
  if ("VerifyCollection" in obj) {
    return new VerifyCollection()
  }
  if ("UnverifyCollection" in obj) {
    return new UnverifyCollection()
  }
  if ("SetAndVerifyCollection" in obj) {
    return new SetAndVerifyCollection()
  }
  if ("MintToCollectionV1" in obj) {
    return new MintToCollectionV1()
  }
  if ("SetDecompressibleState" in obj) {
    return new SetDecompressibleState()
  }
  if ("UpdateMetadata" in obj) {
    return new UpdateMetadata()
  }

  throw new Error("Invalid enum object")
}

export function fromJSON(
  obj: types.InstructionNameJSON
): types.InstructionNameKind {
  switch (obj.kind) {
    case "Unknown": {
      return new Unknown()
    }
    case "MintV1": {
      return new MintV1()
    }
    case "Redeem": {
      return new Redeem()
    }
    case "CancelRedeem": {
      return new CancelRedeem()
    }
    case "Transfer": {
      return new Transfer()
    }
    case "Delegate": {
      return new Delegate()
    }
    case "DecompressV1": {
      return new DecompressV1()
    }
    case "Compress": {
      return new Compress()
    }
    case "Burn": {
      return new Burn()
    }
    case "CreateTree": {
      return new CreateTree()
    }
    case "VerifyCreator": {
      return new VerifyCreator()
    }
    case "UnverifyCreator": {
      return new UnverifyCreator()
    }
    case "VerifyCollection": {
      return new VerifyCollection()
    }
    case "UnverifyCollection": {
      return new UnverifyCollection()
    }
    case "SetAndVerifyCollection": {
      return new SetAndVerifyCollection()
    }
    case "MintToCollectionV1": {
      return new MintToCollectionV1()
    }
    case "SetDecompressibleState": {
      return new SetDecompressibleState()
    }
    case "UpdateMetadata": {
      return new UpdateMetadata()
    }
  }
}

export function layout(property?: string) {
  const ret = borsh.rustEnum([
    borsh.struct([], "Unknown"),
    borsh.struct([], "MintV1"),
    borsh.struct([], "Redeem"),
    borsh.struct([], "CancelRedeem"),
    borsh.struct([], "Transfer"),
    borsh.struct([], "Delegate"),
    borsh.struct([], "DecompressV1"),
    borsh.struct([], "Compress"),
    borsh.struct([], "Burn"),
    borsh.struct([], "CreateTree"),
    borsh.struct([], "VerifyCreator"),
    borsh.struct([], "UnverifyCreator"),
    borsh.struct([], "VerifyCollection"),
    borsh.struct([], "UnverifyCollection"),
    borsh.struct([], "SetAndVerifyCollection"),
    borsh.struct([], "MintToCollectionV1"),
    borsh.struct([], "SetDecompressibleState"),
    borsh.struct([], "UpdateMetadata"),
  ])
  if (property !== undefined) {
    return ret.replicate(property)
  }
  return ret
}
