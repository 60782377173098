import { useNavigate } from "react-router-dom"
import classNames from "classnames"
import { useEffect, useState } from "react"
import styles from "./NFTListItem.module.scss"
import { Ticket } from "../css/icons"

const NFTListItem = ({ nft }) => {
  const navigate = useNavigate()
  const sealed = nft.metadata._json.secret?.state === "sealed"
  const [order, setOrder] = useState(null)

  const getOrder = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/get-order`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      mint: nft.mint?.address.toString(),
      uuid: nft.uuid,
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      setOrder(resp)
    } catch (err) {
      console.log(err, "Error in get-order")
      // alert("err")
    }
  }

  useEffect(() => {
    getOrder()
  }, [nft]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={styles.nftListItem}
      onClick={() => {
        let mint = nft.mint?.address.toString()
        if (!mint) mint = nft.uuid
        navigate(`/holder/ticket/${mint}`)
      }}
    >
      <div
        className={classNames(
          styles.banner,
          sealed ? styles.actionRequired : styles.transferred
        )}
      >
        {sealed ? "Action Required" : "Transferred"}
      </div>
      <div className={styles.nftInfo}>
        <img
          alt="img"
          className={styles.nftImage}
          src={nft.metadata._json.image}
        />

        <div className={styles.metadata}>
          <div className={styles.titleContainer}>
            <div className={styles.nftTitle}>{order?.event?.title}</div>
            <div className={styles.nftVenue}>
              {order?.event?.venue} • {order?.event?.city}
            </div>
          </div>{" "}
          <div className={styles.rightContent}>
            <div className={styles.ticketQuantity}>
              <Ticket /> {order?.quantity}{" "}
              {order?.quantity > 1 ? "Tickets" : "Ticket"}
            </div>
            <div className={styles.nftDate}>
              <span className={styles.dayTime}>
                {order?.event?.date_formatted}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default NFTListItem
