import { useEffect, useState, useRef } from "react"
import { Button, Input, Layout } from "../components"
import styles from "./cctp/CCTP.module.scss"
import Web3 from "web3"
import classNames from "classnames"
import {
  FilledCircle,
  Circle,
  Wallet,
  SolidCircle,
  CheckCircleGreenTransparent,
} from "../css/icons"
import {
  transferUSDC,
  approveMessengerContract,
  burnUSDC,
  getMessage,
  fetchAttestation,
  receiveFunds,
} from "./cctp/contract.js"
import { useSelector } from "react-redux"
import erc20ABI from "./cctp/abis/erc20.json"
import { getAssociatedTokenAddress } from "@solana/spl-token"
import { PublicKey, Connection } from "@solana/web3.js"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import postToSlack from "../postToSlack"
dayjs.extend(utc)

//const SOLANA_USDC_ADDRESS =
//  process.env.SOLANA_USDC_ADDRESS ??
//  "4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU"
//const CIRCLE_ATTESTATION_API =
//  process.env.CIRCLE_ATTESTATION_API ?? "https://iris-api-sandbox.circle.com"
//const USDC_ETH_CONTRACT_ADDRESS =
//  process.env.USDC_ETH_CONTRACT_ADDRESS ??
//  "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238"
//const REACT_APP_RPC =
//  process.env.REACT_APP_RPC ?? "https://api.devnet.solana.com"

const SOLANA_USDC_ADDRESS = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
const CIRCLE_ATTESTATION_API = "https://iris-api.circle.com"
const USDC_ETH_CONTRACT_ADDRESS = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
const REACT_APP_RPC =
  "https://rpc.helius.xyz/?api-key=eed43175-8bd3-45df-bcaf-8b52da7403db"

const CCTPPage = () => {
  const web3Ref = useRef(null)
  const userRef = useRef(null)
  const [ethAccounts, setEthAccounts] = useState([])
  const [usdcBalance, setUSDCBalance] = useState(null)
  const [transferAmount, setTransferAmount] = useState(null)
  const [solUSDCBalance, setSolUSDCBalance] = useState(null)
  const [transferHistory, setTransferHistory] = useState(null)
  const [SOLError, setSOLError] = useState(null)
  const user = useSelector((state) => state.user.user)
  if (user) userRef.current = user

  console.log(userRef.current, "the user")

  async function getSolanaUSDCBalance() {
    if (!userRef.current?.publicKey) return
    const connection = await new Connection(REACT_APP_RPC)
    const mintPublickey = new PublicKey(SOLANA_USDC_ADDRESS)
    console.log(userRef.current?.publicKey, "the user public key")

    try {
      const userTokenAccount = await getAssociatedTokenAddress(
        mintPublickey,
        new PublicKey(userRef.current?.publicKey)
      )

      if (userTokenAccount) {
        const tokenAmount = await connection.getTokenAccountBalance(
          userTokenAccount
        )
        setSolUSDCBalance(tokenAmount.value.uiAmount)
      } else {
        setSOLError("No token account found")
        return
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      getSolanaUSDCBalance()
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  async function connectWallet() {
    const web3 = new Web3(window.ethereum)
    postToSlack(`CCTP: User connecting metamask wallet`, "firehose", user)
    try {
      await window.ethereum.enable()
      web3Ref.current = web3
      return true
    } catch (error) {
      console.error(`Unable to connect wallet: ${error}`)
      return false
    }
  }

  async function getUSDCBalance(walletAddress) {
    const tokenAddress = USDC_ETH_CONTRACT_ADDRESS
    const contract = new web3Ref.current.eth.Contract(erc20ABI, tokenAddress)

    const balance = await contract.methods.balanceOf(walletAddress).call()
    const decimals = await contract.methods.decimals().call()

    const balanceUSDC = balance / 10n ** decimals

    setUSDCBalance(balanceUSDC.toString())
  }

  async function handleConnectWallet() {
    await getSolanaUSDCBalance()
    let connected = false
    if (!web3Ref.current) {
      connected = await connectWallet()
      if (connected) {
        const ethAccounts = await web3Ref.current.eth.getAccounts()
        setEthAccounts(ethAccounts)
        const etherBalance = await web3Ref.current.eth.getBalance(
          ethAccounts[0]
        )
        await getUSDCBalance(ethAccounts[0])
      }
    }
  }

  async function handleTransfer() {
    //--> TODO: Check for transfer in progress
    const history = {
      status: "In Progress",
      started: dayjs.utc().format(),
      ended: "",
      step1: null,
    }

    localStorage.setItem("cctp", JSON.stringify(history))
    postToSlack(
      `CCTP: User starting transfer for ${(transferAmount / 10 ** 6).toFixed(
        2
      )} USDC`,
      "firehose",
      user
    )

    try {
      history.step1 = {
        started: dayjs.utc().format(),
      }
      setTransferHistory(JSON.parse(JSON.stringify(history)))
      const step1Tx = await approveMessengerContract(
        web3Ref.current,
        ethAccounts[0],
        transferAmount
      )
      history.step1.data = step1Tx
      history.step1.ended = dayjs.utc().format()
      console.log("Step 1 Tx: ", step1Tx)
      setTransferHistory(JSON.parse(JSON.stringify(history)))

      history.step2 = {
        started: dayjs.utc().format(),
      }

      setTransferHistory(JSON.parse(JSON.stringify(history)))

      const step2Tx = await burnUSDC(
        web3Ref.current,
        userRef.current?.publicKey?.toString(),
        ethAccounts[0],
        transferAmount
      )
      history.step2.data = step2Tx
      history.step2.ended = dayjs.utc().format()
      console.log("Step 2 Tx: ", step2Tx)
      setTransferHistory(JSON.parse(JSON.stringify(history)))

      postToSlack(`CCTP: User has burned USDC`, "firehose", user)

      history.step3 = {
        started: dayjs.utc().format(),
      }

      setTransferHistory(JSON.parse(JSON.stringify(history)))

      const message = await getMessage(web3Ref.current, step2Tx)
      history.step3.data = message.bytes
      history.step3.ended = dayjs.utc().format()
      console.log("Message Bytes: ", message.bytes)
      setTransferHistory(JSON.parse(JSON.stringify(history)))

      history.step4 = {
        started: dayjs.utc().format(),
      }

      setTransferHistory(JSON.parse(JSON.stringify(history)))

      const step4Tx = await fetchAttestation(message.hash)
      history.step4.data = step4Tx
      history.step4.ended = dayjs.utc().format()
      console.log("Step 4 Tx: ", step4Tx)
      setTransferHistory(JSON.parse(JSON.stringify(history)))

      history.step5 = {
        started: dayjs.utc().format(),
      }

      setTransferHistory(JSON.parse(JSON.stringify(history)))

      const step5Tx = await receiveFunds(
        userRef.current?.publicKey?.toString(),
        message.bytes,
        step4Tx
      )
      history.step5.data = step5Tx
      history.step5.ended = dayjs.utc().format()
      console.log("Step 5 Tx: ", step5Tx)
      setTransferHistory(JSON.parse(JSON.stringify(history)))

      postToSlack(`CCTP: User has received SOL USDC`, "firehose", user)
    } catch (error) {
      history.status = "Failed"
      history.error = error.message
      console.log(error.message)
      setTransferHistory(JSON.parse(JSON.stringify(history)))
    }

    localStorage.setItem("cctp", JSON.stringify(history))

    if (history.error) return
  }

  function getStepStatus(stepData) {
    if (!stepData?.started) {
      return "NOT STARTED"
    } else if (stepData.started && !stepData.ended) {
      return "IN PROGRESS"
    } else if (stepData.started && stepData.ended && !stepData.data) {
      return "INCOMPLETE"
    } else if (stepData.started && stepData.ended && stepData.data) {
      return "COMPLETED"
    }
  }

  function handleTransferAmount(e) {
    //--> TODO: Validate
    if (e.currentTarget.value > 500) {
      e.currentTarget.value = 0
    }
    setTransferAmount(Number(e.currentTarget.value) * 10 ** 6)
  }

  return (
    <Layout>
      <div className={styles.container}>
        <h1>Circle Cross Chain Transfer Protocol</h1>
        <p>Convert your EVM USDC to SOL USDC using Circle's new protocol</p>
        <div>
          {!web3Ref.current && (
            <div className={styles.step1}>
              <img
                src="https://hngr-icons.s3.amazonaws.com/ticketdex/multi-chain-10.5+1.png"
                alt="steps"
              />

              <h2>Cross-Chain Transfer Protocol</h2>
              <p>
                Convert and pay for your ticket with ETH.{" "}
                <a href="https://www.circle.com/en/cross-chain-transfer-protocol?_gl=1*1difijm*_up*MQ..&gclid=CjwKCAjw7oeqBhBwEiwALyHLM1qDI5fbtwkkYQR3tPC7hVBsSqM0LjBgMU-dAkyJ4UiI93ppvFUrpBoCe4sQAvD_BwE">
                  Learn More
                </a>
              </p>
              <div className={styles.contactInfoHeader}>
                {web3Ref.current ? (
                  <Button variant="gray" onClick={() => handleConnectWallet()}>
                    Connected
                  </Button>
                ) : (
                  <Button variant="beige" onClick={() => handleConnectWallet()}>
                    Connect Metamask Wallet
                  </Button>
                )}
              </div>
            </div>
          )}

          {ethAccounts.length > 0 && !transferHistory && (
            <div className={styles.step2}>
              <p>Enter USDC Amount</p>
              <div className={styles.usdcInput}>
                $
                <Input
                  disabled={false}
                  name="transferAmt"
                  onChange={(e) => handleTransferAmount(e)}
                  placeholder={"0.00"}
                  type="number"
                  min="0.01"
                  max="500"
                  step="0.01"
                />
              </div>

              <div className={styles.walletInfo}>
                <Wallet />
                {ethAccounts.map((pk, i) => (
                  <p>
                    {pk.substring(0, 5)}...{pk.substring(pk.length - 4)}
                  </p>
                ))}
              </div>

              <Button variant="beige" onClick={() => handleTransfer()}>
                Transfer USDC to SOL USDC
              </Button>
            </div>
          )}

          {transferHistory &&
            getStepStatus(transferHistory?.step5) !== "COMPLETED" && (
              <div className={styles.step3}>
                <div className={styles.progressBar}>
                  <div
                    className={classNames(
                      styles.step,
                      styles.progress1,
                      styles.active,
                      getStepStatus(transferHistory.step1) === "COMPLETED" &&
                        styles.complete
                    )}
                  >
                    <span>Transfer Initiation</span>
                    <SolidCircle />
                  </div>
                  <div
                    className={classNames(
                      styles.step,
                      styles.progress2,
                      styles.inactive,
                      getStepStatus(transferHistory.step2) === "IN PROGRESS" &&
                        styles.active,
                      getStepStatus(transferHistory.step2) === "COMPLETED" &&
                        styles.complete
                    )}
                  >
                    <span>Burn ETH USDC</span>
                    <SolidCircle />
                  </div>
                  <div
                    className={classNames(
                      styles.step,
                      styles.progress3,
                      styles.inactive,
                      (getStepStatus(transferHistory.step3) === "IN PROGRESS" ||
                        getStepStatus(transferHistory.step4) ===
                          "IN PROGRESS" ||
                        getStepStatus(transferHistory.step5) ===
                          "IN PROGRESS") &&
                        styles.active,
                      getStepStatus(transferHistory.step3) === "COMPLETED" &&
                        styles.complete
                    )}
                  >
                    <span>Fetch Attestation Hash</span>
                    <SolidCircle />
                  </div>
                  <div
                    className={classNames(
                      styles.step,
                      styles.progress4,
                      styles.inactive,
                      getStepStatus(transferHistory.step5) === "IN PROGRESS" &&
                        styles.active,
                      getStepStatus(transferHistory.step5) === "COMPLETED" &&
                        styles.complete
                    )}
                  >
                    <span>Receive SOL USDC</span>
                    <SolidCircle />
                  </div>
                </div>

                {getStepStatus(transferHistory.step1) === "IN PROGRESS" && (
                  <div className={styles.progressContent}>
                    <img
                      src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
                      alt="loading usdc balance"
                      className={styles.walletLoading}
                    />
                    <h2>Transfer Initiation</h2>
                    <p className={styles.subtitle}>
                      Initiate the transfer process in your Metamask wallet when
                      prompted.
                    </p>
                    <p className={styles.subtitleSmall}>
                      You're setting a cap on the transfer as well as approving
                      the transaction gas. Transaction speeds depend on network
                      performance.
                    </p>
                  </div>
                )}

                {getStepStatus(transferHistory.step2) === "IN PROGRESS" && (
                  <div className={styles.progressContent}>
                    <img
                      src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
                      alt="loading usdc balance"
                      className={styles.walletLoading}
                    />
                    <h2>Burn ETH USDC</h2>
                    <p className={styles.subtitle}>
                      Please approve the Burn ETH USDC in your Metamask wallet
                      when prompted.
                    </p>
                    <p className={styles.subtitleSmall}>
                      To transfer ETH based USDC to SOL USDC, your ETH based
                      USDC must be burned before it is transferred to Solana in
                      the next step.
                    </p>
                  </div>
                )}

                {(getStepStatus(transferHistory.step3) === "IN PROGRESS" ||
                  getStepStatus(transferHistory.step4) === "IN PROGRESS" ||
                  getStepStatus(transferHistory.step5) === "IN PROGRESS") && (
                  <div className={styles.progressContent}>
                    <img
                      src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
                      alt="loading usdc balance"
                      className={styles.walletLoading}
                    />
                    <h2>Fetching Attestation Hash</h2>
                    <p className={styles.subtitle}>
                      We're fetching the attestation hash and interacting with
                      Circle's CCTP.
                    </p>
                    <p className={styles.subtitleSmall}>
                      The Attestation Hash confirms to the CCTP that your ETH
                      based USDC has been burned and that it should thus be
                      minted as SOL USDC.
                    </p>
                  </div>
                )}
              </div>
            )}

          {transferHistory &&
            getStepStatus(transferHistory?.step5) === "COMPLETED" && (
              <div className={styles.transactionComplete}>
                <CheckCircleGreenTransparent />
                <h2>Transfer Successful</h2>
                <p>
                  You have successfully transferred{" "}
                  {(transferAmount / 10 ** 6).toFixed(2)} of USDC from your
                  Metamask wallet to your Solana wallet with XP to bring your
                  total USDC to {solUSDCBalance.toFixed(2)}
                </p>
              </div>
            )}
        </div>

        <div className={styles.balances}>
          <div className={styles.usdcBalance}>
            <div className={styles.usdcBalanceHeader}>SOL USDC Balance:</div>
            <div className={styles.usdcBalanceAmount}>
              {solUSDCBalance?.toFixed(2) || (
                <img
                  className={styles.balanceLoading}
                  src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
                  alt="loading"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CCTPPage
