export const Dollar = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.69697 7.41113L6.88447 6.58822C6.55895 6.49447 6.33238 6.18978 6.33238 5.85124C6.33238 5.42676 6.67613 5.08301 7.10061 5.08301H8.82718C9.14488 5.08301 9.45738 5.17936 9.7178 5.35645C9.87666 5.46322 10.0902 5.43717 10.2256 5.30436L11.1319 4.41895C11.3168 4.23926 11.2907 3.93978 11.085 3.78092C10.447 3.28092 9.65009 3.00228 8.83238 2.99967V1.74967C8.83238 1.52051 8.64488 1.33301 8.41572 1.33301H7.58238C7.35322 1.33301 7.16572 1.52051 7.16572 1.74967V2.99967H7.10061C5.44176 2.99967 4.10843 4.42415 4.26207 6.11426C4.37145 7.31478 5.28811 8.29134 6.44436 8.62988L9.11363 9.41113C9.43916 9.50749 9.66572 9.80957 9.66572 10.1481C9.66572 10.5726 9.32197 10.9163 8.89749 10.9163H7.17093C6.85322 10.9163 6.54072 10.82 6.2803 10.6429C6.12145 10.5361 5.90791 10.5622 5.77249 10.695L4.86624 11.5804C4.68134 11.7601 4.70738 12.0596 4.91311 12.2184C5.55113 12.7184 6.34801 12.9971 7.16572 12.9997V14.2497C7.16572 14.4788 7.35322 14.6663 7.58238 14.6663H8.41572C8.64488 14.6663 8.83238 14.4788 8.83238 14.2497V12.9945C10.0459 12.971 11.1839 12.2497 11.585 11.1012C12.1449 9.49707 11.2048 7.85124 9.69697 7.41113V7.41113Z"
      fill="#85D5FF"
    />
  </svg>
)
