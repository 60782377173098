import { useEffect, useRef, useState } from "react"
import {
  ArtistCard,
  Button,
  CompactEventCard,
  CustomDatePicker,
  Layout,
  LocationPicker,
  MobileEventCard,
} from "../components"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import styles from "./SearchResults.module.scss"
import classNames from "classnames"
import FilterButton from "../components/FilterButton/FilterButton"
import { Calendar, CheckMark, RedX } from "../css/icons"
import VenueCard from "../components/VenueCard/VenueCard"
const { cbsaToCityState } = require("../helpers/locationToCBSAName")

const SearchResults = () => {
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)
  const [query, setQuery] = useState("")
  const [performers, setPerformers] = useState([])
  const [venues, setVenues] = useState([])
  const [events, setEvents] = useState([])
  const [cities, setCities] = useState([])
  const [nearbyName, setNearbyName] = useState("")
  const [location, setLocation] = useState(null)
  const [showDateMenu, setShowDateMenu] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [date, setDate] = useState("All Dates")
  const [showDatePicker, setShowDatePicker] = useState(false)

  const routeParams = useParams()
  const dateMenuRef = useRef()

  useEffect(() => {
    console.log("venue")
  }, [venues])

  const dates = [
    "All Dates",
    "This Week",
    "This Weekend",
    "This Month",
    "Custom Dates",
  ]

  useEffect(() => {
    window.EVENT_GET_KEY = 0
    window.scrollTo(0, 0)
    applyUrlParams()

    // todo: record iterable info
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (routeParams?.query) {
      setQuery(routeParams.query)
    } else {
      setQuery(null)
    }

    applyUrlParams()
  }, [routeParams]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (query !== "") {
      getEvents()
    }
  }, [query, startDate, endDate, location]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkForExactMatch = async () => {
    if(!query) return 
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/dropdown/exact-match`
    const params = {query:query}
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    let exactMatch = resp.exact_match
    if (resp && resp.exact_match){
      if(exactMatch.type === "event") {    
        navigate(`/event/${exactMatch.event.event_id}`)    
      } else if(exactMatch.type === "performer") {    
        navigate(`/artist/${exactMatch.performer.performer_id}`)    
      } else if(exactMatch.type === "venue") {    
        navigate(`/venue/${exactMatch.venue.venue_id}`)    
      } else if(exactMatch.type === "city") {    
        navigate(`/events/city/${exactMatch.city.name}/${exactMatch.city.state}`)    
      }       
    }
  }

  const getEvents = async () => {
    try { 
      await checkForExactMatch()
    } catch (err) {
      console.log("error getting exact match", err)
    }
    setLoaded(false)
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/dropdown/v2`

    const params = { experimental: false, limit: 24 }

    params["query"] = query

    if (location) {
      params["cbsa"] = location.cbsaname
    }

    if (startDate) {
      params["min_date"] = startDate
    }

    if (endDate) {
      params["max_date"] = endDate
    }

    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((response) => response.json())

    console.log(response, "the response")
    setPerformers(response.performers)
    setVenues([...response.venues])
    setEvents(response.events)
    setCities(response.cities)
    setLoaded(true)
  }

  const [searchParams, setSearchParams] = useSearchParams()

  console.log(venues, "the venues")

  const applyUrlParams = () => {
    if (searchParams.get("query")) {
      setQuery(searchParams.get("query"))
    }

    if (searchParams.get("cbsa")) {
      setLocation({
        cbsaname: searchParams.get("cbsa"),
      })
      setNearbyName(cbsaToCityState(searchParams.get("cbsa")))
    }

    if (searchParams.get("min_date")) {
      setStartDate(new Date(searchParams.get("min_date")))
      setDate("Custom Dates")
    }

    if (searchParams.get("max_date")) {
      setEndDate(new Date(searchParams.get("max_date")))
    }
  }

  const showEndDate =
    startDate &&
    endDate &&
    startDate?.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }) !==
      endDate?.toLocaleDateString("en-us", {
        month: "short",
        day: "numeric",
      })

  const clearFilters = () => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete("min_date")
    newSearchParams.delete("max_date")
    newSearchParams.delete("cbsa")
    setSearchParams(newSearchParams)
    setStartDate(null)
    setEndDate(null)
    setLocation(null)
    setNearbyName("")
    setDate("All Dates")
  }

  const FilterRow = () => (
    <div className={styles.eventListHeroContainer}>
      <div className={styles.eventTagContainer}>
        {window.innerWidth < 960 && (
          <LocationPicker
            containerClass={styles.mobileLocationContainer}
            className={styles.mobileLocation}
            nearbyName={nearbyName}
            updateLocation={(location) => {
              const newSearchParams = new URLSearchParams(searchParams)
              if (location) {
                newSearchParams.set("cbsa", location.cbsaname)
              }
              setSearchParams(newSearchParams)
            }}
          />
        )}
        {window.innerWidth >= 960 && (
          <LocationPicker
            containerClass={styles.locationContainer}
            nearbyName={nearbyName}
            updateLocation={(location) => {
              const newSearchParams = new URLSearchParams(searchParams)
              if (location) {
                newSearchParams.set("cbsa", location.cbsaname)
              }
              setSearchParams(newSearchParams)
            }}
          />
        )}
        <div className={classNames(styles.menuContainer)}>
          <FilterButton
            id="DateDropdownButton"
            icon={<Calendar />}
            onClick={() => {
              setShowDateMenu(!showDateMenu)
            }}
            buttonCopy={
              startDate
                ? `${startDate.toLocaleDateString("en-us", {
                    month: "short",
                    day: "numeric",
                  })} ${
                    showEndDate &&
                    "- " +
                      endDate?.toLocaleDateString("en-us", {
                        month: "short",
                        day: "numeric",
                      })
                  }`
                : date
            }
          ></FilterButton>
          {showDateMenu && (
            <div
              className={classNames(
                styles.eventTypeMenuContainer,
                styles.mobileDateContainer
              )}
              ref={dateMenuRef}
            >
              {dates.map((d) => {
                return (
                  <div
                    className={classNames(
                      styles.eventTypeMenuItem,
                      date === d && styles.selectedEventType
                    )}
                    onClick={() => {
                      if (d === "Custom Dates") {
                        setShowDatePicker(true)
                        setShowDateMenu(false)
                        setDate("Custom Dates")
                      } else if (d === "This Week") {
                        const now = new Date()
                        const currentDay = now.getDay()
                        const diffToMonday = (currentDay + 6) % 7
                        const firstDay = new Date(now)
                        firstDay.setDate(now.getDate() - diffToMonday)
                        const lastDay = new Date(firstDay)
                        lastDay.setDate(firstDay.getDate() + 6)
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        )
                        newSearchParams.set("min_date", firstDay)
                        newSearchParams.set("max_date", lastDay)
                        setSearchParams(newSearchParams)
                        setDate(d)
                        setShowDateMenu(false)
                      } else if (d === "This Weekend") {
                        const now = new Date()
                        const currentDay = now.getDay()
                        const diffToSaturday = (6 - currentDay + 7) % 7
                        const firstDay = new Date(now)
                        firstDay.setDate(now.getDate() + diffToSaturday)
                        const lastDay = new Date(firstDay)
                        lastDay.setDate(firstDay.getDate() + 1)
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        )
                        newSearchParams.set("min_date", firstDay)
                        newSearchParams.set("max_date", lastDay)
                        setSearchParams(newSearchParams)
                        setDate(d)
                        setShowDateMenu(false)
                      } else if (d === "This Month") {
                        const now = new Date()
                        const firstDay = new Date(
                          now.getFullYear(),
                          now.getMonth(),
                          1
                        )
                        const lastDay = new Date(
                          now.getFullYear(),
                          now.getMonth() + 1,
                          0
                        )
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        )
                        newSearchParams.set("min_date", firstDay)
                        newSearchParams.set("max_date", lastDay)
                        setSearchParams(newSearchParams)
                        setDate(d)
                        setShowDateMenu(false)
                      } else {
                        const newSearchParams = new URLSearchParams(
                          searchParams
                        )
                        newSearchParams.delete("min_date")
                        newSearchParams.delete("max_date")
                        setSearchParams(newSearchParams)
                        setStartDate(null)
                        setEndDate(null)
                        setDate(d)
                        setShowDateMenu(false)
                      }
                    }}
                  >
                    <span>{d}</span>
                    {date === d && <CheckMark />}
                  </div>
                )
              })}
            </div>
          )}
          {showDatePicker && (
            <CustomDatePicker
              className={styles.datePickerContainer}
              containerStartDate={startDate}
              setDates={(start, end) => {
                const newSearchParams = new URLSearchParams(searchParams)
                if (start) {
                  newSearchParams.set("min_date", start)
                }
                if (end) {
                  newSearchParams.set("max_date", end)
                }
                setSearchParams(newSearchParams)
              }}
              containerEndDate={endDate}
              onClose={() => setShowDatePicker(false)}
            />
          )}
        </div>
        <div
          className={styles.clear}
          onClick={() => {
            clearFilters()
          }}
        >
          Clear Filters
        </div>
      </div>
    </div>
  )

  return (
    <Layout
      contentClassName={styles.eventListLayout}
      footerClass={styles.footerBg}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        className={styles.ticketVideo}
        width={window.innerWidth}
        id="bg-tickets"
      >
        <source
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/PillarsHoriz_v04_compressed.mp4"
          type="video/mp4"
        />
      </video>
      {loaded ? (
        <>
          <div className={styles.eventListHeader}>
            <h1 className={styles.pageHeader}>
              Results for <span>{query}</span>
            </h1>
          </div>
          <div className={styles.eventListBody}>
            {performers.length > 0 && (
              <>
                <h2>Performers</h2>
                <div className={styles.eventContainer}>
                  {performers.map((performer, i) => {
                    if (i >= 6) return null
                    return (
                      <ArtistCard
                        key={performer.id}
                        performer={performer}
                        className={styles.eventListCard}
                      />
                    )
                  })}
                </div>
              </>
            )}

            {venues.length > 0 && (
              <>
                <h2>Venues</h2>

                <div className={styles.eventContainer}>
                  {venues.map((venue, i) => {
                    if (i >= 3) return null
                    return (
                      <VenueCard
                        key={venue.venue_id}
                        venue={venue}
                        className={styles.eventListCard}
                      />
                    )
                  })}
                </div>
              </>
            )}

            {/* <h2>Cities</h2>  */}

            <div>
              <h2>Matching Events</h2>
              <FilterRow />
              {events.length > 0 && (
                <div className={styles.bottomEventSection}>
                  {events.map((event) => {
                    if (event.name.includes("Parking")) return null
                    if (window.innerWidth > 960) {
                      return (
                        <CompactEventCard
                          key={event.event_id}
                          event={event}
                          className={styles.eventListCard}
                        />
                      )
                    } else {
                      return (
                        <MobileEventCard key={event.event_id} event={event} />
                      )
                    }
                  })}
                </div>
              )}
              {loaded && events.length === 0 && (
                <div className={styles.emptyState}>
                  <RedX />
                  <h1>No Events Found</h1>
                  <p>
                    Don't worry, there are always new events popping up! Try
                    using other filters to uncover the perfect experience for
                    you.
                  </p>
                  <Button variant="beige" onClick={() => clearFilters()}>
                    Back
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.loadingStateContainer}>
          {[...Array(6)].map(() => (
            <div className={styles.loading}>
              <img
                src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                alt="Loading"
              />
              <div className={styles.loadingTitle}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                  alt="Loading"
                />
                <img
                  src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                  alt="Loading"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </Layout>
  )
}

export default SearchResults
