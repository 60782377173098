import { LAMPORTS_PER_SOL } from "@solana/web3.js";

/**
 * @group Currency & Amount
 */
export class Currency {
  /**
   * Name of the currency.
   */
  public name: string;

  /**
   * Formal symbol of the currency.
   * For example, `SOL` for Solana accounts.
   */
  public symbol: string;

  /**
   * Logo of the currency.
   */
  public logoURI: string | null;

  /**
   * Number of decimal places.
   *
   * Defines correlation between `value` and `qty` using the following formula:
   * ```
   * value = qty / 10 ** decimals
   * ```
   * where
   * * `value` represents amount in base currency units;
   * * `qty` represents amount in atomic currency units.
   *
   * @example 1 SOL (value) == 1_000_000_000 lamports (qty), decimals == 9
   */
  public readonly decimals: number;

  /**
   * @param name     Name of the currency
   * @param symbol   Symbol of the currency
   * @param logoURI  Logo of the currency
   * @param decimals Number of decimal places,
   *                 see {@link decimals} property description for details.
   */
  public constructor(
    name: string,
    symbol: string,
    logoURI: string | null,
    decimals: number,
  ) {
    this.name = name;
    this.symbol = symbol;
    this.logoURI = logoURI;
    this.decimals = decimals;
  }

  /**
   * Default name for unknown token.
   */
  public static UNKNOWN_NAME: string = "Unknown Token";

  /**
   * Default symbol for unknown token.
   */
  public static UNKNOWN_SYMBOL: string = "UNKNOWN";

  /**
   * Default logo URI for unknown token.
   */
  public static UNKNOWN_LOGO_URI: string | null = null;

  /**
   * Unknown token factory.
   *
   * @default
   * * {@link name} == {@link UNKNOWN_NAME}
   * * {@link symbol} == {@link UNKNOWN_SYMBOL}
   * * {@link logoURI} == {@link UNKNOWN_LOGO_URI}
   */
  public static unknownToken(decimals: number): Currency {
    return new this(
      this.UNKNOWN_NAME,
      this.UNKNOWN_SYMBOL,
      this.UNKNOWN_LOGO_URI,
      decimals,
    );
  }

  /**
   * Creates {@link Amount} from the currency itself and passed `value`.
   *
   * @see {@link decimals} description for `value` definition.
   */
  public amountFromValue(value: bigint | number | string): Amount {
    return new Amount(this, value);
  }

  /**
   * Creates {@link Amount} from the currency itself and passed `qty`.
   *
   * @see {@link decimals} description for `qty` definition.
   */
  public amountFromQty(qty: bigint | number | string): Amount {
    return new Amount(this, undefined, qty);
  }

  /**
   * Converts `value` to `qty`.
   *
   * @throws Error if `value` is not finite number.
   *
   * @see {@link decimals} description for `value` and `qty` definitions.
   */
  public valueToQty(value: bigint | number | string): bigint {
    if (typeof value == "bigint") {
      return this.decimals ? value * 10n ** BigInt(this.decimals) : value;
    }
    value = Number(value);
    if (!isFinite(value)) throw new Error(`Invalid value: ${value}`);
    return BigInt(this.decimals ? value * 10 ** this.decimals : value);
  }

  /**
   * Converts `qty` to `value`.
   *
   * May return `Infinite`,
   * if `qty` is too big to fit into `number` type.
   *
   * @see {@link decimals} description for `qty` and `value` definitions.
   */
  public qtyToValue(qty: bigint | number | string): number {
    qty = Number(qty);
    return this.decimals ? qty / 10 ** this.decimals : qty;
  }

  /**
   * Formats `value` into string representation
   * using {@link AmountFormat | format rules}.
   *
   * @see {@link decimals} description for `value` definition.
   */
  public formatValue(
    value: bigint | number | string,
    format: AmountFormat = {},
  ): string {
    return this.formatQty(this.valueToQty(value), format);
  }

  /**
   * Formats `qty` into string representation
   * using {@link AmountFormat | format rules}.
   *
   * @see {@link decimals} description for `qty` definition.
   */
  public formatQty(
    qty: bigint | number | string,
    format: AmountFormat = {},
  ): string {
    qty = BigInt(qty);

    let integer: string;
    let fractional: string = "";
    let approxSign: string = "";
    const symbol = format.incSymbol === false ? "" : " " + this.symbol;

    if (!this.decimals) {
      integer = qty.toString();
    } else {
      const factor = 10n ** BigInt(this.decimals);
      let integerNum = qty / factor;
      let fractionalNum = Number(qty % factor);

      if (format.fixed == undefined) {
        if (fractionalNum) {
          fractional = fractionalNum
            .toString()
            .padStart(this.decimals, "0")
            .replace(/0+$/, "");
        }
      } else if (format.fixed >= this.decimals) {
        fractional = fractionalNum
          .toString()
          .padStart(this.decimals, "0")
          .padEnd(format.fixed, "0");
      } else {
        if (fractionalNum) {
          const roundCeil = 10 ** format.fixed;
          const unrounded =
            fractionalNum / 10 ** (this.decimals - format.fixed);
          fractionalNum = Math.round(unrounded);
          if (fractionalNum != unrounded) {
            approxSign = format.approxSign ?? "~";
          }
          if (fractionalNum == roundCeil) {
            fractionalNum = 0;
            integerNum += 1n;
          }
        }
        if (format.fixed) {
          fractional = fractionalNum.toString().padStart(format.fixed, "0");
        }
      }

      if (fractional) fractional = (format.point ?? ".") + fractional;
      integer = integerNum.toString();
    }

    if (format.thouSep && integer.length > 3) {
      const start = integer.length % 3;
      const groups = [integer.slice(0, start)];
      for (let i = start; i < integer.length; i += 3) {
        groups.push(integer.slice(i, i + 3));
      }
      integer = groups.join(format.thouSep);
    }

    return [approxSign, integer, fractional, symbol].join("");
  }
}

/**
 * Solana currency.
 *
 * * {@link Currency.name} == "Solana"
 * * {@link Currency.symbol} == "SOL"
 * * {@link Currency.decimals} == 9
 * * {@link Currency.logoURI} == "https://solana.com/src/img/branding/solanaLogoMark.svg"
 *
 * @group Currency & Amount
 */
export const SOL: Currency = new Currency(
  "Solana",
  "SOL",
  "https://solana.com/src/img/branding/solanaLogoMark.svg",
  Math.log10(LAMPORTS_PER_SOL),
);

/**
 * @group Currency & Amount
 */
export class Amount {
  /**
   * Currency of the amount.
   */
  public readonly currency: Currency;

  private _qty: bigint = 0n;

  /**
   * Returns amount in atomic currency units.
   *
   * @see Currency.{@link Currency.decimals} for details.
   */
  public get qty(): bigint {
    return this._qty;
  }

  /**
   * Sets amount in atomic currency units.
   *
   * @see Currency.{@link Currency.decimals} for details.
   */
  public set qty(qty: bigint | number | string) {
    this._qty = BigInt(qty);
    this._value = this.currency.qtyToValue(this._qty);
  }

  private _value: number = 0;

  /**
   * Returns amount in base currency units.
   *
   * May return `Infinite`,
   * if amount is too big to fit into `number` type.
   *
   * @see Currency.{@link Currency.decimals} for details.
   */
  public get value(): number {
    return this._value;
  }

  /**
   * Sets amount in base currency units.
   *
   * @throws Error if `value` is not finite number.

   * @see Currency.{@link Currency.decimals} for details.
   */
  public set value(value: bigint | number | string) {
    this._qty = this.currency.valueToQty(value);
    this._value = Number(value);
  }

  /**
   * @param currency Currency of the amount.
   * @param value    Optional initial `value`.
   * @param qty      Optional initial `qty`. Overrides `value` if passed.
   *
   * @see
   * Currency.{@link Currency.decimals}
   * for `value` and `qty` definitions.
   *
   * @see
   * Currency.{@link Currency.amountFromValue},
   * Currency.{@link Currency.amountFromQty}
   */
  public constructor(
    currency: Currency,
    value?: bigint | number | string,
    qty?: bigint | number | string,
  ) {
    this.currency = currency;
    if (value != null) this.value = value;
    if (qty != null) this.qty = qty;
  }

  /**
   * Formats amount into string representation
   * using default {@link AmountFormat | format rules}.
   */
  public toString(): string {
    return this.format();
  }

  /**
   * Formats amount into string representation
   * using {@link AmountFormat | format rules}.
   */
  public format(format: AmountFormat = {}): string {
    return this.currency.formatQty(this.qty, format);
  }
}

/**
 * Amount format rules.
 *
 * @group Currency & Amount
 */
export interface AmountFormat {
  /**
   * Decimal point character.
   *
   * @default `"."`
   *
   * @example
   * SOL.formatValue(1.5)                 // returns "1.5 SOL"
   * SOL.formatValue(1.5, { point: "," }) // returns "1,5 SOL"
   *
   * @see
   * {@link SOL},
   * Currency.{@link Currency.formatValue},
   * Currency.{@link Currency.formatQty},
   * Amount.{@link Amount.format}.
   */
  point?: string;

  /**
   * Fixed number of decimal places after the point.
   *
   * @default `undefined`, i.e. no fixed number of decimal places will be used.
   *
   * @example
   * SOL.formatValue(1.45)               // returns "1.45 SOL"
   * SOL.formatValue(1.45, { fixed: 4 }) // returns "1.4500 SOL"
   * SOL.formatValue(1.45, { fixed: 1 }) // returns "~1.5 SOL"
   *
   * @see
   * {@link approxSign},
   * {@link SOL},
   * Currency.{@link Currency.formatValue},
   * Currency.{@link Currency.formatQty},
   * Amount.{@link Amount.format}.
   */
  fixed?: number;

  /**
   * Approximation sign character.
   *
   * The sign will prepend formatting result,
   * when fractional part of value is rounded to fit into fixed decimal places.
   *
   * @default `"~"`
   *
   * @example
   * SOL.formatValue(1.45)                               // returns "1.45 SOL"
   * SOL.formatValue(1.45, { fixed: 1 })                 // returns "~1.5 SOL"
   * SOL.formatValue(1.45, { fixed: 1, approxSign: "" }) // returns "1.5 SOL"
   *
   * @see
   * {@link fixed},
   * {@link SOL},
   * Currency.{@link Currency.formatValue},
   * Currency.{@link Currency.formatQty},
   * Amount.{@link Amount.format}.
   */
  approxSign?: string;

  /**
   * Thousand separator character
   *
   * @default `""` empty string
   *
   * @example
   * SOL.formatValue(1000)                   // returns "1000 SOL"
   * SOL.formatValue(1000, { thouSep: "'" }) // returns "1'000 SOL"
   *
   * @see
   * {@link SOL},
   * Currency.{@link Currency.formatValue},
   * Currency.{@link Currency.formatQty},
   * Amount.{@link Amount.format}.
   */
  thouSep?: string;

  /**
   * Whether to include {@link Currency.symbol | Currency.symbol} into result.
   *
   * Currency symbol will be added at the end of the result,
   * when `true`.
   *
   * @default `true`
   *
   * @example
   * SOL.formatValue(1)                       // returns "1 SOL"
   * SOL.formatValue(1, { incSymbol: false }) // returns "1"
   *
   * @see
   * {@link SOL},
   * Currency.{@link Currency.formatValue},
   * Currency.{@link Currency.formatQty},
   * Amount.{@link Amount.format}.
   */
  incSymbol?: boolean;
}
