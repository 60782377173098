export const AngleRight = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.8055 7.48168L6.42763 1.54788C6.16835 1.26179 5.74775 1.26179 5.48847 1.54788L4.86116 2.24007C4.60216 2.52586 4.60188 2.98874 4.86005 3.27514L9.12226 7.99983L4.86033 12.7248C4.60188 13.0112 4.60244 13.4741 4.86144 13.7599L5.48875 14.4521C5.74803 14.7382 6.16863 14.7382 6.42791 14.4521L11.8055 8.51797C12.0648 8.23188 12.0648 7.76778 11.8055 7.48168Z"
      fill="#404040"
    />
  </svg>
)
