import { useRef } from "react"
import Search from "../Search/Search"
import styles from "./MobileSearch.module.scss"
import classNames from "classnames"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"

const MobileSearch = ({ className, close }) => {
  const ref = useRef()

  useDetectClickOutside(ref, () => {
    close()
  })

  return (
    <div className={classNames(styles.searchContainer, className)} ref={ref}>
      <Search isMobile close={close} />
      <div className={styles.emptyState}>
        <div className={styles.icon}>🔎</div>
        <div>Search for artists, events, or venues</div>
      </div>
    </div>
  )
}

export default MobileSearch
