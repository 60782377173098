export const AngleDown = ({ className }) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.11128 8.85415L1.66093 4.82072C1.44636 4.62626 1.44636 4.31081 1.66093 4.11635L2.18007 3.64587C2.39441 3.45162 2.74157 3.45141 2.95637 3.64504L6.49989 6.84169L10.0436 3.64525C10.2584 3.45141 10.6056 3.45183 10.8199 3.64608L11.3391 4.11656C11.5536 4.31102 11.5536 4.62647 11.3391 4.82093L6.8885 8.85415C6.67392 9.04861 6.32585 9.04861 6.11128 8.85415Z"
      fill="#FAFAFA"
    />
  </svg>
)
