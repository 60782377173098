export const cbsaToCityState = (cbsa) => {
  let list = [
    {
      location: "Chicago",
      cbsaname: "Chicago-Naperville-Joliet  IL-IN-WI",
    },
    {
      location: "New York",
      cbsaname: "New York-Newark-Jersey City  NY-NJ-PA",
    },
    {
      location: "Los Angeles",
      cbsaname: "Los Angeles-Long Beach-Anaheim  CA",
    },
    {
      location: "Miami",
      cbsaname: "Miami-Fort Lauderdale-Pompano Beach  FL",
    },
    {
      location: "Denver",
      cbsaname: "Denver-Aurora-Lakewood  CO",
    },
    {
      location: "Boston",
      cbsaname: "Boston-Cambridge-Newton  MA-NH",
    },
    {
      location: "Phoenix",
      cbsaname: "Phoenix-Mesa-Chandler  AZ",
    },
    {
      location: "Atlanta",
      cbsaname: "Atlanta-Sandy Springs-Marietta  GA",
    },
  ]

  for (let i = 0; i < list.length; i++) {
    if (cbsa == list[i].cbsaname) return list[i].location
  }
}
