export const RadioSelected = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.99805C17.524 1.99805 22.002 6.47605 22.002 12C22.002 17.523 17.524 22.001 12 22.001C6.47605 22.001 1.99805 17.523 1.99805 12C1.99805 6.47605 6.47605 1.99805 12 1.99805ZM12 3.49805C10.8759 3.48585 9.76041 3.69675 8.71827 4.11852C7.67612 4.54029 6.72798 5.16457 5.92871 5.95521C5.12944 6.74586 4.49492 7.68718 4.06187 8.72469C3.62882 9.7622 3.40584 10.8753 3.40584 11.9995C3.40584 13.1238 3.62882 14.2369 4.06187 15.2744C4.49492 16.3119 5.12944 17.2532 5.92871 18.0439C6.72798 18.8345 7.67612 19.4588 8.71827 19.8806C9.76041 20.3023 10.8759 20.5132 12 20.501C14.2321 20.4668 16.3611 19.5561 17.9274 17.9656C19.4937 16.3751 20.3717 14.2323 20.3717 12C20.3717 9.76776 19.4937 7.62503 17.9274 6.0345C16.3611 4.44398 14.2321 3.53228 12 3.49805ZM11.996 5.99805C13.5868 5.99805 15.1124 6.62998 16.2373 7.75482C17.3621 8.87966 17.994 10.4053 17.994 11.996C17.994 13.5868 17.3621 15.1124 16.2373 16.2373C15.1124 17.3621 13.5868 17.994 11.996 17.994C10.4053 17.994 8.87966 17.3621 7.75482 16.2373C6.62998 15.1124 5.99805 13.5868 5.99805 11.996C5.99805 10.4053 6.62998 8.87966 7.75482 7.75482C8.87966 6.62998 10.4053 5.99805 11.996 5.99805Z"
      fill="#28C8FF"
    />
  </svg>
)
