import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { recordIterableUser } from "../helpers/recordIterableUser"

const initialState = {
  usdc: null,
  numTickets: null,
}

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (user, { getState }) => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/update-user`
    let headers = {
      "Content-Type": "application/json",
    }
    const state = getState()
    const publicKey = state.user.user.publicKey
    const uuid = state.user.user.uuid
    await recordIterableUser({ ...user, publicKey, uuid })

    // update in local storage
    let u = localStorage.getItem("user")
    let _user = JSON.parse(u)
    let newUser = {
      ..._user,
      ...user,
    }
    localStorage.setItem("user", JSON.stringify(newUser))

    // update in db
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(user),
      }).then((resp) => resp.json())
      return resp
    } catch (err) {
      console.log("error recording user", err)
    }
  }
)

export const getUserCbsa = createAsyncThunk(
  "users/getUserLocation",
  async (user) => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/get-cbsa`
    let headers = {
      "Content-Type": "application/json",
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(user),
      }).then((resp) => resp.json())
      return resp
    } catch (err) {
      console.log("error getting cbsa", err)
    }
  }
)

export const getUserIp = createAsyncThunk("users/getUserIp", async (user) => {
  let url = `${process.env.REACT_APP_HNGR_API}/api/xp/get-ip`
  let headers = {
    "Content-Type": "application/json",
  }
  try {
    let resp = await fetch(url, {
      method: "get",
      headers: headers,
    }).then((resp) => resp.json())
    return resp.ip
  } catch (err) {
    console.log("error getting cbsa", err)
  }
})

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    },
    signOut: (state) => {
      window.authenticating = false
      localStorage.removeItem("publicKey")
      localStorage.removeItem("user")
      state.user = null
      window.location.href = "/"
    },
    setUSDCBalance: (state, action) => {
      state.usdc = action.payload
    },
    setNumTickets: (state, action) => {
      state.numTickets = action.payload
    },
    setNumRewards: (state, action) => {
      state.numRewards = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    })
    builder.addCase(getUserCbsa.fulfilled, (state, action) => {
      state.cbsa = action.payload
    })
    builder.addCase(getUserIp.fulfilled, (state, action) => {
      state.ip = action.payload
    })
  },
})

export const {
  setPreferredLocation,
  setUser,
  signOut,
  setUSDCBalance,
  setNumTickets,
  setNumRewards,
} = userSlice.actions

export default userSlice.reducer
