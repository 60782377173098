import React from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import classNames from "classnames"
import styles from "./SortableItem.module.scss"

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "none",
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={classNames(
        styles.horseCard,
        props.item.scratched && styles.scratched
      )}
      {...attributes}
      {...listeners}
    >
      {props.item.scratched && (
        <div className={styles.scratchedLabel}>Replacement</div>
      )}
      <div className={styles.horseLabel}>
        <div  style={props.item.scratched ? {paddingTop:'5px'} : {}}>
          {props.item.scratched ? props.item.scratched?.name : props.item.name}
        </div>
        <div >
          {props.item.scratched ? props.item.scratched?.odds : props.item.odds}
        </div>
      </div>
      <img
        className={styles.horseImage}
        src={props.item.static_image}
        alt={props.item.name}
      />
    </div>
  )
}
