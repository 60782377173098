export const EyeClosed = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99995 8.75002C4.8148 8.75002 3.85543 7.83268 3.76715 6.67018L2.12809 5.40347C1.91262 5.67378 1.71434 5.95956 1.55434 6.27206C1.5186 6.34276 1.49997 6.42088 1.49997 6.50011C1.49997 6.57933 1.5186 6.65745 1.55434 6.72815C2.40168 8.38143 4.07918 9.50002 5.99995 9.50002C6.42042 9.50002 6.82604 9.43752 7.21698 9.33658L6.4062 8.70924C6.2723 8.73511 6.13632 8.74876 5.99995 8.75002ZM10.9034 9.65783L9.17604 8.32284C9.70075 7.88063 10.1323 7.33851 10.4456 6.728C10.4813 6.65729 10.4999 6.57918 10.4999 6.49995C10.4999 6.42072 10.4813 6.34261 10.4456 6.2719C9.59822 4.61863 7.92073 3.50004 5.99995 3.50004C5.1955 3.50101 4.40411 3.70353 3.69808 4.0891L1.71028 2.5527C1.68436 2.53253 1.65471 2.51766 1.62304 2.50895C1.59138 2.50024 1.5583 2.49786 1.52571 2.50193C1.49312 2.50601 1.46165 2.51647 1.43311 2.53271C1.40456 2.54896 1.37949 2.57067 1.35934 2.5966L1.05262 2.99145C1.01193 3.04379 0.993703 3.11015 1.00194 3.17593C1.01017 3.24171 1.0442 3.30153 1.09653 3.34223L10.2896 10.4474C10.3155 10.4675 10.3452 10.4824 10.3769 10.4911C10.4085 10.4998 10.4416 10.5022 10.4742 10.4981C10.5068 10.494 10.5382 10.4836 10.5668 10.4673C10.5953 10.4511 10.6204 10.4294 10.6406 10.4035L10.9474 10.0086C10.9881 9.95625 11.0063 9.88988 10.998 9.8241C10.9898 9.75831 10.9557 9.69851 10.9034 9.65783ZM8.03276 7.43909L7.4187 6.9644C7.47041 6.81494 7.49784 6.65817 7.49995 6.50003C7.503 6.26851 7.4517 6.0395 7.35019 5.8314C7.24868 5.62329 7.09978 5.4419 6.91544 5.30178C6.73111 5.16167 6.51648 5.06674 6.28881 5.02462C6.06113 4.9825 5.82674 4.99436 5.60448 5.05925C5.6987 5.18692 5.74967 5.34136 5.74995 5.50003C5.74762 5.55283 5.73955 5.60522 5.72589 5.65628L4.57574 4.76738C4.97526 4.4335 5.47928 4.25042 5.99995 4.25004C6.29547 4.24987 6.58812 4.30796 6.86118 4.42097C7.13424 4.53399 7.38234 4.69971 7.5913 4.90868C7.80027 5.11764 7.96599 5.36574 8.07901 5.6388C8.19202 5.91186 8.25011 6.20451 8.24994 6.50003C8.24994 6.838 8.16729 7.153 8.03276 7.43924V7.43909Z"
      fill="#FAFAFA"
      fillOpacity="0.5"
    />
  </svg>
)
