export const CheckMark = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.86258 12.7759L1.52924 8.44254C1.2689 8.1822 1.2689 7.76009 1.52924 7.49972L2.47203 6.55691C2.73236 6.29655 3.1545 6.29655 3.41484 6.55691L6.33398 9.47603L12.5865 3.22357C12.8468 2.96324 13.2689 2.96324 13.5293 3.22357L14.4721 4.16639C14.7324 4.42673 14.7324 4.84884 14.4721 5.1092L6.80539 12.7759C6.54502 13.0362 6.12291 13.0362 5.86258 12.7759Z"
      fill="#85D5FF"
    />
  </svg>
)
