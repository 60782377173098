import { Person, PersonPlus, Twitter } from "../css/icons"
import styles from "./PrizePool.module.scss"
import classNames from "classnames"

export const PrizeHeader = ({ pot, payouts, threshold, numPlayers }) => {
  const progressBarWidth = () => {
    if (threshold === 1000) {
      return "16%"
    } else if (threshold === 3000) {
      return "33%"
    } else if (threshold === 5000) {
      return "48%"
    } else if (threshold === 10000) {
      return "66%"
    } else if (threshold === 25000) {
      return "84%"
    } else if (threshold === 50000) {
      return "100%"
    }
  }

  return (
    <div className={styles.prizeHeader}>
      <div className={styles.prizeHeaderLeft}>
        <div className={styles.participants}>
          <div className={styles.participantLabel}>
            <Person />
            Players
          </div>
          <span className={styles.participantCount}>{numPlayers}/ </span>
          {threshold}
        </div>
        <div className={styles.participantsRemaining}>
          {threshold - numPlayers} to unlock
        </div>
      </div>
      <div className={styles.prizeHeaderRight}>
        <div className={styles.prizeLabel}>Increase the Pool</div>
        <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBar}
            style={{ width: progressBarWidth() }}
          />
        </div>
        <div className={styles.progressBarLabels}>
          <div
            className={classNames(
              styles.progressBarLabel,
              threshold == 1000 && styles.goalMet
            )}
          >
            $1K 
          </div>

          <div
            className={classNames(
              styles.progressBarLabel,
              threshold == 3000 && styles.goalMet
            )}
          >
            $3K
          </div>

          <div
            className={classNames(
              styles.progressBarLabel,
              threshold == 5000 && styles.goalMet
            )}
          >
            $5K
          </div>
        
          <div
            className={classNames(
              styles.progressBarLabel,
              threshold == 10000 && styles.goalMet
            )}
          >
            $10K
          </div>       

          <div
            className={classNames(
              styles.progressBarLabel,
              threshold == 25000 && styles.goalMet
            )}
          >
            $25K
          </div>       

          <div
            className={classNames(
              styles.progressBarLabel,
              threshold == 50000 && styles.goalMet
            )}
          >
            $50K
          </div>       

        </div>
      </div>
    </div>
  )
}

const PrizePool = ({ pot, payouts, threshold, numPlayers }) => (
  <div className={styles.prizeContainer}>
    <PrizeHeader
      pot={pot}
      payouts={payouts}
      threshold={threshold}
      numPlayers={numPlayers}
    />
    <div className={styles.prizePool}>
      <div className={styles.prizePoolLabel}>Total Prize Pool</div>
      <div className={styles.prizePoolValue}>${pot}</div>
      <div className={styles.prizePoolSubheader}>In XP Credit</div>
    </div>
    <div className={styles.prizeLevel}>
      <div className={styles.prizeLevelLeft}>
        <div className={styles.prizeLevelCorrect}>5/5</div>
        <div className={styles.prizeLevelLabel}>Correct</div>
      </div>
      <div className={styles.prizeLevelRight}>
        <div className={styles.winContainer}>
          <span className={styles.win}>Win</span>
          <span className={styles.winLabel}>Up To</span>
        </div>
        <div className={styles.winnings}>
          ${payouts ? payouts[5] : ""}
          <span className={styles.xpCredit}>XP Credit</span>
        </div>
      </div>
    </div>
    <div className={styles.prizeLevel}>
      <div className={styles.prizeLevelLeft}>
        <div className={styles.prizeLevelCorrect}>3/5</div>
        <div className={styles.prizeLevelLabel}>Correct</div>
      </div>
      <div className={styles.prizeLevelRight}>
        <div className={styles.winContainer}>
          <span className={styles.win}>Win</span>
          <span className={styles.winLabel}>Up To</span>
        </div>
        <div className={styles.winnings}>
          ${payouts ? payouts[3] : ""}
          <span className={styles.xpCredit}>XP Credit</span>
        </div>
      </div>
    </div>
    <div className={styles.prizeLevel}>
      <div className={styles.prizeLevelLeft}>
        <div className={styles.prizeLevelCorrect}>2/5</div>
        <div className={styles.prizeLevelLabel}>Correct</div>
      </div>
      <div className={styles.prizeLevelRight}>
        <div className={styles.winContainer}>
          <span className={styles.win}>Win</span>
          <span className={styles.winLabel}>Up To</span>
        </div>
        <div className={styles.winnings}>
          ${payouts ? payouts[2] : ""}
          <span className={styles.xpCredit}>XP Credit</span>
        </div>
      </div>
    </div>
    <div className={classNames(styles.prizeLevel, styles.prizeLevelLast)}>
      <div className={styles.prizeLevelLeft}>
        <div className={styles.prizeLevelCorrect}>1/5</div>
        <div className={styles.prizeLevelLabel}>Correct</div>
      </div>
      <div className={styles.prizeLevelRight}>
        <div className={styles.winContainer}>
          <span className={styles.win}>Win</span>
          <span className={styles.winLabel}>Up To</span>
        </div>
        <div className={styles.winnings}>
          ${payouts ? payouts[1] : ""}
          <span className={styles.xpCredit}>XP Credit</span>
        </div>
      </div>
    </div>

    <div className={styles.invite}>
      <div className={styles.inviteLeft}>
        <span className={styles.inviteIcon}>💰</span>
        <span className={styles.inviteText}>
          Invite friends and increase the prize pool!
        </span>
      </div>
      <div
        className={styles.inviteButton}
        onClick={() => {
          if (navigator.share) {
            navigator
              .share({
                url: "https://xp.xyz/g/derby",
                text: "Just made my picks in the XP Derby Game!🏇No horsing around —the more of us that play, the bigger the prize pot grows! Saddle up and join! 🌟",
              })
              .then(() => console.log("Successful share"))
              .catch((error) => console.log("Error sharing:", error))
          } else {
            console.log("Web Share API not supported.")
            window.open(
              "https://twitter.com/intent/tweet?text=Just%20made%20my%20picks%20in%20the%20XP%20Derby%20Game!%F0%9F%8F%87No%20horsing%20around%20%E2%80%94the%20more%20of%20us%20that%20play,%20the%20bigger%20the%20prize%20pot%20grows!%20Saddle%20up%20and%20join!%20%F0%9F%8C%9F%20Play%20here%3A%20https%3A//xp.xyz/g/derby",
              "_blank"
            )
          }
        }}
      >
        {window.innerWidth > 960 ? <Twitter /> : <PersonPlus />} Invite
      </div>
    </div>
  </div>
)

export default PrizePool
