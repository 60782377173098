import { useNavigate } from "react-router-dom"
import styles from "./CompactEventCard.module.scss"

const CompactEventCard = ({ event }) => {
  const navigate = useNavigate()
  return (
    <div
      className={styles.compactEventCard}
      onClick={() => navigate(`/event/${event.event_id}`)}
    >
      <img src={event?.image} alt={event?.title} />
      <div className={styles.compactCardInfo}>
        <div className={styles.compactCardTitle}>
          {event?.short_title || event?.title}
        </div>
        <div className={styles.compactCardVenue}>
          {event?.venue_name} • {event?.venue_city} • {event?.venue_state}
        </div>
        <div className={styles.compactCardPrice}>
          From ${Math.ceil(event.min_ticket_price / 100)}
        </div>
      </div>
    </div>
  )
}

export default CompactEventCard
