// questions:
// how are we defining the category for marketing to choose the carousels?
// ex. if it matches one of our categories, get what's defined in the db otherwise use a default
// what should the default be

// fallback:
// featured events in that category
// events under $50 in that category in the next month

// they want to be able to change the order of the sections

// ask scott for:
// 1. make performers by league not case sensitive
// 2. some way to get the cheapest ticket price for a performer

// ask kevan for:
// 1. team colors

// todos:
// 1. figure out how to detect the sport type to show the right icon

import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  Button,
  CompactEventCard,
  EmailCapture,
  EventCarousel,
  Layout,
  LocationPicker,
  MobileEventCard,
  Search,
  Testimonials,
} from "../components"
import styles from "./CategoryPage.module.scss"
import { useEffect, useState, useRef } from "react"
import QualityXperienceGuarantee from "../Home/QualityXperienceGuarantee"
import { ArrowDown, Basketball, Football, Hockey, Soccer } from "../css/icons"
import classNames from "classnames"
import ValueProp from "./ValueProp"
import VerticalCarousel from "../Home/VerticalCarousel"
import MobileSearch from "../components/MobileSearch/MobileSearch"
import { Search as SearchIcon } from "../css/icons"
import DatePickerMenu from "../components/DatePickerMenu/DatePickerMenu"
import { useDetectClickOutside } from "../hooks/detectClickOutside"
const { cbsaToCityState } = require("../helpers/locationToCBSAName")

// const categoryList = {
//   league: ["nfl", "nba", "ncaa", "nba", ""],
//   genre: [],
//   eventType: ["theater"],
//   category: ["broadway"],
// }

const defaultPageOrder = [
  "hero",
  "primary_carousel",
  "performer_list",
  "transparent_pricing",
  "carousel_2",
  "carousel_3",
  "email_collection",
  "testimonials",
  "more_categories",
  "trust_and_safety",
  "upcoming_events",
]

const capitalize = (string) => {
  if (
    string === "mlb" ||
    string === "nfl" ||
    string === "nba" ||
    string === "nhl" ||
    string === "mls"
  ) {
    return string.toUpperCase()
  } else if (string.includes("ncaa basketball")) {
    return "NCAA Basketball"
  } else if (string.includes("ncaa football")) {
    return "NCAA Football"
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

const CategoryPage = () => {
  const [events, setEvents] = useState()
  const [performers, setPerformers] = useState()
  const [defaultCampaign, setDefaultCampaign] = useState()
  const routeParams = useParams()
  const [carousel1Events, setCarousel1Events] = useState()
  const [carousel2Events, setCarousel2Events] = useState()
  const [carousel3Events, setCarousel3Events] = useState()
  const [featuredEvents, setFeaturedEvents] = useState()
  const [showMobileSearch, setShowMobileSearch] = useState(false)
  const [performerCount, setPerformerCount] = useState(5)
  const [searchParams, setSearchParams] = useSearchParams()
  const [cbsa, setCbsa] = useState()
  const [locationName, setLocationName] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [date, setDate] = useState("All Dates")
  const [showDateMenu, setShowDateMenu] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [pageContent, setPageContent] = useState({})
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const anchorRef = useRef()

  const content = {
    pageTitle: "NFL Tickets",
    pageSubtitle: "Get tickets to this season’s hottest games.",
    sectionLinks: {
      carousel2: "Featured Events",
      performerList: "View all Teams",
      carousel3: "Around The League",
    },
    carousel1Header: "Events",
    carousel2Header: "Featured Events",
    carousel3Header: "Carousel 3 Header",
    testimonials: [],
    carousel1Filters: [],
    carousel2Filters: [],
    carousel3Filters: [],
  }

  useEffect(() => {
    const currentDate = new Date() // Get the current date
    const futureDate = new Date(currentDate) // Create a copy of the current date
    futureDate.setDate(futureDate.getDate() + 30) // Add 30 days to the current date

    applyUrlParams()
    getAllEvents()
    getPerformersByLeague()
    getDefaultCampaign()
    getContent()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cbsa || startDate || endDate) {
      const currentDate = new Date() // Get the current date
      const futureDate = new Date(currentDate) // Create a copy of the current date
      futureDate.setDate(futureDate.getDate() + 30) // Add 30 days to the current date
      setLoading(true)
      if (pageContent?.carousels) {
        getCarouselEvents(1, { ...pageContent?.carousels[0], cbsa })
      }
    }

    getAllEvents({
      cbsa: cbsa?.cbsaname,
      min_date: startDate,
      max_date: endDate,
    })
  }, [cbsa, startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    applyUrlParams()
  }, [searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const applyUrlParams = () => {
    if (searchParams.get("cbsa")) {
      setCbsa({
        cbsaname: searchParams.get("cbsa"),
      })
      setLocationName(cbsaToCityState(searchParams.get("cbsa")))
    }

    if (searchParams.get("min_date")) {
      setStartDate(new Date(searchParams.get("min_date")))
      setDate("Custom Dates")
    }

    if (searchParams.get("max_date")) {
      setEndDate(new Date(searchParams.get("max_date")))
    }
  }

  const getContent = async () => {
    const url = `${process.env.REACT_APP_HNGR_API}/api/xp/league-page`
    let params = { league: routeParams.subcategory }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
      resp = await resp.json()

      setPageContent(resp.json)
      setLoading(false)

      resp.json.carousels.forEach((carousel, i) => {
        // todo: add something here for preset carousels
        getCarouselEvents(i + 1, { ...carousel })
      })

      if (resp.json.vertical_event_ids) {
        const verticalCarouselEvents = await getCustomEvents(
          resp.json.vertical_event_ids
        )
        const events = []
        verticalCarouselEvents.forEach((event) => {
          events.push({
            card_title: event?.short_title || event?.title,
            card_event: event?.event_id,
          })
        })
        setFeaturedEvents(events)
      }
    } catch (err) {
      console.log(err, "error")

      // redirect to events page with the params in the url
      if (routeParams.category === "sports") {
        navigate(`/events?event_type=sports&league=${routeParams.subcategory}`)
        // window.location.href = `/events/sports/${routeParams.subcategory}`
      } else if (routeParams.category === "concerts") {
        navigate(`/events?event_type=concerts&genre=${routeParams.subcategory}`)
      } else if (routeParams.category === "theater") {
        navigate(`/events?event_type=theater`)
      } else if (routeParams.category === "comedy") {
        navigate(`/events?event_type=comedy`)
      }
    }
  }

  const getEvents = async (filters) => {
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let params = { ...filters }

    const { category, subcategory } = routeParams

    switch (category) {
      case "sports":
        params.league = subcategory
        break
      case "concerts":
        params.genre = subcategory
        break
      case "theater":
        params.event_type = "theater"
        // params.category = subcategory
        break
      case "comedy":
        params.event_type = "comedy"
        // params.category = subcategory
        break
      default:
      // return
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
      resp = await resp.json()

      return resp.events
    } catch (err) {
      console.log(err, "error")
    }
  }

  const getAllEvents = async (filters) => {
    const allEvents = await getEvents(filters)
    setEvents(allEvents)
  }

  const getCarouselEvents = async (carouselId, filters = {}) => {
    let carouselEvents = []
    if (filters?.events && filters?.events?.length > 0) {
      carouselEvents = await getCustomEvents(filters?.events)
    } else if (filters) {
      if (filters?.performer_id) {
        carouselEvents = await getEventsByPerformer(filters?.performer_id)
      } else if (carouselId === 1) {
        carouselEvents = await getEvents({
          ...filters,
          // override the one in the filters if there is one specified
          cbsa:
            cbsa && cbsa.cbsaname !== filters.cbsa
              ? cbsa.cbsaname
              : filters.cbsa,
        })
      } else {
        carouselEvents = await getEvents({
          ...filters,
        })
      }
    } else {
      carouselEvents = await getEvents({ featured: true })
    }

    if (carouselId === 1) {
      setCarousel1Events(carouselEvents)
    } else if (carouselId === 2) {
      if (filters?.performer_id) {
        // events = [...carouselEvents]
        setCarousel2Events(carouselEvents)
      } else if (filters.events.length > 0) {
        setCarousel2Events(carouselEvents)
      } else {
        let events = []

        carouselEvents.forEach((event) => {
          if (carousel1Events?.indexOf(event) === -1) {
            events.push(event)
          }
        })
        setCarousel2Events(events)
      }
    } else if (carouselId === 3) {
      const events = []
      if (filters?.performerId) {
        setCarousel3Events(carouselEvents)
      } else if (filters?.events?.length > 0) {
        setCarousel3Events(carouselEvents)
      } else {
        carouselEvents.forEach((event) => {
          if (
            carousel1Events?.indexOf(event) === -1 &&
            carousel2Events?.indexOf(event) === -1
          ) {
            events.push(event)
          }
        })

        setCarousel3Events(carouselEvents)
      }
    }
  }

  const getCustomEvents = async (events) => {
    try {
      let carouselEvents = await Promise.all(
        events.map(async (eventId) => {
          let headers = {
            "Content-Type": "application/json",
          }

          const endpoint = "/api/stagehand/event"
          const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

          const response = await fetch(url, {
            method: "post",
            headers,
            body: JSON.stringify({ event_id: eventId }),
          })

          return response.json()
        })
      )
      return carouselEvents
    } catch (err) {
      console.log(err, "error")
    }
  }

  const getEventsByPerformer = async (performerId) => {
    const performers = performerId.split(",")
    try {
      let carouselEvents = await Promise.all(
        performers.map(async (performer) => {
          const endpoint = "/api/stagehand/filters"
          const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`
          let headers = {
            "Content-Type": "application/json",
          }

          const response = await fetch(url, {
            method: "post",
            headers,
            body: JSON.stringify({ performer_id: performer }),
          }).then((jsonresponse) => {
            return jsonresponse.json()
          })
          return response.events
        })
      )
      return carouselEvents.flat()
    } catch (err) {
      console.log(err)
    }
  }

  const getPerformersByLeague = async () => {
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/performers-by-league`
    const params = { league: routeParams.subcategory }

    if (routeParams.subcategory === "mls") {
      params.league = "MLS (Major League Soccer)"
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
      resp = await resp.json()

      setPerformers(resp.performers)

      // return resp.events
    } catch (err) {
      console.log(err, "error")
    }
  }

  const getPerformerListTitle = () => {
    const { category, subcategory } = routeParams

    switch (category) {
      case "sports":
        return `${capitalize(subcategory)} Teams`
      case "concerts":
        return `${subcategory} Performers`
      case "theater":
        return `${subcategory} Performers`
      case "comedy":
        return `${subcategory} Performers`
      default:
        return "Performers"
    }
  }

  const getDefaultCampaign = async () => {
    let headers = {
      "Content-Type": "application/json",
    }
    const endpoint = "/api/xp/campaigns"
    const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

    const response = await fetch(url, {
      method: "post",
      headers,
    }).then((res) => res.json())

    const defaultCamp = response.campaigns.find(
      (campaign) => campaign.is_default
    )
    setDefaultCampaign(defaultCamp) // put this back in
  }

  const Hero = () => (
    <div className={styles.heroContainer}>
      {loading ? (
        <div className={styles.loading}>
          <div className={styles.loadingLeft}>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
              alt="Loading"
              className={styles.loadingTitle}
            />
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
              alt="Loading"
              className={styles.loadingSubtitle}
            />

            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
              alt="Loading"
              className={styles.loadingSearch}
            />
          </div>
          <div className={styles.loadingRight}>
            <img
              src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
              alt="Loading"
              className={styles.loadingCarousel}
            />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.heroLeft}>
            <h1>
              {pageContent?.pageTitle || `${routeParams.subcategory} Events`}
            </h1>
            <p>
              {pageContent?.pageSubtitle ||
                `Upfront pricing. No hidden fees. All the best events.`}
            </p>

            {window.innerWidth > 960 ? (
              <Search withButton className={styles.categorySearchContainer} />
            ) : (
              <div className={styles.searchBorder1}>
                <div className={styles.searchBorder2}>
                  <div
                    className={classNames(styles.searchContainer)}
                    onClick={() => setShowMobileSearch(true)}
                  >
                    <SearchIcon />
                    Search for events
                  </div>
                </div>
              </div>
            )}
            {showMobileSearch && (
              <MobileSearch
                className={styles.velvetSearch}
                close={() => setShowMobileSearch(false)}
              />
            )}
          </div>
          <div className={styles.heroRight}>
            {pageContent?.vertical_event_ids && window.innerWidth > 1350 && (
              <VerticalCarousel heroCardCarousel={featuredEvents} />
            )}
          </div>
        </>
      )}
    </div>
  )

  const PrimaryCarousel = () => (
    <EventCarousel
      className={styles.carousel}
      events={carousel1Events}
      showCTA={false}
      title={
        pageContent?.carousels?.length > 0 && pageContent?.carousels[0]?.header
      }
      updateLocation={(location) => {
        const newSearchParams = new URLSearchParams(searchParams)
        if (location) {
          newSearchParams.set("cbsa", location.cbsaname)
        }
        setSearchParams(newSearchParams)
      }}
      loaded={!loading}
      locationName={locationName}
      showLocationPicker={
        !(
          pageContent?.carousels[0].events?.length > 0 ||
          pageContent?.carousels[0].performer_id ||
          pageContent?.carousels[0].city
        )
      }
      shouldRollUpEvents={
        (routeParams.category === "theater" &&
          pageContent?.carousels?.length > 0 &&
          !pageContent?.carousels[0].events) ||
        (pageContent?.carousels?.length > 0 &&
          !pageContent?.carousels[0]?.performer_id?.split(",")?.length > 0)
      }
    />
  )

  const PerformerList = () => {
    if (performers?.length === 0) {
      return null
    }
    return (
      <div className={styles.performerListContainer}>
        <h2>{getPerformerListTitle()}</h2>
        <div className={styles.performerList}>
          {performers?.map((performer, i) => {
            if (window.innerWidth <= 960 && i > performerCount) {
              return null
            }
            if (!performer?.secondary_color || !performer?.min_ticket_price) {
              return null
            }

            return (
              <Link
                key={performer.performer_id}
                className={styles.performerListItem}
                to={`/artist/${performer.performer_id}`}
              >
                {routeParams.category === "sports" && (
                  <div className={styles.performerColorsContainer}>
                    <div
                      className={styles.performerColorsLeft}
                      style={{ backgroundColor: performer?.primary_color }}
                    />
                    <div
                      className={styles.performerColorsRight}
                      style={{ backgroundColor: performer?.secondary_color }}
                    />

                    {performer.category === "NFL" ||
                      (performer.sport?.includes("Football") && <Football />)}
                    {performer.category === "NBA" ||
                      (performer.sport?.includes("Basketball") && (
                        <Basketball />
                      ))}
                    {/* {performer.category === "MLB" ||
                    (performer.category?.includes("Baseball") && <Baseball />)} */}
                    {performer.category === "NHL" ||
                      (performer.sport?.includes("Hockey") && <Hockey />)}
                    {performer.category === "MLS" ||
                      (performer.sport?.includes("Soccer") && <Soccer />)}
                  </div>
                )}
                <div className={styles.performerInfo}>
                  <div className={styles.performerName}>{performer.name}</div>
                  {performer?.min_ticket_price && (
                    <div className={styles.performerPriceInfo}>
                      <div className={styles.performerPriceLabel}>From</div>
                      <div className={styles.performerPrice}>
                        ${performer.min_ticket_price / 100}
                      </div>
                    </div>
                  )}
                </div>
              </Link>
            )
          })}
          <Button
            variant="secondary"
            compact
            className={styles.showMoreButton}
            onClick={() => setPerformerCount(performers.length)}
            rightIcon={<ArrowDown />}
          >
            Show All
          </Button>
        </div>
      </div>
    )
  }
  const TransparentPricing = () => <ValueProp className={styles.valueProp} />

  const Carousel2 = () => {
    return (
      <EventCarousel
        className={styles.carousel}
        events={carousel2Events}
        showCTA={false}
        title={
          pageContent?.carousels?.length > 0 &&
          pageContent?.carousels[1]?.header
        }
        loaded={!loading}
        showLocationPicker={false}
        shouldRollUpEvents={
          (routeParams.category === "theater" &&
            pageContent?.carousels?.length > 0 &&
            !pageContent?.carousels[1]?.events) ||
          (pageContent?.carousels?.length > 0 &&
            !pageContent?.carousels[1]?.performer_id?.split(",")?.length > 0)
        }
      />
    )
  }

  const Carousel3 = () => {
    return (
      <EventCarousel
        className={styles.carousel}
        events={carousel3Events}
        showCTA={false}
        title={
          pageContent?.carousels?.length > 0 &&
          pageContent?.carousels[2]?.header
        }
        loaded={!loading}
        showLocationPicker={false}
        shouldRollUpEvents={
          (routeParams.category === "theater" &&
            pageContent?.carousels?.length > 0 &&
            !pageContent?.carousels[2].events) ||
          (pageContent?.carousels?.length > 0 &&
            !pageContent?.carousels[2]?.performer_id?.split(",")?.length > 0)
        }
      />
    )
  }

  const showEndDate =
    startDate &&
    endDate &&
    startDate?.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }) !==
      endDate?.toLocaleDateString("en-us", {
        month: "short",
        day: "numeric",
      })

  const dateMenuRef = useRef()
  useDetectClickOutside(dateMenuRef, (event) => {
    if (event.target && event.target?.id !== "DateDropdownButton") {
      setShowDateMenu(false)
    }
  })

  const dates = [
    "All Dates",
    "This Week",
    "This Weekend",
    "This Month",
    "Custom Dates",
  ]

  const UpcomingEvents = () => (
    <div className={styles.upcomingEventsContainer} ref={anchorRef}>
      <h2>Upcoming Events</h2>
      <div className={styles.filterRow}>
        <LocationPicker
          containerClass={styles.mobileLocationContainer}
          className={styles.mobileLocation}
          nearbyName={locationName}
          updateLocation={(location) => {
            const newSearchParams = new URLSearchParams(searchParams)
            if (location) {
              newSearchParams.set("cbsa", location.cbsaname)
            }
            setSearchParams(newSearchParams)
          }}
        />
        <DatePickerMenu
          date={date}
          dateMenuRef={dateMenuRef}
          dates={dates}
          endDate={endDate}
          setDate={setDate}
          setEndDate={setEndDate}
          setSearchParams={setSearchParams}
          setStartDate={setStartDate}
          setShowDateMenu={setShowDateMenu}
          setShowDatePicker={setShowDatePicker}
          searchParams={searchParams}
          showDateMenu={showDateMenu}
          showDatePicker={showDatePicker}
          showEndDate={showEndDate}
          startDate={startDate}
        />
      </div>
      <div className={styles.upcomingEvents}>
        {events?.map((event, i) => {
          if (event?.min_ticket_price === null) {
            return null
          }
          if (i > 25) return null
          if (window.innerWidth > 960) {
            return (
              <CompactEventCard
                key={event.event_id}
                event={event}
                className={styles.eventListCard}
              />
            )
          } else {
            return <MobileEventCard key={event.event_id} event={event} />
          }
        })}
      </div>
    </div>
  )

  const EmailCollection = () => (
    <EmailCapture
      header="Love free tickets? So do we."
      subtitle="Sign up to stay in the know around giveaways, promotions, and more."
    />
  )
  const TestimonialSection = () => (
    <Testimonials
      testimonials={
        content.testimonials.length > 0
          ? content.testimonials
          : defaultCampaign?.json.testimonials
      }
    />
  )
  const TrustAndSafety = () => (
    <>
      <video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        className={styles.heroVideo}
        width={window.innerWidth}
        id="bg-shield"
      >
        <source
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Shield_v05.mp4"
          type="video/mp4"
        />
      </video>
      <picture>
        <img
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Shield_v05.avif"
          alt="bg-shield"
          id="bg-shield-fallback"
          className={classNames(styles.heroVideo, styles.fallbackImage)}
        />
      </picture>
      <QualityXperienceGuarantee className={styles.quality} />
    </>
  )

  const loadPage = (section) => {
    switch (section) {
      case "hero":
        return <Hero />
      case "carousel_1":
        return <PrimaryCarousel />
      case "performer_list":
        return <PerformerList />
      case "transparent_pricing":
        return <TransparentPricing />
      case "carousel_2":
        return <Carousel2 />
      case "carousel_3":
        return <Carousel3 />
      case "email_collection":
        return <EmailCollection />
      case "testimonials":
        return <TestimonialSection />

      case "trust_and_safety":
        return <TrustAndSafety />
      case "upcoming_events":
        return <UpcomingEvents />
      default:
        return null
    }
  }

  return (
    <Layout contentClassName={styles.layout} footerClass={styles.footerBg}>
      <video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        className={styles.ticketVideo}
        width={window.innerWidth}
        id="bg-tickets"
      >
        <source
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/PillarsHoriz_v04_compressed.mp4"
          type="video/mp4"
        />
      </video>
      {pageContent?.ordering?.map((section) => loadPage(section)) ||
        defaultPageOrder.map((section) => loadPage(section))}
    </Layout>
  )
}

export default CategoryPage
