import { configureStore } from "@reduxjs/toolkit"
import eventReducer from "./reducers/eventSlice"
import ticketReducer from "./reducers/ticketSlice"
import userReducer from "./reducers/userSlice"

export const store = configureStore({
  reducer: {
    events: eventReducer,
    tickets: ticketReducer,
    user: userReducer,
  },
})
