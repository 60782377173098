export const ContactCircle = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.5C4.85685 1.5 1.5 4.85685 1.5 9C1.5 13.1431 4.85685 16.5 9 16.5C13.1431 16.5 16.5 13.1431 16.5 9C16.5 4.85685 13.1431 1.5 9 1.5ZM9 4.40323C10.4698 4.40323 11.6613 5.59476 11.6613 7.06452C11.6613 8.53427 10.4698 9.72581 9 9.72581C7.53024 9.72581 6.33871 8.53427 6.33871 7.06452C6.33871 5.59476 7.53024 4.40323 9 4.40323ZM9 14.8065C7.2248 14.8065 5.63407 14.002 4.56956 12.744C5.1381 11.6734 6.25101 10.9355 7.54839 10.9355C7.62097 10.9355 7.69355 10.9476 7.7631 10.9688C8.15625 11.0958 8.56754 11.1774 9 11.1774C9.43246 11.1774 9.84677 11.0958 10.2369 10.9688C10.3065 10.9476 10.379 10.9355 10.4516 10.9355C11.749 10.9355 12.8619 11.6734 13.4304 12.744C12.3659 14.002 10.7752 14.8065 9 14.8065Z"
      fill="#85D5FF"
    />
  </svg>
)
