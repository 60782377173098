import { useEffect, useState, useRef } from "react"
import classNames from "classnames"
import { Helmet } from "react-helmet"
import {
  Button,
  CompactEventCard,
  Layout,
  LocationPicker,
  MobileEventCard,
} from "../components"
import styles from "./EventList.module.scss"
import { BrowseArrow, CheckMark, RedX } from "../css/icons"
import { EventCard } from "../components"

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useDetectClickOutside } from "../hooks/detectClickOutside"
import FilterButton from "../components/FilterButton/FilterButton"
import { useSelector } from "react-redux"
import { recordIterablePageview } from "../helpers/recordIterableUser"
import { trackFbPageView } from "../helpers/facebook"
import DatePickerMenu from "../components/DatePickerMenu/DatePickerMenu"

const { cbsaToCityState } = require("../helpers/locationToCBSAName")

const EventList = () => {
  const [loaded, setLoaded] = useState(false)

  const navigate = useNavigate()
  const routeParams = useParams()
  const browserLocation = useLocation()

  const scrollToTop = () => {
    document.getElementById("layoutContainer").scrollTo(0, 0)
  }

  const [location, setLocation] = useState("")
  const [nearbyName, setNearbyName] = useState("")
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [nearby, setNearby] = useState(null)
  const [venue, setVenue] = useState({ venueId: null, venueName: null })
  const [query, setQuery] = useState("")
  const [numberOfResults, setNumberOfResults] = useState(14)
  const [featured, setFeatured] = useState(false)
  const [justAdded, setJustAdded] = useState(false)
  const [maxPrice, setMaxPrice] = useState(null)
  const [league, setLeague] = useState(null)
  const [genre, setGenre] = useState(null)
  const [showEventTypeMenu, setShowEventTypeMenu] = useState(false)
  const [eventType, setEventType] = useState(null)
  const [showDateMenu, setShowDateMenu] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [date, setDate] = useState("All Dates")
  const [events, setEvents] = useState([])
  const user = useSelector((state) => state.user?.user)

  const eventTypes = ["concerts", "sports", "theater"]
  const dates = [
    "All Dates",
    "This Week",
    "This Weekend",
    "This Month",
    "Custom Dates",
  ]

  const eventTypeRef = useRef()
  useDetectClickOutside(eventTypeRef, (event) => {
    if (event.target && event.target?.id !== "EventListDropdownButton") {
      setShowEventTypeMenu(false)
    }
  })

  const dateMenuRef = useRef()
  useDetectClickOutside(dateMenuRef, (event) => {
    if (event.target && event.target?.id !== "DateDropdownButton") {
      setShowDateMenu(false)
    }
  })

  useEffect(() => {
    window.EVENT_GET_KEY = 0
    window.scrollTo(0, 0)
    applyUrlParams()

    if (user) {
      recordIterablePageview(user, window.location.pathname, "Event List", {
        searchParams: browserLocation.search || routeParams.query,
      })
      trackFbPageView(user)
    }
    return () => (window.searchedNearby = false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoaded(true)
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: VERIFY THAT THIS STILL WORKS
  useEffect(() => {
    applyUrlParams()

    if (user) {
      recordIterablePageview(user, window.location.pathname, "Event List", {
        searchParams: browserLocation.search || routeParams.query,
      })
      trackFbPageView(user)
    }
  }, [browserLocation.search]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoaded(true)
  }, [events]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (routeParams?.query) {
      setQuery(routeParams.query)
    } else {
      setQuery(null)
    }

    if (routeParams?.city) {
      let city = routeParams.city
      let state = routeParams.state
      setLocation({ city: city, state: state })
      setNearbyName(`${city}, ${state}`)
    }

    if (user) {
      recordIterablePageview(user, window.location.pathname, "Event List", {
        searchParams: browserLocation.search || routeParams.query,
      })
      trackFbPageView(user)
    }
  }, [routeParams]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    scrollToTop()
    if (query) {
      setLeague(null)
      setEventType(null)
      setGenre(null)
    }
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.EVENT_GET_KEY += 1
    getEvents(window.EVENT_GET_KEY)
  }, [
    maxPrice,
    startDate,
    endDate,
    eventType,
    league,
    featured,
    nearby,
    location,
    query,
    genre,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  const getMetaTitle = () => {
    let title = `Get tickets to your favorite ${routeParams?.city} concerts, sports games, & more, with no hidden fees.`
  }

  const [searchParams, setSearchParams] = useSearchParams()

  const applyUrlParams = () => {
    if (searchParams.get("eventType")) {
      setEventType(searchParams.get("eventType"))
    } else {
      setEventType(null)
    }
    if (searchParams.get("nearby")) {
      setNearby(true)
    }

    if (searchParams.get("cbsa")) {
      setLocation({
        cbsaname: searchParams.get("cbsa"),
      })
      setNearbyName(cbsaToCityState(searchParams.get("cbsa")))
    }

    if (searchParams.get("featured")) {
      setFeatured(true)
    }

    if (searchParams.get("league")) {
      setLeague(searchParams.get("league"))
      setEventType(null)
      setMaxPrice(null)
    }

    if (searchParams.get("venue_id")) {
      setVenue({
        venueId: searchParams.get("venue_id"),
        venueName: searchParams.get("venue_name"),
      })
      setMaxPrice(null)
    }

    if (searchParams.get("justAdded")) {
      setJustAdded(true)
    }

    if (searchParams.get("query")) {
      setQuery(searchParams.get("query"))
    }

    if (searchParams.get("featured")) {
      setFeatured(true)
    }

    if (searchParams.get("max_price")) {
      setMaxPrice(searchParams.get("max_price"))
    }

    if (searchParams.maxPrice) {
      setMaxPrice(searchParams.get("maxPrice"))
    }

    if (searchParams.get("min_date")) {
      setStartDate(new Date(searchParams.get("min_date")))
      setDate("Custom Dates")
    }

    if (searchParams.get("max_date")) {
      setEndDate(new Date(searchParams.get("max_date")))
    }

    if (searchParams.get("genre")) {
      setGenre(searchParams.get("genre"))
    }
  }

  const getEvents = async (EVENT_GET_KEY) => {
    setLoaded(false)
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/filters`
    let params = {}

    // make sure location and nearby arent both true
    if (searchParams.get("cbsa") || location) {
      if (searchParams.get("cbsa")) {
        params["cbsa"] = searchParams.get("cbsa")
      } else {
        params["city"] = location.city
        params["state"] = location.state
      }
    } else {
      params["nearby"] = true
    }
    if (query) {
      params["query"] = query
      params["nearby"] = false // non-location specific for searches
    } else {
      if (featured) {
        params["featured"] = true
      }
      if (genre) {
        params["genre"] = genre
      }
      if (league) {
        params["league"] = league
      } else if (eventType) {
        params["event_type"] = eventType
      }
    }

    if (maxPrice) {
      params["max_price"] = maxPrice
    }

    params["min_date"] = startDate
    params["max_date"] = endDate

    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    if (EVENT_GET_KEY == window.EVENT_GET_KEY) {
      setEvents(resp.events)
      if (resp.nearby) {
        setNearbyName(resp.nearby)
      }
    } else {
      console.log("keys dont match", EVENT_GET_KEY, window.EVENT_GET_KEY)
    }
  }

  const clearFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setLocation(null)
    setEventType(null)
    setFeatured(false)
    navigate("/events")
  }

  const updateEventType = (type) => {
    const newSearchParams = new URLSearchParams(searchParams)
    if (type) {
      newSearchParams.set("eventType", type)
      newSearchParams.delete("genre")
      newSearchParams.delete("league")
    } else {
      newSearchParams.delete("eventType")
    }
    setSearchParams(newSearchParams)

    window.scrollTo(0, 0)
    setLeague(null)
    setGenre(null)
    setQuery(null)
    setMaxPrice(null)
  }

  const dropdownText = () => {
    if (league) {
      return `All ${league} Events`
    } else if (query) {
      return query
    } else if (genre) {
      return genre
    } else if (eventType) {
      return eventType
    } else if (maxPrice) {
      return `Events under $${maxPrice}`
    } else return "All Events"
  }

  const showEndDate =
    startDate &&
    endDate &&
    startDate?.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    }) !==
      endDate?.toLocaleDateString("en-us", {
        month: "short",
        day: "numeric",
      })

  const renderBottomEvents = () => {
    if (!events) return null

    if (genre || league || eventType === "sports") {
      return (
        <div className={styles.bottomEventSection}>
          {events.map((event, i) => {
            if (event.min_ticket_price === null) {
              return null
            }
            if (i < 6 || i > 50) return null
            if (window.innerWidth > 960) {
              return (
                <CompactEventCard
                  key={event.event_id}
                  className={classNames(styles.eventListCard)}
                  event={event}
                />
              )
            } else {
              return <MobileEventCard key={event.event_id} event={event} />
            }
          })}
        </div>
      )
    }

    if (!league) {
      let key = "event_type"
      if (eventType) key = "genre"

      console.log("key is", key)

      // organize events by category
      let categories = {}
      for (let i = 6; i < events.length; i++) {
        let e = events[i]
        if (!(e[key] in categories)) {
          categories[e[key]] = []
        }
        categories[e[key]].push(e)
      }
      let category_length = {}
      for (let category in categories) {
        category_length[category] = categories[category].length
      }

      let sortable = []
      for (var category in category_length) {
        if (category !== "null") {
          sortable.push([category, category_length[category]])
        }
      }

      sortable.sort(function (a, b) {
        return b[1] - a[1]
      })
      let sortedCategories = []
      for (let i = 0; i < sortable.length; i++) {
        sortedCategories.push(sortable[i][0])
      }

      // sortedCategories is now like "concert", "theatre"
      return sortedCategories.map((category) => (
        <div className={styles.bottomEventSection}>
          <div className={styles.eventTypeHeaderSection}>
            <h2>
              {category === "sport"
                ? "sports"
                : category === "concert"
                ? "concerts"
                : category}
            </h2>
            <Button
              onClick={() => {
                if (key === "event_type") {
                  updateEventType(category)
                } else {
                  const newSearchParams = new URLSearchParams(searchParams)
                  if (key === "genre") {
                    newSearchParams.set("genre", category)
                  }
                  setSearchParams(newSearchParams)
                  scrollToTop()
                }
              }}
              rightIcon={<BrowseArrow />}
              rm
            >
              <div>
                BROWSE{" "}
                {category === "sport"
                  ? "sports"
                  : category === "concert"
                  ? "concerts"
                  : category}
              </div>
            </Button>
          </div>
          {categories[category].map((event, i) => {
            if (event.min_ticket_price === null) {
              return null
            }
            if (i > 10) return null
            if (window.innerWidth > 960) {
              return (
                <CompactEventCard
                  key={event.event_id}
                  className={classNames(styles.eventListCard)}
                  event={event}
                />
              )
            } else {
              return <MobileEventCard key={event.event_id} event={event} />
            }
          })}
        </div>
      ))
    }
  }

  const FilterRow = () => (
    <div className={styles.eventListHeroContainer}>
      <div className={styles.eventTagContainer}>
        {window.innerWidth < 960 && (
          <LocationPicker
            containerClass={styles.mobileLocationContainer}
            className={styles.mobileLocation}
            nearbyName={nearbyName}
            updateLocation={(location) => {
              const newSearchParams = new URLSearchParams(searchParams)
              if (location) {
                newSearchParams.set("cbsa", location.cbsaname)
              }
              setSearchParams(newSearchParams)
            }}
          />
        )}
        <div className={classNames(styles.tagRow, styles.topRow)}>
          {!query && (
            <div className={styles.menuContainer}>
              <FilterButton
                id="EventListDropdownButton"
                onClick={() => {
                  if (showEventTypeMenu) {
                    setShowEventTypeMenu(false)
                  } else {
                    setShowEventTypeMenu(true)
                  }
                }}
                buttonCopy={dropdownText()}
              ></FilterButton>
              {showEventTypeMenu && (
                <div
                  className={styles.eventTypeMenuContainer}
                  ref={eventTypeRef}
                >
                  <div
                    className={classNames(
                      styles.eventTypeMenuItem,
                      !eventType && styles.selectedEventType
                    )}
                    onClick={() => {
                      updateEventType(null)
                      setShowEventTypeMenu(false)
                    }}
                  >
                    <span>All Events</span>
                    {!eventType && <CheckMark />}
                  </div>
                  {eventTypes.map((type) => {
                    if (type === "concert") {
                      return (
                        <div
                          className={classNames(
                            styles.eventTypeMenuItem,
                            eventType === type && styles.selectedEventType
                          )}
                          onClick={() => {
                            updateEventType(type)
                            setShowEventTypeMenu(false)
                          }}
                        >
                          <span>Concerts</span>
                          {eventType === type && <CheckMark />}
                        </div>
                      )
                    } else
                      return (
                        <div
                          className={classNames(
                            styles.eventTypeMenuItem,
                            eventType === type && styles.selectedEventType
                          )}
                          onClick={() => {
                            updateEventType(type)
                            setShowEventTypeMenu(false)
                          }}
                        >
                          <span>{type}</span>
                          {eventType === type && <CheckMark />}
                        </div>
                      )
                  })}
                </div>
              )}
            </div>
          )}
          {window.innerWidth >= 960 && (
            <LocationPicker
              containerClass={styles.locationContainer}
              nearbyName={nearbyName}
              updateLocation={(location) => {
                const newSearchParams = new URLSearchParams(searchParams)
                if (location) {
                  newSearchParams.set("cbsa", location.cbsaname)
                }
                setSearchParams(newSearchParams)
              }}
            />
          )}
          <DatePickerMenu
            date={date}
            dateMenuRef={dateMenuRef}
            dates={dates}
            endDate={endDate}
            setDate={setDate}
            setEndDate={setEndDate}
            setSearchParams={setSearchParams}
            setStartDate={setStartDate}
            setShowDateMenu={setShowDateMenu}
            setShowDatePicker={setShowDatePicker}
            searchParams={searchParams}
            showDateMenu={showDateMenu}
            showDatePicker={showDatePicker}
            showEndDate={showEndDate}
            startDate={startDate}
          />
        </div>
      </div>
    </div>
  )

  return (
    <Layout
      contentClassName={styles.eventListLayout}
      footerClass={styles.footerBg}
    >
      {routeParams?.city && (
        <Helmet>
          <title>{getMetaTitle()}</title>
          <meta property="og:title" content={getMetaTitle()} />
          <meta property="twitter:title" content={getMetaTitle()} />
        </Helmet>
      )}

      <video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        className={styles.ticketVideo}
        width={window.innerWidth}
        id="bg-tickets"
      >
        <source
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/PillarsHoriz_v04_compressed.mp4"
          type="video/mp4"
        />
      </video>
      <div className={styles.eventListHeader}>
        <FilterRow />
      </div>
      <div className={styles.eventListBody}>
        {loaded ? (
          <>
            {events?.length > 0 ? (
              <div className={styles.eventContainer}>
                {events.map((event, i) => {
                  if (i >= 6) return null
                  // don't show events that don't have prices / ticket groups
                  if (event.min_ticket_price === null) {
                    return null
                  }
                  return (
                    <EventCard
                      key={event.event_id}
                      className={classNames(
                        styles.card3w,
                        styles.eventListCard
                      )}
                      event={event}
                      inEventList
                    />
                  )
                })}

                {query && <FilterRow />}

                {renderBottomEvents()}

                {events?.length > 6 && (
                  <div className={styles.moreResults}>
                    <h2>More Results</h2>
                    {events.map((event, i) => {
                      if (i < 6) return null
                      if (i >= numberOfResults) return null
                      if (event.min_ticket_price === null) {
                        return null
                      }
                      if (window.innerWidth > 960) {
                        return (
                          <CompactEventCard
                            key={event.event_id}
                            className={classNames(styles.eventListCard)}
                            event={event}
                          />
                        )
                      } else {
                        return (
                          <MobileEventCard key={event.event_id} event={event} />
                        )
                      }
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.emptyState}>
                <RedX />
                <h1>No Events Found</h1>
                <p>
                  Don't worry, there are always new events popping up! Try using
                  other filters to uncover the perfect experience for you.
                </p>
                <Button variant="beige" onClick={() => clearFilters()}>
                  Back
                </Button>
              </div>
            )}

            {/*events?.length >= numberOfResults && (
              <Button
                rightIcon={<ArrowDown />}
                variant="beigeOutline"
                className={styles.showMoreButton}
                onClick={() => setNumberOfResults(numberOfResults + 24)}
              >
                Show More
              </Button>
            )*/}
          </>
        ) : (
          <div className={styles.loadingStateContainer}>
            {[...Array(6)].map(() => (
              <div className={styles.loading}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                  alt="Loading"
                />
                <div className={styles.loadingTitle}>
                  <img
                    src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                    alt="Loading"
                  />
                  <img
                    src="https://hngr-icons.s3.amazonaws.com/ticketdex/Animation+-+1700155477235.gif"
                    alt="Loading"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default EventList




