export const Twitter = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_549_1454)">
      <path
        d="M18.5 3.76747C17.826 4.07226 17.111 4.27232 16.379 4.36101C17.1415 3.8945 17.727 3.15577 18.0027 2.27558C17.2778 2.71466 16.4847 3.02402 15.6577 3.19029C14.984 2.45773 14.0243 1.99991 12.9621 1.99991C10.9226 1.99991 9.26909 3.68766 9.26909 5.76929C9.26909 6.06476 9.30179 6.35242 9.36472 6.62838C6.29558 6.47113 3.57448 4.97048 1.75304 2.68991C1.43523 3.24663 1.25312 3.89421 1.25312 4.58493C1.25312 5.89273 1.90513 7.04644 2.89597 7.72244C2.30953 7.7037 1.73599 7.54202 1.22323 7.25091C1.22302 7.2667 1.22302 7.28249 1.22302 7.29835C1.22302 9.12469 2.49596 10.6482 4.18529 10.9945C3.64148 11.1455 3.07107 11.1676 2.51762 11.0591C2.98752 12.5567 4.35137 13.6464 5.96729 13.6769C4.70342 14.6879 3.11105 15.2906 1.38095 15.2906C1.08282 15.2906 0.788914 15.2727 0.5 15.2379C2.13427 16.3074 4.07539 16.9314 6.16086 16.9314C12.9535 16.9314 16.6679 11.1876 16.6679 6.20644C16.6679 6.04295 16.6644 5.88039 16.6573 5.71876C17.3802 5.18528 18.0042 4.52451 18.5 3.76747Z"
        fill="#FFE9D2"
      />
    </g>
    <defs>
      <clipPath id="clip0_549_1454">
        <rect width="18" height="18" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
