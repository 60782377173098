const getUuidFromPublicKey = async (publicKey) => {
   let url = `${process.env.REACT_APP_HNGR_API}/api/xp/get-user-uuid`    
    let headers = {    
      "Content-Type": "application/json",    
    }    
    let data = {    
      public_key: publicKey.toString(),    
    }    
       
    let resp = await fetch(url, {    
      method: "post",    
      headers: headers,    
      body: JSON.stringify(data),    
    })                                 
    let json = await resp.json()    
    return json.uuid
}

export const augmentUser = async (u) => {
  if (!u) return u;
  if (u.uuid) return u
  if (u.publicKey && !u.uuid) {
    const uuid = await getUuidFromPublicKey(u.publicKey);
    u.uuid = uuid;
    return u;
  }
  return u
}
