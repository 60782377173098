import classNames from "classnames"
import styles from "./DateTag.module.scss"

const DateTag = ({ className, event }) => {
  const dateDay = event?.date_formatted.split(" ")[2] // this is gross
  return (
    <div className={classNames(styles.cardDateTag, className)}>
      <div className={styles.month}>{event?.date_month}</div>
      <div className={styles.day}>{dateDay}</div>
    </div>
  )
}




export default DateTag
