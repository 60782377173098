//require("dotenv").config();
//const Web3 = require('web3')
import Web3 from "web3"

import tokenMessengerAbi from "./abis/TokenMessenger.json"
import messageAbi from "./abis/Message.json"
import usdcAbi from "./abis/Usdc.json"
import messageTransmitterAbi from "./abis/MessageTransmitter.json"
import bs58 from "bs58"
//import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import { hexlify } from "ethers"
import { getAssociatedTokenAddress } from "@solana/spl-token"
import { PublicKey, Connection } from "@solana/web3.js"

const Buffer = require("buffer/").Buffer
//const ETH_TOKEN_MESSENGER_CONTRACT_ADDRESS =
//  process.env.ETH_TOKEN_MESSENGER_CONTRACT_ADDRESS ??
//  "0x9f3B8679c73C2Fef8b59B4f3444d4e156fb70AA5"
//const USDC_ETH_CONTRACT_ADDRESS =
//  process.env.USDC_ETH_CONTRACT_ADDRESS ??
//  "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238"
//const ETH_MESSAGE_CONTRACT_ADDRESS =
//  process.env.ETH_MESSAGE_CONTRACT_ADDRESS ??
//  "0x7865fAfC2db2093669d92c0F33AeEF291086BEFD"
//const SOLANA_USDC_ADDRESS =
//  process.env.SOLANA_USDC_ADDRESS ??
//  "4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU"
//const CIRCLE_ATTESTATION_API =
//  process.env.CIRCLE_ATTESTATION_API ?? "https://iris-api-sandbox.circle.com"

const ETH_TOKEN_MESSENGER_CONTRACT_ADDRESS =
  "0xbd3fa81b58ba92a82136038b25adec7066af3155"
const USDC_ETH_CONTRACT_ADDRESS = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
const ETH_MESSAGE_CONTRACT_ADDRESS =
  "0x0a992d191deec32afe36203ad87d7d289a738f81"
const SOLANA_USDC_ADDRESS = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
const CIRCLE_ATTESTATION_API = "https://iris-api.circle.com"

const waitForTransaction = async (web3, txHash) => {
  let transactionReceipt = await web3.eth.getTransactionReceipt(txHash)
  while (transactionReceipt != null && transactionReceipt.status === "FALSE") {
    transactionReceipt = await web3.eth.getTransactionReceipt(txHash)
    await new Promise((r) => setTimeout(r, 4000))
  }
  return transactionReceipt
}

// STEP 1 Method
const approveMessengerContract = async (web3, senderAddress, amount) => {
  const usdcEthContract = new web3.eth.Contract(
    usdcAbi,
    USDC_ETH_CONTRACT_ADDRESS,
    { from: senderAddress }
  )
  const approveTxGas = await usdcEthContract.methods
    .approve(ETH_TOKEN_MESSENGER_CONTRACT_ADDRESS, amount)
    .estimateGas()
  const gasPrice = await web3.eth.getGasPrice()
  const nonce = await web3.eth.getTransactionCount(senderAddress)

  const tx = {
    from: senderAddress,
    to: USDC_ETH_CONTRACT_ADDRESS,
    gasPrice: gasPrice,
    gas: approveTxGas,
    nonce: nonce,
    data: usdcEthContract.methods
      .approve(ETH_TOKEN_MESSENGER_CONTRACT_ADDRESS, amount)
      .encodeABI(),
  }

  const txReceipt = await web3.eth.sendTransaction(tx)

  const approveTxReceipt = await waitForTransaction(
    web3,
    txReceipt.transactionHash
  )
  console.log("ApproveTxReceipt: ", approveTxReceipt)

  return approveTxReceipt.transactionHash
}

// STEP 2 Method: Burn USDC
const burnUSDC = async (web3, receiverPublicKey, senderAddress, amount) => {
  // initialize contracts using address and ABI
  const ethTokenMessengerContract = new web3.eth.Contract(
    tokenMessengerAbi,
    ETH_TOKEN_MESSENGER_CONTRACT_ADDRESS,
    { from: senderAddress }
  )

  // SOL destination address
  const mintRecipient = receiverPublicKey
  // convert base 58 to hex
  const mintRecipientBytes = bs58.decode(mintRecipient)
  //const mintRecipientHex = "0x" + Buffer.from(mintRecipientBytes).toString("hex");
  //const destinationAddressInBytes32 = Buffer.from(mintRecipientBytes)

  //const SOLANA_USDC_ADDRESS =
  //  process.env.SOLANA_USDC_ADDRESS ??
  //  "4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU"
  const mintPublickey = new PublicKey(SOLANA_USDC_ADDRESS)

  const userTokenAccount = await getAssociatedTokenAddress(
    mintPublickey,
    new PublicKey(mintRecipient)
  )

  const destinationAddressInBytes32 = hexlify(
    bs58.decode(userTokenAccount.toString())
  )

  const SOL_DESTINATION_DOMAIN = 5

  const burnTxGas = await ethTokenMessengerContract.methods
    .depositForBurn(
      amount,
      SOL_DESTINATION_DOMAIN,
      destinationAddressInBytes32,
      USDC_ETH_CONTRACT_ADDRESS
    )
    .estimateGas()

  const gasPrice = await web3.eth.getGasPrice()

  const nonce = await web3.eth.getTransactionCount(senderAddress)

  const tx = {
    from: senderAddress,
    to: ETH_TOKEN_MESSENGER_CONTRACT_ADDRESS,
    gasPrice: gasPrice,
    gas: burnTxGas,
    nonce: nonce,
    data: ethTokenMessengerContract.methods
      .depositForBurn(
        amount,
        SOL_DESTINATION_DOMAIN,
        destinationAddressInBytes32,
        USDC_ETH_CONTRACT_ADDRESS
      )
      .encodeABI(),
  }

  const txReceipt = await web3.eth.sendTransaction(tx)

  const approveTxReceipt = await waitForTransaction(
    web3,
    txReceipt.transactionHash
  )
  console.log("ApproveTxReceipt: ", approveTxReceipt)

  return approveTxReceipt.transactionHash
}

// STEP 3 Method: Retrieve message bytes from logs
const getMessage = async (web3, txHash) => {
  const transactionReceipt = await web3.eth.getTransactionReceipt(txHash)
  const eventTopic = web3.utils.keccak256("MessageSent(bytes)")
  const log = transactionReceipt.logs.find((l) => l.topics[0] === eventTopic)
  const messageBytes = web3.eth.abi.decodeParameters(["bytes"], log.data)[0]
  const messageHash = web3.utils.keccak256(messageBytes)

  console.log(`MessageBytes: ${messageBytes}`)
  console.log(`MessageHash: ${messageHash}`)

  return {
    bytes: messageBytes,
    hash: messageHash,
  }
}

// STEP 4 Fetch Attestation
const fetchAttestation = async (messageHash) => {
  let attestationResponse = { status: "pending" }
  while (attestationResponse.status != "complete") {
    try {
      const response = await fetch(
        `${CIRCLE_ATTESTATION_API}/attestations/${messageHash}`
      )
      attestationResponse = await response.json()
    } catch (error) {
      console.error(`Error fetching attestation: ${error}`)
    }
    await new Promise((r) => setTimeout(r, 2000))
  }

  const attestationSignature = attestationResponse.attestation
  console.log(`Signature: ${attestationSignature}`)

  return attestationSignature
}

// STEP 5 Receive Funds
const receiveFunds = async (
  receiverPublicKey,
  messageBytes,
  attestationSignature
) => {
  const response = await fetch(
    `${process.env.REACT_APP_EXPRESS_API}/api/cctp/transfer`,
    {
      method: "post",
      body: JSON.stringify({
        account: receiverPublicKey.toString(),
        message: messageBytes,
        attestation: attestationSignature,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  )

  if (!response.ok) {
    throw Error(
      `Error minting USDC. Status: ${response.status} ${response.statusText}`
    )
  }

  const body = await response.json()

  if (!body.valid) {
    throw Error(`Error returned from minting`)
  }

  return true
}


export {
  approveMessengerContract,
  burnUSDC,
  getMessage,
  fetchAttestation,
  receiveFunds,
}
