export const XLogo = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2439 2.25H21.5532L14.3251 10.5094L22.8282 21.75H16.172L10.9548 14.9344L4.99229 21.75H1.67822L9.40791 12.9141L1.25635 2.25H8.08135L12.7923 8.47969L18.2439 2.25ZM17.0813 19.7719H18.9142L7.08291 4.125H5.11416L17.0813 19.7719Z"
      fill="#FDFCEA"
    />
  </svg>
)
