import { Button, Modal } from "../components"
import { CloseCircle } from "../css/icons"
import styles from "./UnsealModal.module.scss"

const UnsealModal = ({
  close,
  email,
  unseal,
  setShowSendToAnotherAttendeeModal,
}) => (
  <Modal
    onClose={() => close()}
    width="medium"
    containerClass={styles.modalContainer}
  >
    <div className={styles.unsealModalContainer}>
      <CloseCircle className={styles.close} onClick={() => close()} />
      <div className={styles.unsealWarning}>Ready for the event?</div>

      <div className={styles.subtext}>
        Tickets will be sent to {email || "provided email"}. Upon receipt,
        tickets will be marked as 'open' on the blockchain.
      </div>
      <div className={styles.unsealImageContainer}>
        <img
          src="https://hngr-icons.s3.amazonaws.com/ticketdex/Ticket+Status.png"
          alt="locked tickets"
        />
      </div>

      <p
        className={styles.sendLink}
        onClick={() => setShowSendToAnotherAttendeeModal(true)}
      >
        Send to Another Attendee
      </p>

      <div className={styles.buttonContainer}>
        <Button variant="gray" onClick={() => close()}>
          Not Now
        </Button>
        <Button variant="blue" onClick={() => unseal()}>
          Receive Tickets
        </Button>
      </div>
    </div>
  </Modal>
)

export default UnsealModal
