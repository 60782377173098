import styles from "./Derby.module.scss"
import React, { useState, useEffect } from "react"
import classNames from "classnames"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  setConfig,
  CNFT,
  PDA,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
} from "@captainxyz/solana-core"

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable"
import { restrictToWindowEdges } from "@dnd-kit/modifiers"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { useSelector } from "react-redux"
import postToSlack from "../postToSlack"

import { getHorseCNFTS, getBurnedHorseCNFTS } from "../helpers/getTokenAccounts"

import SignInModal from "../Authentication/SignInModal"

import { SortableItem } from "./SortableItem"

import { Button, Layout, Modal, Dispenser } from "../components"
import PrizePool, { PrizeHeader } from "./PrizePool"
import { CheckCircleGreenTransparent, CloseCircle, Twitter } from "../css/icons"
const { formatCents } = require("../helpers/money")

function Derby() {
  const user = useSelector((state) => state.user.user)
  const adapter = useWallet()

  const [showSigninModal, setShowSigninModal] = useState(false)
  const [activeTab, setActiveTab] = useState("game")
  const [gameActive, setGameActive] = useState(false) // change to false
  const [horsesLoading, setHorsesLoading] = useState(false)
  const [viewingHorses, setViewingHorses] = useState(false)
  const [activeModalHorse, setActiveModalHorse] = useState(0)
  const [showInstructions, setShowInstructions] = useState(true) // change to true
  const [showSavedLineup, setShowSavedLineup] = useState(true) // change to true
  const [clickedGetHorses, setClickedGetHorses] = useState(false)
  const [submittingHorses, setSubmittingHorses] = useState(false)
  const [submittedHorses, setSubmittedHorses] = useState(false)
  const [showSubmittedModal, setShowSubmittedModal] = useState(false)
  const [raceEnded, setRaceEnded] = useState(true) //change on date
  const [showRulesModal, setShowRulesModal] = useState(false)
  const [payouts, setPayouts] = useState(null)
  const [pot, setPot] = useState(null)
  const [threshold, setThreshold] = useState(null)
  const [numPlayers, setNumPlayers] = useState(null)
  const [startGameOnceUserIsSet, setStartGameOnceUserIsSet] = useState(null)
  const [hasClaimedPrize, setHasClaimedPrize] = useState(null)
  const [payoutAmount, setPayoutAmount] = useState(null)
  const [claimKey, setClaimKey] = useState(null)

  const [horses, setHorses] = useState([])
  const [positions, setPositions] = useState([])
  const [hasUnusedHorses, setHasUnusedHorses] = useState(false)
  const [horseCNFTs, setHorseCNFTs] = useState([])
  const [burnedHorseCNFTs, setBurnedHorseCNFTs] = useState(false)
  const [items, setItems] = useState(horses)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [scratchedHorse, setScratchedHorse] = useState(false)
  const [banned, setBanned] = useState(false)

  useEffect(() => {
    document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute(
        "content",
        "https://xp-metadata.s3.amazonaws.com/derbyshare.png"
      )
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute(
        "content",
        "https://xp-metadata.s3.amazonaws.com/derbyshare.png"
      )

    checkCountry()
    getPayouts()
    return () => {
      window.CHECKED_FOR_HORSE_CNFTS = false
      window.COMPARED_WEB2_WEB3_HORSE_LISTS = false
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    /*
    if (user?.publicKey) {
      init()
    }
    */
    /*

    if (user?.loginMethod === "wallet") {
      checkForHorseCNFTS()
    }

    if (user?.publicKey && clickedGetHorses) {
      if (!horsesLoading) {
        getHorses()
        setHorsesLoading(true)
      }
    }
    */
  }, [user]) // eslint-disable-line

  useEffect(() => {
    if (startGameOnceUserIsSet && user && !window.STARTING_GAME) {
      window.STARTING_GAME = true
      setStartGameOnceUserIsSet(false)
      startGame()
    }
  }, [startGameOnceUserIsSet, user]) // eslint-disable-line

  useEffect(() => {
    if (horses?.length && positions?.length && raceEnded) {
      for (let i = 0; i < horses.length; i++) {
        horses[i].correct = positions[i]?.correct
      }
    }
  }, [horses, positions]) // eslint-disable-line

  useEffect(() => {
    setItems(horses)
    for (let i = 0; i < horses.length; i++) {
      if (horses[i].scratched) {
        setScratchedHorse(true)
      }
    }
  }, [horses]) // eslint-disable-line

  useEffect(() => {
    if (
      horses?.length &&
      horseCNFTs.length &&
      burnedHorseCNFTs !== false &&
      !window.COMPARED_WEB2_WEB3_HORSE_LISTS
    ) {
      compareWeb2Web3Lists()
      window.COMPARED_WEB2_WEB3_HORSE_LISTS = true
    }
  }, [horses, horseCNFTs, burnedHorseCNFTs]) // eslint-disable-line

  /*
  const init = async () => {
    let hasHorses = await alreadyHasHorses()
    if (hasHorses) {
      setHorsesLoading(true)
      getHorsePositions()
    }
  }
  */

  const recordPaidOut = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/derby/record-paid-out`
    let headers = {
      "Content-Type": "application/json",
    }
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        wallet: user.publicKey.toString(),
      }),
    })
    resp = await resp.json()
  }

  const checkCountry = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/derby/country`
    let headers = {
      "Content-Type": "application/json",
    }
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        referrer: document.referrer,
        useragent: window.navigator?.userAgent,
      }),
    })
    resp = await resp.json()
    if (!resp.allowed) setBanned(true)
  }

  const getPayouts = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/derby/prizes`
    let headers = {
      "Content-Type": "application/json",
    }
    let resp = await fetch(url, {
      method: "get",
      headers: headers,
    })
    resp = await resp.json()
    setPayouts(resp.payouts)
    console.log("payouts", resp.payouts)
    setPot(resp.pot)
    setThreshold(resp.threshold)
    setNumPlayers(resp.players)
  }

  const horseRealName = (name) => {
    let a = {}
    a["Nordoch"] = "Dornoch"
    a["Lierra Seone"] = "Sierra Leone"
    a["Niercefess"] = "Fierceness"
    a["Mystical Daniel"] = "Mystik Dan"
    a["Monor Harie"] = "Honor Marie"
    a["Just Iron"] = "Just Steel"
    a["Just A Sprinkly"] = "Just A Touch"
    a["Enciyes"] = "Encino"
    a["Catching Liberty"] = "Catching Freedom"
    a["East Waratoga"] = "West Saratoga"
    a["Pomestic Doduct"] = "Domestic Product"
    a["Yorever Fung (JPN)"] = "Forever Young (JPN)"
    a["Track Ghost"] = "Track Phantom"
    a["TO Username"] = "TO Password (JPN)"
    a["Stronggrip"] = "Stronghold"
    a["Society Woman"] = "Society Man"
    a["Resiliencia"] = "Resilience"
    a["Grand Go For Thirst"] = "Grand Mo The First"
    a["Catalyst"] = "Catalytic"
    a["Never Ending"] = "Endlessly"
    return a[name]
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const startGame = () => {
    if (!user?.publicKey) {
      console.log("user not set yet...")
      setStartGameOnceUserIsSet(true)
      return
    }
    let msg = "has entered game"
    postToSlack(msg, "derby", user)
    setShowSigninModal(false)
    getPayoutInfo()
    if (user.loginMethod === "phone") {
      getPhoneHorses()
    } else {
      getWalletHorses()
    }
  }

  const getPayoutInfo = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/derby/payout-info`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      wallet: user.publicKey.toString(),
    }
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setHasClaimedPrize(resp.has_claimed_prize)
    setClaimKey(resp.claim_key)
    setPayoutAmount(resp.payout_amount)
  }

  /*
    --- get the positions
    --- get their horse cnfts
    
    --- goal is to get new list of positions
    --- loop over positions and init a cnft
    ------ if cnft is burned or owned by someone 
           other than them or the oracle ........ remove from list

    --- if any of their cnfts are not part of the positions, prepend them


  */
  const getWalletHorses = async () => {
    setHorsesLoading(true)
    let positions = await getHorsePositions(false)
    if (!positions) {
      let msg = "Sign this message and give me horses to play XP Derby"
      let encodedMessage = new TextEncoder().encode(msg)
      try {
        let sig = await adapter.signMessage(encodedMessage)
      } catch (err) {
        console.log("User did not sign message", err)
        return
      }
      await dispenser()
      return
    }

    let cnfts = await getHorseCNFTS(user.publicKey)
    let cnftMints = []
    for (let i = 0; i < cnfts.length; i++) {
      cnftMints.push(cnfts[i].address.toString())
    }

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })
    let operator = new Operator("mainnet-beta")
    let filteredPositionMints = []

    for (let i = 0; i < positions.length; i++) {
      let mint = positions[i].mint

      // if cnft is not in their wallet, check to see if someone else has it
      if (!cnftMints.includes(mint)) {
        let cnft = await CNFT.init(operator, { address: mint })

        // owned by oracle
        if (
          cnft.owner.toString() ===
          "FqyiygsWRLc2jsMWNdME8JCYyizcC4KEa6y18AmW4JQb"
        ) {
          filteredPositionMints.push(mint)
        } else {
          // transferred out cnft
          let msg = "No longer owns " + cnft.owner.toString()
          postToSlack(msg, "derby", user)
        }
      } else {
        // it's in their wallet
        filteredPositionMints.push(mint)
      }
    }

    // loop over cnft mints and see if any of them aren't in the filtered positions
    for (let i = 0; i < cnftMints.length; i++) {
      let mint = cnftMints[i]
      if (!filteredPositionMints.includes(mint)) {
        filteredPositionMints.unshift(mint)
      } else {
      }
    }

    // TODO burning and hitting the dispenser
    if (filteredPositionMints.length > 5) {
      setHasUnusedHorses(true)
      filteredPositionMints = filteredPositionMints.slice(
        filteredPositionMints.length - 5,
        filteredPositionMints.length
      )
    }

    let horses = await mintsToHorses(filteredPositionMints)
    setHorses(horses)
    submitHorses(horses, true)
    setHorsesLoading(false)
    setGameActive(true)
  }

  /*
  When we find cnfts, we're looking for any cnfts that aren't in our
  list of horses we have for them.... that lives in horses

  If we find one that isn't in the list we:
  ---- setExtraHorses
  ---- hit backend to take that horse of the list of the other person
  */

  const compareWeb2Web3Lists = async () => {
    console.log("nft inside compare")
    let web2mints = []
    let web3mints = []
    let burnedMints = []
    for (let i = 0; i < horses.length; i++) web2mints.push(horses[i].mint)

    for (let i = 0; i < horseCNFTs.length; i++) {
      web3mints.push(horseCNFTs[i].address.toString())
    }

    for (let i = 0; i < burnedHorseCNFTs.length; i++) {
      burnedMints.push(burnedHorseCNFTs[i].address.toString())
    }

    let web3onlyMints = []
    for (let i = 0; i < web3mints.length; i++) {
      let mint = web3mints[i]
      // extra extra read all about it
      if (!web2mints.includes(mint)) {
        let msg = "has horse nft from third party " + mint
        postToSlack(msg, "derby", user)
        markHorseAsOwnedElsewhere(mint)
        web3onlyMints.push(mint)
      }
    }
    console.log("nft web3only mints", web3onlyMints)

    // with the web3onlyMints .....
    /*
      if they have less than 5 horses, then prepend some of these
      if they have more , add these to extras
    */
    let web3onlyHorses = await mintsToHorses(web3onlyMints)
    //let combinedHorses = JSON.parse(JSON.stringify(horses))
    let combinedHorses = []
    for (let i = 0; i < horses.length; i++) {
      if (!burnedMints.includes(horses[i].mint)) {
        combinedHorses.push(JSON.parse(JSON.stringify(horses[i])))
      }
    }

    if (horses.length < 5) {
      let missingHorses = 5 - horses.length
      for (let i = 0; i < horses.length; i++) {
        if (i < web3onlyHorses.length) {
          combinedHorses.unshift(web3onlyHorses[i])
        }
      }
    }
    setHorses(combinedHorses)
    console.log("nft web3only horses", web3onlyHorses)
    console.log("nft combined horses", combinedHorses)
    let unusedHorses = []
    for (let i = 0; i < web3onlyHorses.length; i++) {
      let web3horse = web3onlyHorses[i]
      console.log("web3 only horse nft", web3horse)
      let found = false
      for (let j = 0; j < combinedHorses.length; j++) {
        if (combinedHorses[j].mint === web3horse.mint) found = true
      }
      if (!found) unusedHorses.push(JSON.parse(JSON.stringify(web3horse)))
    }
    console.log("nft unused horses", unusedHorses)
    //setUnusedHorses(unusedHorses)
  }

  const markHorseAsOwnedElsewhere = async (mint) => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/markHorseUsedElsewhere`

    let params = {
      mint: mint,
    }
    let resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    })
    let respJson = await resp.json()
    console.log("resp from marking horse elsewhere", respJson)
  }

  const checkForHorseCNFTS = async () => {
    if (window.CHECKED_FOR_HORSE_CNFTS) {
      console.log("Already checked for horse nfts...")
      return
    }
    console.log("Checking for horse nfts...")
    window.CHECKED_FOR_HORSE_CNFTS = true
    let tokens = await getHorseCNFTS(user.publicKey)
    let burned = await getBurnedHorseCNFTS(user.publicKey)
    setBurnedHorseCNFTs(burned)
    setHorseCNFTs(tokens)
  }

  const dispenser = async () => {
    let msg = "is about to hit derby dispenser"
    postToSlack(msg, "derby", user)
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/couponDispenser`
    let dispenserUrl = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/kentuckyderby`

    let params = {
      wallet: user.publicKey.toString(),
      url: dispenserUrl,
    }
    if (user.loginMethod === "wallet") params.airdrop = true

    try {
      let resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      })

      let respJson = await resp.json()
      if (!respJson.success) {
        // SCOTT TODO: implement
        alert("Error getting horses")
        let msg = "Had a fail on derby dispenser"
        postToSlack(msg, "derby", user)
        setHorsesLoading(false)
        return
      }

      let msg = "Had success on derby dispenser"
      postToSlack(msg, "derby", user)

      let mints = respJson.mints
      let horses = await mintsToHorses(mints)
      setHorses(horses)
      submitHorses(horses, true)
      setHorsesLoading(false)
      setViewingHorses(true)
    } catch (err) {
      console.log("error getting horses", err)
      let msg = "Had a 500 fail on derby dispenser"
      postToSlack(msg, "derby", user)
      // SCOTT TODO: implement
      alert("Unknown error getting horses")
    }
  }

  const loadCnftMetadata = async (mint) => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/cnftMetadata`
    let params = { mint: mint }
    let resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    })
    let json = await resp.json()
    return json
  }

  // takes in a list of mints and returns a list of
  // horses set up like
  /*
    {
      id: 4,
      name: "Track Phantom",
      image:
        "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/placeholder+horse+4.png",
      odds: "2/7",
      correct: false,
    },
  */
  const mintsToHorses = async (mints) => {
    // TODO build in retries
    let horses = []
    for (let i in mints) {
      let mint = mints[i]
      let metadata = await loadCnftMetadata(mint)
      let horse = metadata
      metadata.mint = mint
      metadata.id = mint
      horses.push(horse)
    }
    return horses
  }

  const alreadyHasHorses = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/derby/positions`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      wallet: user.publicKey.toString(),
    }
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    if (resp.positions?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const getHorsePositions = async (save) => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/derby/positions`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      wallet: user.publicKey.toString(),
    }
    let msg = "is getting horse positions"
    postToSlack(msg, "derby", user)
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    console.log("horse positions resp", resp)
    if (!resp.positions || !resp.positions.length) {
      return null
    }

    let mints = []
    for (let i in resp.positions) {
      let mint = resp.positions[i]["mint"]
      mints.push(mint)
    }
    let horses = await mintsToHorses(mints)
    if (save) {
      setHorses(horses)
      setHorsesLoading(false)
      setGameActive(true)
    }
    setPositions(resp.positions)
    return resp.positions
  }

  // horse dispenser
  const getPhoneHorses = async () => {
    setHorsesLoading(true)
    let horses = await getHorsePositions(true)
    if (!horses) {
      let msg = " has no horses... hitting dispenser"
      postToSlack(msg, "derby", user)
      console.log("No horses. Hitting dispenser")
      await dispenser()
    }
  }

  const submitHorses = async (_horses) => {
    if (raceEnded) return true
    let url = `${process.env.REACT_APP_HNGR_API}/api/derby/save-lineup`
    let headers = {
      "Content-Type": "application/json",
    }
    let mints = []
    if (_horses) {
      for (let i = 0; i < _horses.length; i++) {
        mints.push(_horses[i].mint)
      }
    } else {
      for (let i = 0; i < items.length; i++) {
        mints.push(items[i].mint)
      }
    }
    let params = {
      wallet: user.publicKey.toString(),
      mints: mints,
    }
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    let msg = "saved horses  " + JSON.stringify(mints)
    postToSlack(msg, "derby", user)
    setSubmittingHorses(false)
    setSubmittedHorses(true)
    //dont show on initial save
    if (!_horses) {
      setShowSubmittedModal(true)
    }
    setUnsavedChanges(false)
  }

  const numCorrect = () => {
    let num = 0
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].correct) num++
    }
    return `${num}/5`
  }

  const GameFooterLinks = () => (
    <div className={styles.gameFooterLinks}>
      <span
        className={styles.footerLink}
        onClick={() => {
          if (window.innerWidth > 960) {
            setShowRulesModal(true)
          } else {
            setActiveTab("rules")
          }
        }}
        style={{ textAlign: "left" }}
      >
        Rules
      </span>
      <a
        href="https://t.me/+-eyZj-pB7qMwZTdh"
        className={styles.footerLink}
        style={{ textAlign: "center" }}
      >
        Help
      </a>
      <a
        href="https://faint-ballcap-e83.notion.site/XP-Derby-How-to-play-Terms-dfd2ada59c1e45699da213cefa860179"
        className={styles.footerLink}
        style={{ textAlign: "right" }}
      >
        Terms & Conditions
      </a>
      <a
        href="https://xp-metadata.s3.amazonaws.com/privacy-policy.pdf"
        className={styles.footerLink}
        style={{ textAlign: "right" }}
      >
        Privacy Policy
      </a>
    </div>
  )

  const Rules = () => (
    <div className={styles.rulesContainer}>
      <h2>How to Play</h2>
      <div className={styles.rule}>
        <div className={styles.ruleNumber}>1</div>
        <div className={styles.ruleContent}>
          <h3>Sign up & reveal your horses</h3>
          <p>
            Start the game, create an account, and reveal your 5 randomly
            assigned horses.
          </p>
        </div>
      </div>
      <div className={styles.rule}>
        <div className={styles.ruleNumber}>2</div>
        <div className={styles.ruleContent}>
          <h3>Arrange your lineup</h3>
          <p>
            Make your picks by dragging & dropping to arrange your lineup from
            slowest to fastest.
          </p>
        </div>
      </div>
      <div className={styles.rule}>
        <div className={styles.ruleNumber}>3</div>
        <div className={styles.ruleContent}>
          <h3>Win a piece of the prize pool</h3>
          <p>
            Check back in after the race to see how you did and cash in your XP
            ticket credit prize.
          </p>
        </div>
      </div>
    </div>
  )

  if (banned) {
    return (
      <Layout
        hidePageBackground={true}
        className={styles.derbyContainer}
        contentClassName={styles.derbyContent}
        showFooter={false}
      >
        <div className={styles.bannedContainer}>
          <div className={styles.bannedHeader}>ACCESS RESTRICTED</div>
          <div className={styles.bannedContent}>
            Access to xp derby is currently restricted to users within the
            United States as per our regional service policies.
            <br />
            <br />
            If you are visiting from another country, we appreciate your
            interest and suggest reviewing our Terms of Use for more details. We
            look forward to serving you in the future as we expand our
            availability.
          </div>
          <GameFooterLinks />
        </div>
      </Layout>
    )
  }

  return (
    <Layout
      className={styles.derbyContainer}
      hidePageBackground={true}
      contentClassName={styles.derbyContent}
      showFooter={false}
      startGame={() => startGame()}
    >
      {showSigninModal && (
        <SignInModal
          startGame={() => startGame()}
          onClose={() => {
            setShowSigninModal(false)
          }}
        />
      )}
      <div className={styles.content}>
        <img
          alt="XP Derby"
          className={styles.derbyLogo}
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Derby+Logo+Glow.png"
        />
        {window.innerWidth < 960 && (
          <div className={styles.tabContainer}>
            <div
              className={classNames(
                styles.tab,
                activeTab === "game" && styles.activeTab
              )}
              onClick={() => setActiveTab("game")}
            >
              Game
            </div>
            <div
              className={classNames(
                styles.tab,
                activeTab === "prizes" && styles.activeTab
              )}
              onClick={() => setActiveTab("prizes")}
            >
              Prizes
            </div>
            <div
              className={classNames(
                styles.tab,
                activeTab === "rules" && styles.activeTab
              )}
              onClick={() => setActiveTab("rules")}
            >
              Rules
            </div>
          </div>
        )}

        <div className={styles.gameContent}>
          {window.innerWidth > 960 && (
            <div className={styles.gameLeft}>
              <PrizePool
                pot={pot}
                payouts={payouts}
                numPlayers={numPlayers}
                threshold={threshold}
                className={styles.prizePool}
              />
            </div>
          )}
          {activeTab === "game" && (
            <div className={styles.tabContent}>
              {window.innerWidth < 960 && (
                <div className={styles.poolBanner}>
                  <span className={styles.poolValue}>${pot}</span>
                  <div className={styles.poolDisclaimer}>
                    <span> Total Prize Pool</span>
                    <span className={styles.xpCredit}>In XP credit</span>
                  </div>
                </div>
              )}
              <div className={styles.gameContainer}>
                {raceEnded && gameActive ? (
                  <>
                    <div className={styles.raceEndedBanner}>
                      Official Race Results
                    </div>
                    <>
                      <div className={styles.results}>
                        <div className={styles.arrowContainer}>
                          <div className={styles.arrowInner}>
                            <div className={styles.arrowLabels}>
                              <span className={styles.arrowLabel}>
                                Slowest 🐢
                              </span>
                              <span className={styles.arrowLabel}>
                                Fastest 🐇
                              </span>
                            </div>
                            <img
                              src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Line+76.png"
                              alt="arrow"
                              className={styles.arrow}
                            />
                          </div>
                          <img
                            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/flag.png"
                            alt="flag"
                            className={styles.flag}
                          />
                        </div>
                        <div className={styles.horseList}>
                          {horses.map((horse) => (
                            <div className={styles.horseCard}>
                              <div
                                className={classNames(
                                  styles.horseLabel,
                                  horse.correct
                                    ? styles.horseLabelCorrect
                                    : styles.horseLabelIncorrect
                                )}
                              >
                                {horse.name}
                              </div>
                              <img
                                className={styles.horseImage}
                                src={horse.static_image}
                                alt={horse.name}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={styles.rewardBanner}>
                        <div className={styles.rewardLeft}>
                          <span className={styles.rewardIcon}>💰</span>
                          <div className={styles.rewardContent}>
                            <span className={styles.rewardHeader}>
                              Congrats!
                            </span>
                            <span className={styles.rewardCorrect}>
                              {numCorrect()} picks correct
                            </span>
                          </div>
                        </div>
                        <div className={styles.rewardValue}>
                          <div className={styles.reward}>
                            {formatCents(payoutAmount)}
                          </div>
                          <div className={styles.rewardDisclaimer}>
                            XP credit
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.endInviteButton}
                        onClick={() => {
                          if (navigator.share) {
                            navigator
                              .share({
                                url: "https://xp.xyz/g/derby",
                                text: "Just made my picks in the XP Derby Game!🏇No horsing around —the more of us that play, the bigger the prize pot grows! Saddle up and join! 🌟",
                              })
                              .then(() => console.log("Successful share"))
                              .catch((error) =>
                                console.log("Error sharing:", error)
                              )
                          } else {
                            console.log("Web Share API not supported.")
                            window.open(
                              "https://twitter.com/intent/tweet?text=Just%20made%20my%20picks%20in%20the%20XP%20Derby%20Game!%F0%9F%8F%87No%20horsing%20around%20%E2%80%94the%20more%20of%20us%20that%20play,%20the%20bigger%20the%20prize%20pot%20grows!%20Saddle%20up%20and%20join!%20%F0%9F%8C%9F%20Play%20here%3A%20https%3A//xp.xyz/g/derby",
                              "_blank"
                            )
                          }
                        }}
                      >
                        <Twitter />
                        Share
                      </div>
                      <div className={styles.lineupInfo}>
                        Lineup is from horses you received, not from all derby
                        horses.
                      </div>
                    </>

                    {window.innerWidth > 960 && raceEnded && (
                      <div className={styles.endButtonContainer}>
                        {hasClaimedPrize ? (
                          <div
                            className={classNames(
                              styles.claimButton,
                              styles.claimFullWidth
                            )}
                          >
                            Prize Already Claimed!
                          </div>
                        ) : (
                          <>
                            {claimKey && (
                              <Dispenser
                                onCloseCallback={()=>setHasClaimedPrize(true)}
                                onSuccess={() => recordPaidOut()}
                                setParentLoading={() => {}}
                                className={classNames(
                                  styles.claimButton,
                                  styles.claimFullWidth
                                )}
                                fragment={"derbypayout"}
                                buttonCopy="Claim Prize"
                                claimKey={claimKey}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {gameActive ? (
                      <>
                        {submittedHorses ? (
                          <div className={styles.submittedBanner}>
                            Your lineup is set. Check back after the race!
                          </div>
                        ) : (
                          <div className={styles.instructions}>
                            Arrange your lineup & predict who crosses the finish
                            line first
                          </div>
                        )}
                        {showInstructions && (
                          <div
                            className={styles.instructionsContainer}
                            onClick={() => setShowInstructions(false)}
                          >
                            <div className={styles.instructionsHeader}>
                              Drag and drop to organize your lineup
                            </div>
                            <div className={styles.instructionsSubheader}>
                              Arrange the horses from slowest to fastest
                            </div>
                            <div className={styles.instructionsImageContainer}>
                              <img
                                src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/placeholder+horse+1.png"
                                alt="card"
                                className={styles.instructionsCard}
                              />
                              <img
                                src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/drag.gif"
                                alt="drag"
                                className={styles.instructionsDrag}
                              />
                            </div>
                          </div>
                        )}

                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCenter}
                          onDragEnd={handleDragEnd}
                          modifiers={[restrictToWindowEdges]}
                        >
                          <SortableContext
                            items={items}
                            strategy={horizontalListSortingStrategy}
                          >
                            <div className={styles.horseContainer}>
                              <div className={styles.arrowContainer}>
                                <div className={styles.arrowInner}>
                                  <div className={styles.arrowLabels}>
                                    <span className={styles.arrowLabel}>
                                      Slowest 🐢
                                    </span>
                                    <span className={styles.arrowLabel}>
                                      Fastest 🐇
                                    </span>
                                  </div>
                                  <img
                                    src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Line+76.png"
                                    alt="arrow"
                                    className={styles.arrow}
                                  />
                                </div>
                                <img
                                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/flag.png"
                                  alt="flag"
                                  className={styles.flag}
                                />
                              </div>
                              <div className={styles.horseList}>
                                {items.map((item) => (
                                  <SortableItem
                                    key={item.id}
                                    id={item.id}
                                    item={item}
                                  />
                                ))}
                              </div>
                            </div>
                          </SortableContext>
                        </DndContext>
                        {!!(scratchedHorse && !showInstructions) && (
                          <div className={styles.scratchedContainer}>
                            <div className={styles.horseScratchedIcon}>⚠️</div>
                            <div className={styles.scratchedLeft}>
                              <div className={styles.scratchedHeader}>
                                Horse Scratched
                              </div>
                              <div className={styles.scratchedSubheader}>
                                We've replaced your scratched horse with its
                                also-eligible replacement. Let the horse lie or
                                shake up your line up, the choice is yours!
                              </div>
                            </div>
                          </div>
                        )}

                        {!!(hasUnusedHorses && !showInstructions) && (
                          <div className={styles.scratchedContainer}>
                            <div className={styles.horseScratchedIcon}>⚠️</div>
                            <div className={styles.scratchedLeft}>
                              <div className={styles.scratchedHeader}>
                                Extra Horses
                              </div>
                              <div className={styles.scratchedSubheader}>
                                You have more than five horses. If you'd like to
                                play with a specific set of five horses, make
                                sure to transfer the other ones out of your
                                wallet and re-open this page on a new tab.
                              </div>
                            </div>
                          </div>
                        )}

                        <div className={styles.lineupInfo}>
                          Lineup is from horses you received, not from all derby
                          horses.
                        </div>
                      </>
                    ) : (
                      <>
                        {raceEnded ? (
                          <div className={styles.gameInactive}>
                            <h2>the xp derby is over</h2>
                            <p>sign in to view and claim your prize</p>
                            <img
                              src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/treasure+chest.png"
                              alt="treasure chest"
                            />
                            <Button
                              variant="blue"
                              onClick={() => {
                                if (!user?.publicKey) {
                                  window.START_GAME_AFTER_LOGIN = true
                                  setShowSigninModal(true)
                                } else {
                                  startGame()
                                }
                              }}
                            >
                              sign in &amp; claim your prize
                            </Button>
                          </div>
                        ) : (
                          <div className={styles.gameInactive}>
                            <h2>How to Play</h2>
                            <p>1. Sign up & reveal your assigned horses</p>
                            <p>
                              2. Arrange your lineup from slowest to fastest
                            </p>
                            <p>3. Guess correctly and win!</p>
                            <img
                              src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/treasure+chest.png"
                              alt="treasure chest"
                            />
                            <Button
                              variant="blue"
                              onClick={() => {
                                if (!user?.publicKey) {
                                  window.START_GAME_AFTER_LOGIN = true
                                  setShowSigninModal(true)
                                } else {
                                  startGame()
                                }
                              }}
                            >
                              Start Game
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                {window.innerWidth > 960 && gameActive && !raceEnded && (
                  <Button
                    variant="blueModule"
                    disabled={!showInstructions && !unsavedChanges}
                    className={styles.button}
                    onClick={() => {
                      if (showInstructions) {
                        setShowInstructions(false)
                      } else {
                        setSubmittingHorses(true)
                        submitHorses()
                      }
                    }}
                  >
                    {showInstructions ? "Continue" : "Save Lineup"}
                  </Button>
                )}
              </div>
              {window.innerWidth > 960 && <GameFooterLinks />}
            </div>
          )}
        </div>
        {activeTab === "prizes" && (
          <PrizePool
            pot={pot}
            payouts={payouts}
            numPlayers={numPlayers}
            threshold={threshold}
          />
        )}
        {window.innerWidth < 960 && activeTab === "rules" && <Rules />}
        {window.innerWidth > 960 && showRulesModal && (
          <Modal
            onClose={() => setShowRulesModal(false)}
            className={classNames(styles.modal, styles.rulesModal)}
          >
            <CloseCircle onClick={() => setShowRulesModal(false)} />
            <Rules />
          </Modal>
        )}

        {horsesLoading && (
          <Modal
            className={styles.modal}
            containerClass={styles.modalContainer}
            onClose={() => console.log("")}
          >
            <div className={styles.modalHeader}>
              <h1>Gathering Your Horses...</h1>
              <p>This make take up to 60 seconds</p>
            </div>
            <div className={styles.imageContainer}>
              <img
                src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/cardback_shuffleintro-2-ezgif.com-optimize.gif"
                alt="horse"
              />
            </div>
          </Modal>
        )}

        {viewingHorses && (
          <Modal
            className={classNames(styles.modal, styles.horseViewingModal)}
            containerClass={styles.modalContainer}
            onClose={() => {
              setViewingHorses(false)
              setGameActive(true)
            }}
          >
            <div className={styles.modalHeader}>
              <h1>Meet Your Horses</h1>
              <p>
                Your stable is ready for the track. Strategize, race, and lead
                your horses to victory!
              </p>
            </div>
            <div className={styles.horseNameContainer}>
              <div className={styles.horseName}>
                {horses[activeModalHorse].name}
              </div>
              <div className={styles.horseCount}>
                <span>
                  {activeModalHorse + 1} of {horses.length}
                </span>
                <span>horses</span>
              </div>
            </div>

            <div className={styles.horseOdds}>
              <span>odds</span>
              <span>{horses[activeModalHorse].odds}</span>
            </div>

            <div
              className={styles.horseOdds}
              style={{
                fontSize: "8px",
                padding: "0px 1.5rem",
                paddingBottom: "8px",
              }}
            >
              {horses[activeModalHorse].name}'s placement is determined by the
              results of {horseRealName(horses[activeModalHorse].name)} on
              5/4/24.
            </div>

            <div className="slider-container">
              <Slider
                dots={false}
                infinite={false}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                beforeChange={(current, next) => {
                  setActiveModalHorse(next)
                }}
              >
                {horses.map((horse, index) => (
                  <div className={styles.horseImageContainer}>
                    <img
                      className={styles.carouselHorseImage}
                      src={horse.image}
                      alt={horse.name}
                      key={index}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <Button
              variant="blueModule"
              onClick={() => {
                setViewingHorses(false)
                setGameActive(true)
              }}
            >
              Continue
            </Button>
          </Modal>
        )}
        {submittingHorses && (
          <Modal
            className={styles.modal}
            containerClass={styles.modalContainer}
            onClose={() => console.log("")}
          >
            <div className={styles.modalHeader}>
              <h1>Locking in your picks...</h1>
              <p>This may take up to 60 seconds</p>
            </div>
            <div className={styles.imageContainer}>
              <img
                src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/horse+lock+in.gif"
                alt="horse"
              />
            </div>
          </Modal>
        )}

        {showSubmittedModal && (
          <Modal
            className={styles.modal}
            containerClass={styles.modalContainer}
            onClose={() => setShowSubmittedModal(false)}
          >
            <div
              className={classNames(styles.modalHeader, styles.submittedHeader)}
            >
              <div>Lineup Saved</div>
              <CheckCircleGreenTransparent />
            </div>
            <PrizeHeader
              className={styles.prizePool}
              pot={pot}
              payouts={payouts}
              numPlayers={numPlayers}
              threshold={threshold}
            />
            <div className={styles.prizePoolContainer}>
              <span className={styles.poolLabel}>Current Pool</span>
              <span className={styles.pool}>${pot}</span>
              <span className={styles.poolDisclaimer}>In XP credit</span>
            </div>
            <div className={styles.poolInvite}>
              Invite more people & increase the prize pool!
            </div>
            <div className={styles.buttonRow}>
              <div
                className={styles.inviteButton}
                onClick={() => {
                  if (navigator.share) {
                    navigator
                      .share({
                        url: "https://xp.xyz/g/derby",
                        text: "Just made my picks in the XP Derby Game!🏇No horsing around —the more of us that play, the bigger the prize pot grows! Saddle up and join! 🌟",
                      })
                      .then(() => console.log("Successful share"))
                      .catch((error) => console.log("Error sharing:", error))
                  } else {
                    console.log("Web Share API not supported.")
                    window.open(
                      "https://twitter.com/intent/tweet?text=Just%20made%20my%20picks%20in%20the%20XP%20Derby%20Game!%F0%9F%8F%87No%20horsing%20around%20%E2%80%94the%20more%20of%20us%20that%20play,%20the%20bigger%20the%20prize%20pot%20grows!%20Saddle%20up%20and%20join!%20%F0%9F%8C%9F%20Play%20here%3A%20https%3A//xp.xyz/g/derby",
                      "_blank"
                    )
                  }
                }}
              >
                <Twitter />
                Invite
              </div>
              <div
                className={styles.continueButton}
                onClick={() => setShowSubmittedModal(false)}
              >
                Continue
              </div>
            </div>
          </Modal>
        )}

        {window.innerWidth < 960 && gameActive && !raceEnded && (
          <Button
            variant="blueModule"
            className={styles.button}
            disabled={!showInstructions && !unsavedChanges}
            onClick={() => {
              if (showInstructions) {
                setShowInstructions(false)
              } else {
                setSubmittingHorses(true)
                submitHorses()
              }
            }}
          >
            {showInstructions ? "Continue" : "Save Lineup"}
          </Button>
        )}
        {window.innerWidth < 960 && raceEnded && (
          <div className={styles.endButtonContainer}>
            {hasClaimedPrize ? (
              <div
                className={classNames(
                  styles.claimButton,
                  styles.claimFullWidth
                )}
              >
                Prize Already Claimed!
              </div>
            ) : (
              <>
                {claimKey && (
                  <Dispenser
                    onCloseCallback={()=>setHasClaimedPrize(true)}
                    onSuccess={() => recordPaidOut()}
                    setParentLoading={() => {}}
                    className={classNames(
                      styles.claimButton,
                      styles.claimFullWidth
                    )}
                    fragment={"derbypayout"}
                    buttonCopy="Claim Prize"
                    claimKey={claimKey}
                  />
                )}
              </>
            )}
          </div>
        )}
        {window.innerWidth < 960 && <GameFooterLinks />}
      </div>
    </Layout>
  )

  function handleDragEnd(event) {
    setUnsavedChanges(true)
    const { active, over } = event

    let msg = "dragged a horse"
    postToSlack(msg, "derby", user)
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(
          items.find((item) => item.id === active.id)
        )
        const newIndex = items.indexOf(
          items.find((item) => item.id === over.id)
        )

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }
}

export default Derby
