export const Gift = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.70768 16.2507C2.70768 16.8268 3.17318 17.2923 3.74935 17.2923H8.95768V12.084H2.70768V16.2507ZM11.041 17.2923H16.2493C16.8255 17.2923 17.291 16.8268 17.291 16.2507V12.084H11.041V17.2923ZM17.291 6.87565H15.9206C16.1224 6.48177 16.2493 6.04557 16.2493 5.57357C16.2493 3.99479 14.9635 2.70898 13.3848 2.70898C12.0306 2.70898 11.1549 3.40234 10.0319 4.93229C8.90885 3.40234 8.0332 2.70898 6.67904 2.70898C5.10026 2.70898 3.81445 3.99479 3.81445 5.57357C3.81445 6.04557 3.93815 6.48177 4.14323 6.87565H2.70768C2.13151 6.87565 1.66602 7.34115 1.66602 7.91732V10.5215C1.66602 10.8079 1.90039 11.0423 2.18685 11.0423H17.8118C18.0983 11.0423 18.3327 10.8079 18.3327 10.5215V7.91732C18.3327 7.34115 17.8672 6.87565 17.291 6.87565ZM6.67578 6.87565C5.95638 6.87565 5.3737 6.29297 5.3737 5.57357C5.3737 4.85417 5.95638 4.27148 6.67578 4.27148C7.32357 4.27148 7.80208 4.37891 9.47852 6.87565H6.67578ZM13.3848 6.87565H10.582C12.2552 4.38542 12.7207 4.27148 13.3848 4.27148C14.1042 4.27148 14.6868 4.85417 14.6868 5.57357C14.6868 6.29297 14.1042 6.87565 13.3848 6.87565Z"
      fill="#FAFAFA"
    />
  </svg>
)
