export const Hockey = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66667 11.5C2.66667 8.73958 8.63542 6.5 16 6.5C23.3646 6.5 29.3333 8.73958 29.3333 11.5C29.3333 14.2604 23.3646 16.5 16 16.5C8.63542 16.5 2.66667 14.2604 2.66667 11.5ZM2.66667 15.7812V21.5C2.66667 24.2604 8.63542 26.5 16 26.5C23.3646 26.5 29.3333 24.2604 29.3333 21.5V15.7812C23.4271 20.0677 8.57813 20.0729 2.66667 15.7812Z"
      fill="#FDFCEA"
    />
  </svg>
)
