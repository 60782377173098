import {
  TransactionInstruction,
  PublicKey,
  AccountMeta,
} from "@solana/web3.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh"; // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types"; // eslint-disable-line @typescript-eslint/no-unused-vars
import { PROGRAM_ID } from "../programId";

export interface UpdateMetadataArgs {
  root: Array<number>;
  nonce: BN;
  index: number;
  currentMetadata: types.MetadataArgsFields;
  updateArgs: types.UpdateArgsFields;
}

export interface UpdateMetadataAccounts {
  treeAuthority: PublicKey;
  /**
   * Either collection authority or tree owner/delegate, depending
   * on whether the item is in a verified collection
   */
  authority: PublicKey;
  /** Used when item is in a verified collection */
  collectionMint: PublicKey;
  /** Used when item is in a verified collection */
  collectionMetadata: PublicKey;
  collectionAuthorityRecordPda: PublicKey;
  leafOwner: PublicKey;
  leafDelegate: PublicKey;
  payer: PublicKey;
  merkleTree: PublicKey;
  logWrapper: PublicKey;
  compressionProgram: PublicKey;
  tokenMetadataProgram: PublicKey;
  systemProgram: PublicKey;
  proof: PublicKey[];
}

export const layout = borsh.struct([
  borsh.array(borsh.u8(), 32, "root"),
  borsh.u64("nonce"),
  borsh.u32("index"),
  types.MetadataArgs.layout("currentMetadata"),
  types.UpdateArgs.layout("updateArgs"),
]);

/** Updates metadata for a leaf node that is not part of a verified collection. */
export function updateMetadata(
  args: UpdateMetadataArgs,
  accounts: UpdateMetadataAccounts,
  programId: PublicKey = PROGRAM_ID,
) {
  const keys: Array<AccountMeta> = [
    { pubkey: accounts.treeAuthority, isSigner: false, isWritable: false },
    { pubkey: accounts.authority, isSigner: true, isWritable: false },
    { pubkey: accounts.collectionMint, isSigner: false, isWritable: false },
    { pubkey: accounts.collectionMetadata, isSigner: false, isWritable: false },
    {
      pubkey: accounts.collectionAuthorityRecordPda,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: accounts.leafOwner, isSigner: false, isWritable: false },
    { pubkey: accounts.leafDelegate, isSigner: false, isWritable: false },
    { pubkey: accounts.payer, isSigner: true, isWritable: false },
    { pubkey: accounts.merkleTree, isSigner: false, isWritable: true },
    { pubkey: accounts.logWrapper, isSigner: false, isWritable: false },
    { pubkey: accounts.compressionProgram, isSigner: false, isWritable: false },
    {
      pubkey: accounts.tokenMetadataProgram,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: accounts.systemProgram, isSigner: false, isWritable: false },
    ...accounts.proof.map((key) => ({
      pubkey: key,
      isSigner: false,
      isWritable: false,
    })),
  ];
  const identifier = Buffer.from([170, 182, 43, 239, 97, 78, 225, 186]);
  const buffer = Buffer.alloc(1000);
  const len = layout.encode(
    {
      root: args.root,
      nonce: args.nonce,
      index: args.index,
      currentMetadata: types.MetadataArgs.toEncodable(args.currentMetadata),
      updateArgs: types.UpdateArgs.toEncodable(args.updateArgs),
    },
    buffer,
  );
  const data = Buffer.concat([identifier, buffer]).slice(0, 8 + len);
  const ix = new TransactionInstruction({ keys, programId, data });
  return ix;
}
