import { useSelector } from "react-redux"
import { useState, useEffect } from "react"
import { Button, Input, Layout } from "../components"
import { useWallet } from "@solana/wallet-adapter-react"
import { useNavigate } from "react-router-dom"
import SignInModal from "../Authentication/SignInModal"
import styles from "./Unwrapped.module.scss"
import classNames from "classnames"

const { keycodeDispenser } = require("../helpers/dispenser")

const Unwrapped = () => {
  const user = useSelector((state) => state.user.user)
  const [file, setFile] = useState()
  const [image, setImage] = useState()
  const [showLoading, setShowLoading] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [showCongrats, setShowCongrats] = useState(false)
  const [showSuccess, setShowSuccess] = useState(true) 
  const [showSignInModal, setShowSignInModal] = useState(false)
  const [showFile, setShowFile] = useState(false)
  const [waitingForUser, setWaitingForUser] = useState(false)
  const navigate = useNavigate()       


  useEffect(() => {
    if (user && waitingForUser) {
      uploadAndDispense()
      setWaitingForUser(false)
    }
  }, [user, waitingForUser]) // eslint-disable-line react-hooks/exhaustive-deps


  // if not logged in, make them go through signup flow with email
  const claimReward = async () => {
    if(user) {
      uploadAndDispense()
    } else {
      setShowSignInModal(true)
    }
  }

  const dispense = async () => {
    if(!user){
      setWaitingForUser(true)
      return
    }
    setShowLoading(true)
    setShowSignInModal(false)
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/couponDispenser`    
    let dispenserUrl = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/unwrapped` 
    let params = {    
      wallet: user.publicKey.toString(),
      url: dispenserUrl
    }    
    let resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    })
    resp = await resp.json()
    if (resp.success) {
      //let msg = `had success with keycode kc:${keycode} on ${FRAGMENT}`
      //postToSlack(msg, "dispenser", user)
      //setLoading(false)
      //setShowDispenserSuccess(true)
      if(resp.metadata) {
        let discount = resp.metadata.discount
        const formattedDiscount = "$" + (discount / 100).toFixed(0)
        const formattedMinimum = "$" + (resp.metadata.minimum / 100).toFixed(0)
        /*
        setDiscount(formattedDiscount)
        setExpiration(resp.metadata.expiration)
        setMinimum(formattedMinimum)
        alert('dispensed')
        */
        upload()
        setShowLoading(false)
        setShowSuccess(true)
      } else {
        alert('no metadata')
      }
    }

    
  }
  

  const uploadAndDispense = async () => {
    //upload()
    dispense()

  }

  const upload = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/unwrapped-upload`
    let headers = {    
      "Content-Type": "application/json",    
    }    
    var data = new FormData()
    data.append('file',file) 
    data.append('user_id', user.uuid)
    let resp = await fetch(url, {    
      method: "post",    
      body: data 
    })    
    resp = await resp.json()    
    console.log("response is", resp)
  }

  const showLoadingThenCongrats = async () => {
    setShowLoading(true)
    setTimeout(() => {
      setShowLoading(false)
      setShowImage(true)
      
      setTimeout(() => {
        setShowImage(false)
        setShowCongrats(true)
      }, 3000)

    }, 3000)
  }

  if (showSuccess) {
    return (
      <Layout>
        <div className={styles.successInnerContainer}>
          <div className={styles.successContent}>
            <div className={styles.successHeader}>
              You’ve claimed your reward!
            </div>

            <div className={styles.successSubheader}>
              Visit “My Rewards” to view your credit, 
              and tap ‘Apply Rewards’ at checkout once you’re ready to use it.
            </div>

            <div className={styles.ticketBox}>
              <img 
                src='https://cdn.hngr.co/xp/giftimage.png' 
                className={styles.ticketImage}
              />
            </div>

            <Button    
              className={styles.button}    
              variant="blue"    
              fullWidth    
              onClick={() => {    
                navigate("/")    
              }}    
            >    
              Browse Events    
            </Button>  
          </div>
        </div>
      </Layout>

    )

  }

  if(showLoading) {
    return (
      <div className={styles.unwrappedContainer}>
        <div className={styles.loadingOuterContainer}>
          <div className={styles.loadingContainer}>
            <div className={styles.loadingContainerHeader}>
              Unwrapping your reward...
            </div>
            <img src='https://cdn.hngr.co/xp/unwrapping.gif' className={styles.loadingGif} />
            <div className={styles.loadingSubheader}>
              Analyzing artist data...
            </div>
          </div>
          <img src='https://cdn.hngr.co/xp/xp_wrapped.png' className={styles.loadingLogo} />
        </div>
      </div>
    )
  }


  if(showImage) {
    return (
      <div className={styles.unwrappedContainer}>
        <div className={styles.loadingOuterContainer}>
          <div className={styles.loadingContainer}>
            <div className={styles.loadingContainerHeaderSmall}>
              Wow, you’ve got great taste!
            </div>
            <img src={image} className={styles.image} />
          </div>
          <img src='https://cdn.hngr.co/xp/xp_wrapped.png' className={styles.loadingLogo} />
        </div>
      </div>
    )
  }



  if(showCongrats) {
    return (
      <div className={styles.unwrappedContainer}>
        
        {showSignInModal && (
          <SignInModal 
            shouldCaptureEmail={true}
            onClose={() => setShowSignInModal(false)}
            startGame={() => uploadAndDispense()}
          />
        )}

        <div className={styles.congratsBox}>
          <div className={styles.congratsHeader}>
            Congrats!
          </div>
        
          <div className={styles.congratsText}>
            You’ve unwrapped 
          </div>

          <div className={styles.moneyBox}>
            <div className={styles.moneyText}>
              $20
            </div>
          </div>

          <div className={styles.congratsText}>
            to see your favorite artists live. 
          </div>

          <div className={styles.congratsText} style={{marginTop:'18px'}}>
            Sign up or log in to receive your ticket credit.
          </div>

          <div onClick={claimReward} className={styles.unwrappedButton}>
            <div>Claim My Reward</div>
          </div>

          
        </div>
      


      </div>

    )
  }


  return (
    <div className={styles.unwrappedContainerFirst}>
      <div className={styles.content}>
        <img
          src='https://cdn.hngr.co/xp/unwrappedxp.png'
          className={styles.unwrappedLogo}
        />
        <img
          src='https://cdn.hngr.co/xp/unwrapped_text.png'
          className={styles.unwrappedTextLogo}
        />
        <div className={styles.unwrappedSubheader}>
          Upload your Spotify Wrapped and win ticket credit to see your favorite artists live.
        </div>

    
        <label for="file-upload" className={styles.unwrappedButton}>
          <div>Upload Your Wrapped</div>
        </label>

        <input 
          id='file-upload' 
          type="file" 
          name="media_file" 
          onChange={(e) => {
            setFile(e.target.files[0])
            setImage(URL.createObjectURL(e.target.files[0]));
            showLoadingThenCongrats() 
          }}
          style={{display:'none'}}
        />
      </div>
      <img src='https://cdn.hngr.co/xp/unwrappedhero.png' className={styles.unwrappedHero} />
    </div>
  )
}

export default Unwrapped 
