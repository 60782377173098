export type CustomError =
  | AssetOwnerMismatch
  | PublicKeyMismatch
  | HashingMismatch
  | UnsupportedSchemaVersion
  | CreatorShareTotalMustBe100
  | DuplicateCreatorAddress
  | CreatorDidNotVerify
  | CreatorNotFound
  | NoCreatorsPresent
  | CreatorHashMismatch
  | DataHashMismatch
  | CreatorsTooLong
  | MetadataNameTooLong
  | MetadataSymbolTooLong
  | MetadataUriTooLong
  | MetadataBasisPointsTooHigh
  | TreeAuthorityIncorrect
  | InsufficientMintCapacity
  | NumericalOverflowError
  | IncorrectOwner
  | CollectionCannotBeVerifiedInThisInstruction
  | CollectionNotFound
  | AlreadyVerified
  | AlreadyUnverified
  | UpdateAuthorityIncorrect
  | LeafAuthorityMustSign
  | CollectionMustBeSized
  | MetadataMintMismatch
  | InvalidCollectionAuthority
  | InvalidDelegateRecord
  | CollectionMasterEditionAccountInvalid
  | CollectionMustBeAUniqueMasterEdition
  | UnknownExternalError
  | DecompressionDisabled
  | MissingCollectionMintAccount
  | MissingCollectionMetadataAccount
  | CollectionMismatch
  | MetadataImmutable
  | PrimarySaleCanOnlyBeFlippedToTrue
  | CreatorDidNotUnverify

export class AssetOwnerMismatch extends Error {
  static readonly code = 6000
  readonly code = 6000
  readonly name = "AssetOwnerMismatch"
  readonly msg = "Asset Owner Does not match"

  constructor(readonly logs?: string[]) {
    super("6000: Asset Owner Does not match")
  }
}

export class PublicKeyMismatch extends Error {
  static readonly code = 6001
  readonly code = 6001
  readonly name = "PublicKeyMismatch"
  readonly msg = "PublicKeyMismatch"

  constructor(readonly logs?: string[]) {
    super("6001: PublicKeyMismatch")
  }
}

export class HashingMismatch extends Error {
  static readonly code = 6002
  readonly code = 6002
  readonly name = "HashingMismatch"
  readonly msg = "Hashing Mismatch Within Leaf Schema"

  constructor(readonly logs?: string[]) {
    super("6002: Hashing Mismatch Within Leaf Schema")
  }
}

export class UnsupportedSchemaVersion extends Error {
  static readonly code = 6003
  readonly code = 6003
  readonly name = "UnsupportedSchemaVersion"
  readonly msg = "Unsupported Schema Version"

  constructor(readonly logs?: string[]) {
    super("6003: Unsupported Schema Version")
  }
}

export class CreatorShareTotalMustBe100 extends Error {
  static readonly code = 6004
  readonly code = 6004
  readonly name = "CreatorShareTotalMustBe100"
  readonly msg = "Creator shares must sum to 100"

  constructor(readonly logs?: string[]) {
    super("6004: Creator shares must sum to 100")
  }
}

export class DuplicateCreatorAddress extends Error {
  static readonly code = 6005
  readonly code = 6005
  readonly name = "DuplicateCreatorAddress"
  readonly msg = "No duplicate creator addresses in metadata"

  constructor(readonly logs?: string[]) {
    super("6005: No duplicate creator addresses in metadata")
  }
}

export class CreatorDidNotVerify extends Error {
  static readonly code = 6006
  readonly code = 6006
  readonly name = "CreatorDidNotVerify"
  readonly msg = "Creator did not verify the metadata"

  constructor(readonly logs?: string[]) {
    super("6006: Creator did not verify the metadata")
  }
}

export class CreatorNotFound extends Error {
  static readonly code = 6007
  readonly code = 6007
  readonly name = "CreatorNotFound"
  readonly msg = "Creator not found in creator Vec"

  constructor(readonly logs?: string[]) {
    super("6007: Creator not found in creator Vec")
  }
}

export class NoCreatorsPresent extends Error {
  static readonly code = 6008
  readonly code = 6008
  readonly name = "NoCreatorsPresent"
  readonly msg = "No creators in creator Vec"

  constructor(readonly logs?: string[]) {
    super("6008: No creators in creator Vec")
  }
}

export class CreatorHashMismatch extends Error {
  static readonly code = 6009
  readonly code = 6009
  readonly name = "CreatorHashMismatch"
  readonly msg =
    "User-provided creator Vec must result in same user-provided creator hash"

  constructor(readonly logs?: string[]) {
    super(
      "6009: User-provided creator Vec must result in same user-provided creator hash"
    )
  }
}

export class DataHashMismatch extends Error {
  static readonly code = 6010
  readonly code = 6010
  readonly name = "DataHashMismatch"
  readonly msg =
    "User-provided metadata must result in same user-provided data hash"

  constructor(readonly logs?: string[]) {
    super(
      "6010: User-provided metadata must result in same user-provided data hash"
    )
  }
}

export class CreatorsTooLong extends Error {
  static readonly code = 6011
  readonly code = 6011
  readonly name = "CreatorsTooLong"
  readonly msg = "Creators list too long"

  constructor(readonly logs?: string[]) {
    super("6011: Creators list too long")
  }
}

export class MetadataNameTooLong extends Error {
  static readonly code = 6012
  readonly code = 6012
  readonly name = "MetadataNameTooLong"
  readonly msg = "Name in metadata is too long"

  constructor(readonly logs?: string[]) {
    super("6012: Name in metadata is too long")
  }
}

export class MetadataSymbolTooLong extends Error {
  static readonly code = 6013
  readonly code = 6013
  readonly name = "MetadataSymbolTooLong"
  readonly msg = "Symbol in metadata is too long"

  constructor(readonly logs?: string[]) {
    super("6013: Symbol in metadata is too long")
  }
}

export class MetadataUriTooLong extends Error {
  static readonly code = 6014
  readonly code = 6014
  readonly name = "MetadataUriTooLong"
  readonly msg = "Uri in metadata is too long"

  constructor(readonly logs?: string[]) {
    super("6014: Uri in metadata is too long")
  }
}

export class MetadataBasisPointsTooHigh extends Error {
  static readonly code = 6015
  readonly code = 6015
  readonly name = "MetadataBasisPointsTooHigh"
  readonly msg = "Basis points in metadata cannot exceed 10000"

  constructor(readonly logs?: string[]) {
    super("6015: Basis points in metadata cannot exceed 10000")
  }
}

export class TreeAuthorityIncorrect extends Error {
  static readonly code = 6016
  readonly code = 6016
  readonly name = "TreeAuthorityIncorrect"
  readonly msg = "Tree creator or tree delegate must sign."

  constructor(readonly logs?: string[]) {
    super("6016: Tree creator or tree delegate must sign.")
  }
}

export class InsufficientMintCapacity extends Error {
  static readonly code = 6017
  readonly code = 6017
  readonly name = "InsufficientMintCapacity"
  readonly msg = "Not enough unapproved mints left"

  constructor(readonly logs?: string[]) {
    super("6017: Not enough unapproved mints left")
  }
}

export class NumericalOverflowError extends Error {
  static readonly code = 6018
  readonly code = 6018
  readonly name = "NumericalOverflowError"
  readonly msg = "NumericalOverflowError"

  constructor(readonly logs?: string[]) {
    super("6018: NumericalOverflowError")
  }
}

export class IncorrectOwner extends Error {
  static readonly code = 6019
  readonly code = 6019
  readonly name = "IncorrectOwner"
  readonly msg = "Incorrect account owner"

  constructor(readonly logs?: string[]) {
    super("6019: Incorrect account owner")
  }
}

export class CollectionCannotBeVerifiedInThisInstruction extends Error {
  static readonly code = 6020
  readonly code = 6020
  readonly name = "CollectionCannotBeVerifiedInThisInstruction"
  readonly msg = "Cannot Verify Collection in this Instruction"

  constructor(readonly logs?: string[]) {
    super("6020: Cannot Verify Collection in this Instruction")
  }
}

export class CollectionNotFound extends Error {
  static readonly code = 6021
  readonly code = 6021
  readonly name = "CollectionNotFound"
  readonly msg = "Collection Not Found on Metadata"

  constructor(readonly logs?: string[]) {
    super("6021: Collection Not Found on Metadata")
  }
}

export class AlreadyVerified extends Error {
  static readonly code = 6022
  readonly code = 6022
  readonly name = "AlreadyVerified"
  readonly msg = "Collection item is already verified."

  constructor(readonly logs?: string[]) {
    super("6022: Collection item is already verified.")
  }
}

export class AlreadyUnverified extends Error {
  static readonly code = 6023
  readonly code = 6023
  readonly name = "AlreadyUnverified"
  readonly msg = "Collection item is already unverified."

  constructor(readonly logs?: string[]) {
    super("6023: Collection item is already unverified.")
  }
}

export class UpdateAuthorityIncorrect extends Error {
  static readonly code = 6024
  readonly code = 6024
  readonly name = "UpdateAuthorityIncorrect"
  readonly msg = "Incorrect leaf metadata update authority."

  constructor(readonly logs?: string[]) {
    super("6024: Incorrect leaf metadata update authority.")
  }
}

export class LeafAuthorityMustSign extends Error {
  static readonly code = 6025
  readonly code = 6025
  readonly name = "LeafAuthorityMustSign"
  readonly msg =
    "This transaction must be signed by either the leaf owner or leaf delegate"

  constructor(readonly logs?: string[]) {
    super(
      "6025: This transaction must be signed by either the leaf owner or leaf delegate"
    )
  }
}

export class CollectionMustBeSized extends Error {
  static readonly code = 6026
  readonly code = 6026
  readonly name = "CollectionMustBeSized"
  readonly msg = "Collection Not Compatable with Compression, Must be Sized"

  constructor(readonly logs?: string[]) {
    super("6026: Collection Not Compatable with Compression, Must be Sized")
  }
}

export class MetadataMintMismatch extends Error {
  static readonly code = 6027
  readonly code = 6027
  readonly name = "MetadataMintMismatch"
  readonly msg = "Metadata mint does not match collection mint"

  constructor(readonly logs?: string[]) {
    super("6027: Metadata mint does not match collection mint")
  }
}

export class InvalidCollectionAuthority extends Error {
  static readonly code = 6028
  readonly code = 6028
  readonly name = "InvalidCollectionAuthority"
  readonly msg = "Invalid collection authority"

  constructor(readonly logs?: string[]) {
    super("6028: Invalid collection authority")
  }
}

export class InvalidDelegateRecord extends Error {
  static readonly code = 6029
  readonly code = 6029
  readonly name = "InvalidDelegateRecord"
  readonly msg = "Invalid delegate record pda derivation"

  constructor(readonly logs?: string[]) {
    super("6029: Invalid delegate record pda derivation")
  }
}

export class CollectionMasterEditionAccountInvalid extends Error {
  static readonly code = 6030
  readonly code = 6030
  readonly name = "CollectionMasterEditionAccountInvalid"
  readonly msg = "Edition account doesnt match collection"

  constructor(readonly logs?: string[]) {
    super("6030: Edition account doesnt match collection")
  }
}

export class CollectionMustBeAUniqueMasterEdition extends Error {
  static readonly code = 6031
  readonly code = 6031
  readonly name = "CollectionMustBeAUniqueMasterEdition"
  readonly msg = "Collection Must Be a Unique Master Edition v2"

  constructor(readonly logs?: string[]) {
    super("6031: Collection Must Be a Unique Master Edition v2")
  }
}

export class UnknownExternalError extends Error {
  static readonly code = 6032
  readonly code = 6032
  readonly name = "UnknownExternalError"
  readonly msg = "Could not convert external error to BubblegumError"

  constructor(readonly logs?: string[]) {
    super("6032: Could not convert external error to BubblegumError")
  }
}

export class DecompressionDisabled extends Error {
  static readonly code = 6033
  readonly code = 6033
  readonly name = "DecompressionDisabled"
  readonly msg = "Decompression is disabled for this tree."

  constructor(readonly logs?: string[]) {
    super("6033: Decompression is disabled for this tree.")
  }
}

export class MissingCollectionMintAccount extends Error {
  static readonly code = 6034
  readonly code = 6034
  readonly name = "MissingCollectionMintAccount"
  readonly msg = "Missing collection mint account"

  constructor(readonly logs?: string[]) {
    super("6034: Missing collection mint account")
  }
}

export class MissingCollectionMetadataAccount extends Error {
  static readonly code = 6035
  readonly code = 6035
  readonly name = "MissingCollectionMetadataAccount"
  readonly msg = "Missing collection metadata account"

  constructor(readonly logs?: string[]) {
    super("6035: Missing collection metadata account")
  }
}

export class CollectionMismatch extends Error {
  static readonly code = 6036
  readonly code = 6036
  readonly name = "CollectionMismatch"
  readonly msg = "Collection mismatch"

  constructor(readonly logs?: string[]) {
    super("6036: Collection mismatch")
  }
}

export class MetadataImmutable extends Error {
  static readonly code = 6037
  readonly code = 6037
  readonly name = "MetadataImmutable"
  readonly msg = "Metadata not mutable"

  constructor(readonly logs?: string[]) {
    super("6037: Metadata not mutable")
  }
}

export class PrimarySaleCanOnlyBeFlippedToTrue extends Error {
  static readonly code = 6038
  readonly code = 6038
  readonly name = "PrimarySaleCanOnlyBeFlippedToTrue"
  readonly msg = "Can only update primary sale to true"

  constructor(readonly logs?: string[]) {
    super("6038: Can only update primary sale to true")
  }
}

export class CreatorDidNotUnverify extends Error {
  static readonly code = 6039
  readonly code = 6039
  readonly name = "CreatorDidNotUnverify"
  readonly msg = "Creator did not unverify the metadata"

  constructor(readonly logs?: string[]) {
    super("6039: Creator did not unverify the metadata")
  }
}

export function fromCode(code: number, logs?: string[]): CustomError | null {
  switch (code) {
    case 6000:
      return new AssetOwnerMismatch(logs)
    case 6001:
      return new PublicKeyMismatch(logs)
    case 6002:
      return new HashingMismatch(logs)
    case 6003:
      return new UnsupportedSchemaVersion(logs)
    case 6004:
      return new CreatorShareTotalMustBe100(logs)
    case 6005:
      return new DuplicateCreatorAddress(logs)
    case 6006:
      return new CreatorDidNotVerify(logs)
    case 6007:
      return new CreatorNotFound(logs)
    case 6008:
      return new NoCreatorsPresent(logs)
    case 6009:
      return new CreatorHashMismatch(logs)
    case 6010:
      return new DataHashMismatch(logs)
    case 6011:
      return new CreatorsTooLong(logs)
    case 6012:
      return new MetadataNameTooLong(logs)
    case 6013:
      return new MetadataSymbolTooLong(logs)
    case 6014:
      return new MetadataUriTooLong(logs)
    case 6015:
      return new MetadataBasisPointsTooHigh(logs)
    case 6016:
      return new TreeAuthorityIncorrect(logs)
    case 6017:
      return new InsufficientMintCapacity(logs)
    case 6018:
      return new NumericalOverflowError(logs)
    case 6019:
      return new IncorrectOwner(logs)
    case 6020:
      return new CollectionCannotBeVerifiedInThisInstruction(logs)
    case 6021:
      return new CollectionNotFound(logs)
    case 6022:
      return new AlreadyVerified(logs)
    case 6023:
      return new AlreadyUnverified(logs)
    case 6024:
      return new UpdateAuthorityIncorrect(logs)
    case 6025:
      return new LeafAuthorityMustSign(logs)
    case 6026:
      return new CollectionMustBeSized(logs)
    case 6027:
      return new MetadataMintMismatch(logs)
    case 6028:
      return new InvalidCollectionAuthority(logs)
    case 6029:
      return new InvalidDelegateRecord(logs)
    case 6030:
      return new CollectionMasterEditionAccountInvalid(logs)
    case 6031:
      return new CollectionMustBeAUniqueMasterEdition(logs)
    case 6032:
      return new UnknownExternalError(logs)
    case 6033:
      return new DecompressionDisabled(logs)
    case 6034:
      return new MissingCollectionMintAccount(logs)
    case 6035:
      return new MissingCollectionMetadataAccount(logs)
    case 6036:
      return new CollectionMismatch(logs)
    case 6037:
      return new MetadataImmutable(logs)
    case 6038:
      return new PrimarySaleCanOnlyBeFlippedToTrue(logs)
    case 6039:
      return new CreatorDidNotUnverify(logs)
  }

  return null
}
