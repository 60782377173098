import classNames from "classnames"
import styles from "./Input.module.scss"

const Input = ({
  autoFocus,
  autoComplete,
  className,
  disabled,
  disableAutocomplete,
  inputMode,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onInput,
  onKeyDown,
  onKeyUp,
  placeholder,
  step,
  tabIndex,
  type,
  value,
  max,
}) => {
  return (
    <input
      autoFocus={autoFocus}
      className={classNames(styles.input, className)}
      disabled={disabled}
      autoComplete={autoComplete}
      inputMode={inputMode}
      maxLength={maxLength}
      min={min}
      max={max}
      name={name}
      onBlur={(e) => onBlur && onBlur(e)}
      onChange={(e) => onChange && onChange(e)}
      onInput={(e) => onInput && onInput(e)}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      onKeyUp={(e) => onKeyUp && onKeyUp(e)}
      placeholder={placeholder}
      step={step}
      tabIndex={tabIndex}
      type={type}
      value={value}
    />
  )
}

export default Input
