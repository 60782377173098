export const BrowseArrow = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M3.26948 4.8949L3.26948 3.91634C3.26948 3.502 3.60455 3.16699 4.01678 3.1692L12.5854 3.16699C12.9998 3.16699 13.3349 3.502 13.3327 3.91414L13.3327 12.4788C13.3327 12.8931 12.9976 13.2281 12.5854 13.2259L11.6066 13.2259C11.1878 13.2259 10.8505 12.8799 10.8593 12.4611L10.985 7.2796L4.64945 13.6138C4.35626 13.9069 3.88451 13.9069 3.59132 13.6138L2.88591 12.9085C2.59272 12.6154 2.59272 12.1438 2.88591 11.8506L9.22142 5.51642L4.03441 5.64205C3.61337 5.65307 3.26727 5.31586 3.26948 4.8949Z"
      fill="#85D5FF"
    />
  </svg>
)
