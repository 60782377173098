export const Seat = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.58429 4.66683C5.58429 3.8986 6.00616 3.23454 6.62595 2.87256V8.00016H7.87595V2.5835H9.12595V8.00016H10.376V2.87256C10.9957 3.23454 11.4176 3.8986 11.4176 4.66683V8.00016H12.6676V4.66683C12.6676 2.82568 11.1754 1.3335 9.33429 1.3335H7.66762C5.82648 1.3335 4.33429 2.82568 4.33429 4.66683V8.00016H5.58429V4.66683ZM14.29 10.2371L14.0114 9.40381C13.8968 9.06266 13.5791 8.8335 13.2197 8.8335H3.7796C3.42023 8.8335 3.10252 9.06266 2.98793 9.40381L2.70929 10.2371C2.5322 10.7762 2.93325 11.3335 3.50095 11.3335V14.2502C3.50095 14.4793 3.68845 14.6668 3.91762 14.6668H4.75095C4.98012 14.6668 5.16762 14.4793 5.16762 14.2502V11.3335H11.8343V14.2502C11.8343 14.4793 12.0218 14.6668 12.251 14.6668H13.0843C13.3135 14.6668 13.501 14.4793 13.501 14.2502V11.3335C14.0687 11.3335 14.4697 10.7762 14.29 10.2371Z"
      fill="#FDFCEA"
    />
  </svg>
)
