import postToSlack from "../postToSlack"

export const keycodeDispenser = async ({keycode, user, setLoading, onSuccess, onError}) => {
  setLoading(true)
  let url = `${process.env.REACT_APP_EXPRESS_API}/api/keycodeDispenser`
  let params = {
    wallet: user.publicKey.toString(),
    claimKey: keycode.toUpperCase(),
    email:user?.email || user?.user?.email
  }
  let msg = `is trying keycode kc:${keycode} `
  postToSlack(msg, "dispenser", user)

  try {
    let resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    })
    resp = await resp.json()
    if (resp.success) {
      let msg = `had success with keycode kc:${keycode} on ${resp.fragment} `
      postToSlack(msg, "dispenser", user)
      setLoading(false)
      onSuccess(resp)
      return
    } else {
      let msg = `${resp.message}  with keycode kc:${keycode} on ${resp.fragment} `
      postToSlack(msg, "dispenser", user)
      onError(resp.message)
      return
    }
  } catch (err) {
    console.log("dispenser error", err)
    let msg = `Had a dispenser craswh with keycode kc:${keycode} ` 
    postToSlack(msg, "dispenser", user)
    onError('Unknown error')
    return
  }
  
}


