import { PublicKey, AccountInfo } from "@solana/web3.js";
import * as SPLToken from "@solana/spl-token";
import * as MPLTokenMetadata from "@metaplex-foundation/mpl-token-metadata";

import { Operator, TransactionResult } from "../operator";
import { Currency } from "../currency";
import { Transactions } from "../transactions";
import { PDA } from "../pda";
import { BaseAccount } from "./base";
import fetch from "cross-fetch";

/**
 * @group Accounts
 */
export interface JSONMetadata {
  name?: string;
  symbol?: string;
  description?: string;
  seller_fee_basis_points?: number;
  image?: string;
  external_url?: string;
  attributes?: Array<{
    trait_type?: string;
    value?: string;
    [key: string]: unknown;
  }>;
  properties?: {
    creators?: Array<{
      address?: string;
      share?: number;
      [key: string]: unknown;
    }>;
    files?: Array<{
      type?: string;
      uri?: string;
      [key: string]: unknown;
    }>;
    [key: string]: unknown;
  };
  collection?: {
    name?: string;
    family?: string;
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

/**
 * @group Accounts
 */
export class MetadataAccount extends BaseAccount {
  public readonly currency: Currency;

  private _account: MPLTokenMetadata.Metadata | null;

  public get updateAuthority(): PublicKey | null {
    return this._account?.updateAuthority ?? null;
  }

  public get collectionMintAddress(): PublicKey | null {
    return this._account?.collection?.key ?? null;
  }

  public get collectionSize(): bigint | null {
    const size = this._account?.collectionDetails?.size;
    return size ? BigInt(size.toString()) : null;
  }

  public get creators(): MPLTokenMetadata.Creator[] | null {
    return this._account?.data.creators ?? null;
  }

  private _json: JSONMetadata | null;
  public get json(): JSONMetadata | null {
    return this._json;
  }

  protected constructor(params: {
    operator: Operator;
    address: PublicKey;
    lamports: number | bigint;
    currency: Currency;
    account: MPLTokenMetadata.Metadata | null;
    json: JSONMetadata | null;
  }) {
    super(params);
    this.currency = params.currency;
    this._account = params.account;
    this._json = params.json;
  }

  static async init(
    operator: Operator,
    mint: SPLToken.Mint,
    accountInfo?: AccountInfo<Buffer> | null
  ): Promise<MetadataAccount> {
    const address = PDA.tokenMetadata(mint.address);
    const existent = await this._checkExistent<MetadataAccount>(
      operator,
      address,
      accountInfo
    );
    if (existent) return existent;

    if (typeof accountInfo === "undefined") {
      // Try to load `accountInfo` if the argument is omitted only.
      // If it's `null`, it means there was already an attempt to load it,
      // but it doesn't exist. So it doesn't make sense to repeat the loading.
      accountInfo = await operator.connection.getAccountInfo(address);
    }

    let currency: Currency;
    let account: MPLTokenMetadata.Metadata | null = null;
    let json: JSONMetadata | null = null;

    if (!accountInfo) {
      if (!legacyTokenList.size) await loadLegacyTokenList();
      const tokenInfo = legacyTokenList.get(mint.address.toString());
      currency = !tokenInfo
        ? Currency.unknownToken(mint.decimals)
        : new Currency(
            tokenInfo.name ?? Currency.UNKNOWN_NAME,
            tokenInfo.symbol ?? Currency.UNKNOWN_SYMBOL,
            tokenInfo.logoURI ?? Currency.UNKNOWN_LOGO_URI,
            mint.decimals
          );
    } else {
      [account] = MPLTokenMetadata.Metadata.fromAccountInfo(accountInfo);
      const jsonURI = trimNulls(account.data.uri ?? "");
      let logoURI = Currency.UNKNOWN_LOGO_URI;
      if (jsonURI) {
        try {
          json = (await operator.storage.get(jsonURI)).json as JSONMetadata;
          if (json && json.image) logoURI = json.image;
        } catch (e) {
          console.warn(`Unable to handle JSON metadata of ${address}`);
          console.warn(e);
        }
      }
      currency = new Currency(
        trimNulls(account.data.name ?? Currency.UNKNOWN_NAME),
        trimNulls(account.data.symbol ?? Currency.UNKNOWN_SYMBOL),
        logoURI,
        mint.decimals
      );
    }

    const result = new this({
      operator,
      address,
      currency,
      lamports: accountInfo?.lamports ?? 0,
      account,
      json,
    });
    return await result._init();
  }

  /**
   * Checks whether the {@link operator}
   * can perform {@link mint} action on the account.
   */
  public get canUpdate(): boolean {
    return this._getCached("canUpdate", () =>
      Boolean(
        this.updateAuthority &&
          this.operator.identity?.publicKey.equals(this.updateAuthority)
      )
    );
  }

  public async update(json: JSONMetadata): Promise<TransactionResult> {
    if (!this.canUpdate) {
      throw new Error(`Operator has no update authority on ${this}`);
    }
    const account = this._account as MPLTokenMetadata.Metadata;
    const jsonURI = await this.operator.storage.upload({
      json: {
        ...this._json,
        ...json,
      },
    });
    return await this.operator.execute(
      Transactions.updateMetadataAccount({
        address: this.address,
        updateAuthority: account.updateAuthority,
        data: {
          ...account.data,
          name: json.name ?? account.data.name,
          symbol: json.symbol ?? account.data.symbol,
          collection: account.collection,
          uses: account.uses,
          uri: jsonURI,
        },
      })
    );
  }

  public async updateCollection(
    collectionMint: PublicKey
  ): Promise<TransactionResult> {
    if (!this.canUpdate) {
      throw new Error(`Operator has no update authority on ${this}`);
    }
    const account = this._account as MPLTokenMetadata.Metadata;
    const transactions = [];

    if (account.collection) {
      transactions.push(
        Transactions.unverifyNFTCollectionItem({
          payer: this.operator.identity!.publicKey,
          metadata: this.address,
          collectionMint: account.collection.key,
        })
      );
    }

    transactions.push(
      Transactions.updateMetadataAccount({
        address: this.address,
        updateAuthority: account.updateAuthority,
        data: {
          ...account.data,
          collection: { key: collectionMint, verified: false },
          uses: account.uses,
        },
      }),
      Transactions.verifyNFTCollectionItem({
        payer: this.operator.identity!.publicKey,
        metadata: this.address,
        collectionMint: collectionMint,
      })
    );

    return await this.operator.execute(...transactions);
  }

  protected async _refresh(accountInfo: AccountInfo<Buffer>): Promise<void> {
    super._refresh(accountInfo);
    if (!accountInfo.data.length) return;

    const [account] = MPLTokenMetadata.Metadata.fromAccountInfo(accountInfo);
    this._account = account;

    this.currency.name = trimNulls(account.data.name ?? Currency.UNKNOWN_NAME);
    this.currency.symbol = trimNulls(
      account.data.symbol ?? Currency.UNKNOWN_SYMBOL
    );

    const jsonURI = trimNulls(account.data.uri ?? "");
    if (jsonURI) {
      try {
        const json = (await this.operator.storage.get(jsonURI)).json;
        this._json = json as JSONMetadata;
        if (json && json.image) this.currency.logoURI = json.image;
      } catch (e) {
        console.warn(`Unable to handle JSON metadata of ${this.address}`);
        console.warn(e);
      }
    }
  }
}

/**
 * @return Input string with trimmed trailing null characters.
 */
function trimNulls(input: string): string {
  for (let i = 0; i < input.length; i++) {
    if (!input.charCodeAt(i)) return input.slice(0, i);
  }
  return input;
}

/**
 * Get metadata from legacy token list by mint address.
 *
 * @see https://github.com/solana-labs/token-list/
 */
const legacyTokenList: Map<
  string,
  {
    name: string;
    symbol: string;
    logoURI: string;
  }
> = new Map();
async function loadLegacyTokenList(): Promise<void> {
  const urls = [
    "https://cdn.jsdelivr.net/gh/solana-labs/token-list@latest/src/tokens/solana.tokenlist.json",
    "https://raw.githubusercontent.com/solana-labs/token-list/main/src/tokens/solana.tokenlist.json",
  ];
  for (let url of urls) {
    const response = await fetch(url);
    if (response.status == 200) {
      const data = await response.json();
      for (let token of data.tokens) {
        legacyTokenList.set(token.address, {
          name: token.name,
          symbol: token.symbol,
          logoURI: token.logoURI,
        });
      }
      break;
    }
  }
}
