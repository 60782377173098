import { Button, Modal } from "../components"
import styles from "./EventPage.module.scss"
import { Minus, Plus, Seat } from "../css/icons"

const TicketSelectorModal = ({
  availableQuantities,
  dateRef,
  onClose,
  selectedQuantity,
  setSelectedQuantity,
  filterTicketGroups,
  setHasSelectedQuantity,
}) => (
  <Modal onClose={onClose} className={styles.ticketSelectorModal}>
    <div className={styles.ticketSelector}>
      <h2>How many tickets?</h2>
      <div className={styles.stepperContainer}>
        <div
          className={styles.stepperButton}
          onClick={() => {
            if (selectedQuantity > 1) {
              setSelectedQuantity(selectedQuantity - 1)
              filterTicketGroups(selectedQuantity - 1)
            }
          }}
        >
          <Minus />
        </div>
        <span className={styles.selectedQuantity}>{selectedQuantity}</span>
        <div
          className={styles.stepperButton}
          onClick={() => {
            setSelectedQuantity(selectedQuantity + 1)
            filterTicketGroups(selectedQuantity + 1)
          }}
        >
          <Plus />
        </div>
      </div>

      <p className={styles.seatDisclaimer}>
        {selectedQuantity > 1 && (
          <>
            Tickets are seated together <Seat />
            <Seat />
          </>
        )}
      </p>

      <Button
        disabled={!availableQuantities.includes(selectedQuantity)}
        fullWidth
        loading={filterTicketGroups.length === 0}
        onClick={() => {
          if (window.innerHeight <= 600) {
            dateRef.current.scrollIntoView({
              behavior: "smooth",
            })
          }

          setHasSelectedQuantity(true)
          onClose()
        }}
      >
        {availableQuantities.includes(selectedQuantity)
          ? "Continue"
          : "Selected quantity not available"}
      </Button>
    </div>
  </Modal>
)


export default TicketSelectorModal
