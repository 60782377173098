// uses styles from newvelvet

import { useNavigate } from "react-router-dom"
import styles from "./NewVelvet.module.scss"
import { Button, Dispenser } from "../components"
import RedeemInputField from "../components/RedeemInputField/RedeemInputField"

const HeroCarouselCard = ({ params, campaign, setDispenserLoading }) => {
  const navigate = useNavigate()

  return (
    <div
      className={styles.heroSection}
      style={{
        backgroundImage: `url(${
          window.innerWidth > 960
            ? campaign?.img
            : campaign?.json.campaign.campaign_mobile_bg_image || campaign?.img
        })`,
      }}
    >
      {campaign?.json.campaign.campaign_icon && (
        <img src={campaign?.json.campaign.campaign_icon} alt="icon" />
      )}
      <h1 className={styles.heroHeader}>
        {process.env.REACT_APP_SKIN == "ticketnetwork" ? (
          <>You got a guy.</>
        ) : (
          <span dangerouslySetInnerHTML={{__html:campaign?.json.campaign.campaign_header}}></span>
        )}
      </h1>
      <p className={styles.heroParagraph}>
        {campaign?.json.campaign.campaign_description}
      </p>
      {campaign?.json.campaign.campaign_cta_action === "dispenser" ? (
        <Dispenser
          campaign={params?.campaign_name}
          setInitialDispenser={true}
          setParentLoading={setDispenserLoading}
          className={styles.heroButton}
          fragment={campaign?.json.campaign.campaign_dispenser_fragment}
          buttonCopy={campaign?.json.campaign.campaign_cta_text}
        />
      ) : (
        <>
          {campaign?.json.campaign.campaign_cta_action === "redeem" ? (
            <RedeemInputField 
              emailCapture={campaign?.json.campaign.campaign_email_capture }
            />
          ) : (
            <Button
              className={styles.heroButton}
              onClick={() => {
                const link = campaign?.json.campaign.campaign_cta_link
                if (link !== "") {
                  if (link.includes("http")) {
                    window.open(link, "_blank")
                  } else {
                    navigate(link)
                  }
                }
              }}
            >
              {campaign?.json.campaign.campaign_cta_text}
            </Button>
          )}
        </>
      )}
      <div className={styles.heroDisclaimerContainer}>
        <p className={styles.heroDisclaimer}>
          Any trademarks seen here belong to their rightful owners, who are not
          affiliated with nor endorse XP.
        </p>
      </div>
    </div>
  )
}

export default HeroCarouselCard
