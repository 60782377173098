export const Ticket = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.58333 4.25C1.57214 4.25 0.75 5.03477 0.75 6V7.75C0.75 7.99062 0.961979 8.1793 1.19974 8.25859C1.73828 8.43633 2.125 8.92578 2.125 9.5C2.125 10.0742 1.73828 10.5637 1.19974 10.7414C0.961979 10.8207 0.75 11.0094 0.75 11.25V13C0.75 13.9652 1.57214 14.75 2.58333 14.75H15.4167C16.4279 14.75 17.25 13.9652 17.25 13V11.25C17.25 11.0094 17.038 10.8207 16.8003 10.7414C16.2617 10.5637 15.875 10.0742 15.875 9.5C15.875 8.92578 16.2617 8.43633 16.8003 8.25859C17.038 8.1793 17.25 7.99062 17.25 7.75V6C17.25 5.03477 16.4279 4.25 15.4167 4.25H2.58333ZM4.41667 7.3125V11.6875C4.41667 11.9281 4.62292 12.125 4.875 12.125H13.125C13.3771 12.125 13.5833 11.9281 13.5833 11.6875V7.3125C13.5833 7.07187 13.3771 6.875 13.125 6.875H4.875C4.62292 6.875 4.41667 7.07187 4.41667 7.3125ZM3.5 6.875C3.5 6.39102 3.90964 6 4.41667 6H13.5833C14.0904 6 14.5 6.39102 14.5 6.875V12.125C14.5 12.609 14.0904 13 13.5833 13H4.41667C3.90964 13 3.5 12.609 3.5 12.125V6.875Z"
      fill="#FAFAFA"
    />
  </svg>
)
