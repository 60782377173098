import classNames from "classnames"
import styles from "./ValueProp.module.scss"

const ValueProp = ({ className }) => {
  const waitForVideo = (videoId) => {
    return new Promise((resolve) => {
      let video = document.getElementById(videoId)
      if (video) {
        video.oncanplay = () => {
          return resolve(document.getElementById(videoId))
        }
      }
    })
  }

  waitForVideo("bg-disco").then((video) => {
    const fallbackImage = document.getElementById("bg-disco-fallback")
    video?.play().catch((error) => {
      console.error("Error attempting to play", error)
      video.style.display = "none"
      if (fallbackImage) {
        fallbackImage.style.display = "block"
      }
    })
  })

  waitForVideo("bg-turntable").then((video) => {
    const fallbackImage = document.getElementById("bg-turntable-fallback")
    video?.play().catch((error) => {
      console.error("Error attempting to play", error)
      video.style.display = "none"
      if (fallbackImage) {
        fallbackImage.style.display = "block"
      }
    })
  })

  return (
    <div className={className}>
      <video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        className={classNames(styles.heroVideo, styles.discoVideo)}
        width={window.innerWidth}
        id="bg-disco"
      >
        <source
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Disc%2BBBall_v02.mp4"
          type="video/mp4"
        />
      </video>
      <picture>
        <source
          srcSet="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Disc%2BBBall_v03.avif"
          type="image/avif"
        />
        <img
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Disc%2BBBall_v03.webp"
          alt="bball bg"
          className={classNames(
            styles.heroVideo,
            styles.discoVideo,
            styles.fallbackImage
          )}
          id="bg-disco-fallback"
        />
      </picture>
      <div className={styles.valuePropSection}>
        <h1 className={styles.valuePropHeader}>
          Know what you'll pay every step of the way.
        </h1>
        <p className={styles.valuePropDescription}>
          With our transparent ticket pricing, you'll never feel sticker shock
          at checkout.
        </p>
      </div>
      <video
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
        className={styles.heroVideo}
        width={window.innerWidth}
        id="bg-turntable"
      >
        <source
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Disc%2BBBall_v03disccropped_compressed.mp4"
          type="video/mp4"
        />
      </video>
      <picture>
        <source
          srcSet="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Disc%2BBBall_v03disccropped.avif"
          type="image/avif"
        />
        <img
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Disc%2BBBall_v03disccropped.webp"
          alt="turntable"
          className={classNames(styles.heroVideo, styles.fallbackImage)}
          id="bg-turntable-fallback"
        />
      </picture>
    </div>
  )
}

export default ValueProp
