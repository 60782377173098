import styles from "./EventCarousel.module.scss"
import { useNavigate } from "react-router-dom"
import { EventCard, Button, ArtistCard, LocationPicker, PricePicker } from "../"
import { ArrowUpRight, Crossroads } from "../../css/icons"
import { useEffect, useState } from "react"
import classNames from "classnames"

const EventCarousel = ({
  buttonCta,
  className,
  title,
  events,
  type,
  link,
  loaded,
  locationName,
  maxPrice,
  updateLocation,
  updateMaxPrice,
  showGenreList,
  showLeagueList,
  showPriceFilter,
  showLocationPicker = true,
  showCTA = true,
  shouldRollUpEvents = true,
}) => {
  const navigate = useNavigate()
  const [genres, setGenres] = useState([])
  const [leagues, setLeagues] = useState([])

  useEffect(() => {
    if (showGenreList) {
      getGenres()
    }
    if (showLeagueList) {
      getLeagues()
    }
  }, [events]) // eslint-disable-line

  const getGenres = () => {
    let genreList = []

    events?.forEach((event) => {
      if (event.genre && genreList.indexOf(event.genre) === -1) {
        genreList.push(event.genre)
      }
    })

    setGenres(genreList)
  }

  const getLeagues = () => {
    let leagueList = []

    events?.forEach((event) => {
      if (event.category && leagueList.indexOf(event.category) === -1) {
        leagueList.push(event.category)
      }
    })

    setLeagues(leagueList)
  }

  const seen = {}

  const filterEvents = (events) => {
    if (shouldRollUpEvents) {
      return events?.filter((event) => {
        if (!event.min_ticket_price) return false

        if (event.performer_id in seen) {
          seen[event.performer_id] += 1
          return false
        }
        if (!(event.performer_id in seen)) {
          seen[event.performer_id] = 0
          return true
        }
        return false
      })
    } else return events
  }

  return (
    <div className={classNames(styles.homeDiscoveryRow, className)}>
      <div className={styles.discoverySectionHeader}>
        <h2>
          {title} {showLocationPicker && `Near`}
        </h2>
        {showLocationPicker && (
          <LocationPicker
            containerClass={styles.carouselLocationMenuContainer}
            className={styles.carouselLocationPicker}
            updateLocation={updateLocation}
            nearbyName={locationName}
          />
        )}
        {showPriceFilter && (
          <>
            <h2>Under</h2>
            <PricePicker updatePrice={updateMaxPrice} maxPrice={maxPrice} />
          </>
        )}
        {showCTA && (
          <>
            {window.innerWidth > 960 ? (
              <Button
                compact
                className={styles.button}
                variant="primary"
                rm
                onClick={() => navigate(link)}
                rightIcon={<ArrowUpRight />}
              >
                {buttonCta}
              </Button>
            ) : (
              <ArrowUpRight
                className={styles.arrow}
                onClick={() => navigate(link)}
              />
            )}
          </>
        )}
      </div>
      {window.innerWidth > 960 && showGenreList && (
        <div className={styles.categoryListContainer}>
          {genres.map((genre, i) => {
            if (!genre || i > 7) return null
            return (
              <div
                className={styles.subnavFilter}
                key={genre}
                onClick={() =>
                  navigate(`/events?genre=${genre.replace(" ", "+")}`)
                }
              >
                {genre}
              </div>
            )
          })}
        </div>
      )}
      {window.innerWidth > 960 && showLeagueList && (
        <div className={styles.categoryListContainer}>
          {leagues.map((league, i) => {
            if (!league || i > 7) return null
            return (
              <div
                className={styles.subnavFilter}
                key={league}
                onClick={() =>
                  navigate(`/events?league=${league.replace(" ", "+")}`)
                }
              >
                {league}
              </div>
            )
          })}
        </div>
      )}
      <div className={styles.discoverySectionList}>
        <>
          {type === "big" &&
            filterEvents(events)?.map((event, i) => {
              if (i > 1) return null
              return (
                <EventCard key={i} className={styles.card2w} event={event} />
              )
            })}

          {type !== "big" &&
            filterEvents(events)?.map((event, i) => {
              if (i > 6) return null
              if (seen[event.performer_id] > 1) {
                return <ArtistCard event={event} key={event.event_id} />
              } else {
                return (
                  <EventCard event={event} className={styles.card4w} key={i} />
                )
              }
            })}
        </>
        {loaded && events?.length === 0 && (
          <div className={styles.noEvents}>
            <Crossroads />
            <h3>No events here yet, but there's more nearby!</h3>
            <p>
              Try searching in another nearby city to find concerts and games
              you’ll love.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
export default EventCarousel
