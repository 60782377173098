import { Cluster, clusterApiUrl } from "@solana/web3.js";

export type AnyCluster = Cluster | "unittest";

export interface Config {
  rpcEndpoint: string;
  bundlrEndpoint: string;
}

export function setConfig(
  cluster: AnyCluster,
  params: { rpcEndpoint?: string; bundlrEndpoint?: string },
): void {
  _config.set(cluster, { ...getConfig(cluster), ...params });
}

export function getConfig(cluster: AnyCluster): Config {
  return _config.get(cluster) as Config;
}

const _config: Map<AnyCluster, Config> = new Map([
  [
    "mainnet-beta",
    {
      rpcEndpoint: clusterApiUrl("mainnet-beta"),
      bundlrEndpoint: "https://node1.bundlr.network",
    },
  ],
  [
    "testnet",
    {
      rpcEndpoint: clusterApiUrl("testnet"),
      bundlrEndpoint: "https://devnet.bundlr.network",
    },
  ],
  [
    "devnet",
    {
      rpcEndpoint: clusterApiUrl("devnet"),
      bundlrEndpoint: "https://devnet.bundlr.network",
    },
  ],
  [
    "unittest",
    {
      rpcEndpoint: "http://localhost:8899",
      bundlrEndpoint: "", // Mock is used
    },
  ],
]);
