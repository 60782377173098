import {
  PublicKey,
  SystemProgram,
  Keypair,
  SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import * as SPLMemo from "@solana/spl-memo";
import * as SPLToken from "@solana/spl-token";
import * as SPLCompession from "@solana/spl-account-compression";
import * as MPLTokenMetadata from "@metaplex-foundation/mpl-token-metadata";
import * as MPLBubblegum from "./programs/mpl-bubblegum";
import BN from "bn.js";

import { PDA } from "./pda";
import { TransactionBlock } from "./operator";

export namespace Transactions {
  export function addMemo(params: { memo: string }): TransactionBlock {
    return {
      instructions: [SPLMemo.createMemoInstruction(params.memo)],
    };
  }

  export function sendSOLs(params: {
    src: PublicKey;
    dst: PublicKey;
    qty: bigint;
  }): TransactionBlock {
    return {
      instructions: [
        SystemProgram.transfer({
          fromPubkey: params.src,
          toPubkey: params.dst,
          lamports: params.qty,
        }),
      ],
    };
  }

  export function sendTokens(params: {
    src: PublicKey;
    srcToken: PublicKey;
    dstToken: PublicKey;
    programId?: PublicKey;
    qty: bigint;
  }): TransactionBlock {
    return {
      instructions: [
        SPLToken.createTransferInstruction(
          params.srcToken, //  Source token account
          params.dstToken, //  Destination token account
          params.src, //       Owner of the source account
          params.qty, //       Amount to transfer
          [], //               Signing accounts if owner is a multisig
          params.programId, // Token program id
        ),
      ],
    };
  }

  export function mintTokens(params: {
    mint: PublicKey;
    dstToken: PublicKey;
    mintAuthority: PublicKey;
    qty: bigint;
    programId?: PublicKey;
  }): TransactionBlock {
    return {
      instructions: [
        SPLToken.createMintToInstruction(
          params.mint, //          Mint address
          params.dstToken, //      Destination token account address
          params.mintAuthority, // The mint authority
          params.qty, //           Amount to mint
          [], //                   Signing accounts if authority is a multisig
          params.programId, //     Token program id
        ),
      ],
    };
  }

  export function createTokenAccount(params: {
    payer: PublicKey;
    owner?: PublicKey;
    address: PublicKey;
    mint: PublicKey;
    programId?: PublicKey;
  }): TransactionBlock {
    params.owner ??= params.payer;
    return {
      allocate: [params.address],
      instructions: [
        SPLToken.createAssociatedTokenAccountInstruction(
          params.payer, //                 Payer
          params.address, //               Token account address
          params.owner, //                 Owner of the account
          params.mint, //                  Mint address
          params.programId, //             Token program id
          // ASSOCIATED_TOKEN_PROGRAM_ID   Associated token program id
        ),
      ],
    };
  }

  export function createMintAccount(params: {
    payer: PublicKey;
    keypair?: Keypair;
    lamports: number;
    decimals: number;
    mintAuthority?: PublicKey;
    freezeAuthority?: PublicKey;
    programId?: PublicKey;
  }): TransactionBlock {
    params.keypair ??= Keypair.generate();
    params.mintAuthority ??= params.payer;
    params.freezeAuthority ??= params.payer;
    params.programId ??= SPLToken.TOKEN_PROGRAM_ID;
    return {
      signers: [params.keypair],
      allocate: [params.keypair.publicKey],
      instructions: [
        SystemProgram.createAccount({
          fromPubkey: params.payer,
          newAccountPubkey: params.keypair.publicKey,
          space: SPLToken.MINT_SIZE,
          lamports: params.lamports,
          programId: params.programId,
        }),
        SPLToken.createInitializeMint2Instruction(
          params.keypair.publicKey, //  New mint address
          params.decimals, //           Number of decimals in amounts
          params.mintAuthority, //      Minting authority
          params.freezeAuthority, //    Freezing authority
          params.programId, //          Token program id
        ),
      ],
    };
  }

  export function createMetadataAccount(params: {
    payer: PublicKey;
    mint: PublicKey;
    address?: PublicKey;
    mintAuthority?: PublicKey;
    updateAuthority?: PublicKey;
    collectionMint?: PublicKey;
    isCollection?: boolean;
    name: string;
    symbol: string;
    uri: string;
  }): TransactionBlock {
    params.address ??= PDA.tokenMetadata(params.mint);
    params.mintAuthority ??= params.payer;
    params.updateAuthority ??= params.payer;
    return {
      allocate: [params.address],
      instructions: [
        MPLTokenMetadata.createCreateMetadataAccountV3Instruction(
          {
            payer: params.payer,
            mint: params.mint,
            metadata: params.address,
            mintAuthority: params.mintAuthority,
            updateAuthority: params.updateAuthority,
          },
          {
            createMetadataAccountArgsV3: {
              data: {
                name: params.name,
                symbol: params.symbol,
                uri: params.uri,
                sellerFeeBasisPoints: 0,
                creators: [
                  {
                    address: params.mintAuthority,
                    verified: true,
                    share: 100,
                  },
                ],
                collection: params.collectionMint
                  ? { key: params.collectionMint, verified: false }
                  : null,
                uses: null,
              },
              isMutable: true,
              collectionDetails: params.isCollection
                ? { __kind: "V1" as const, size: 0 }
                : null,
            },
          },
        ),
      ],
    };
  }

  export function updateMetadataAccount(params: {
    address: PublicKey;
    updateAuthority: PublicKey;
    data: MPLTokenMetadata.DataV2;
  }): TransactionBlock {
    return {
      instructions: [
        MPLTokenMetadata.createUpdateMetadataAccountV2Instruction(
          {
            metadata: params.address,
            updateAuthority: params.updateAuthority,
          },
          {
            updateMetadataAccountArgsV2: {
              data: params.data,
              updateAuthority: params.updateAuthority,
              isMutable: true,
              primarySaleHappened: null,
            },
          },
        ),
      ],
    };
  }

  export function createNFTMasterEdition(params: {
    payer: PublicKey;
    mint: PublicKey;
    address?: PublicKey;
    metadata?: PublicKey;
    mintAuthority?: PublicKey;
    updateAuthority?: PublicKey;
    programId?: PublicKey;
  }): TransactionBlock {
    params.address ??= PDA.masterEdition(params.mint);
    params.metadata ??= PDA.tokenMetadata(params.mint);
    params.mintAuthority ??= params.payer;
    params.updateAuthority ??= params.payer;
    params.programId ??= SPLToken.TOKEN_PROGRAM_ID;
    return {
      allocate: [params.address],
      instructions: [
        MPLTokenMetadata.createCreateMasterEditionV3Instruction(
          {
            edition: params.address,
            mint: params.mint,
            mintAuthority: params.mintAuthority,
            updateAuthority: params.updateAuthority,
            payer: params.payer,
            metadata: params.metadata,
            tokenProgram: params.programId,
          },
          { createMasterEditionArgs: { maxSupply: 0 } },
        ),
      ],
    };
  }

  export function verifyNFTCollectionItem(params: {
    payer: PublicKey;
    metadata: PublicKey;
    collectionAuthority?: PublicKey;
    collectionMint: PublicKey;
    collectionMetadata?: PublicKey;
    collectionMasterEdition?: PublicKey;
  }): TransactionBlock {
    params.collectionAuthority ??= params.payer;
    params.collectionMetadata ??= PDA.tokenMetadata(params.collectionMint);
    params.collectionMasterEdition ??= PDA.masterEdition(params.collectionMint);
    return {
      instructions: [
        MPLTokenMetadata.createVerifySizedCollectionItemInstruction({
          metadata: params.metadata,
          collectionAuthority: params.collectionAuthority,
          payer: params.payer,
          collectionMint: params.collectionMint,
          collection: params.collectionMetadata,
          collectionMasterEditionAccount: params.collectionMasterEdition,
        }),
      ],
    };
  }

  export function unverifyNFTCollectionItem(params: {
    payer: PublicKey;
    metadata: PublicKey;
    collectionAuthority?: PublicKey;
    collectionMint: PublicKey;
    collectionMetadata?: PublicKey;
    collectionMasterEdition?: PublicKey;
  }): TransactionBlock {
    params.collectionAuthority ??= params.payer;
    params.collectionMetadata ??= PDA.tokenMetadata(params.collectionMint);
    params.collectionMasterEdition ??= PDA.masterEdition(params.collectionMint);
    return {
      instructions: [
        MPLTokenMetadata.createUnverifySizedCollectionItemInstruction({
          metadata: params.metadata,
          collectionAuthority: params.collectionAuthority,
          payer: params.payer,
          collectionMint: params.collectionMint,
          collection: params.collectionMetadata,
          collectionMasterEditionAccount: params.collectionMasterEdition,
        }),
      ],
    };
  }

  export function burnNFT(params: {
    owner: PublicKey;
    mint: PublicKey;
    address?: PublicKey;
    metadata?: PublicKey;
    masterEdition?: PublicKey;
    collectionMint?: PublicKey | null;
    programId?: PublicKey;
  }): TransactionBlock {
    params.programId ??= SPLToken.TOKEN_PROGRAM_ID;
    params.address ??= PDA.token(params.mint, params.owner, params.programId);
    params.metadata ??= PDA.tokenMetadata(params.mint);
    params.masterEdition ??= PDA.masterEdition(params.mint);
    return {
      instructions: [
        MPLTokenMetadata.createBurnNftInstruction({
          metadata: params.metadata,
          owner: params.owner,
          mint: params.mint,
          tokenAccount: params.address,
          splTokenProgram: params.programId,
          masterEditionAccount: params.masterEdition,
          collectionMetadata: params.collectionMint
            ? PDA.tokenMetadata(params.collectionMint)
            : undefined,
        }),
      ],
    };
  }

  export function createMerkelTree(params: {
    owner: PublicKey;
    keypair: Keypair;
    auth?: PublicKey;
    space: number;
    lamports: number;
    maxBufferSize: number;
    maxDepth: number;
  }): TransactionBlock {
    params.auth ??= PDA.merkleTreeAuthority(params.keypair.publicKey);
    return {
      signers: [params.keypair],
      allocate: [params.keypair.publicKey, params.auth],
      instructions: [
        SystemProgram.createAccount({
          fromPubkey: params.owner,
          newAccountPubkey: params.keypair.publicKey,
          lamports: params.lamports,
          space: params.space,
          programId: SPLCompession.SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
        }),
        MPLBubblegum.createTree(
          {
            maxBufferSize: params.maxBufferSize,
            maxDepth: params.maxDepth,
            public: false,
          },
          {
            merkleTree: params.keypair.publicKey,
            treeAuthority: params.auth,
            treeCreator: params.owner,
            payer: params.owner,
            logWrapper: SPLCompession.SPL_NOOP_PROGRAM_ID,
            compressionProgram:
              SPLCompession.SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
          },
          MPLBubblegum.PROGRAM_ID,
        ),
      ],
    };
  }

  export function mintCNFT(params: {
    payer: PublicKey;
    tree: PublicKey;
    auth?: PublicKey;
    to?: PublicKey;
    name: string;
    symbol: string;
    uri: string;
    collectionMint?: PublicKey;
  }): TransactionBlock {
    params.to ??= params.payer;
    params.auth ??= PDA.merkleTreeAuthority(params.tree);

    const nft: MPLBubblegum.MetadataArgsFields = {
      name: params.name,
      symbol: params.symbol,
      uri: params.uri,
      sellerFeeBasisPoints: 0,
      creators: [],
      tokenProgramVersion: new MPLBubblegum.TokenProgramVersion.Original(),
      tokenStandard: new MPLBubblegum.TokenStandard.NonFungible(),
      uses: null,
      editionNonce: null,
      collection: params.collectionMint
        ? {
            key: params.collectionMint,
            verified: false,
          }
        : null,
      primarySaleHappened: false,
      isMutable: true,
    };
    return {
      instructions: [
        params.collectionMint
          ? MPLBubblegum.mintToCollectionV1(
              { metadataArgs: nft },
              {
                merkleTree: params.tree,
                treeAuthority: params.auth,
                treeDelegate: params.payer,
                payer: params.payer,
                leafDelegate: params.to,
                leafOwner: params.to,
                compressionProgram:
                  SPLCompession.SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
                logWrapper: SPLCompession.SPL_NOOP_PROGRAM_ID,
                collectionAuthority: params.payer,
                collectionAuthorityRecordPda: MPLBubblegum.PROGRAM_ID,
                collectionMint: params.collectionMint,
                collectionMetadata: PDA.tokenMetadata(params.collectionMint),
                editionAccount: PDA.masterEdition(params.collectionMint),
                bubblegumSigner: PDA.bublegumSigner("collection_cpi"),
                tokenMetadataProgram: MPLTokenMetadata.PROGRAM_ID,
                systemProgram: SystemProgram.programId,
              },
            )
          : MPLBubblegum.mintV1(
              { message: nft },
              {
                merkleTree: params.tree,
                treeAuthority: params.auth,
                treeDelegate: params.payer,
                payer: params.payer,
                leafDelegate: params.to,
                leafOwner: params.to,
                compressionProgram:
                  SPLCompession.SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
                logWrapper: SPLCompession.SPL_NOOP_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
              },
            ),
      ],
    };
  }

  export function transferCNFT(params: {
    to: PublicKey;
    tree: PublicKey;
    auth?: PublicKey;
    leaf: {
      id: PublicKey;
      owner: PublicKey;
      delegate: PublicKey;
      index: number;
      nonce: BN;
      proofPath: PublicKey[];
      rootHash: Uint8Array;
      dataHash: Uint8Array;
      creatorHash: Uint8Array;
    };
  }): TransactionBlock {
    params.auth ??= PDA.merkleTreeAuthority(params.tree);

    return {
      instructions: [
        MPLBubblegum.transfer(
          {
            root: [...params.leaf.rootHash],
            dataHash: [...params.leaf.dataHash],
            creatorHash: [...params.leaf.creatorHash],
            nonce: params.leaf.nonce,
            index: params.leaf.index,
          },
          {
            newLeafOwner: params.to,
            leafOwner: params.leaf.owner,
            leafDelegate: params.leaf.delegate,
            merkleTree: params.tree,
            treeAuthority: params.auth,
            compressionProgram:
              SPLCompession.SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
            logWrapper: SPLCompession.SPL_NOOP_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
            proof: params.leaf.proofPath,
          },
        ),
      ],
    };
  }

  export function burnCNFT(params: {
    tree: PublicKey;
    auth?: PublicKey;
    leaf: {
      id: PublicKey;
      owner: PublicKey;
      delegate: PublicKey;
      index: number;
      nonce: BN;
      proofPath: PublicKey[];
      rootHash: Uint8Array;
      dataHash: Uint8Array;
      creatorHash: Uint8Array;
    };
  }): TransactionBlock {
    params.auth ??= PDA.merkleTreeAuthority(params.tree);

    return {
      instructions: [
        MPLBubblegum.burn(
          {
            root: [...params.leaf.rootHash],
            dataHash: [...params.leaf.dataHash],
            creatorHash: [...params.leaf.creatorHash],
            nonce: params.leaf.nonce,
            index: params.leaf.index,
          },
          {
            leafOwner: params.leaf.owner,
            leafDelegate: params.leaf.delegate,
            merkleTree: params.tree,
            treeAuthority: params.auth,
            compressionProgram:
              SPLCompession.SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
            logWrapper: SPLCompession.SPL_NOOP_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
            proof: params.leaf.proofPath,
          },
        ),
      ],
    };
  }

  export function redeemCNFT(params: {
    tree: PublicKey;
    auth?: PublicKey;
    voucher?: PublicKey;
    leaf: {
      id: PublicKey;
      owner: PublicKey;
      delegate: PublicKey;
      index: number;
      nonce: BN;
      proofPath: PublicKey[];
      rootHash: Uint8Array;
      dataHash: Uint8Array;
      creatorHash: Uint8Array;
    };
  }): TransactionBlock {
    params.auth ??= PDA.merkleTreeAuthority(params.tree);
    params.voucher ??= PDA.CNFTVoucher(params.tree, params.leaf.index);

    return {
      allocate: [params.voucher],
      instructions: [
        MPLBubblegum.redeem(
          {
            root: [...params.leaf.rootHash],
            dataHash: [...params.leaf.dataHash],
            creatorHash: [...params.leaf.creatorHash],
            nonce: params.leaf.nonce,
            index: params.leaf.index,
          },
          {
            voucher: params.voucher,
            leafOwner: params.leaf.owner,
            leafDelegate: params.leaf.delegate,
            merkleTree: params.tree,
            treeAuthority: params.auth,
            compressionProgram:
              SPLCompession.SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
            logWrapper: SPLCompession.SPL_NOOP_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
            proof: params.leaf.proofPath,
          },
        ),
      ],
    };
  }

  export function uncompressCNFT(params: {
    tree: PublicKey;
    auth?: PublicKey;
    voucher?: PublicKey;
    leaf: {
      id: PublicKey;
      owner: PublicKey;
      delegate: PublicKey;
      index: number;
      nonce: BN;
      proofPath: PublicKey[];
      rootHash: Uint8Array;
      dataHash: Uint8Array;
      creatorHash: Uint8Array;
      metadata: MPLBubblegum.MetadataArgs;
    };
    mint?: PublicKey;
    tokenAccount?: PublicKey;
    metadata?: PublicKey;
    masterEdition?: PublicKey;
  }): TransactionBlock {
    params.auth ??= PDA.merkleTreeAuthority(params.tree);
    params.voucher ??= PDA.CNFTVoucher(params.tree, params.leaf.index);

    const mintAuthority = PDA.CNFTMintAuthority(params.tree, params.leaf.nonce);

    params.mint ??= params.leaf.id;
    params.tokenAccount ??= PDA.token(params.mint, params.leaf.owner);
    params.metadata ??= PDA.tokenMetadata(params.mint);
    params.masterEdition ??= PDA.masterEdition(params.mint);

    return {
      allocate: [
        params.mint,
        params.tokenAccount,
        params.metadata,
        params.masterEdition,
      ],
      instructions: [
        MPLBubblegum.decompressV1(
          {
            metadata: params.leaf.metadata,
          },
          {
            voucher: params.voucher,
            leafOwner: params.leaf.owner,
            tokenAccount: params.tokenAccount,
            mint: params.mint,
            mintAuthority,
            metadata: params.metadata,
            masterEdition: params.masterEdition,
            sysvarRent: SYSVAR_RENT_PUBKEY,
            tokenMetadataProgram: MPLTokenMetadata.PROGRAM_ID,
            tokenProgram: SPLToken.TOKEN_PROGRAM_ID,
            associatedTokenProgram: SPLToken.ASSOCIATED_TOKEN_PROGRAM_ID,
            logWrapper: SPLCompession.SPL_NOOP_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
          },
        ),
      ],
    };
  }

  export function updateCNFTMetadata(params: {
    payer: PublicKey;
    tree: PublicKey;
    auth?: PublicKey;
    leaf: {
      id: PublicKey;
      owner: PublicKey;
      delegate: PublicKey;
      index: number;
      nonce: BN;
      proofPath: PublicKey[];
      rootHash: Uint8Array;
      dataHash: Uint8Array;
      creatorHash: Uint8Array;
      metadata: MPLBubblegum.MetadataArgs;
    };
    update: {
      name?: string;
      symbol?: string;
      uri: string;
    };
  }): TransactionBlock {
    params.auth ??= PDA.merkleTreeAuthority(params.tree);

    let collectionMint = MPLBubblegum.PROGRAM_ID;
    let collectionMetadata = MPLBubblegum.PROGRAM_ID;
    if (params.leaf.metadata.collection) {
      collectionMint = params.leaf.metadata.collection.key;
      collectionMetadata = PDA.tokenMetadata(collectionMint);
    }

    return {
      instructions: [
        MPLBubblegum.updateMetadata(
          {
            root: [...params.leaf.rootHash],
            nonce: params.leaf.nonce,
            index: params.leaf.index,
            currentMetadata: params.leaf.metadata,
            updateArgs: {
              name: params.update.name ?? null,
              symbol: params.update.symbol ?? null,
              uri: params.update.uri,
              creators: null,
              sellerFeeBasisPoints: null,
              primarySaleHappened: null,
              isMutable: null,
            },
          },
          {
            leafOwner: params.leaf.owner,
            leafDelegate: params.leaf.delegate,
            merkleTree: params.tree,
            treeAuthority: params.auth,
            compressionProgram:
              SPLCompession.SPL_ACCOUNT_COMPRESSION_PROGRAM_ID,
            logWrapper: SPLCompession.SPL_NOOP_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
            authority: params.payer,
            collectionMint: collectionMint,
            collectionMetadata: collectionMetadata,
            collectionAuthorityRecordPda: MPLBubblegum.PROGRAM_ID,
            payer: params.payer,
            tokenMetadataProgram: MPLTokenMetadata.PROGRAM_ID,
            proof: params.leaf.proofPath,
          },
        ),
      ],
    };
  }
}
