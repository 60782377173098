const postToSlack = async (msg, room, user, adapter) => {
  let prepend = 'Anon user '
  if (user) {
    if (user.loginMethod === 'phone') {
      prepend = user?.phone + ' '
    } else {
      prepend = user?.publicKey.toString() + ' ' 
      if (adapter) {
        prepend +=  ` (${adapter?.wallet?.adapter?.name}) `
      }
    }
  } 
  msg = prepend + msg
  room = room || "xp" 
  let url = `${process.env.REACT_APP_HNGR_API}/fcsc/post-to-slack`
  let headers = {
    "Content-Type": "application/json",
  }
  let params = { msg: msg , room:room }
  await fetch(url, {
    method: "post",
    headers: headers,
    body: JSON.stringify(params),
  })
}

export default postToSlack
