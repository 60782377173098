import {
  getSeed,
  getPrivateKey,
  getPublicKey,
  encryptPrivate,
  decryptPrivate,
  encryptShared,
  decryptShared,
} from "./crypt";
import { encodeHex, decodeSeed, decodeMessage, decodePublicKey } from "./codec";
import { Identity, JSONMetadata } from "@captainxyz/solana-core";

export interface EncodedSealedSecretLegacy {
  type: "sealed";
  seed: string;
  message: string;
  onUnseal?: JSONMetadata;
}

export interface EncodedSealedSecret {
  protocol: "tamperproof";
  version: [1, 0];
  state: "sealed";
  seed: string;
  message: string;
  onUnseal?: JSONMetadata;
}

export class SealedSecret {
  readonly type = "sealed";

  readonly seed: Uint8Array;
  readonly message: Uint8Array;
  readonly onUnseal?: JSONMetadata;

  constructor(seed: Uint8Array, message: Uint8Array, onUnseal?: JSONMetadata) {
    this.seed = seed;
    this.message = message;
    this.onUnseal = onUnseal;
  }

  static async create(
    message: string,
    identity: Identity,
    onUnseal?: JSONMetadata
  ): Promise<SealedSecret> {
    const seed = getSeed();
    const privateKey = await getPrivateKey(seed, identity);
    return new SealedSecret(
      seed,
      await encryptPrivate(privateKey, message),
      onUnseal
    );
  }

  static decode(payload: any): SealedSecret {
    if (getState(payload) !== "sealed") {
      throw new Error("Expected secret of type 'sealed'");
    }
    return new SealedSecret(
      decodeSeed(payload.seed),
      decodeMessage(payload.message),
      payload.onUnseal
    );
  }

  encode(): EncodedSealedSecret {
    return {
      protocol: "tamperproof",
      version: [1, 0],
      state: this.type,
      seed: encodeHex(this.seed),
      message: encodeHex(this.message),
      onUnseal: this.onUnseal,
    };
  }

  async getPublicKey(identity: Identity): Promise<Uint8Array> {
    const privateKey = await getPrivateKey(this.seed, identity);
    return await getPublicKey(privateKey);
  }

  async unseal(
    identity: Identity,
    holderKey: Uint8Array
  ): Promise<UnsealedSecret> {
    const privateKey = await getPrivateKey(this.seed, identity);
    const message = await decryptPrivate(privateKey, this.message);
    return new UnsealedSecret(
      this.seed,
      await encryptShared(privateKey, holderKey, message),
      await getPublicKey(privateKey),
      holderKey
    );
  }
}

export interface EncodedUnsealedSecretLegacy {
  type: "unsealed";
  seed: string;
  message: string;
  issuerKey: string;
  holderKey: string;
}

export interface EncodedUnsealedSecret {
  protocol: "tamperproof";
  version: [1, 0];
  state: "unsealed";
  seed: string;
  message: string;
  issuerKey: string;
  holderKey: string;
}

export class UnsealedSecret {
  readonly type = "unsealed";

  readonly seed: Uint8Array;
  readonly message: Uint8Array;
  readonly issuerKey: Uint8Array;
  readonly holderKey: Uint8Array;

  constructor(
    seed: Uint8Array,
    message: Uint8Array,
    issuerKey: Uint8Array,
    holderKey: Uint8Array
  ) {
    this.seed = seed;
    this.message = message;
    this.issuerKey = issuerKey;
    this.holderKey = holderKey;
  }

  static decode(payload: any): UnsealedSecret {
    if (getState(payload) !== "unsealed") {
      throw new Error("Expected secret of type 'unsealed'");
    }
    return new UnsealedSecret(
      decodeSeed(payload.seed),
      decodeMessage(payload.message),
      decodePublicKey(payload.issuerKey),
      decodePublicKey(payload.holderKey)
    );
  }

  encode(): EncodedUnsealedSecret {
    return {
      protocol: "tamperproof",
      version: [1, 0],
      state: this.type,
      seed: encodeHex(this.seed),
      message: encodeHex(this.message),
      issuerKey: encodeHex(this.issuerKey),
      holderKey: encodeHex(this.holderKey),
    };
  }

  async decrypt(identity: Identity): Promise<string> {
    const privateKeyA = await getPrivateKey(this.seed, identity);
    const publicKeyA = await getPublicKey(privateKeyA);
    const publicKeyB = this.holderKey.every(
      (value: number, index: number) => value == publicKeyA[index]
    )
      ? this.issuerKey
      : this.holderKey;
    const message = await decryptShared(privateKeyA, publicKeyB, this.message);
    return Buffer.from(message).toString("utf-8");
  }
}

export function getState(
  secret:
    | EncodedSealedSecret
    | EncodedUnsealedSecret
    | EncodedSealedSecretLegacy
    | EncodedUnsealedSecretLegacy
): "sealed" | "unsealed" {
  // @ts-ignore
  return secret.state ?? secret.type;
}
