import classNames from "classnames"
import styles from "./Loading.module.scss"

const Loading = () => (
  <div className={classNames(styles.loadingContainer, "loading")}>
    <img
      src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
      alt="spinner"
    />
  </div>
)

export default Loading
