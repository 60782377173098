import { useNavigate } from "react-router-dom"
import styles from "./MobileEventCard.module.scss"
import { useEffect, useState } from "react"

const MobileEventCard = ({ event }) => {
  const navigate = useNavigate()
  const [fullEvent, setFullEvent] = useState(event)
  const [error, setError] = useState(false)

  // since we use this in multiple places, with multiple event formats
  useEffect(() => {
    if (!event?.title) {
      getEvent()
    }
  }, [event]) // eslint-disable-line react-hooks/exhaustive-deps

  const getEvent = async () => {
    try {
      const endpoint = "/api/stagehand/event"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`
      let headers = {
        "Content-Type": "application/json",
      }
      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ event_id: event.event_id }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      if (response.error) {
        setError(true)
        return
      } else {
        setFullEvent(response)
      }
    } catch (err) {
      console.error(err)
    }
  }

  if (error) {
    return null
  }

  return (
    <div
      className={styles.mobileEventCard}
      onClick={() => navigate(`/event/${event?.event_id}`)}
    >
      <div className={styles.mobileEventDate}>
        <span className={styles.mobileMonth}>{fullEvent?.date_month}</span>
        <span className={styles.mobileDay}>{fullEvent?.date_day}</span>
        <span className={styles.mobileTime}>
          {new Date(fullEvent?.date_local?.split(",")[0]).toLocaleDateString(
            "en-US",
            {
              weekday: "short",
            }
          )}{" "}
          • {fullEvent?.date_formatted?.split(" • ")[1]}
        </span>
      </div>
      <div className={styles.mobileRightContent}>
        <h3>{fullEvent?.title}</h3>
        <p>
          <span>{fullEvent?.venue_name}</span> •{" "}
          <span>{fullEvent?.venue_city}</span> •{" "}
          <span>{fullEvent?.venue_state}</span>
        </p>
        <p className={styles.mobileTicketPrice}>
          {" "}
          from {(fullEvent?.min_ticket_price / 100).toFixed(2)}
        </p>
      </div>
    </div>
  )
}

export default MobileEventCard
