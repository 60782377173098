import styles from "./Testimonials.module.scss"

const Testimonials = ({ testimonials }) => {
  if (!testimonials) {
    return null
  }
  return (
    <div className={styles.testimonialSection}>
      <h1>Why Fans Love XP</h1>
      <div className={styles.testimonialCarousel}>
        {testimonials.map((testimonial, index) => (
          <div className={styles.testimonialCard} key={index}>
            <img src={testimonial.image} alt={testimonial.name} />
            <div className={styles.testimonialContent}>
              <div className={styles.testimonialDescription}>
                {testimonial.description}
              </div>
              <div>
                <div className={styles.testimonialName}>{testimonial.name}</div>
                <div className={styles.testimonialShow}>
                  Bought tickets to {testimonial.show}
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* and a second time for continuous animation */}
        {window.innerWidth > 960 &&
          testimonials.map((testimonial, index) => (
            <div className={styles.testimonialCard} key={index}>
              <img src={testimonial.image} alt={testimonial.name} />
              <div className={styles.testimonialContent}>
                <div className={styles.testimonialDescription}>
                  {testimonial.description}
                </div>
                <div>
                  <div className={styles.testimonialName}>
                    {testimonial.name}
                  </div>
                  <div className={styles.testimonialShow}>
                    Bought tickets to {testimonial.show}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default Testimonials
