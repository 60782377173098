import styles from "./ExtendModal.module.scss"
import { Button, Modal } from "../components"
import { X } from "../css/icons"
import { useNavigate } from "react-router-dom"
import classNames from "classnames"

const ExtendSuccess = ({
  discount,
  minimum,
  onClose,
  expiration,
  setExtendSuccess,
}) => {
  const exp = new Date(expiration)
  const add30 = new Date(exp.setDate(exp.getDate() + 30))

  const navigate = useNavigate()

  return (
    <Modal onClose={onClose} width="medium">
      <div className={styles.extendModal}>
        <div className={styles.navigation}>
          <div
            className={classNames(styles.circle, styles.close)}
            onClick={() => onClose()}
          >
            <X onClick={onClose} />
          </div>
        </div>
        <h1>Your Reward Has Been Extended!</h1>
        <p>
          We've added an extra 30 days to your reward's expiration. Enjoy it at
          your next checkout.
        </p>
        <div className={styles.imageContainer}>
          <img
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Redemption+Image.png"
            alt="discount"
          />
          <div className={styles.info}>
            <div className={styles.discountAmount}>{discount}</div>
            {minimum && (
              <div className={styles.minimum}>
                <span>{minimum} Minimum Spend</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.discountDetails}>
          <div className={styles.extend}>
            <div className={styles.extendContent}>
              <div className={styles.extendHeader}>Time extended!</div>
            </div>
          </div>
          <div className={styles.detail}>
            <span className={styles.expirationLabel}>Expiration</span>
            <span className={styles.expiration}>
              {add30.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                timeZone: "UTC",
              })}
            </span>
          </div>
        </div>

        <Button
          onClick={() => {
            setExtendSuccess(false)
            onClose()
            navigate("/events")
          }}
          variant="blue"
          fullWidth
          className={styles.button}
        >
          Browse Events
        </Button>
      </div>
    </Modal>
  )
}

export default ExtendSuccess
