import React, { useEffect, useState, useRef } from "react"
import { Layout } from "./components"
import { initOnRamp } from "@coinbase/cbpay-js"

function Test() {
  const [isReady, setIsReady] = useState(false)
  const [destinationWalletAddress, setDestinationWalletAddress] = useState(null)
  const onrampInstance = useRef()

  useEffect(() => {
    //initOnRamp parameters
    const options: InitOnRampOptions = {
      appId: "20bc7a8e-0e6c-4796-86d2-86d6b163fdf9",
      target: "#cbpay-button-container",
      widgetParameters: {
        destinationWallets: [
          {
            address: destinationWalletAddress,
            blockchains: ["solana"],
          },
        ],
      },
      onSuccess: () => {
        alert("success")
        // handle navigation when user successfully completes the flow
      },
      onExit: () => {
        alert("exit")
        // handle navigation from dismiss / exit events due to errors
      },
      onEvent: (event) => {
        alert("on event")
        console.log("event", event)
        // event stream
      },
      experienceLoggedIn: "embedded",
      experienceLoggedOut: "popup",
    }

    // instance.destroy() should be called before initOnramp if there is already an instance.
    if (onrampInstance.current) {
      onrampInstance.current.destroy()
    }

    initOnRamp(options, (error, instance) => {
      if (instance) {
        onrampInstance.current = instance
        setIsReady(true)
      }
    })
  }, [destinationWalletAddress])

  return (
    <Layout>
      <div style={{ height: "100vh" }}>
        <a id="cbpay-button-container">
          <img src="https://cdn.hngr.co/cbpay.png" alt="cpay" />
        </a>
        <input onChange={(e) => setDestinationWalletAddress(e.target.value)} />
      </div>
    </Layout>
  )
}

export default Test
