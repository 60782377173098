export const AppleMusic = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="18"
      height="18"
      rx="4.26562"
      fill="url(#paint0_linear_2315_6437)"
    />
    <g filter="url(#filter0_i_2315_6437)">
      <path
        d="M12.3859 10.116V5.89214C12.3859 5.71448 12.2233 5.58132 12.0491 5.61643L7.23175 6.58768C7.07861 6.61855 6.96847 6.75311 6.96847 6.90933V12.9989C6.96847 14.0118 6.16416 14.8415 5.15185 14.873L4.92443 14.8801C4.12445 14.905 3.4624 14.2633 3.4624 13.4629C3.4624 12.7937 3.92338 12.2127 4.57505 12.0606L5.85293 11.7623C6.09688 11.7053 6.26944 11.4878 6.26944 11.2373V4.3588C6.26944 4.09138 6.45771 3.86093 6.71976 3.8076L12.4946 2.63227C12.7996 2.57019 13.085 2.80329 13.085 3.11457V11.8354C13.085 12.7714 12.3579 13.5464 11.4238 13.6061L11.0432 13.6304C10.2811 13.6791 9.62239 13.1039 9.56799 12.3422C9.51176 11.6175 10.002 10.9636 10.7134 10.8144L12.0457 10.5349C12.244 10.4933 12.3859 10.3185 12.3859 10.116Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_2315_6437"
        x="3.4624"
        y="2.62219"
        width="9.62256"
        height="12.493"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.234375" />
        <feGaussianBlur stdDeviation="0.164062" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.631373 0 0 0 0 0.298039 0 0 0 0 0.290196 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2315_6437"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2315_6437"
        x1="9"
        y1="0"
        x2="9"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E96777" />
        <stop offset="1" stopColor="#E63E45" />
      </linearGradient>
    </defs>
  </svg>
)
