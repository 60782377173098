import { useEffect, forwardRef, useImperativeHandle } from "react"
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { useSelector } from "react-redux"
import { CardElement } from "@stripe/react-stripe-js"
import {ExpressCheckoutElement} from '@stripe/react-stripe-js';
import postToSlack from "../postToSlack"

const INPUT_STYLE = {
  base: {
    fontSize: "20px",
    paddingTop: "7px",
    textAlign: "left",
    fontFamily: "sans-serif",
    color: "white",
    "::placeholder": {
      color: "#aab7c4",
      fontWeight: 100,
      fontSize: "20px",
    },
  },
}

const StripeStuff = forwardRef((props, ref) => {
  const user = useSelector((state) => state.user.user)
  const stripe = useStripe()
  const elements = useElements()

  useImperativeHandle(ref, () => ({
    submitPayment,
    update
  }))


  const update = async (event) => {
    let a = await elements.fetchUpdates()
  }


  const applePayCancel = () => {
    postToSlack(`Cancelled Apple Pay modal on ${props.selectedEvent.title}`, "firehose", user)
  }

  const submitExpressPayment = async (event) => {
    let selectedEvent = props.selectedEvent
    let ticketGroup = props.ticketGroup
    let promo = (props.promo/100).toFixed(2)
    let total = (props.total/100).toFixed(2)
    let msg = `${user.first_name} ${user.last_name} ${user.email} ${user.phone} submitted Apple Pay for ${props.quantity} tickets to <https://xp.xyz/event/${selectedEvent?.event_id}|${selectedEvent?.title}>, ${selectedEvent?.date_formatted} ${selectedEvent?.venue_name}, ${selectedEvent?.venue_city}, ${selectedEvent?.venue_state}. Paid $${total} (${props.paymentIntentId}) Promo $${promo} Sec ${ticketGroup?.section?.name} Row ${ticketGroup?.row?.name} (TG ${props.ticket_group_uvid})`
    postToSlack(
      msg,
      "firehose",
      user
    )


    postToSlack(`Submitted Apple Pay on ${props.selectedEvent.title}`, "firehose", user)

    if (!stripe || !elements) {
      return false
    }

    const {error} = await stripe.confirmPayment({
      // `elements` instance used to create the Express Checkout Element
      elements:elements,
      // `clientSecret` from the created PaymentIntent
      clientSecret:props.clientSecret,
      redirect:'if_required',
      payment_method_data: {
        billing_details: {
          email:user?.user?.email
        }
      },
    });
    if(error){
      postToSlack(`Apple Pay payment error ${props.selectedEvent.title}`, "firehose", user)
      alert('Unknown payment error')
    } else {
      props.expressSuccess()
    }
   
  }

  const submitPayment = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    if (event) {
      event.preventDefault()
    }

    if (!stripe || !elements) {
      return false
    }

    var cardElement = elements.getElement("card")

    let billingDetails = {}
    if(user?.user?.first_name){
      billingDetails.name = user?.user?.first_name + " " + user?.user?.last_name
    }
    billingDetails.email = user?.user?.email

    const result = await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: billingDetails,
      },
    })
    //`Elements` instance that was used to create the Payment Element

    if (result.error) {
      postToSlack("Card decline charging stripe " + result.error.message, "firehose", user)
      alert(result.error.message)
      return false
    } else {
      return true
    }
  }

  return (
    <div>
        {props.applePay ? (
          <ExpressCheckoutElement onCancel={()=>applePayCancel()} onConfirm={submitExpressPayment} />
        ) : (
          <form>
            <CardElement
              options={{
                style: INPUT_STYLE,
                classes: {
                  base: "card-number-input",
                },
              }}
            />
          </form>
        )}
    </div>
  )
})

export default StripeStuff
