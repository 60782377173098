import { AngleDown } from "../../css/icons"
import styles from "./Select.module.scss"
import { useState, useRef } from "react"
import classNames from "classnames"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"

const Select = ({ selected, options, setSelected }) => {
  const [showOptions, setShowOptions] = useState(false)

  const selectRef = useRef(null)

  useDetectClickOutside(selectRef, () => setShowOptions(false))

  return (
    <div className={styles.optionsContainer}>
      <div
        onClick={() => setShowOptions(!showOptions)}
        className={classNames(styles.select, showOptions && styles.selectOpen)}
      >
        {selected}
        <AngleDown />
      </div>
      {showOptions && (
        <div className={styles.selectOptions} ref={selectRef}>
          {options.map((option) => (
            <div
              onClick={() => {
                setSelected(option)
                setShowOptions(false)
              }}
              className={classNames(
                styles.option,
                selected === option && styles.selected
              )}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Select
