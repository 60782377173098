import { useSelector } from "react-redux"
import { Button, Input, Modal } from "../components"
import { AngleLeft, ArrowRight, X } from "../css/icons"
import styles from "./ExtendModal.module.scss"
import classNames from "classnames"
import { useState } from "react"

const ExtendModal = ({
  expiration,
  goBack,
  onClose,
  setExtending,
  setExtendSuccess,
}) => {
  const user = useSelector((state) => state.user)
  const exp = new Date(expiration)
  const add30 = new Date(exp.setDate(exp.getDate() + 30))
  const [email, setEmail] = useState("")

  const handleSubmit = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/record-marketing-user`
    let params = {
      wallet: user.user.publicKey,
      email: email,
      url:window.location.href,
      source: "extend",
    }
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    if (resp.ok) {
      let data = await resp.json()
      console.log(data)
    }
  }
  return (
    <Modal onClose={onClose} width="medium">
      <div className={styles.extendModal}>
        <div className={styles.navigation}>
          <div className={styles.circle} onClick={() => goBack()}>
            <AngleLeft />
          </div>
          <div className={styles.circle} onClick={() => onClose()}>
            <X onClick={onClose} />
          </div>
        </div>
        <h1>
          Add an email, add <span className={styles.blue}>30 days</span>
        </h1>
        <p>Add your email & we’ll extend this discount.</p>
        <p>
          You’ll get a reminder before this promo ends, along with additional
          deals, announcements and more!
        </p>
        <div className={styles.expirationRow}>
          <div className={classNames(styles.expiration, styles.expired)}>
            <span className={styles.expLabel}>Expiration Date</span>
            <span className={styles.expDate}>
              {new Date(expiration).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                timeZone: "UTC",
              })}
            </span>
          </div>
          <ArrowRight />
          <div className={classNames(styles.expiration)}>
            <span className={styles.expLabel}>New Expiration Date</span>
            {add30.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              timeZone: "UTC",
            })}
          </div>
        </div>
        <div className={styles.label}>Email</div>
        <Input
          className={styles.input}
          placeholder="Enter your email address"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          onClick={() => {
            // submit
            handleSubmit()
            setExtendSuccess(true)
            setExtending(false)
          }}
          variant="blue"
          fullWidth
          className={styles.button}
        >
          Extend My Discount
        </Button>
      </div>
    </Modal>
  )
}

export default ExtendModal
