import { TransactionInstruction, PublicKey, AccountMeta } from "@solana/web3.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types" // eslint-disable-line @typescript-eslint/no-unused-vars
import { PROGRAM_ID } from "../programId"

export interface SetDecompressableStateArgs {
  decompressableState: types.DecompressibleStateKind
}

export interface SetDecompressableStateAccounts {
  treeAuthority: PublicKey
  treeCreator: PublicKey
}

export const layout = borsh.struct([
  types.DecompressibleState.layout("decompressableState"),
])

/** Sets the `decompressible_state` of a tree. */
export function setDecompressableState(
  args: SetDecompressableStateArgs,
  accounts: SetDecompressableStateAccounts,
  programId: PublicKey = PROGRAM_ID
) {
  const keys: Array<AccountMeta> = [
    { pubkey: accounts.treeAuthority, isSigner: false, isWritable: true },
    { pubkey: accounts.treeCreator, isSigner: true, isWritable: false },
  ]
  const identifier = Buffer.from([18, 135, 238, 168, 246, 195, 61, 115])
  const buffer = Buffer.alloc(1000)
  const len = layout.encode(
    {
      decompressableState: args.decompressableState.toEncodable(),
    },
    buffer
  )
  const data = Buffer.concat([identifier, buffer]).slice(0, 8 + len)
  const ix = new TransactionInstruction({ keys, programId, data })
  return ix
}
