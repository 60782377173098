import { PublicKey } from "@solana/web3.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import BN from "bn.js" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as types from "../types" // eslint-disable-line @typescript-eslint/no-unused-vars
import * as borsh from "@coral-xyz/borsh"

export type V1Fields = {
  id: PublicKey
  owner: PublicKey
  delegate: PublicKey
  nonce: BN
  dataHash: Array<number>
  creatorHash: Array<number>
}
export type V1Value = {
  id: PublicKey
  owner: PublicKey
  delegate: PublicKey
  nonce: BN
  dataHash: Array<number>
  creatorHash: Array<number>
}

export interface V1JSON {
  kind: "V1"
  value: {
    id: string
    owner: string
    delegate: string
    nonce: string
    dataHash: Array<number>
    creatorHash: Array<number>
  }
}

export class V1 {
  static readonly discriminator = 0
  static readonly kind = "V1"
  readonly discriminator = 0
  readonly kind = "V1"
  readonly value: V1Value

  constructor(value: V1Fields) {
    this.value = {
      id: value.id,
      owner: value.owner,
      delegate: value.delegate,
      nonce: value.nonce,
      dataHash: value.dataHash,
      creatorHash: value.creatorHash,
    }
  }

  toJSON(): V1JSON {
    return {
      kind: "V1",
      value: {
        id: this.value.id.toString(),
        owner: this.value.owner.toString(),
        delegate: this.value.delegate.toString(),
        nonce: this.value.nonce.toString(),
        dataHash: this.value.dataHash,
        creatorHash: this.value.creatorHash,
      },
    }
  }

  toEncodable() {
    return {
      V1: {
        id: this.value.id,
        owner: this.value.owner,
        delegate: this.value.delegate,
        nonce: this.value.nonce,
        data_hash: this.value.dataHash,
        creator_hash: this.value.creatorHash,
      },
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromDecoded(obj: any): types.LeafSchemaKind {
  if (typeof obj !== "object") {
    throw new Error("Invalid enum object")
  }

  if ("V1" in obj) {
    const val = obj["V1"]
    return new V1({
      id: val["id"],
      owner: val["owner"],
      delegate: val["delegate"],
      nonce: val["nonce"],
      dataHash: val["data_hash"],
      creatorHash: val["creator_hash"],
    })
  }

  throw new Error("Invalid enum object")
}

export function fromJSON(obj: types.LeafSchemaJSON): types.LeafSchemaKind {
  switch (obj.kind) {
    case "V1": {
      return new V1({
        id: new PublicKey(obj.value.id),
        owner: new PublicKey(obj.value.owner),
        delegate: new PublicKey(obj.value.delegate),
        nonce: new BN(obj.value.nonce),
        dataHash: obj.value.dataHash,
        creatorHash: obj.value.creatorHash,
      })
    }
  }
}

export function layout(property?: string) {
  const ret = borsh.rustEnum([
    borsh.struct(
      [
        borsh.publicKey("id"),
        borsh.publicKey("owner"),
        borsh.publicKey("delegate"),
        borsh.u64("nonce"),
        borsh.array(borsh.u8(), 32, "data_hash"),
        borsh.array(borsh.u8(), 32, "creator_hash"),
      ],
      "V1"
    ),
  ])
  if (property !== undefined) {
    return ret.replicate(property)
  }
  return ret
}
