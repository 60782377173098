export function encodeHex(value: Uint8Array): string {
  return Buffer.from(value).toString("hex");
}

export function decodeMessage(value: any): Uint8Array {
  return decodeHex(value);
}

export function decodeSeed(value: any): Uint8Array {
  return decodeHex(value, 12);
}

export function decodePublicKey(value: any): Uint8Array {
  return decodeHex(value, 65);
}

export function decodeHex(value: any, length?: number): Uint8Array {
  if (typeof value !== "string") {
    throw new TypeError(`Expected string, got ${typeof value}`);
  }
  if (value.length % 2) {
    throw new Error("Invalid unpadded hex");
  }
  const byteLen = value.length / 2;
  if (typeof length === "number" && byteLen != length) {
    throw new Error(`Expected ${length} bytes, got ${byteLen}`);
  }
  const array = new Uint8Array(byteLen);
  for (let i = 0; i < array.length; i++) {
    const j = i * 2;
    const hexByte = value.slice(j, j + 2);
    const byte = Number.parseInt(hexByte, 16);
    if (Number.isNaN(byte) || byte < 0) {
      throw new Error("Invalid byte sequence");
    }
    array[i] = byte;
  }
  return array;
}
