import React from "react"
import { useState } from "react"
import { InlineInput } from "../"
import styles from "./Footer.module.scss"
import { useNavigate } from "react-router-dom"
import { Instagram, Telegram, Tiktok, XLogo } from "../../css/icons"
import classNames from "classnames"

const Footer = ({ className }) => {
  const navigate = useNavigate()
  const [validEmail, setValidEmail] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const validateEmail = (input) => {
    if (/^\S+@\S+\.\S+$/.test(input)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }
  const subscribe = async (email) => {
    if (validEmail) {
      await fetch("https://formspree.io/f/myyqyvpg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      })

      setSubmitted(true)
    }
  }

  return (
    <>
      <div className={classNames(styles.footer, className)}>
        <img
          src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+no+glow+logo.png"
          alt="XP"
          className={styles.logo}
        />
        <div className={styles.footerConnect}>
          <h3>Connect with Us</h3>
          <div>
            <a
              className={styles.socialLink}
              href="https://twitter.com/xpticket"
              target="_blank"
              rel="noreferrer"
            >
              <XLogo />
            </a>
            <a
              href="https://www.instagram.com/xp.tickets/"
              target="_blank"
              rel="noreferrer"
              className={styles.socialLink}
            >
              <Instagram />
            </a>
            <a
              href="https://www.tiktok.com/@xp.tix"
              target="_blank"
              rel="noreferrer"
              className={styles.socialLink}
            >
              <Tiktok />
            </a>
          </div>
        </div>
        <div className={styles.footerContent}>
          <div className={styles.footerLinkContainer}>
            <div className={styles.footerLinkCategory}>
              <div className={styles.footerLinkCategoryHeader}>Get Tickets</div>
              <div
                className={styles.footerLink}
                onClick={() => {
                  navigate("/events")
                }}
              >
                Find Events Near You
              </div>
              <a
                href="https://xpsupport.zendesk.com/hc/en-us/articles/25552755543323-What-is-the-Quality-XPerience-Guarantee"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Quality XPerience Guarantee
              </a>
            </div>
            <div className={styles.footerLinkCategory}>
              <div className={styles.footerLinkCategoryHeader}>Need Help?</div>
              <a
                href="https://xpsupport.zendesk.com/hc/en-us/requests/new"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Contact Support
              </a>
              <a
                href="https://xpsupport.zendesk.com"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Help Center
              </a>
              <a
                href="https://cdn.hngr.co/xptc.pdf"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Terms & Conditions
              </a>
              <a
                href="https://xp-metadata.s3.amazonaws.com/privacy-policy.pdf"
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <div className={styles.footerFormContainer}>
            <div className={styles.footerForm}>
              <h3>It pays to stay in the know.</h3>
              <p>Sign up for giveaways, promotions, and more.</p>
              <InlineInput
                buttonText={submitted ? "Submitted" : "Submit"}
                disabled={submitted}
                onSubmit={subscribe}
                onChange={validateEmail}
                placeholder="Enter your email"
              />
            </div>
            <p className={styles.disclaimer}>
              By signing up, you acknowledge and accept our privacy policy and
              consent to receiving emails.
            </p>
          </div>
        </div>
        <div className={styles.footerLogoContainer}>
          <div className={styles.copyright}>XP © 2024</div>
          <div className={styles.horizontalLine} />
        </div>
      </div>
    </>
  )
}

export default Footer
