import styles from "./EmailCapture.module.scss"
import { useState } from "react"
import { InlineInput } from "../"

const EmailCapture = ({ header, subtitle }) => {
  const [submittedEmail, setSubmittedEmail] = useState(false)
  const subscribe = async (email) => {
    const validEmail = /^\S+@\S+\.\S+$/.test(email)
    if (validEmail) {
      await fetch("https://formspree.io/f/myyqyvpg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      })

      setSubmittedEmail(true)
    }
  }
  return (
    <div className={styles.emailCaptureOuter}>
      <div className={styles.emailCaptureSection}>
        <div className={styles.emailCaptureLeft}>
          <h2>{header}</h2>
          <p>{subtitle}</p>
        </div>
        <div className={styles.emailCaptureRight}>
          <InlineInput
            disabled={submittedEmail}
            placeholder="Enter your email"
            onSubmit={subscribe}
            buttonText={submittedEmail ? "Submitted!" : "Submit"}
          />

          <p>
            By signing up, you acknowledge and accept our privacy policy and
            consent to receiving emails.
          </p>
        </div>
      </div>
    </div>
  )
}

export default EmailCapture
