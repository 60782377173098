import styles from "./SignInModal.module.scss"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setUser, updateUser } from "../reducers/userSlice"

import { Input, Button, Modal } from "../components"
const { augmentUser } = require("../helpers/augmentUser")

const EmailCapture = ({ onClose, startGame }) => {
  const user = useSelector((state) => state.user?.user)
  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)

  const dispatch = useDispatch()

  const validateEmail = (input) => {
    if (/^\S+@\S+\.\S+$/.test(input)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }

  const validateAndSubmitEmail = async () => {
    if (!validEmail) {
      return
    }

    let u2 = {
      wallet: user?.publicKey?.toString(),
      email: email,
      // these will be null but required in api (for now)
      first_name: user?.first_name,
      last_name: user?.last_name,
      phone: user?.phone,
    }
    u2 = await augmentUser(u2)
    dispatch(updateUser(u2))
    dispatch(setUser(u2))
    let merged = { ...user, ...u2 }
    localStorage.setItem("user", JSON.stringify(merged))

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/record-marketing-user`
    let params = {
      wallet: user.publicKey,
      email: email,
      url: window.location.href,
      source: `signup_${window.location.pathname}`,
    }
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    if (resp.ok) {
      let data = await resp.json()
      console.log(data)
      console.log(user)
    }

    startGame && startGame()
    window.EMAIL_CAPTURED = true
    onClose()
  }
  return (
    <Modal
      onClose={() => console.log("required")}
      width="medium"
      className={styles.noOverflow}
    >
      <div className={styles.signInModal}>
        <h1 className={styles.enterCode}>
          Enter your email to claim your prize
        </h1>
        <p> Once you've claimed your prize, it'll be stored in your account.</p>
        <Input
          className={styles.input}
          disableAutocomplete={true}
          placeholder="Email address"
          onChange={(e) => {
            setEmail(e.target.value)
            validateEmail(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              validateAndSubmitEmail()
            }
          }}
          value={email}
          type="search" // disables autofill until we can find a better solution
        ></Input>

        <Button
          fullWidth
          className={styles.modalButton}
          disabled={!validEmail}
          onClick={() => {
            validateAndSubmitEmail()
          }}
        >
          Claim Prize
        </Button>
      </div>
    </Modal>
  )
}

export default EmailCapture
