export const NoChair = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.81297 3.5C3.81297 2.92383 4.12938 2.42578 4.59422 2.1543V6H5.53172V1.9375H6.46922V6H7.40672V2.1543C7.87156 2.42578 8.18797 2.92383 8.18797 3.5V6H9.12547V3.5C9.12547 2.11914 8.00633 1 6.62547 1H5.37547C3.99461 1 2.87547 2.11914 2.87547 3.5V6H3.81297V3.5ZM10.3423 7.67773L10.1333 7.05273C10.0473 6.79688 9.80906 6.625 9.53953 6.625H2.45946C2.18992 6.625 1.95164 6.79688 1.86571 7.05273L1.65672 7.67773C1.52391 8.08203 1.82469 8.5 2.25047 8.5V10.6875C2.25047 10.8594 2.3911 11 2.56297 11H3.18797C3.35985 11 3.50047 10.8594 3.50047 10.6875V8.5H8.50047V10.6875C8.50047 10.8594 8.6411 11 8.81297 11H9.43797C9.60985 11 9.75047 10.8594 9.75047 10.6875V8.5C10.1763 8.5 10.477 8.08203 10.3423 7.67773Z"
      fill="#F7F7F7"
    />
    <path
      d="M0.789571 3.23696L0.790738 3.23786L9.98293 10.3424C9.98303 10.3425 9.98313 10.3426 9.98323 10.3427C10.3096 10.5958 10.7808 10.538 11.0353 10.2104C11.0354 10.2104 11.0354 10.2103 11.0354 10.2102L11.3422 9.81557L11.3423 9.81539C11.5962 9.48849 11.5374 9.01749 11.2104 8.76325L11.2093 8.76234L2.01743 1.65806C2.01717 1.65786 2.01691 1.65766 2.01665 1.65746C1.68973 1.40381 1.21879 1.46274 0.964689 1.78979C0.964644 1.78984 0.964599 1.7899 0.964554 1.78996L0.65781 2.18464L0.657676 2.18481C0.403835 2.51171 0.462617 2.98271 0.789571 3.23696Z"
      fill="#F7F7F7"
      stroke="#3B3D41"
    />
  </svg>
)
