export const RedX = () => (
  <svg
    width="192"
    height="192"
    viewBox="0 0 192 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1029_3817)">
      <path
        d="M95.9998 72.667C83.1101 72.667 72.6665 83.1105 72.6665 96.0003C72.6665 108.89 83.1101 119.334 95.9998 119.334C108.89 119.334 119.333 108.89 119.333 96.0003C119.333 83.1105 108.89 72.667 95.9998 72.667ZM107.441 102.125C107.883 102.568 107.883 103.283 107.441 103.725L103.715 107.441C103.273 107.883 102.558 107.883 102.115 107.441L95.9998 101.269L89.8748 107.441C89.4326 107.883 88.7176 107.883 88.2754 107.441L84.559 103.715C84.1168 103.273 84.1168 102.558 84.559 102.116L90.731 96.0003L84.559 89.8753C84.1168 89.4331 84.1168 88.7181 84.559 88.2759L88.2848 84.5501C88.727 84.1079 89.442 84.1079 89.8842 84.5501L95.9998 90.7315L102.125 84.5595C102.567 84.1173 103.282 84.1173 103.724 84.5595L107.45 88.2853C107.892 88.7275 107.892 89.4425 107.45 89.8847L101.269 96.0003L107.441 102.125Z"
        fill="#D41503"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1029_3817"
        x="-4"
        y="-4"
        width="200"
        height="200"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="8"
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_1029_3817"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="32" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 0.0823529 0 0 0 0 0.0117647 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1029_3817"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1029_3817"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
