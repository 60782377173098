export const Calendar = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.19039 1.58334V2.50001H2.76182C1.97313 2.50001 1.33325 3.11589 1.33325 3.87501V5.25H14.6666V3.87501C14.6666 3.11589 14.0267 2.50001 13.238 2.50001H11.8094V1.58334C11.8094 1.07631 11.3838 0.666672 10.8571 0.666672C10.3303 0.666672 9.90468 1.07631 9.90468 1.58334V2.50001H6.09516V1.58334C6.09516 1.07631 5.66956 0.666672 5.14278 0.666672C4.61599 0.666672 4.19039 1.07631 4.19039 1.58334ZM14.6666 6.16667H1.33325V13.9583C1.33325 14.7175 1.97313 15.3333 2.76182 15.3333H13.238C14.0267 15.3333 14.6666 14.7175 14.6666 13.9583V6.16667Z"
      fill="#FAFAFA"
    />
  </svg>
)
