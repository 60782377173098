import classNames from "classnames"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import styles from "./Layout.module.scss"

const Layout = ({
  children,
  className,
  velvetHeader,
  contentClassName,
  footerClass,
  noScroll,
  showHeader = true,
  showFooter = true,
  hidePageBackground,
  style,
  contentStyle,
  startGame,
  shouldCaptureEmail,
}) => (
  <div className={classNames(styles.pageContainer, className)} style={style}>
    {!hidePageBackground && <div className={styles.pageBackground} />}
    <div>
      {showHeader && (
        <Header
          startGame={startGame}
          velvetHeader={velvetHeader}
          shouldCaptureEmail={shouldCaptureEmail}
        />
      )}
      {!showHeader && (
        <div style={{ display: "none" }}>
          <Header velvetHeader={velvetHeader} />
        </div>
      )}
      <div
        className={classNames(
          styles.contentContainer,
          contentClassName,
          noScroll && styles.noScroll
        )}
        id="layoutContainer"
        style={contentStyle}
      >
        {children}
        {showFooter && <Footer className={footerClass} />}
      </div>
    </div>
  </div>
)

export default Layout
