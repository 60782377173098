export const LogOut = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1026 10.5619L12.5468 16.1177C12.0507 16.6138 11.1909 16.2665 11.1909 15.5555V12.3808H6.69333C6.25349 12.3808 5.89964 12.0269 5.89964 11.5871V8.41232C5.89964 7.97248 6.25349 7.61863 6.69333 7.61863H11.1909V4.44387C11.1909 3.73616 12.0474 3.38562 12.5468 3.88167L18.1026 9.4375C18.4102 9.74836 18.4102 10.251 18.1026 10.5619ZM8.01615 15.9524V14.6296C8.01615 14.4113 7.83757 14.2327 7.6193 14.2327H4.84139C4.25604 14.2327 3.78313 13.7598 3.78313 13.1745V6.82494C3.78313 6.23959 4.25604 5.76669 4.84139 5.76669H7.6193C7.83757 5.76669 8.01615 5.58811 8.01615 5.36984V4.04702C8.01615 3.82876 7.83757 3.65018 7.6193 3.65018H4.84139C3.08865 3.65018 1.66663 5.07221 1.66663 6.82494V13.1745C1.66663 14.9272 3.08865 16.3492 4.84139 16.3492H7.6193C7.83757 16.3492 8.01615 16.1706 8.01615 15.9524Z"
      fill="#FF3D2B"
    />
  </svg>
)
