import styles from "./InlineInput.module.scss"
import Button from "../Button/Button"
import { useState } from "react"
import classNames from "classnames"

const InlineInput = ({
  buttonText,
  className,
  disabled,
  onChange,
  onSubmit,
  placeholder,
}) => {
  const [value, setValue] = useState("")
  return (
    <div className={classNames(styles.border1, className)}>
      <div className={styles.border2}>
        <div className={styles.inlineInputContainer}>
          <input
            autoComplete="new-password"
            type="email"
            placeholder={placeholder}
            onChange={(e) => {
              onChange && onChange(e.target.value)
              setValue(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSubmit(value)
              }
            }}
          />
          <Button rm disabled={disabled} onClick={() => onSubmit(value)}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default InlineInput
