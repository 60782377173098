import FilterButton from "../FilterButton/FilterButton"
import classNames from "classnames"
import styles from "./DatePickerMenu.module.scss"
import { Calendar, CheckMark } from "../../css/icons"
import CustomDatePicker from "../DatePicker/DatePicker"

const DatePickerMenu = ({
  date,
  dateMenuRef,
  dates,
  endDate,
  setDate,
  searchParams,
  setEndDate,
  setSearchParams,
  setShowDateMenu,
  setShowDatePicker,
  setStartDate,
  showDateMenu,
  showDatePicker,
  showEndDate,
  startDate,
}) => {
  return (
    <div className={classNames(styles.menuContainer)}>
      <FilterButton
        id="DateDropdownButton"
        icon={<Calendar />}
        onClick={() => {
          setShowDateMenu(!showDateMenu)
        }}
        buttonCopy={
          startDate
            ? `${startDate.toLocaleDateString("en-us", {
                month: "short",
                day: "numeric",
              })} ${
                showEndDate &&
                "- " +
                  endDate?.toLocaleDateString("en-us", {
                    month: "short",
                    day: "numeric",
                  })
              }`
            : date
        }
      ></FilterButton>
      {showDateMenu && (
        <div
          className={classNames(
            styles.eventTypeMenuContainer,
            styles.mobileDateContainer
          )}
          ref={dateMenuRef}
        >
          {dates.map((d) => {
            return (
              <div
                className={classNames(
                  styles.eventTypeMenuItem,
                  date === d && styles.selectedEventType
                )}
                onClick={() => {
                  if (d === "Custom Dates") {
                    setShowDatePicker(true)
                    setShowDateMenu(false)
                    setDate("Custom Dates")
                  } else if (d === "This Week") {
                    const now = new Date()
                    const currentDay = now.getDay()
                    const diffToMonday = (currentDay + 6) % 7
                    const firstDay = new Date(now)
                    firstDay.setDate(now.getDate() - diffToMonday)
                    const lastDay = new Date(firstDay)
                    lastDay.setDate(firstDay.getDate() + 6)
                    const newSearchParams = new URLSearchParams(searchParams)
                    newSearchParams.set("min_date", firstDay)
                    newSearchParams.set("max_date", lastDay)
                    setSearchParams(newSearchParams)
                    setDate(d)
                    setShowDateMenu(false)
                  } else if (d === "This Weekend") {
                    const now = new Date()
                    const currentDay = now.getDay()
                    const diffToSaturday = (6 - currentDay + 7) % 7
                    const firstDay = new Date(now)
                    firstDay.setDate(now.getDate() + diffToSaturday)
                    const lastDay = new Date(firstDay)
                    lastDay.setDate(firstDay.getDate() + 1)
                    const newSearchParams = new URLSearchParams(searchParams)
                    newSearchParams.set("min_date", firstDay)
                    newSearchParams.set("max_date", lastDay)
                    setSearchParams(newSearchParams)
                    setDate(d)
                    setShowDateMenu(false)
                  } else if (d === "This Month") {
                    const now = new Date()
                    const firstDay = new Date(
                      now.getFullYear(),
                      now.getMonth(),
                      1
                    )
                    const lastDay = new Date(
                      now.getFullYear(),
                      now.getMonth() + 1,
                      0
                    )
                    const newSearchParams = new URLSearchParams(searchParams)
                    newSearchParams.set("min_date", firstDay)
                    newSearchParams.set("max_date", lastDay)
                    setSearchParams(newSearchParams)
                    setDate(d)
                    setShowDateMenu(false)
                  } else {
                    const newSearchParams = new URLSearchParams(searchParams)
                    newSearchParams.delete("min_date")
                    newSearchParams.delete("max_date")
                    setSearchParams(newSearchParams)
                    setStartDate(null)
                    setEndDate(null)
                    setDate(d)
                    setShowDateMenu(false)
                  }
                }}
              >
                <span>{d}</span>
                {date === d && <CheckMark />}
              </div>
            )
          })}
        </div>
      )}
      {showDatePicker && (
        <CustomDatePicker
          className={styles.datePickerContainer}
          containerStartDate={startDate}
          setDates={(start, end) => {
            const newSearchParams = new URLSearchParams(searchParams)
            if (start) {
              newSearchParams.set("min_date", start)
            }
            if (end) {
              newSearchParams.set("max_date", end)
            }
            setSearchParams(newSearchParams)
          }}
          containerEndDate={endDate}
          onClose={() => setShowDatePicker(false)}
        />
      )}
    </div>
  )
}

export default DatePickerMenu
