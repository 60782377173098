export const Crossroads = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.0883 7.57756L26.8326 5.32131C26.5201 5.00881 26.0961 4.83329 25.6539 4.83329H17.6659V3.99996C17.6659 3.53954 17.293 3.16663 16.8326 3.16663H15.1659C14.7055 3.16663 14.3326 3.53954 14.3326 3.99996V4.83329H5.58255C4.89245 4.83329 4.33255 5.39319 4.33255 6.08329V10.25C4.33255 10.9401 4.89245 11.5 5.58255 11.5H25.6539C26.0961 11.5 26.5195 11.3244 26.8326 11.0119L29.0883 8.75621C29.4138 8.43017 29.4138 7.90308 29.0883 7.57756ZM14.3326 29C14.3326 29.4604 14.7055 29.8333 15.1659 29.8333H16.8326C17.293 29.8333 17.6659 29.4604 17.6659 29V23.1666H14.3326V29ZM26.4159 14.8333H17.6659V13.1666H14.3326V14.8333H6.34453C5.90234 14.8333 5.47891 15.0088 5.16589 15.3213L2.91016 17.5776C2.58464 17.9031 2.58464 18.4307 2.91016 18.7562L5.16589 21.0119C5.47839 21.3244 5.90234 21.5 6.34453 21.5H26.4159C27.106 21.5 27.6659 20.9401 27.6659 20.25V16.0833C27.6659 15.3932 27.106 14.8333 26.4159 14.8333Z"
      fill="#8B8B8B"
    />
  </svg>
)
